import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDates } from '../../../hooks/useDates';
import GenericPromises from '../../../api/GenericPromises';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../interfaces/Security/menu';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { usePermissions } from '../../../hooks/usePermissions';
import { Header } from '../../../components/Header';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { ButtonLoading, PrimaryButton } from '../../../theme/buttons';
import DataTable from '../../../components/Tables/GridTableMaterialUI/DataTable';
import { Project } from '../../../interfaces/Projects/projects';
import { Autocomplete, Box, Button, ListItemButton, Popover, TextField } from '@mui/material';
import { useFormulaEvaluator } from '../../../hooks/useFormulaEvaluator';
import FunctionsIcon from '@mui/icons-material/Functions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import { useCalculateFormulasProjects } from './components/helpers/hookCalculateFormulas/hookCalculateFormulas';
import { LoadingScreenFunctions } from './components/LoadingScreenFunctions';
import { Parameter } from '../../../interfaces/Commons/parameters';
import { useValidateCentinelValue } from './components/helpers/hookValidateCentinelValue/hookValidateCentinelValue';

type ColumnType = GridColDef & {
  field?: string,
  headerName?: string,
  headerClassName?: string,
  flex?: number,
  type?: string,
  headerAlign?: string,
  valueGetter?: (params: GridValueGetterParams) => any;
};

interface DataObject {
  [key: string]: any;
}

export type ProjectQueue = {
  status: string,
  data: Project,
}

export const TableProjects = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { CheckAndValidateFormulas, CancelProcess } = useCalculateFormulasProjects();
  const { EvaluateFormula, fetchParameters } = useFormulaEvaluator();
  const { GetResourceByUrl } = usePermissions();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { HasCentinelValue, GetNameParamToReplace, HasValidateResponse } = useValidateCentinelValue();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [isloadingFunctions, setIsLoadingFunctions] = useState(false);
  const [buttonAcceptLoading, setButtonAcceptLoading] = useState(true);
  const [isCancelLoadingFunctions, setIsCancelLoadingFunctions] = useState(false);
  const [projectsData, setProjectsData] = useState<GridRowsProp>([]);
  const [myProjectsQueueToFunctions, setMyProjectsQueueToFunctions] = useState<ProjectQueue[]>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const [comboParameters, setComboParameters] = useState<Parameter[]>([])
  const [columns, setColumns] = useState<GridColDef<Project>[]>([]);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const [comboEnum, setComboEnum] = useState([
    { enum_id: 1, option_name: t("projects.comboEnum.all"), route: `/projects` },
    { enum_id: 2, option_name: t("projects.comboEnum.onlyclose"), route: `/projects?filter=${btoa(`project_is_closed=1`)}` },
    { enum_id: 3, option_name: t("projects.comboEnum.onlynoclose"), route: `/projects?filter=${btoa(`project_is_closed=0`)}` },
  ]);
  const [myEnumValue, setMyEnumValue] = useState(comboEnum[2]);
  const [defaultEnum, setDefaultEnum] = useState(comboEnum[2]);
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        showSnackBar(t("generic.snackbar.add"), "success");
        break;
      case "delete":
        showSnackBar(t("generic.snackbar.delete"), "success");
        break;
    }
  }, [location.state, t, showSnackBar]);

  const loadPreferences = async () => {
    const myPreferences = await localStorage.getItem("grid-projects");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    } else {
      const defaultPreferences = {
        "last_update_user": false,
        "creation_date": false,
        "last_update_date": false,
        "user_name": false,
        "project_start_date": false,
        "project_end_date": false,
        "project_type_name": false,
      };

      setMyPreferences(defaultPreferences);
    }
  };

  const createColumnsFromData = (data: any) => {
    const fixedColumns = [
      { field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1 },
      { field: 'project_type_name', headerName: t("projects.fields.project_type"), headerClassName: 'header-grid-table', flex: 1 },
      {
        field: 'business_name', headerName: t("projects.fields.customer"), headerClassName: 'header-grid-table', flex: 1,
        valueGetter: (params: any) => `${(params.row.customer_identifier) ? params.row.customer_identifier : "XXX"} - ${(params.row.comercial_name) ? params.row.comercial_name : params.row.business_name}`,
      },
      { field: 'user_name', headerName: t("projects.fields.project_manager"), headerClassName: 'header-grid-table', flex: 1 },
      {
        field: 'project_start_date', headerName: t("projects.fields.project_start_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
        valueGetter: (params: any) => TimeConverter(params.value),
      },
      {
        field: 'project_end_date', headerName: t("projects.fields.project_end_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
        valueGetter: (params: any) => TimeConverter(params.value),
      },
      {
        field: 'project_user_closed', headerName: t("projects.fields.project_user_closed"), headerClassName: 'header-grid-table', flex: 1,
      },
      {
        field: 'project_user_date_closed', headerName: t("projects.fields.project_user_date_closed"), headerClassName: 'header-grid-table', type: "date", flex: 1,
        valueGetter: (params: any) => params.value ? TimeConverter(params.value) : undefined,
      },
      {
        field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
        valueGetter: (params: any) => TimeConverter(params.value),
      },
      { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
      {
        field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
        valueGetter: (params: any) => TimeConverter(params.value),
      },
    ];

    if (data.length > 0) {
      const sampleRow = data[0];
      const dynamicColumns: ColumnType[] = [];

      Object.keys(sampleRow).forEach(key => {
        if (key.startsWith('value_')) {
          const columnKey = key.replace('value_', '');
          const typeKey = `type_${columnKey}`;
          const type = sampleRow[typeKey] ? sampleRow[typeKey].toLowerCase() : 'string';

          const column: ColumnType = {
            field: key,
            headerName: columnKey,
            headerClassName: 'header-grid-table',
            flex: 1,
            type: type,
            headerAlign: 'left',
            align: 'left'
          };

          if (type === 'date') {
            column.valueGetter = (params) => TimeConverter(params.value);
          }

          dynamicColumns.push(column);
        }
      });

      const columnMap = dynamicColumns.reduce((map, column) => {
        if (column.headerName) {
          map[column.headerName] = column;
        }
        return map;
      }, {} as { [key: string]: ColumnType });

      // Asegurarse de incluir columnas fijas
      setColumns([...fixedColumns, ...dynamicColumns]);
    }
  };

  const loadColumnsOrder = async () => {
    const myOrderColumns = await localStorage.getItem("grid-projects-columns");
    if (myOrderColumns != null) {
      const myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.valueGetter = function (params: GridValueGetterParams<Project, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`projects.fields.${element.field}`);
        }
      }
      setColumns(myJson);

    }
  };

  const onCalculateFormulas = async (projectsArrayData: any, isForced: boolean) => {
    await CheckAndValidateFormulas(projectsArrayData, setMyProjectsQueueToFunctions, isForced, setIsLoadingFunctions, isCancelLoadingFunctions);
  }

  const ValidateResourceCalculateFormulas = async (isForced: boolean, myCalculatedValues?: any) => {
    await GetResourceByUrl(`/projectextrafields/updateprojectextrafields/byprojectid`)
      .then(async (responsePermissions: Menuitem) => {
        if (responsePermissions.update === true) {
          await onCalculateFormulas(myCalculatedValues ?? projectsData, isForced)
            .then(async () => {
              await setButtonAcceptLoading((prev) => false);
              await setIsCancelLoadingFunctions((prev) => true);
              await handleClose1();
            });
        }
        else if (isForced) {
          showSnackBar(t("generic.snackbar.unauthorize"), "error");
          await setButtonAcceptLoading((prev) => false);
          await setIsCancelLoadingFunctions((prev) => true);
          handleClose1();
        }
        else {
          handleClose1();
          await setButtonAcceptLoading((prev) => false);
          await setIsCancelLoadingFunctions((prev) => true);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        handleClose1();
      });
  }

  const replaceFormulas = async (data: DataObject[], myLocalParameters?: Parameter[]): Promise<DataObject[]> => {
    return Promise.all(
      data.map(async (item) => {
        await Promise.all(
          Object.keys(item).map(async (key) => {
            if (key.startsWith('type_') && String(item[key]).toLowerCase() === 'formula') {
              const field = key.substring(5);
              const valueKey = `value_${field}`;
              if (valueKey in item) {
                try {
                  const responseProjectExtrafields = await GenericGetResource(
                    `/projectextrafields/byprojectid/${item["project_id"]}`
                  );
                  let myComboParameters: Parameter[] = []
                  //Si hay algo en el combo agarre los parametros de este
                  if (comboParameters.length > 0) {
                    myComboParameters = comboParameters
                  }
                  //Si no hay algo en el combo agarre el parametro local y si no que los parametros esten vacios
                  else if (myLocalParameters && myLocalParameters.length > 0) {
                    myComboParameters = myLocalParameters
                  }
                  const evaluatedValue = await EvaluateFormula(
                    item[valueKey] ?? '',
                    responseProjectExtrafields.data.data ?? [],
                    myComboParameters
                  );
                  if (!HasValidateResponse(evaluatedValue) && HasCentinelValue(field ?? '') && !(item["project_is_closed"])) {
                    // calcula el valor con hoy
                    const now = new Date();
                    const isoString = now.toISOString();
                    await EvaluateFormula(`${item[valueKey].replace(`{${GetNameParamToReplace(field ?? '')}}`, `new Date("${isoString}")`)}`, responseProjectExtrafields.data.data ?? [], myComboParameters)
                      .then(async (responseExtraField) => {
                        if (responseExtraField > 0) {
                          item[valueKey] = responseExtraField;
                        }
                        else {
                          item[valueKey] = evaluatedValue;
                        }
                      });
                  }
                  else {
                    item[valueKey] = evaluatedValue;
                  }

                } catch (error) {
                  item[valueKey] = t("projectextrafields.info.no-calculated");
                }
              }
            }
          })
        );
        return item;
      })
    );
  }

  useEffect(() => {
    setIsLoadingDataTable(false);
    GenericGetResource(myEnumValue.route)
      .then(async (response) => {
        let myCalculatedValues = await replaceFormulas(response.data.data);
        await setProjectsData((prev) => myCalculatedValues);
        setIsLoadingDataTable(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, [myEnumValue]);

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("projects.title")}`;
    });

    if (location.state !== null && location.state.method) methodUsed();

    GenericGetResource(myEnumValue.route)
      .then(async (response) => {
        let myParameters: Parameter[] = [];
        myParameters = await fetchParameters();
        setComboParameters(() => myParameters);
        let myCalculatedValues = await replaceFormulas(response.data.data, myParameters);
        setProjectsData(myCalculatedValues);
        ValidateResourceCalculateFormulas(false, myCalculatedValues);
        let myPromises = [
          GetResourceByUrl("/projects"),
        ];
        await Promise.all(myPromises)
          .then(async (responses) => {
            responses[0] && setResourceScreen(responses[0]);
            await createColumnsFromData(response.data.data);

            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
          })
          .catch((error) => {
            setMessageSnack(error.message);
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      })
      .catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {isloadingFunctions &&
        <DialogEntity
          open={isloadingFunctions}
          title={
            <Header
              title={t("projects.functions.title")}
              child={
                <div className='d-flex flex-row-reverse'>
                  <ButtonLoading variant="oultined" onClick={() => { setIsLoadingFunctions((prev) => false); setButtonAcceptLoading((prev) => true); setIsCancelLoadingFunctions((prev) => false); setMyProjectsQueueToFunctions((prev) => []); }} isLoading={buttonAcceptLoading && !isCancelLoadingFunctions}>{t("generic.buttons.accept")}</ButtonLoading>
                  {buttonAcceptLoading &&
                    <ButtonLoading variant="oultined" onClick={() => { setIsCancelLoadingFunctions((prev) => true); CancelProcess(); setIsCancelLoadingFunctions((prev) => false); }} isLoading={isCancelLoadingFunctions} disabled={!buttonAcceptLoading}>{t("generic.buttons.cancel")}</ButtonLoading>
                  }
                </div>
              }
            />
          }
          content={
            <>
              <LoadingScreenFunctions projectsQueue={myProjectsQueueToFunctions} />
            </>
          }
          fullWidth
        />
      }
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header
          title={t("projects.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              <Button
                aria-describedby={id1}
                onClick={handleClick1}
                variant='outlined'
              >
                <MoreHorizIcon />
              </Button>
              <Popover
                id={id1}
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: 200 }}
              >
                <ListItemButton
                  onClick={() => {
                    setIsLoadingFunctions((prev) => true);
                    ValidateResourceCalculateFormulas(true);
                  }}
                  disabled={isloadingFunctions}
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    minWidth: 200,
                  }}
                >
                  {t("projects.actions.calculate-cost")}
                  <FunctionsIcon />
                </ListItemButton>
              </Popover>
              {resourceScreen?.create === true &&
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
              }
              <Autocomplete
                size="small"
                options={comboEnum}
                defaultValue={defaultEnum}
                getOptionLabel={(option) => option.option_name}
                renderOption={(props, option) => (
                  <div key={option.enum_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.option_name}
                    </Box>
                  </div>
                )}
                sx={{ width: "20%", paddingRight: 1 }}
                isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                onChange={(_, values) => {
                  setMyEnumValue(values ?? { enum_id: 3, option_name: t("projects.comboEnum.onlynoclose"), route: `/projects?filter=${btoa(`project_is_closed=null`)}` });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("generic.filter")}`}
                    variant="filled"
                  />
                )}
                clearOnEscape={false}
                clearIcon={null}
              />
            </div>
          }
        />
        <div className="d-flex justify-content-center">
          {!isLoadingDataTable && <Spinner />}
          {isLoadingDataTable &&
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={projectsData}
              entityId={"project_id"}
              entity={`Projects`}
              preferences={myPreferences}
              namePreferences={"grid-projects"}
              nameOrderColumns={"grid-projects-columns"}
              hasDoubleClick
            />
          }
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  );
};
