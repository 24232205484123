import { Button, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FileSQL } from '../../../../interfaces/Commons/files';
import GenericPromises from '../../../../api/GenericPromises';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { useTranslation } from 'react-i18next';
import { Payment } from '../../../../interfaces/Purchases/Payments/payments';
import { useFiles } from '../../../../hooks/useFiles';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { Divider } from '../../../../components/Commons/Divider';

type StampingProps = {
  paymentLocal: Payment | undefined,
  setPaymentLocal: Dispatch<SetStateAction<Payment | undefined>>,
  isEdit: boolean,
}

export const StampingPayment = ({
  paymentLocal,
  isEdit,
  setPaymentLocal
}: StampingProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericPutResource, GenericDeleteResource, GenericGetResource } = GenericPromises();
  const { DecodeFileSQLToBase64 } = useFiles();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [receiptPaymentFile, setReceiptPaymentFile] = useState<FileSQL>({});
  const sizeLimit = 5242880; // 5 megabytes
  const sizeOfBytes = "MB";

  const onPostImage = (image: FileSQL, type: string) => {
    if (paymentLocal?.payment_id && paymentLocal.payment_id != 0) {
      let myData = {
        file_name: image.file_name,
        type_of_file: image.type_of_file,
        data_file: image.data_file,
      }
      GenericPostResource(`/filessql`, myData)
        .then(async (response) => {
          await UpdateFileId(response.data, type);
          showSnackBar(t("generic.snackbar.update_photo"), "success");
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
    }
  }

  const UpdateFileId = async (image: FileSQL, type: string) => {
    // diccionario para imagenes o accesos entidad Payments
    let myDictionary: { [key: string]: string } = {
      "ReceiptPayment": "receipt_payment_file",
    };
    let key = myDictionary[type as string] as keyof Payment;
    let myData = {
      [key]: image.file_sql_id ?? null,
    }
    if (!paymentLocal?.[key]) {
      // si exite un id para esa foto eliminarla y actualizar registro en payment
      await GenericPutResource(`/payments/${paymentLocal?.payment_id ?? 0}`, myData)
        .then(async () => {
          setReceiptPaymentFile(image);
          await setPaymentLocal((prev) => {
            if (!prev) { return undefined; }
            return {
              ...prev,
              [key]: image.file_sql_id
            };
          });
        });
    }
    else {
      await GenericDeleteResource(`/filessql/${paymentLocal?.[key]}`)
        .then(async () => {
          await GenericPutResource(`/payments/${paymentLocal?.payment_id ?? 0}`, myData)
            .then(async () => {
              setReceiptPaymentFile(image);
              await setPaymentLocal((prev) => {
                if (!prev) { return undefined; }
                return {
                  ...prev,
                  [key]: image.file_sql_id
                };
              });
            });
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
    }

  }

  useEffect(() => {
    if (paymentLocal && paymentLocal.receipt_payment_file) {
      GenericGetResource(`/filessql/${paymentLocal.receipt_payment_file}`)
        .then((responseIcon) => {
          setReceiptPaymentFile(responseIcon.data);
        });
    }
  }, [])


  return (<>
    <div className='w-75'>
      <Typography variant="h6" className="pt-3">
        {t("payments.info.receipt_payment")}
      </Typography>
      <Divider />
    </div>
    <div className='w-100 d-flex d-flex-row'>
      <div className="w-75">
        {receiptPaymentFile && receiptPaymentFile.data_file &&
          <>
            <iframe
              src={receiptPaymentFile.data_file}
              title={receiptPaymentFile.file_name}
              style={{ width: '100%', height: '500px', border: '1px solid #ccc' }}
            />
          </>
        }
      </div>
      <div className="w-25 d-flex flex-column justify-content-start align-items-center">
        <ButtonStyled variant="contained" component="label" disabled={isEdit}>
          <input
            type="file"
            accept=".pdf"
            hidden
            onChange={async (e) => {
              let file = e.target.files;
              if (file && file.length > 0 && file[0].type === "application/pdf" && file[0].size <= sizeLimit) {
                await DecodeFileSQLToBase64(e).then(async (response) => {
                  onPostImage(response, "ReceiptPayment");
                });
              }
            }}
          />
          {t("payments.buttons.receipt_payment")}
        </ButtonStyled>
        <Typography>{t("payments.info.size_limit")}: {sizeLimit / (1024 * 1024)} {sizeOfBytes}</Typography>
      </div>
    </div>
    <SnackbarComponent />
  </>
  )
}
