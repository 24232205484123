import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form"
import { SalesOrder } from "../../../interfaces/Sales/Orders/salesOrders"
import { Customer, CustomerLocation } from "../../../interfaces/Sales/Catalogs/Customers/customers"
import { Currency } from "../../../interfaces/Commons/currencies"
import { useTranslation } from "react-i18next"
import { useFormatNumber } from "../../../hooks/useFormatNumber"
import { FormsErrors } from "../../../hooks/Forms/FormsErrors"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import { ButtonLoading } from "../../../theme/buttons"
import { Menuitem } from "../../../interfaces/Security/menu"


type UpdateSalesOrderHeaderProps = {
  loading: boolean,
  control: Control<SalesOrder, any>,
  errors: FieldErrors<SalesOrder>,
  salesOrderLocal: SalesOrder | undefined,
  getValues: UseFormGetValues<SalesOrder>,
  setValue: UseFormSetValue<SalesOrder>,
  isEdit: boolean,
  defaultCurrency: Currency | undefined,
  defaultCustomerLocation: CustomerLocation | undefined,
  comboCustomerLocations: CustomerLocation[],
  comboCurrencies: Currency[],
  isLoadingCustomerLocations: boolean,
  hasDefaultCustomer: boolean,
  comboCustomers: Customer[],
  defaultCustomer: Customer | undefined,
  comboLocationsDelivery: CustomerLocation[],
  defaultLocationDelivery: CustomerLocation | undefined,
  resourceDelivery: Menuitem | undefined
}

export const UpdateSalesOrderHeader = ({
  loading,
  control,
  errors,
  salesOrderLocal,
  getValues,
  setValue,
  isEdit,
  defaultCurrency,
  defaultCustomerLocation,
  comboCustomerLocations,
  comboCurrencies,
  isLoadingCustomerLocations,
  hasDefaultCustomer,
  comboCustomers,
  defaultCustomer,
  comboLocationsDelivery,
  defaultLocationDelivery,
  resourceDelivery
}: UpdateSalesOrderHeaderProps) => {
  const [t] = useTranslation("global");
  const { setFormatNumber } = useFormatNumber();
  const { GetError } = FormsErrors();
  const totalAmount = useWatch({ control, name: "total_amount" });
  const subtotalAmount = useWatch({ control, name: "subtotal_amount" });
  const discountAmount = useWatch({ control, name: "discount_amount" });
  const taxAmount = useWatch({ control, name: "tax_amount" });
  const retentionAmount = useWatch({ control, name: "retention_amount" });

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <div className='d-flex w-50'>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <Typography>{t("salesinvoices.info.invoiced-to")}</Typography>
            <div>
              <Typography>{salesOrderLocal?.customer_business_name}</Typography>
              <Typography>{salesOrderLocal?.customer_rfc}</Typography>
              <Typography>{salesOrderLocal?.customer_tax_regime_code} - {salesOrderLocal?.customer_tax_regime_description}</Typography>
              <Typography>{getValues("customer_street_name")}, {getValues("customer_city_name")}, {getValues("customer_state_name")}, {getValues("customer_country_name")}</Typography>
            </div>
          </Box>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <Typography>{t("salesinvoices.info.send-to")}</Typography>
            <div>
              <Typography>{salesOrderLocal?.customer_delivery_business_name}</Typography>
              <Typography>{salesOrderLocal?.customer_delivery_rfc}</Typography>
              <Typography>
                {(salesOrderLocal?.customer_delivery_tax_regime_code) ?
                  salesOrderLocal?.customer_delivery_tax_regime_code + " - " +
                  salesOrderLocal?.customer_delivery_tax_regime_description : ""}
              </Typography>
              <Typography>
                {(salesOrderLocal?.customer_delivery_street) ? salesOrderLocal?.customer_delivery_street + ", " : " "}
                {(salesOrderLocal?.customer_delivery_city_name) ? salesOrderLocal?.customer_delivery_city_name + ", " : " "}
                {(salesOrderLocal?.customer_delivery_state_name) ? salesOrderLocal?.customer_delivery_state_name + ", " : " "}
                {salesOrderLocal?.customer_delivery_country_name}
              </Typography>
              {!isLoadingCustomerLocations &&
                <>
                  <Controller
                    name="sales_delivery_addresses_id"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        sx={{ width: "100%" }}
                        options={comboLocationsDelivery ?? []}
                        defaultValue={defaultLocationDelivery}
                        getOptionLabel={(option: CustomerLocation) => `${option.business_name} - ${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                        renderOption={(props, option: CustomerLocation) => (
                          <div key={option.customer_location_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.business_name} - {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.customer_location_id === value.customer_location_id}
                        onChange={async (_, values) => {
                          field.onChange(values?.customer_location_id || null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("customerlocations.title-view")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.sales_delivery_addresses_id}
                            helperText={GetError(errors.sales_delivery_addresses_id?.type)}
                          />
                        )}
                        disabled={(comboLocationsDelivery.length > 0) ? (resourceDelivery?.update) ? isEdit : true : true}
                      />
                    )}
                  />
                </>
              }
            </div>
          </Box>
        </div>
        {/* Right Box*/}
        <div className='d-flex flex-column w-50'>
          {/* first Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 12 }}>
              <Controller
                name="customer_location_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={comboCustomerLocations}
                    defaultValue={defaultCustomerLocation}
                    getOptionLabel={(option: CustomerLocation) => `${option.business_name} - ${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                    renderOption={(props, option: CustomerLocation) => (
                      <div key={option.customer_location_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.business_name} - {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.customer_location_id === value.customer_location_id}
                    onChange={(_, values) => {
                      field.onChange(values?.customer_location_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("salespreorders.fields.address")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.customer_location_id}
                        helperText={GetError(errors.customer_location_id?.type)}
                      />
                    )}
                    disabled={isEdit || isLoadingCustomerLocations}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 12 }} className="d-flex flex-column mx-2">
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>
                {t("salespreorders.fields.tax_amount")}: {setFormatNumber({ value: taxAmount ?? 0 })}
              </Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>
                {t("salespreorders.fields.retention_amount")}: {setFormatNumber({ value: retentionAmount ?? 0 })}
              </Typography>
            </Box>
          </div>
          {/* second Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 12 }}>

              <Controller
                name="currency_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%" }}
                    options={comboCurrencies}
                    defaultValue={defaultCurrency}
                    getOptionLabel={(option: Currency) => `${option.currency_code} - ${option.currency_description}`}
                    renderOption={(props, option: Currency) => (
                      <div key={option.currency_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.currency_code} - {option.currency_description}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                    onChange={(_, values) => {
                      field.onChange(values?.currency_id || null);
                      setValue("currency_code", values?.currency_code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("currencies.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.currency_id}
                        helperText={GetError(errors.currency_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 12 }} className="d-flex flex-column mx-2">
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>
                {t("salespreorders.fields.discount_amount")}: {setFormatNumber({ value: discountAmount ?? 0 })}
              </Typography>
            </Box>
          </div>
          {/* third row */}
          <div className="d-flex mt-2">
            <Box sx={{ flex: 12 }} className="d-flex flex-column mx-2">
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>
                {t("salespreorders.fields.subtotal_amount")}: {setFormatNumber({ value: subtotalAmount ?? 0 })}
              </Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>
                {t("salespreorders.fields.total_amount")}: {setFormatNumber({ value: totalAmount ?? 0 })}
              </Typography>
            </Box>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-2">
        <div className="w-50">
          <ButtonLoading isLoading={loading} type="submit" size="small" className="d-flex align-self-end w-100" variant="outlined" disabled={isEdit}>{t("generic.buttons.save")}</ButtonLoading>
        </div>
      </div>
    </>
  )
}
