import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { myStylePickers, PrimaryButton } from "../../../../../theme/buttons";
import { ProjectTaskTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectTaskTemplate";
import { ProjectGroupTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectGroupsTemplate";
import { TaskState } from "../../../../../interfaces/Projects/Catalogs/taskStates";
import { Group } from "../../../../../interfaces/Security/groups";
import { useBase64 } from "../../../../../hooks/useBase64";
import { FieldType } from "../../../../../interfaces/Projects/Catalogs/fieldTypes";
import { Menuitem } from "../../../../../interfaces/Security/menu";

type UpdateProjectTaskTemplateProps = {
  permissions: Menuitem | undefined;
  setOpenDialogUpdateTask: Dispatch<SetStateAction<boolean>>;
  handleUpdateTask: (updateRow: ProjectTaskTemplate) => void;
  handleDeleteTask: (deleteRow: number) => void;
  project_template_id: number | undefined;
  projectTaskTemplatePayload: ProjectTaskTemplate;
  comboFieldTypes: FieldType[];
};

export const UpdateProjectTaskTemplate = ({
  permissions,
  setOpenDialogUpdateTask,
  handleUpdateTask,
  handleDeleteTask,
  project_template_id,
  projectTaskTemplatePayload,
  comboFieldTypes
}: UpdateProjectTaskTemplateProps) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericPostResource,
    GenericGetResourceGeneric,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboProjectGroupsTemplate, setComboProjectGroupsTemplate] = useState<
    ProjectGroupTemplate[]
  >([]);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [defaultGroup, setDefaultGroup] = useState<Group>();
  const { utf8ToBase64 } = useBase64();
  const [isTypeFormulaStartedDate, setIsTypeFormulaStartedDate] = useState(false);
  const [isTypeFormulaDueDate, setIsTypeFormulaDueDate] = useState(false);
  const [defaultProjectTaskTemplateStartedDate, setDefaultProjectTaskTemplateStartedDate] = useState<string | Date>()
  const [defaultStartedDateFieldTypeId, setDefaultStartedDateFieldTypeId] = useState<FieldType>()
  const [defaultProjectTaskTemplateDueDate, setDefaultProjectTaskTemplateDueDate] = useState<string | Date>()
  const [defaultDueDateFieldTypeId, setDefaultDueDateFieldTypeId] = useState<FieldType>();
  const isEdit = !permissions?.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProjectTaskTemplate>();

  const startedDateFieldTypeId = useWatch({
    control,
    name: "project_task_template_started_date_field_type_id"
  });

  const projectTaskTemplateStartedDate = useWatch({
    control,
    name: "project_task_template_started_date"
  });

  const dueDateFieldTypeId = useWatch({
    control,
    name: "project_task_template_due_date_field_type_id"
  });

  const onSubmit: SubmitHandler<ProjectTaskTemplate> = (data) => onPut(data);

  const onPut = (data: ProjectTaskTemplate) => {
    let myData = {
      project_stage_template_id: data.project_stage_template_id,
      project_group_template_id: data.project_group_template_id,
      current_task_state_template_id: data.current_task_state_template_id,
      project_task_template_name: data.project_task_template_name,
      project_task_template_description:
        data.project_task_template_description ?? null,
      project_task_template_started_date:
        data.project_task_template_started_date ?? null,
      project_task_template_due_date:
        data.project_task_template_due_date ?? null,
        project_task_template_started_date_field_type_id: data.project_task_template_started_date_field_type_id,
        project_task_template_due_date_field_type_id: data.project_task_template_due_date_field_type_id
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projecttasktemplate/${projectTaskTemplatePayload.project_task_template_id}`,
      myData
    )
      .then((response) => {
        let myUpdateData: ProjectTaskTemplate = response.data;
        handleUpdateTask(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        setOpenDialogUpdateTask(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projecttasktemplate/${projectTaskTemplatePayload.project_task_template_id}`
    )
      .then(() => {
        setOpenDialog(false);
        if (projectTaskTemplatePayload.project_task_template_id) {
          handleDeleteTask(projectTaskTemplatePayload.project_task_template_id);
        }

        setOpenDialogUpdateTask(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddProjectGroupsTemplate = (group_id: number) => {
    const existingObject = comboProjectGroupsTemplate.find(
      (projectGroupTemplate) =>
        projectGroupTemplate.group_id === group_id &&
        projectGroupTemplate.project_template_id === project_template_id
    );

    if (existingObject) {
      return existingObject;
    } else {
      let myData = {
        group_id: group_id,
        project_template_id: project_template_id,
      };

      setLoadingPost(true);
      GenericPostResource("/projectgroupstemplate", myData)
        .then((response) => {
          setComboProjectGroupsTemplate([
            ...comboProjectGroupsTemplate,
            response.data,
          ]);

          return response.data;
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/projectgroupstemplate"),
      GenericGetResourceGeneric("/groups", "/ggroups"),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectGroupsTemplate(responses[0].data.data);
        setComboGroups(responses[1].data.data);

        reset({
          project_stage_template_id:
            projectTaskTemplatePayload.project_stage_template_id,
          project_group_template_id:
            projectTaskTemplatePayload.project_group_template_id,
          project_task_template_name:
            projectTaskTemplatePayload.project_task_template_name,
          project_task_template_description:
            projectTaskTemplatePayload.project_task_template_description,
          project_task_template_started_date:
            projectTaskTemplatePayload.project_task_template_started_date,
          project_task_template_due_date:
            projectTaskTemplatePayload.project_task_template_due_date,
            project_task_template_started_date_field_type_id:
            projectTaskTemplatePayload.project_task_template_started_date_field_type_id,
            project_task_template_due_date_field_type_id:
            projectTaskTemplatePayload.project_task_template_due_date_field_type_id
        });

        const projectGroupTemplate = responses[0].data.data.find(
          (projectGroupTemplate: ProjectGroupTemplate) =>
            projectGroupTemplate.project_group_template_id ===
            projectTaskTemplatePayload.project_group_template_id
        );

        if (projectGroupTemplate) {
          const group = responses[1].data.data.find(
            (group: any) => group.group_id === projectGroupTemplate.group_id
          );

          if (group) {
            setDefaultGroup(group);
          }
        }
        setDefaultProjectTaskTemplateStartedDate(
          projectTaskTemplatePayload.project_task_template_started_date
        );

        const startedDateFieldTypeId = comboFieldTypes.find(
          (fielType: FieldType) =>
            fielType.field_type_id === projectTaskTemplatePayload.project_task_template_started_date_field_type_id
        )

        setDefaultStartedDateFieldTypeId(startedDateFieldTypeId);

        if (startedDateFieldTypeId?.field_type_name.toLowerCase() == 'formula') {
          setIsTypeFormulaStartedDate(true);
        }

        setDefaultProjectTaskTemplateDueDate(
          projectTaskTemplatePayload.project_task_template_due_date
        );

        const dueDateFieldTypeId = comboFieldTypes.find(
          (fielType: FieldType) =>
            fielType.field_type_id === projectTaskTemplatePayload.project_task_template_due_date_field_type_id
        )

        setDefaultDueDateFieldTypeId(dueDateFieldTypeId);

        if (dueDateFieldTypeId?.field_type_name.toLowerCase() == 'formula') {
          setIsTypeFormulaDueDate(true);
        }

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="project_group_template_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      getOptionLabel={(option) => `${option.group_name}`}
                      defaultValue={defaultGroup}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.group_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.group_id === value.group_id
                      }
                      onChange={(_, values) => {
                        if (values?.group_id) {
                          let projectGroup = handleAddProjectGroupsTemplate(
                            values?.group_id
                          );
                          field.onChange(
                            projectGroup?.project_group_template_id
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttaskstemplate.fields.project_group_template"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_group_template_id}
                          helperText={GetError(
                            errors.project_group_template_id?.type
                          )}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_task_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttaskstemplate.fields.project_task_template_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_template_name}
                      helperText={GetError(
                        errors.project_task_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-100">
                <Controller
                  name="project_task_template_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttaskstemplate.fields.project_task_template_description"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_template_description}
                      helperText={GetError(
                        errors.project_task_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_task_template_started_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      defaultValue={defaultStartedDateFieldTypeId}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaStartedDate(true);
                        } else {
                          setIsTypeFormulaStartedDate(false);
                        }
                        setValue("project_task_template_started_date", "", {
                          shouldValidate: true,
                        });
                        setValue("project_task_template_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttaskstemplate.fields.project_task_template_started_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_task_template_started_date_field_type_id}
                          helperText={GetError(errors.project_task_template_started_date_field_type_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaStartedDate ? (
                <div className="w-50">
                  <Controller
                    name="project_task_template_started_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projecttaskstemplate.fields.project_task_template_started_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        defaultValue={defaultProjectTaskTemplateStartedDate}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          setValue("project_task_template_due_date", "", {
                            shouldValidate: true,
                          });
                        }}
                        error={!!errors.project_task_template_started_date}
                        helperText={GetError(
                          errors.project_task_template_started_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!startedDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_task_template_started_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openStartDateDatePicker}
                        onClose={() => setOpenStartDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projecttaskstemplate.fields.project_task_template_started_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                          setValue("project_task_template_due_date", "", {
                            shouldValidate: true,
                          });
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenStartDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_task_template_started_date,
                            helperText: GetError(
                              errors.project_task_template_started_date?.type
                            ),
                          },
                        }}
                        format="DD/MM/YYYY"
                        disabled={!startedDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_task_template_due_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      defaultValue={defaultDueDateFieldTypeId}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaDueDate(true);
                        } else {
                          setIsTypeFormulaDueDate(false);
                        }
                        setValue("project_task_template_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttaskstemplate.fields.project_task_template_due_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_task_template_due_date_field_type_id}
                          helperText={GetError(errors.project_task_template_due_date_field_type_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaDueDate ? (
                <div className="w-50">
                  <Controller
                    name="project_task_template_due_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projecttaskstemplate.fields.project_task_template_due_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_task_template_due_date}
                        helperText={GetError(
                          errors.project_task_template_due_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!projectTaskTemplateStartedDate || !dueDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_task_template_due_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openEndDateDatePicker}
                        onClose={() => setOpenEndDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projecttaskstemplate.fields.project_task_template_due_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenEndDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_task_template_due_date,
                            helperText: GetError(
                              errors.project_task_template_due_date?.type
                            ),
                          },
                        }}
                        minDate={dayjs(projectTaskTemplateStartedDate)}
                        format="DD/MM/YYYY"
                        disabled={!projectTaskTemplateStartedDate || !dueDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => setOpenDialogUpdateTask(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions?.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions?.delete ? (
                <PrimaryButton onClick={() => setOpenDialog(true)}>
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttaskstemplate.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
