import { useEffect, useState } from "react";
import { useFiles } from "../../../hooks/useFiles"

type ImageProps = {
    url?: string;
    entity?: string;
    recordId?: number;
    width?: number;
    height?: number;
}

export const ImageDataComponent = ({ url, entity, recordId, width = 250, height = 250 }: ImageProps) => {
    const { GetFile } = useFiles();
    const [loadingData, setLoadingData] = useState(false);
    const [myUrl, setMyUrl] = useState("");

    useEffect(() => {
        if (url === undefined) {
            GetFile(recordId!, entity!)
                .then((response) => {
                    setMyUrl(response.dataURL);
                    setLoadingData(true);
                })
        }
    }, [])

    // useEffect(() => { }, [myUrl]);

    if (url !== undefined) {
        return (
            <>
                <img alt="" src={url} width={width} height={height} />
            </>
        )
    }
    else {
        return (
            <>
                {!loadingData &&
                    <img width={width} height={height} alt="" src={require("../../../assets/img/profile.webp")} />
                }
                {loadingData &&
                    <img width={width} height={height} alt="" src={myUrl} />
                }
            </>
        )
    }
}
