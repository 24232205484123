import { useCallback, useContext, useEffect, useState } from 'react'
import { Header } from '../../../../../components/Header'
import { useLocation } from 'react-router-dom'
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner'
import GenericPromises from '../../../../../api/GenericPromises'
import { PrimaryButton } from '../../../../../theme/buttons'
import { AuthContext } from '../../../../../context/AuthContext'
import { Menuitem } from '../../../../../interfaces/Security/menu'
import DataTable from '../../../../../components/Tables/GridTableMaterialUI/DataTable'
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { CustomerLocation } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { AddCustomerLocations } from './add'
import { UpdateCustomerLocations } from './update'
import { usePermissions } from '../../../../../hooks/usePermissions'
import { DialogEntity } from '../../../../../components/Dialogs/DialogEntity'
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar'
import { useDates } from '../../../../../hooks/useDates'

export const TableCustomerLocations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { showSnackBar, SnackbarComponent } = useSnackBar()
  const { GenericGetResource } = GenericPromises();
  const { TimeConverter } = useDates();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [numTemp, setNumTemp] = useState(0);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [customerLocationsData, setCustomerLocationsData] = useState<GridRowsProp>([]);
  const [customerLocationPayload, setCustomerLocationPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();

  const [shouldLoadLocations, setShouldLoadLocations] = useState(false)
  const [columnsCustomerLocations, setColumnsCustomerLocations] = useState<GridColDef<CustomerLocation>[]>([
    {
      field: 'business_name', headerName: t("customerlocations.fields.business_name"), headerClassName: 'header-grid-table', flex: 1,
    },
    {
      field: 'Address', headerName: t("customerlocations.fields.Address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
      }
    },
    {
      field: 'fiscal_address', headerName: t("customerlocations.fields.fiscal_address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'delivery_address', headerName: t("customerlocations.fields.delivery_address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'default_delivery_address', headerName: t("customerlocations.fields.default_delivery_address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);



  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.update"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-customerlocations");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-customerlocations-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] == 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CustomerLocation, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === 'Address') {
            element.valueGetter = function (params: GridValueGetterParams<CustomerLocation, any, GridTreeNodeWithRender>) {
              return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
            }
          }
          else if (element.field !== 'last_update_user') {
            element.headerName = t(`customers.fields.${element.field}`);
          }
          else if (element.field === "fiscal_address") {
            element.valueGetter = function (params: GridValueGetterParams<CustomerLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field === "delivery_address") {
            element.valueGetter = function (params: GridValueGetterParams<CustomerLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field === "default_delivery_address") {
            element.valueGetter = function (params: GridValueGetterParams<CustomerLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
        }
      }
      setColumnsCustomerLocations(myJson);
    }
  }

  const handleAddRow = (newRow: CustomerLocation) => {
    setShouldLoadLocations(true);
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setCustomerLocationsData((prevState) => [...customerLocationsData, myRow]);
    if (props.add)
      setNumTemp(numTemp + 1)
  };

  const handleUpdateRow = (updateRow: CustomerLocation) => {
    setShouldLoadLocations(true);
    if (customerLocationsData.length === 0) {
      return;
    }
    setCustomerLocationsData((prevRows) => {
      const rowToUpdateIndex = customerLocationsData.findIndex(e => e.customer_location_id === updateRow.customer_location_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    setShouldLoadLocations(true);
    if (customerLocationsData.length == 0) {
      return [customerLocationsData];
    }
    setCustomerLocationsData((prevRows) => {
      const rowToDeleteIndex = customerLocationsData.findIndex(e => e.customer_location_id === deleteRow);
      return [
        ...customerLocationsData.slice(0, rowToDeleteIndex),
        ...customerLocationsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };


  useEffect(() => {
    if (shouldLoadLocations === true) {
      setShouldLoadLocations(false);
      if (!props.add) {
        if (location.state !== null && location.state.method) methodUsed();
        GenericGetResource(`/customerlocations/bycustomerid/${props.customer_id}`)
          .then(
            (response) => {
              setCustomerLocationsData(response.data.data);
              if (response.data.data.length > 0) {
                if (!response.data.data.find((location: CustomerLocation) => location.country_name == "MEXICO") && !props.isNotMexican) {
                  props.setIsNotMexican(true);
                }
                else if (response.data.data.find((location: CustomerLocation) => location.country_name == "MEXICO" && props.isNotMexican)) {
                  props.setIsNotMexican(false);
                  props.setValue("customer_rfc", "");
                }
              }
              GetResourceByUrl(`/customerlocations`)
                .then((response1) => {
                  setResourceScreen((prev) => response1);
                  loadColumnsOrder();
                  loadPreferences();
                  setDataLoaded(true);
                  setShouldLoadLocations(false);
                })
                .catch((error) => {
                  showSnackBar(error.message, "error");
                  setUnauthorized(false);
                });
            }
          ).catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      }
      else {
        if (props.LocationsData.length > 0 && customerLocationsData.length < 1) {
          setCustomerLocationsData(props.LocationsData);
          setNumTemp(props.LocationsData.length);
        }
        else {
          props.setLocationsData(customerLocationsData);
          setNumTemp(customerLocationsData.length);
        }
        if (location.state !== null && location.state.method) methodUsed();
        GetResourceByUrl(`/customerlocations`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
          })

          .catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      }
    }
  }, [handleDeleteRow, handleAddRow, handleUpdateRow]);

  useEffect(() => {
    if (!props.add) {
      if (customerLocationsData.length > 0) {
        if (!customerLocationsData.find((location) => location.country_name == "MEXICO") && !props.isNotMexican) {
          props.setIsNotMexican(true);
        }
        else if (customerLocationsData.find((location) => location.country_name == "MEXICO" && props.isNotMexican)) {
          props.setIsNotMexican(false);
          props.setValue("customer_rfc", "");
        }
      }
      props.reset({ customer_rfc: props.getValues("customer_rfc") });
    }
    setShouldLoadLocations(true);
  }, [])

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../../assets/img/error.webp")} /> <h2>{ }</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container'>
            {resourceScreen?.create === true &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsCustomerLocations}
                setColumns={setColumnsCustomerLocations}
                data={customerLocationsData}
                entityId={"customer_location_id"}
                entity={`CustomerLocations`}
                preferences={myPreferences}
                namePreferences={"grid-customerlocations"}
                nameOrderColumns={"grid-customerlocations-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setCustomerLocationPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateCustomerLocations setValue={props.setValue} customerLocationsData={customerLocationsData} customerLocationPayload={customerLocationPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} add={props.add} showSnackBar={showSnackBar} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("customerlocations.title-view")} size='sm' />}
          />
          <DialogEntity
            content={<AddCustomerLocations setValue={props.setValue} add={props.add} setOpenDialog={setOpenDialogAdd} customer_id={props.customer_id} AddNewRow={handleAddRow} UpdateRow={handleUpdateRow} numTemp={numTemp} customerLocationsData={customerLocationsData} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("customerlocations.title-view")} size='md' />}
          />
          <SnackbarComponent />

        </>
      }
    </>
  )
}
