import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Company } from "../../../../interfaces/Security/companies";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { usePermissions } from "../../../../hooks/usePermissions";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../../hooks/useDates";

export const TableCompanies = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [CompanyData, setCompaniesData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [myPreferences, setMyPreferences] = useState({});
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const { GetResourceByUrl } = usePermissions();
  const [columns, setColumns] = useState<GridColDef<Company>[]>([
    { field: 'business_name', headerName: t("companies.fields.company_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'Address', headerName: t("companies.fields.address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
      }
    },
    { field: 'rfc', headerName: t("companies.fields.rfc"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-companies");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-companies-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<Company, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === 'Address') {
            element.valueGetter = function (params: GridValueGetterParams<Company, any, GridTreeNodeWithRender>) {
              return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
            }
          }
        }
        if (element.field) { }
        else {
          element.headerName = t(`companies.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource("/companies")
      .then(
        (response) => {
          setCompaniesData(response.data.data);
          document.title = `${response.data.data[0].comercial_name} - ${t("companies.title")}`
          GetResourceByUrl(`/companies`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
              setMessageSnack(error.message);
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header title={t("companies.title")} />
        <div className="d-flex justify-content-center">
          <DataTable columns={columns} setColumns={setColumns} data={CompanyData} entityId={"company_id"} entity={`Company`} preferences={myPreferences} namePreferences={"grid-companies"} nameOrderColumns={"grid-companies-columns"} />
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}