import { Control, Controller, FieldErrors } from "react-hook-form";
import { PurchasePreorder } from "../../../../interfaces/Purchases/Preorders/purchasePreorders";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

type AnnotationsPurchasePreordersProps = {
  control: Control<PurchasePreorder>,
  errors: FieldErrors<PurchasePreorder>,
  isEdit?: boolean,
}

export const AnnotationsPurchasePreorders = ({
  control,
  errors,
  isEdit,
}: AnnotationsPurchasePreordersProps) => {
  const { GetError } = FormsErrors();
  const [t] = useTranslation("global");

  return (
      <>
          <div className='w-100'>
              <Controller
                  name="notes"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                      <TextField
                          variant="filled"
                          label={`${t("purchasepreorders.fields.notes")}`}
                          ref={field.ref}
                          value={field.value}
                          multiline
                          minRows={5}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.notes}
                          helperText={GetError(errors.notes?.type)}
                          size="small"
                          sx={{ width: "100%", paddingRight: 2 }}
                          disabled={isEdit}
                      />
                  )}
              />
          </div>
      </>
  )
}