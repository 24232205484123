import { useEffect, useState } from "react"
import { Autocomplete, Avatar, Box, Button, Grow, Switch, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../api/GenericPromises";
import { PrimaryButton } from "../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { Item, ItemFamily, ItemType } from "../../../interfaces/Commons/items";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ItemsStatuses } from "../../../interfaces/Commons/itemstatuses";
import { ImageDataComponent } from "../../../components/Images/ImageData";
import { File } from "../../../interfaces/Commons/files";
import { useFiles } from "../../../hooks/useFiles";
import { Unit } from "../../../interfaces/Commons/units";
import { ProductServiceKey } from "../../../interfaces/Sales/Catalogs/productServiceKeys";
import { TaxObject } from "../../../interfaces/Sales/Catalogs/taxObjects";
import { Retention } from "../../../interfaces/Sales/Catalogs/retentions";
import { Tax } from "../../../interfaces/Sales/Catalogs/taxes";
import { TariffFraction } from "../../../interfaces/Sales/Catalogs/tariffFractions";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";

export const UpdateItems = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource, GenericGet } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { DecodeFileToBase64, GetContentURLImage, PostFiles } = useFiles();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboItemFamilies, setComboItemFamilies] = useState<ItemFamily[]>([]);
  const [defaultItemFamily, setDefaultItemFamily] = useState<ItemFamily>();
  const [comboItemTypes, setComboItemTypes] = useState<ItemType[]>([]);
  const [defaultItemType, setDefaultItemType] = useState<ItemType>();
  const [comboItemsStatuses, setComboItemsStatuses] = useState<ItemsStatuses[]>([]);
  const [defaultItemsStatuses, setDefaultItemsStatuses] = useState<ItemsStatuses>();
  const [comboUnits, setComboUnits] = useState<Unit[]>([]);
  const [defaultUnits, setDefaultUnits] = useState<Unit>();
  const [comboKeys, setComboKeys] = useState<ProductServiceKey[]>([]);
  const [defaultKeys, setDefaultKeys] = useState<ProductServiceKey>();
  const [comboTaxObjects, setComboTaxObjects] = useState<TaxObject[]>([]);
  const [defaultTaxObject, setDefaultTaxObject] = useState<TaxObject>();
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([])
  const [defaultRetention, setDefaultRetention] = useState<Retention>();
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([])
  const [defaultTax, setDefaultTax] = useState<Tax>();
  const [defaultTariffFraction, setDefaultTariffFraction] = useState<TariffFraction>()
  const [comboTariffFractions, setComboTariffFractions] = useState<TariffFraction[]>([])
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hideDetail, setHideDetail] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageFile, setImageFile] = useState<File>({});
  const { itemPayload } = props;
  const isEdit = !props.isEdit;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Item>({
    defaultValues: {
      item_code: "",
      item_description: "",
      default_item_price: undefined,
      weight: undefined,
      item_type_id: undefined,
      item_family_id: undefined,
      item_status_id: undefined,
      unit_id: undefined,
      product_service_key_id: undefined,
      tax_object_id: undefined,
      retention_id: undefined,
      tax_id: undefined,
    }
  });
  const onSubmit: SubmitHandler<Item> = (data) => onPut(data);

  const onPut = (data: Item) => {
    let myData = {
      item_code: data.item_code,
      item_description: data.item_description,
      default_item_price: data.default_item_price ?? null,
      weight: data.weight ?? null,
      item_type_id: data.item_type_id ?? null,
      item_family_id: data.item_family_id ?? null,
      item_status_id: data.item_status_id,
      unit_id: data.unit_id,
      product_service_key_id: data.product_service_key_id,
      tax_object_id: data.tax_object_id,
      retention_id: data.retention_id ?? null,
      tax_id: data.tax_id ?? null,
      tariff_fraction_id: data.tariff_fraction_id ?? null,
    }
    setLoadingPost(true);
    GenericPutResource(`/items/${data.item_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onPostImage = (image: any) => {
    let myFile = {
      entity: "Items",
      recordId: itemPayload.item_id,
      fileName: image.file_name,
      typeOfFile: image.type_of_file,
      schema_name: localStorage.getItem("schemaName"),
      Content: GetContentURLImage(image.dataURL),
    }
    PostFiles(myFile).then(() => {
      setImageFile((prev) => image);
      setImageLoaded(true);
      showSnackBar(t("generic.snackbar.update_photo"), "success");
      setLoadingPost(false);
    })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/itemtypes"),
      GenericGetResource("/itemfamilies"),
      GenericGet("/itemsstatuses"),
      GenericGet(`/files/recordId/${itemPayload.item_id}/entity/Items/schema/${localStorage.getItem("schemaName")}`),
      GenericGetResource("/units"),
      GenericGetResource("/productservicekeys"),
      GenericGetResource("/taxobjects"),
      GenericGetResource("/retentions"),
      GenericGetResource("/taxes"),
      GenericGetResource("/tarifffractions"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboItemTypes(responses[0].data.data);
        setComboItemFamilies(responses[1].data.data);
        setComboItemsStatuses(responses[2].data.data);
        setComboUnits(responses[4].data.data);
        setComboKeys(responses[5].data.data);
        setComboTaxObjects(responses[6].data.data);
        setComboRetentions(responses[7].data.data);
        setComboTaxes(responses[8].data.data);
        setComboTariffFractions(responses[9].data.data)

        reset({
          item_id: itemPayload.item_id,
          item_code: itemPayload.item_code,
          item_description: itemPayload.item_description,
          default_item_price: itemPayload.default_item_price ?? null,
          weight: itemPayload.weight ?? null,
          item_type_id: itemPayload.item_type_id ?? null,
          item_family_id: itemPayload.item_family_id ?? null,
          item_status_id: itemPayload.item_status_id,
          product_service_key_id: itemPayload.product_service_key_id,
          unit_id: itemPayload.unit_id,
          tax_object_id: itemPayload.tax_object_id,
          retention_id: itemPayload.retention_id ?? null,
          tax_id: itemPayload.tax_id ?? null,
          tariff_fraction_id: itemPayload.tariff_fraction_id ?? null,
        });

        setDefaultItemType(responses[0].data.data.find((itemType: ItemType) => itemType.item_type_id === itemPayload.item_type_id));
        setDefaultItemFamily(responses[1].data.data.find((itemFamily: ItemFamily) => itemFamily.item_family_id === itemPayload.item_family_id));
        setDefaultItemsStatuses(responses[2].data.data.find((itemStatus: ItemsStatuses) => itemStatus.item_status_id === itemPayload.item_status_id));
        setDefaultUnits(responses[4].data.data.find((unit: Unit) => unit.unit_id === itemPayload.unit_id));
        setDefaultKeys(responses[5].data.data.find((key: ProductServiceKey) => key.product_service_key_id === itemPayload.product_service_key_id));
        setDefaultUnits(responses[4].data.data.find((unit: Unit) => unit.unit_id === itemPayload.unit_id));
        setDefaultTaxObject(responses[6].data.data.find((taxobj: TaxObject) => taxobj.tax_object_id === itemPayload.tax_object_id));
        setDefaultRetention(responses[7].data.data.find((retention: Retention) => retention.retention_id === itemPayload.retention_id));
        setDefaultTax(responses[8].data.data.find((tax: Tax) => tax.tax_id === itemPayload.tax_id));
        setDefaultTariffFraction(responses[9].data.data.find((tariff: TariffFraction) => tariff.tariff_fraction_id === itemPayload.tariff_fraction_id));

        if (responses[3].data.data && responses[3].data.data.length > 0) {
          GenericGet(`/files/${responses[3].data.data[0].object_id}`)
            .then((response) => {
              let myDataUrl = `data:${responses[3].data.data[0].type_of_file};base64,${response.data.content}`
              setImageFile((prev) => ({ ...prev, dataURL: myDataUrl, file_id: responses[3].data.data[0].file_id }));
              setImageLoaded(true);
            });
        }

        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {dataLoaded &&
        <div className="d-flex justify-content-center">
          <div className='custom-card-view w-75'>
            <Button variant='outlined' className="d-flex justify-content-between w-100" onClick={() => setHideDetail(!hideDetail)}>
              <Typography fontWeight={"Bold"} fontSize={22}>{itemPayload.item_code} - {itemPayload.item_description}</Typography>
              {!hideDetail && <KeyboardArrowLeftIcon />}
              {hideDetail && <ExpandMoreIcon />}
            </Button>

            {hideDetail &&
              <Grow in={hideDetail}>

                <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                  <div className="custom-card-row d-flex justify-content-between">

                    <div className="w-100">

                      <div className="d-flex">

                        <div className="w-50">
                          <Controller
                            name="item_code"
                            control={control}
                            rules={{ required: true, maxLength: 50 }}
                            render={({ field }) =>
                              <TextField
                                variant="filled"
                                label={`${t("items.fields.item_code")}`}
                                ref={field.ref}
                                value={field.value}
                                onChange={(event) => { field.onChange(event.target.value) }}
                                error={!!errors.item_code}
                                helperText={GetError(errors.item_code?.type)}
                                size="small"
                                style={{ width: "100%" }}
                                sx={{ paddingRight: 2 }}
                                disabled={isEdit}
                              />
                            }
                          />
                        </div>

                        <div className="w-50">
                          <Controller
                            name="default_item_price"
                            control={control}
                            rules={{}}
                            render={({ field }) =>
                              <TextField
                                variant="filled"
                                label={`${t("items.fields.default_item_price")}`}
                                type='number'
                                ref={field.ref}
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                  "& input[type=number]": { MozAppearance: "textfield", }
                                }}
                                value={field.value}
                                onChange={(event) => { field.onChange(event.target.value) }}
                                error={!!errors.default_item_price}
                                helperText={GetError(errors.default_item_price?.type)}
                                size="small"
                                style={{ width: "100%" }}
                                disabled={isEdit}
                              />
                            }
                          />
                        </div>

                      </div>

                      <div className="my-2 pt-2">
                        <Controller
                          name="item_description"
                          control={control}
                          rules={{ required: true, maxLength: 300 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              ref={field.ref}
                              value={field.value}
                              label={`${t("items.fields.item_description")}`}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.item_description}
                              helperText={GetError(errors.item_description?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={isEdit}
                            />
                          }
                        />
                      </div>

                      <div className=" d-flex pt-3">

                        <div className="w-50">
                          <Controller
                            name="item_type_id"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%", paddingRight: 2 }}
                                options={comboItemTypes}
                                defaultValue={defaultItemType}
                                getOptionLabel={(option) => `${option.item_type_code} - ${option.item_type_name}`}
                                renderOption={(props, option: ItemType) => (
                                  <div key={option.item_type_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {option.item_type_code} - {option.item_type_name}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.item_type_id === value.item_type_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.item_type_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("items.fields.item_type_name")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.item_type_id}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>

                        <div className="w-50">

                          <Controller
                            name="item_family_id"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={comboItemFamilies}
                                defaultValue={defaultItemFamily}
                                getOptionLabel={(option) => `${option.item_family_code} - ${option.item_family_name}`}
                                renderOption={(props, option: ItemFamily) => (
                                  <div key={option.item_family_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {option.item_family_code} - {option.item_family_name}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.item_family_id === value.item_family_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.item_family_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="filled"
                                    label={`${t("items.fields.item_family_name")}`}
                                    value={field.value}
                                    error={!!errors.item_family_id}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />

                        </div>

                      </div>

                      <div className=" d-flex pt-3">

                        <div className="w-50">
                          <Controller
                            name="unit_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%", paddingRight: 2 }}
                                options={comboUnits}
                                defaultValue={defaultUnits}
                                getOptionLabel={(option) => `${option.unit_code} - ${option.unit_description}`}
                                renderOption={(props, option: Unit) => (
                                  <div key={option.unit_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {`${option.unit_code} - ${option.unit_description}`}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.unit_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("items.fields.unit_id")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.unit_id || field.value === null}
                                    helperText={(errors.unit_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                        <div className="w-50">
                          <Controller
                            name="product_service_key_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={comboKeys}
                                defaultValue={defaultKeys}
                                getOptionLabel={(option) => `${option.product_service_key} - ${option.description}`}
                                renderOption={(props, option: ProductServiceKey) => (
                                  <div key={option.product_service_key_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {`${option.product_service_key} - ${option.description}`}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.product_service_key_id === value.product_service_key_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.product_service_key_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("productservicekeys.fields.product_service_key")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.product_service_key_id || field.value === null}
                                    helperText={(errors.product_service_key_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className=" d-flex pt-3">
                        <div className="w-50">
                          <Controller
                            name="retention_id"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%", paddingRight: 2 }}
                                options={comboRetentions}
                                defaultValue={defaultRetention}
                                getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}%`}
                                renderOption={(props, option: Retention) => (
                                  <div key={option.retention_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {`${option.retention_name} - ${option.retention_percent}%`}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.retention_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("items.fields.retention")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.item_type_id}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                        <div className="w-50">
                          <Controller
                            name="tax_id"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={comboTaxes}
                                defaultValue={defaultTax}
                                getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}%`}
                                renderOption={(props, option: Tax) => (
                                  <div key={option.tax_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {`${option.tax_name} - ${option.tax_percent}%`}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.tax_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("items.fields.tax")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.item_type_id}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className='d-flex pt-3'>
                        <div className='w-50'>
                          <Controller
                            name="tax_object_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%", paddingRight: 2 }}
                                options={comboTaxObjects}
                                defaultValue={defaultTaxObject}
                                getOptionLabel={(option) => `${option.tax_object} - ${option.description}`}
                                renderOption={(props, option: TaxObject) => (
                                  <div key={option.tax_object_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {`${option.tax_object} - ${option.description}`}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.tax_object_id === value.tax_object_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.tax_object_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("items.fields.tax_object")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.tax_object_id}
                                    helperText={errors.tax_object_id?.type ? t("generic.forms-errors.required") : null}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                        <div className="w-50">
                          <Controller
                            name="item_status_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%", }}
                                options={comboItemsStatuses}
                                defaultValue={defaultItemsStatuses}
                                getOptionLabel={(option) => option.status_name}
                                renderOption={(props, option: ItemsStatuses) => (
                                  <div key={option.item_status_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {option.status_name}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.item_status_id === value.item_status_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.item_status_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("items.fields.status_name")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.item_status_id || field.value === null}
                                    helperText={(errors.item_status_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                    disabled={isEdit}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>

                      </div>

                      <div className='d-flex pt-3'>
                        <div className='w-50'>
                          <Controller
                            name="tariff_fraction_id"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={comboTariffFractions}
                                defaultValue={defaultTariffFraction}
                                getOptionLabel={(option) => `${option.tariff_fraction_code} - ${option.tariff_fraction_specific_description}`}
                                renderOption={(props, option: TariffFraction) => (
                                  <div key={option.tariff_fraction_id}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {`${option.tariff_fraction_code} - ${option.tariff_fraction_specific_description}`}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.tariff_fraction_id === value.tariff_fraction_id}
                                onChange={(_, values) => {
                                  field.onChange(values?.tariff_fraction_id || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("tarifffractions.title-view")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.item_type_id}
                                    disabled={isEdit}
                                    sx={{ paddingRight: 2 }}
                                  />
                                )}
                              />

                            )}
                          />
                        </div>
                        <div className='w-50'>
                          <Controller
                            name="weight"
                            control={control}
                            rules={{ maxLength: 23 }}
                            render={({ field }) =>
                              <TextField
                                variant="filled"
                                type='number'
                                inputProps={{ step: '0.00000001' }} // Limitar a 8 decimales
                                ref={field.ref}
                                sx={{
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                  "& input[type=number]": { MozAppearance: "textfield", }, width: "100%",
                                }}
                                value={field.value}
                                label={`${t("items.fields.weight")}`}
                                onChange={(event) => { field.onChange(event.target.value) }}
                                error={!!errors.weight}
                                helperText={GetError(errors.weight?.type)}
                                size="small"
                                disabled={isEdit}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex pt-3'>


                        <div className="w-50">
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                            <Typography sx={{ ml: 2 }}>{t("item-types.fields.sale")}</Typography>
                            <Switch defaultChecked={itemPayload.sale} disabled={true} />
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                            <Typography sx={{ ml: 2 }}>{t("item-types.fields.purchase")}</Typography>
                            <Switch defaultChecked={itemPayload.purchase} disabled={true} />
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                            <Typography sx={{ ml: 2 }}>{t("item-types.fields.stock_management")}</Typography>
                            <Switch defaultChecked={itemPayload.stock_management} disabled={true} />
                          </Box>
                        </div>
                      </div>
                    </div>
                    <Avatar className='mx-4' sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 190, width: 190 }} variant='rounded' alt={""}>
                      <Button component="label">
                        {imageLoaded &&
                          <ImageDataComponent url={imageFile.dataURL} />
                        }
                        {!imageLoaded &&
                          <img alt='profile-photo' width={190} height={190} src={require("../../../assets/img/profile.webp")} />
                        }
                        <input type="file" accept="image/*" hidden
                          onChange={async (e) => {
                            await DecodeFileToBase64(e).then(async (response) => {
                              onPostImage(response);
                            });
                          }
                          }
                        />
                      </Button>
                    </Avatar>

                  </div>

                  <div className='d-flex flex-row-reverse mt-4'>
                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                  </div>

                </form>

              </Grow>
            }

          </div>
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
