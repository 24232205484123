import { useContext, useEffect, useState } from 'react';
import { Header } from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Grid, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { User } from '../../../interfaces/Security/users';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../api/GenericPromises';
import { File } from '../../../interfaces/Commons/files';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import ComboLanguage from '../../../components/Commons/ComboLanguage';
import { AuthContext } from '../../../context/AuthContext';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useFiles } from '../../../hooks/useFiles';

export const Profile = () => {
  const [t] = useTranslation("global");
  const { user } = useContext(AuthContext);
  const { GetError } = FormsErrors();
  const { GenericPutResourceGeneric, GenericPost, GenericGet, GenericDelete, GenericGetResourceGeneric, GenericPut } = GenericPromises();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const { GetContentURLImage } = useFiles();
  const [objectId, setObjectId] = useState("");
  const [myProfileImage, setMyProfileImage] = useState<File>({ dataURL: "" });
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const { handleSubmit, control, reset, formState: { errors }, setValue, getValues } = useForm<User>({
    defaultValues: {
      user_name: "",
      language: "",
      user_phone: ""
    }
  });

  // Función para actualizar los datos del usuario
  const updateUser = async (data: User) => {
    let myUser = {
      user_name: data?.user_name,
      user_email: user?.user_email,
      password: user?.password,
      language: data?.language,
      photo_id: data.photo_id ?? null,
      user_phone: user?.user_phone,
      user_status: true,
      group_id: user?.group_id,
    };

    try {
      await GenericPutResourceGeneric(`/users/${user?.user_id}`, "/gusers", myUser);
      localStorage.setItem("language", data.language);
      showSnackBar(t("generic.snackbar.update"), "success");
      setLoadingPost(false);
    } catch (error: any) {
      setLoadingPost(false);
      showSnackBar(error.message, "error");
    }
  };

  // Función principal para manejar el submit del formulario
  const onSubmit: SubmitHandler<User> = async (data) => {
    setLoadingPost(true);
    await updateUser(data);
  };

  const decodeBase64 = async (e: any) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (getValues("photo_id")) {
        setLoadingPost(true);

        let myFileData = {
          entity: "Users",
          recordId: user?.user_id,
          fileName: file.name,
          typeOfFile: file.type,
          schema_name: 'dbo',
          Content: GetContentURLImage(await reader.result?.toString() ?? ""),
        }
        await GenericPut(`/files/${getValues("photo_id")}`, myFileData)
          .then(async (response) => {
            setObjectId(response.object_id);
            setMyProfileImage({ ...myProfileImage, file_id: response.file_id })
            let myUser = {
              photo_id: response.file_id,
            }
            await GenericPutResourceGeneric(`/users/${user?.user_id}`, "/gusers", myUser)
              .then(async () => {
                showSnackBar(t("generic.snackbar.update"), "success");
                await setValue("photo_id", response.file_id);
                await setMyProfileImage((prev) => ({ ...prev, dataURL: reader.result?.toString() }));
                await setMyProfileImage((prev) => ({ ...prev, file_name: file.name, type_of_file: file.type }));
                setLoadingPost(false);
              });
          });
      }
      else {
        setLoadingPost(true);
        let myFileData = {
          entity: "Users",
          recordId: user?.user_id,
          fileName: file.name,
          typeOfFile: file.type,
          schema_name: 'dbo',
          Content: GetContentURLImage(await reader.result?.toString() ?? ""),
        }
        await GenericPost('/files', myFileData)
          .then(async (response) => {
            setObjectId(response.object_id);
            setMyProfileImage({ ...myProfileImage, file_id: response.file_id })
            let myUser = {
              photo_id: response.file_id,
            }
            await GenericPutResourceGeneric(`/users/${user?.user_id}`, "/gusers", myUser)
              .then(async () => {
                showSnackBar(t("generic.snackbar.update"), "success");
                await setValue("photo_id", response.file_id);
                await setMyProfileImage((prev) => ({ ...prev, dataURL: reader.result?.toString() }));
                await setMyProfileImage((prev) => ({ ...prev, file_name: file.name, type_of_file: file.type }));
                setLoadingPost(false);
              });
          });

      }
    };
    reader.onerror = (error) => {
      console.log(error);
    };
  };

  useEffect(() => {
    setDataLoaded(false);
    setMyProfileImage({});
    GenericGetResourceGeneric(`/users/${user?.user_id}`, "/gusers")
      .then((responseUser) => {
        let userdata = responseUser.data;
        GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
          document.title = `${response.data.data[0].comercial_name} - ${t("profile.title")}`;
        });
        reset({ ...userdata });

        if (userdata?.photo_id !== null && userdata?.user_id !== undefined) {
          GenericGet(`/files/recordId/${userdata.user_id}/entity/Users/schema/dbo`)
            .then(async (response) => {
              let myFiles = response.data.data;
              if (myFiles !== undefined) {
                for (let index = 0; index < response.data.data.length; index++) {
                  const element = response.data.data[index];
                  await GenericGet(`/files/${element.object_id}`)
                    .then(async (response1) => {
                      element.dataURL = await `data:${element.type_of_file};base64,${response1.data.content}`;
                    });
                }

                if (myFiles && myFiles.length > 0) {
                  let photo: File;
                  photo = myFiles.find((photo: File) => photo.file_id === userdata?.photo_id) ?? {};
                  await setMyProfileImage(photo);
                  setObjectId(photo.object_id ?? '');
                  setDataLoaded(true);
                }
              }
            })
            .catch((error) => {
              setLoadingPost(false);
              showSnackBar(error.message, "error");
            });
        }
        setDataLoaded(true);
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("profile.title")} />
          <Grid
            container
            className='w-100'
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{ width: '100%', height: '60vh' }}
          >
            <Grid item sm={6} md={3} xs={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Button component="label">
                <Avatar sx={{ bgcolor: 'transparent', height: 250, width: 250, borderRadius: 10 }} variant='rounded' alt={user?.user_name}>
                  {(myProfileImage.dataURL === "" || myProfileImage.dataURL === undefined) ?
                    <img alt='profile' width={250} height={250} src={require("../../../assets/img/profile.webp")} /> :
                    <img alt='profile' width={250} height={250} src={myProfileImage.dataURL} />
                  }
                </Avatar>
                <input type="file" accept="image/*" hidden onChange={decodeBase64} />
              </Button>
            </Grid>
            <Grid item sm={6} md={6} xs={6} style={{ backgroundColor: '#F2F2F2', padding: 10, borderRadius: 10, width: '50%', height: 380 }} >
              <Typography className='d-flex flex-row-reverse mx-4' color={'primary'} fontSize={18}>{user?.user_email}</Typography>
              <form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
                <div>
                  <Controller
                    name="user_name"
                    control={control}
                    rules={{ required: true, minLength: 6, maxLength: 50 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        ref={field.ref}
                        value={field.value}
                        label={t("users.fields.user_name")}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.user_name}
                        helperText={GetError(errors.user_name?.type)}
                        size="small"
                        style={{ width: "100%" }}
                      />
                    }
                  />
                </div>
                <div className='mt-3'>
                  <Controller
                    name="user_phone"
                    control={control}
                    rules={{ minLength: 10, maxLength: 20 }}
                    render={({ field }) =>
                      <TextField
                        variant="filled"
                        ref={field.ref}
                        value={field.value}
                        label={t("users.fields.user_phone")}
                        onChange={(event) => { field.onChange(event.target.value) }}
                        error={!!errors.user_phone}
                        helperText={GetError(errors.user_phone?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", }
                        }}
                        type="number"
                      />
                    }
                  />
                </div>
                <div className='mt-3'>
                  <Controller
                    name="language"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ComboLanguage
                        ref={field.ref}
                        value={field.value}
                        defaultValue={user?.language}
                        onChange={(event) => { field.onChange(event?.id || null) }}
                        errors={errors}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </div>

                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' disabled={loadingPost} >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                </div>
              </form>
            </Grid>
          </Grid>
        </>
      }
      <SnackbarComponent />
    </>
  );
};
