import React, { Dispatch, SetStateAction, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Locations } from './locations'
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Merchandise } from './merchandise';
import { General } from './general';
import GenericPromises from '../../../../api/GenericPromises';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MerchandiseDetail, TransportationDocument, TransportationDocumentLocation } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import { Driver } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/drivers';
import { Transportation } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportations';
import { Chassis } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/chassis';
import { CustomerLocation } from '../../../../interfaces/Sales/Catalogs/Customers/customers';
import { CompanyLocation } from '../../../../interfaces/Security/companies';
import { TransportationDocumentChassis } from './chassis';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { SalesInvoice } from '../../../../interfaces/Sales/Invoices/salesinvoices';
import { Divider } from '../../../../components/Commons/Divider';
import { Menuitem } from '../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useLocation } from 'react-router-dom';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { ButtonLoading } from '../../../../theme/buttons';
import { useParamsFilter } from '../../../../hooks/useParamsFilter';

type IndexTransportationDocumentProps = {
  isAccounted: boolean;
  company_rfc?: string;
  customer_id?: number;
  salesInvoicePayload?: SalesInvoice;
  setLoadingPut: Dispatch<SetStateAction<boolean>>,
  loadingPut: boolean
}

export const IndexTransportationDocument = forwardRef(({
  isAccounted,
  customer_id,
  salesInvoicePayload,
  company_rfc,
  loadingPut,
  setLoadingPut
}: IndexTransportationDocumentProps, ref) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const [hasTransportation, setHasTransportation] = useState(false);
  const [rows, setRows] = useState<MerchandiseDetail[]>([]);
  const [sourceDate, setSourceDate] = useState<Date>();
  const [newLocation, setNewLocation] = useState(false);
  const { GenericGetResource, GenericPutResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const { GetParamsFromBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboDrivers, setComboDrivers] = useState<Driver[]>([]);
  const [defaultDriver, setDefaultDriver] = useState<Driver>();
  const [comboTransportations, setComboTransportations] = useState<Transportation[]>([]);
  const [defaultTransportation, setDefaultTransportation] = useState<Transportation>();
  const [defaultCompanyLocation, setDefaultCompanyLocation] = useState<CompanyLocation>();
  const [defaultCustomerLocation, setDefaultCustomerLocation] = useState<CustomerLocation>();
  const [defaultCustomerLocation2, setdefaultCustomerLocation2] = useState<CustomerLocation>();
  // const [comboChassis, setComboChassis] = useState<Chassis[]>([]);
  // const [defaultChassis1, setDefaultChassis1] = useState<Chassis>();
  // const [defaultChassis2, setDefaultChassis2] = useState<Chassis>();
  const [transportationDocumentLocations, setTransportationDocumentLocations] = useState<TransportationDocumentLocation[]>([]);
  const [comboCustomerLocations, setComboCustomerLocations] = useState<CustomerLocation[]>([]);
  const [comboCompanyLocations, setComboCompanyLocations] = useState<CompanyLocation[]>([]);
  const [transportationDocumentLocal, setTransportationDocumentLocal] = useState<TransportationDocument>();
  const [resourceScreenTransportationDocument, setResourceScreenTransportationDocument] = useState<Menuitem>();
  const [resourceScreenLocations, setResourceScreenLocations] = useState<Menuitem>();
  const [resourceScreenMerchandise, setResourceMerchandise] = useState<Menuitem>();
  const [checkDate, setCheckDate] = useState(false);
  const [haveDangerousMaterial, setHaveDangerousMaterial] = useState(false);
  const [isChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const [isOriginExists, setIsOriginExists] = useState(false);

  const divView = useRef<any>(null);

  useImperativeHandle(ref, () => {
    return {
      async putTransportationDocument() {
        try {
          await onPut(getValues());
          return true
        }
        catch {
          return false;
        }
      },
      // checkmerchandises() {
      //   var dangerousMerchandises = rows.filter((merchandise: MerchandiseDetail) => merchandise.is_dangerous_material === true)
      //   if (!(rows.find((merchandise: MerchandiseDetail) => merchandise.unit_code === undefined) ||
      //     rows.find((merchandise: MerchandiseDetail) => merchandise.unit_code === null) ||
      //     rows.find((merchandise: MerchandiseDetail) => parseInt(merchandise.quantity ?? "0") <= 0) ||
      //     rows.find((merchandise: MerchandiseDetail) => parseInt(merchandise.weight_in_kg ?? "0") <= 0) ||
      //     (dangerousMerchandises.length > 0 && (dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.hazardous_material_key === undefined) ||
      //       dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.packaging_type_code === undefined) ||
      //       dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.hazardous_material_key === null) ||
      //       dangerousMerchandises.find((merchandise: MerchandiseDetail) => merchandise.packaging_type_code === null)))
      //   )) {
      //     divView.current.scrollTop = divView.current?.scrollHeight;
      //     return false;
      //   }
      // }
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    reset
  } = useForm<TransportationDocument>({
    defaultValues: {
      driver_id: undefined,
    }
  });
  const onSubmit: SubmitHandler<TransportationDocument> = (data) => onPut(data);

  const onPut = (data: TransportationDocument) => {
    trigger()
      .then(async (responseTrigger) => {
        if (responseTrigger) {
          setLoadingPut(true)
          let myDriver, myTransportation, myTransportationDocument: TransportationDocument = {}, withoutData = false;
          //traer datos si es que se contabiliza
          if (comboDrivers.length < 1 || comboTransportations.length < 1) {

            let myPermissions = [
              GenericGetResource("/drivers"),
              GenericGetResource("/transportations"),
              GenericGetResource(`/transportationdocuments/bysalesinvoiceid/${salesInvoicePayload?.sales_invoice_id}`),
            ];
            await Promise.all(myPermissions)
              .then((responses) => {
                myDriver = responses[0].data.data.find((item: Driver) => item.driver_id === data.driver_id);
                myTransportation = responses[1].data.data.find((item: Transportation) => item.transportation_id === data.transportation_id);
                myTransportationDocument = responses[2].data.data[0];
                withoutData = true;
              }).catch((error) => {
                showSnackBar(error.message, "error");
                setLoadingPut(false)
              })
          }
          else {
            myDriver = comboDrivers.find((item: Driver) => item.driver_id === data.driver_id);
            myTransportation = comboTransportations.find((item: Transportation) => item.transportation_id === data.transportation_id);
          }

          let myData = {
            sales_invoice_id: salesInvoicePayload?.sales_invoice_id,
            transportation_id: data.transportation_id,
            vehicle_config_code: myTransportation?.vehicle_config_code,
            transportation_code: myTransportation?.transportation_code,
            plate_number: myTransportation?.plate_number,
            vin: myTransportation?.vin,
            model_name: myTransportation?.model_name,
            model_year: myTransportation?.model_year,
            motor_number: myTransportation?.motor_number,
            permission_sct: myTransportation?.permission_sct,
            permission_number_sct: myTransportation?.permission_number_sct,
            transportation_weight: myTransportation?.transportation_weight,
            insurance_company: myTransportation?.insurance_company,
            insurance_policy: myTransportation?.insurance_policy,
            insurance_expiration_date: myTransportation?.insurance_expiration_date,
            driver_id: data.driver_id,
            driver_type_id: myDriver?.driver_type_id,
            driver_name: myDriver?.driver_name,
            driver_curp: myDriver?.driver_curp,
            driver_rfc: myDriver?.driver_rfc,
            driver_license: myDriver?.driver_license,
            transportation_document_date: data.transportation_document_date,
            id_cpp: data.id_cpp,
            is_transport_international: data.is_transport_international,
            departure_date: new Date(),
            arrival_date: new Date(),
            gross_total_weight: data.gross_total_weight,
            identification_guide_number: data.identification_guide_number,
            identification_guide_description: data.identification_guide_description,
            identification_guide_weight: data.identification_guide_weight,
            weight_unit: data.weight_unit,
            total_distance: data.total_distance,
            environmental_insurance_company: data.environmental_insurance_company,
            environmental_insurance_policy: data.environmental_insurance_policy
          }
          GenericPutResource(`/transportationdocuments/${transportationDocumentLocal?.transportation_document_id ?? myTransportationDocument?.transportation_document_id}`, myData)
            .then(() => {
              if (!withoutData) {
                showSnackBar(t("generic.snackbar.update"), "success");
              }
              setLoadingPut(false)
            }).catch((error) => {
              showSnackBar(error.message, "error");
              setLoadingPut(false);
            });
        }
      });
  }

  const ChangeTotalKm = () => {
    GenericGetResource(`/transportationdocuments/${transportationDocumentLocal?.transportation_document_id}`)
      .then((response) => {
        setValue("total_distance", response.data.total_distance);
        setIsChangedTotalValue((prev) => !prev);
      });
  };

  const ChangeTotalMerchandise = async () => {
    await GenericGetResource(`/transportationdocuments/${transportationDocumentLocal?.transportation_document_id}`)
      .then((response) => {
        setValue("total_merchandise", response.data.total_merchandise);
        setValue("gross_total_weight", response.data.gross_total_weight);
        setIsChangedTotalValue((prev) => !prev);
      });
  };

  const getMySalesInvoiceIdFromParams = (): string | undefined => {
    let salesInvoiceId;
    let myCustomerId;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      salesInvoiceId = myParams.get("sales_invoice_id");
      myCustomerId = myParams.get("customer_id") ?? '';
    }
    return salesInvoiceId ?? undefined;
  }

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/drivers"),
      GenericGetResource("/transportations"),
      GenericGetResource("/chassis"),
      GenericGetResource(`/customerlocations/bycustomerid/${customer_id}`),
      GenericGetResourceGeneric(`/companylocations`, `/gcompanylocations`),
      GenericGetResource(`/transportationdocuments/bysalesinvoiceid/${salesInvoicePayload?.sales_invoice_id}`),
      GenericGetResourceGeneric(`/companylocations`, `/gcompanylocations`),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {

        setComboDrivers((prev) => responses[0].data.data);
        setComboTransportations((prev) => responses[1].data.data);
        // setComboChassis((prev) => responses[2].data.data);
        setComboCustomerLocations((prev) => responses[3].data.data);
        setComboCompanyLocations((prev) => responses[4].data.data);
        setTransportationDocumentLocal((prev) => responses[5].data.data[0]);

        // settear datos de entidad
        reset({ ...responses[5].data.data[0], weight_unit: 'KGM' });

        // historico de Driver y Transportation 
        if (getValues("driver_name")) {
          let myDriver: Driver = {
            driver_id: 0,
            driver_type_id: getValues("driver_type_id") ?? 0,
            driver_name: getValues("driver_name") ?? '',
            driver_curp: getValues("driver_curp") ?? '',
            driver_rfc: getValues("driver_rfc") ?? '',
            driver_license: getValues("driver_license") ?? '',
          }
          setDefaultDriver((prev) => myDriver);
        }
        if (getValues("transportation_code")) {
          let myTransportation: Transportation = {
            transportation_id: 0,
            vehicle_config_code: getValues("vehicle_config_code") ?? '',
            transportation_code: getValues("transportation_code") ?? '',
            plate_number: getValues("plate_number") ?? '',
            vin: getValues("vin") ?? '',
            model_name: getValues("model_name") ?? '',
            model_year: getValues("model_year") ?? '',
            motor_number: getValues("motor_number") ?? '',
            permission_sct: getValues("permission_sct") ?? '',
            permission_number_sct: getValues("permission_number_sct") ?? '',
            transportation_weight: Number(getValues("transportation_weight")) ?? undefined,
            insurance_company: getValues("insurance_company") ?? '',
            insurance_policy: getValues("insurance_policy") ?? '',
            insurance_expiration_date: responses[5].data.data[0].insurance_expiration_date
          }
          setDefaultTransportation((prev) => myTransportation);
        }
        await GenericGetResource(`/transportationdocumentlocations/bytransportationdocumentid/${responses[5].data.data[0].transportation_document_id}`)
          .then(async (responselocations) => {
            setTransportationDocumentLocations((prev) => responselocations.data.data);

            let mySourceLocation = responselocations.data.data.find((item: TransportationDocumentLocation) => item.location_type === 'Origen');
            if (!mySourceLocation) {
              let comboCompanyLocations: CompanyLocation[] = await responses[6].data.data;
              let myDefaultCompanyLocation = comboCompanyLocations.find((item) => item.main_location === true);
              let myCompanyLocation: CompanyLocation = {
                company_location_id: myDefaultCompanyLocation ? 0 : undefined,
                street: myDefaultCompanyLocation?.street,
                city_name: myDefaultCompanyLocation?.city_name,
                state_name: myDefaultCompanyLocation?.state_name,
                state_abbr: myDefaultCompanyLocation?.state_abbr,
                country_name: myDefaultCompanyLocation?.country_name,
                country_code: myDefaultCompanyLocation?.country_code,
                postal_code: myDefaultCompanyLocation?.postal_code
              }
              setDefaultCompanyLocation((prev) => myCompanyLocation);
            }

            if (responselocations.data.data.length <= 1) {
              let myCustomerLocation: CustomerLocation = {
                customer_location_id: 0,
                street: salesInvoicePayload?.customer_street_name,
                city_name: salesInvoicePayload?.customer_city_name,
                state_name: salesInvoicePayload?.customer_state_name,
                state_abbr: salesInvoicePayload?.customer_state_abbr,
                country_code: salesInvoicePayload?.customer_country_code,
                country_name: salesInvoicePayload?.customer_country_name,
                postal_code: salesInvoicePayload?.customer_postal_code,
                customer_rfc: salesInvoicePayload?.customer_rfc,
              }
              setDefaultCustomerLocation((prev) => myCustomerLocation);
            }

            if (responselocations.data.data.length > 2) { setNewLocation((prev) => true) }

          }).catch((error) => {
            showSnackBar(error.message, "error");
          });

        if (transportationDocumentLocal !== undefined || ((location.state === null) ? false : location.state.row.transportation_document_id) || responses[5].data.data[0].transportation_document_id) {
          let myPromises = [
            GenericGetResource(`/merchandisedetails/bytransportationdocumentid/${transportationDocumentLocal?.transportation_document_id ?? (((location.state === null) ? undefined : location.state.row.transportation_document_id) ?? responses[5].data.data[0].transportation_document_id)}`),
          ];
          Promise.all(myPromises)
            .then((responses) => {
              responses[0] && setRows(responses[0].data.data);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
            });
        }

        let myPermissions = [
          GetResourceByUrl("/transportationdocuments"),
          GetResourceByUrl("/transportationdocumentlocations"),
          GetResourceByUrl("/merchandisedetails"),
          GenericGetResource(`/salesinvoicedetails/hastransportation/${transportationDocumentLocal?.sales_invoice_id ?? (((location.state === null) ? undefined : location.state.row.sales_invoice_id) ?? responses[5].data.data[0].sales_invoice_id)}`),
        ];

        await Promise.all(myPermissions)
          .then((responsePermissions) => {
            setResourceScreenTransportationDocument((prev) => responsePermissions[0]);
            setResourceScreenLocations((prev) => responsePermissions[1]);
            setResourceMerchandise((prev) => responsePermissions[2]);
            responsePermissions[3] ? setHasTransportation(responsePermissions[3].data) : setHasTransportation(false);

            if (responsePermissions[0].read) { setDataLoaded(true); }
            if (salesInvoicePayload?.seal_SAT) {
              setResourceScreenTransportationDocument((prev) => {
                let myPermission = prev;
                myPermission && (myPermission.update = false);
                myPermission && (myPermission.delete = false);
                return myPermission;
              });
              setResourceScreenLocations((prev) => {
                let myPermission = prev;
                myPermission && (myPermission.update = false);
                myPermission && (myPermission.delete = false);
                return myPermission;
              });
              setResourceMerchandise((prev) => {
                let myPermission = prev;
                myPermission && (myPermission.update = false);
                myPermission && (myPermission.delete = false);
                return myPermission;
              });
            }
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  useEffect(() => {
    setHaveDangerousMaterial(false);
    for (const element of rows) {
      if (
        (element.is_dangerous_material) ||
        ((element.could_be_hazardous_material) &&
          ((element.packaging_type_code !== undefined && element.packaging_type_code !== null) ||
            (element.hazardous_material_key !== undefined && element.hazardous_material_key !== null)))
      ) {
        setHaveDangerousMaterial(true);
        break; // Exit the loop early
      }
    }
  }, [rows])

  useEffect(() => { }, [sourceDate]);

  useEffect(() => {
    GenericGetResource(`/salesinvoicedetails/hastransportation/${transportationDocumentLocal?.sales_invoice_id ?? getMySalesInvoiceIdFromParams() ?? ((location.state === null) ? undefined : location.state.row.sales_invoice_id)}`)
      .then((response) => {
        response ? setHasTransportation(response.data) : setHasTransportation(false);
      });
  }, [isAccounted]);

  useEffect(() => { setCheckDate(false) }, [checkDate]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex flex-row-reverse mb-2 w-100">
              {!isAccounted && resourceScreenTransportationDocument?.update &&
                <ButtonLoading isLoading={loadingPut} onClick={() => onPut(getValues())} className='w-25' size="small" variant='outlined'>{t("generic.buttons.save")}</ButtonLoading>
              }
            </div>
            <div className='custom-card-view mb-2'>
              <General
                isEditIndex={(isAccounted) ? true :
                  (resourceScreenTransportationDocument) ? !resourceScreenTransportationDocument?.update :
                    true}
                haveDangerousMaterial={haveDangerousMaterial}
                control={control}
                errors={errors}
                reset={reset}
                comboDrivers={comboDrivers}
                defaultDriver={defaultDriver}
                comboTransportations={comboTransportations}
                defaultTransportation={defaultTransportation}
                resourceScreenTransportationDocument={resourceScreenTransportationDocument}
              />
            </div>
            {/* <div className='custom-card-view mb-2'>
            <TransportationDocumentChassis
              comboChassis={comboChassis}
              defaultChassis1={defaultChassis1}
              defaultChassis2={defaultChassis2}
            />
          </div> */}
            {resourceScreenLocations?.read &&
              <div className='custom-card-view'>
                <div className='d-flex flex-row-reverse'>
                  {t("transportationdocuments.fields.total_distance")}: {getValues("total_distance")} KM
                </div>
                {!checkDate &&
                  <Locations
                    setCheckDate={setCheckDate}
                    isEditIndex={isAccounted}
                    defaultCompanyLocation={defaultCompanyLocation}
                    title={t("transportationdocuments.locations.source")}
                    comboCompanyLocations={comboCompanyLocations}
                    transportationDocumentLocations={transportationDocumentLocations}
                    setTransportationDocumentLocations={setTransportationDocumentLocations}
                    transportation_document_id={transportationDocumentLocal?.transportation_document_id ?? 0}
                    rfc={company_rfc ?? ""}
                    sourceDate={sourceDate}
                    setSourceDate={setSourceDate}
                    isSource={true}
                    row_number={0}
                    setNewLocation={setNewLocation}
                    resourceScreenLocations={resourceScreenLocations}
                    ChangeTotalKm={ChangeTotalKm}
                    setIsOriginExists={setIsOriginExists}
                  />
                }
                {!sourceDate && (transportationDocumentLocations.length > 1) && <div className='m-2'><Spinner isBox={false} /></div>}
                {isOriginExists &&
                  <>
                    <Divider />
                    <Locations
                      isEditIndex={isAccounted}
                      defaultCustomerLocation={defaultCustomerLocation}
                      title={t("transportationdocuments.locations.target")}
                      comboCustomerLocations={comboCustomerLocations}
                      transportationDocumentLocations={transportationDocumentLocations}
                      setTransportationDocumentLocations={setTransportationDocumentLocations}
                      transportation_document_id={transportationDocumentLocal?.transportation_document_id ?? 0}
                      rfc={salesInvoicePayload?.company_rfc ?? ""}
                      sourceDate={sourceDate}
                      setSourceDate={setSourceDate}
                      row_number={1}
                      setNewLocation={setNewLocation}
                      resourceScreenLocations={resourceScreenLocations}
                      ChangeTotalKm={ChangeTotalKm}
                      setIsOriginExists={setIsOriginExists}
                    />
                    <>
                      <Divider />
                      {newLocation &&
                        <>
                          <Locations
                            isEditIndex={isAccounted}
                            defaultCustomerLocation={defaultCustomerLocation2}
                            title={t("transportationdocuments.locations.target")}
                            comboCustomerLocations={comboCustomerLocations}
                            transportationDocumentLocations={transportationDocumentLocations}
                            setTransportationDocumentLocations={setTransportationDocumentLocations}
                            transportation_document_id={transportationDocumentLocal?.transportation_document_id ?? 0}
                            rfc={salesInvoicePayload?.company_rfc ?? ""}
                            sourceDate={sourceDate}
                            setSourceDate={setSourceDate}
                            row_number={2}
                            setNewLocation={setNewLocation}
                            resourceScreenLocations={resourceScreenLocations}
                            ChangeTotalKm={ChangeTotalKm}
                            setIsOriginExists={setIsOriginExists}
                          />
                        </>
                      }
                      {!newLocation && transportationDocumentLocations.length > 1 && !isAccounted &&
                        <Button variant='outlined' disabled={(resourceScreenLocations.create) ? false : true} onClick={() => setNewLocation((prev) => true)}>{t("transportationdocuments.locations.addlocation")}</Button>
                      }
                    </>
                  </>
                }
              </div>
            }
            {resourceScreenMerchandise?.read &&
              <div ref={divView} className='custom-card-view mt-2'>
                <Merchandise
                  setRows={setRows}
                  rows={rows}
                  isEditIndex={isAccounted}
                  hasTransportation={hasTransportation}
                  getValues={getValues}
                  transportationDocumentPayload={transportationDocumentLocal}
                  ChangeTotalMerchandise={ChangeTotalMerchandise}
                  resourceScreenMerchandise={resourceScreenMerchandise}
                />
              </div>
            }
            <div className="d-flex flex-row-reverse mt-2 w-100">
              {!isAccounted && resourceScreenTransportationDocument?.update &&
                <ButtonLoading isLoading={loadingPut} onClick={() => onPut(getValues())} className='w-25' size="small" variant='outlined'>{t("generic.buttons.save")}</ButtonLoading>
              }
            </div>
          </form>
        </>
      }
      <SnackbarComponent />
    </>
  )
})