import React, { useEffect, useState } from "react";
import { TaskCard } from "../components/taskCard";
import { Autocomplete, TextField, Typography, Box, Grid } from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useTranslation } from "react-i18next";
import { useBase64 } from "../../../../hooks/useBase64";
import { ProjectTaskDashboard } from "../../../../interfaces/Projects/projectTaskDashboard";
import { DashBoardFilter } from "../components/DashboardFilter";
import { TaskState } from "../../../../interfaces/Projects/Catalogs/taskStates";
import { ProjectStage } from "../../../../interfaces/Projects/Catalogs/projectStages";
import { Project } from "../../../../interfaces/Projects/projects";
import { Parameter } from "../../../../interfaces/Commons/parameters";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { UpdateProjectTask } from "../../Catalogs/ProjectDetails/Details/ProjectTask/update";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { Header } from "../../../../components/Header";
import { FieldType } from "../../../../interfaces/Projects/Catalogs/fieldTypes";

interface TableProjectTaskListProps {
  color: number[] | undefined;
  isEdit: boolean;
  projectGroupId: number | undefined;
  taskStatesLocal: TaskState[];
  projectStagesLocal: ProjectStage[];
  projectsLocal: Project[];
  parametersLocal: Parameter[];
  pendingState: TaskState | undefined;
  doneState: TaskState | undefined;
  resourceScreen: Menuitem;
  comboFieldTypes: FieldType[]
}

export const TableProjectTaskList = ({ color,
  isEdit,
  projectGroupId,
  taskStatesLocal,
  projectStagesLocal,
  projectsLocal,
  parametersLocal,
  pendingState,
  doneState,
  resourceScreen,
  comboFieldTypes
}: TableProjectTaskListProps) => {
  const [t] = useTranslation("global");
  const { utf8ToBase64 } = useBase64();
  const [comboEnum, setComboEnum] = useState([
    { enum_id: 1, option_name: t("projecttaskdashboard.comboEnum.all"), route: `/projecttaskdashboard?filter=${utf8ToBase64(`user_group_id=${projectGroupId}`)}` },
    { enum_id: 2, option_name: t("projecttaskdashboard.comboEnum.onlypending"), route: `/projecttaskdashboard?filter=${utf8ToBase64(`current_task_state_id=${pendingState?.task_state_id}&user_group_id=${projectGroupId}`)}` },
    { enum_id: 3, option_name: t("projecttaskdashboard.comboEnum.onlydone"), route: `/projecttaskdashboard?filter=${utf8ToBase64(`current_task_state_id=${doneState?.task_state_id}&user_group_id=${projectGroupId}`)}` },
  ]);
  const [myEnumValue, setMyEnumValue] = useState({ enum_id: 2, option_name: t("projecttaskdashboard.comboEnum.onlypending"), route: `/projecttaskdashboard?filter=${utf8ToBase64(`current_task_state_id=${pendingState?.task_state_id}&user_group_id=${projectGroupId}`)}` });
  const [defaultEnum, setDefaultEnum] = useState({ enum_id: 2, option_name: t("projecttaskdashboard.comboEnum.onlypending"), route: `/projecttaskdashboard?filter=${utf8ToBase64(`current_task_state_id=${pendingState?.task_state_id}&user_group_id=${projectGroupId}`)}` },);
  const [dataloaded, setDataLoaded] = useState(false);
  const [projectTasksLocal, setProjectTasksLocal] = useState<ProjectTaskDashboard[]>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource } = GenericPromises();
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [filterProjectTasksData, setFilterProjectTasksData] = useState<ProjectTaskDashboard[]>([])
  const [filterValue, setFilterValue] = useState<string>('');
  const [openDialogUpdateTask, setOpenDialogUpdateTask] = useState(false);
  const [projectProjectTask, setProjectProjectTask] = useState<Project>();
    const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();

      const handleUpdateTask = (updateTask: ProjectTaskDashboard) => {
        if (filterProjectTasksData.length === 0) {
          return;
        }
        setFilterProjectTasksData((prevTasks) => {
          const taskToUpdateIndex = filterProjectTasksData.findIndex(e => e.project_task_id === updateTask.project_task_id);
          return prevTasks.map((task, index) =>
            index === taskToUpdateIndex ? updateTask : task,
          );
        });
      };
    
      const handleDeleteTask = (deleteRow: number) => {
        if (filterProjectTasksData.length === 0) {
          return;
        }
    
        setFilterProjectTasksData((prevRows) => {
          const rowToDeleteIndex = filterProjectTasksData.findIndex(
            (e) => e.project_task_id === deleteRow
          );
    
          const updatedData = [
            ...filterProjectTasksData.slice(0, rowToDeleteIndex),
            ...filterProjectTasksData.slice(rowToDeleteIndex + 1),
          ];
    
          return updatedData;
        });
      };

  const handleFilter = (searchTerm: string) => {
    setFilterValue(searchTerm);
    if (projectTasksLocal) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filteredData = projectTasksLocal.filter((projecttask) =>
        Object.keys(projecttask).some((key) => {
          const fieldValue = projecttask[key as keyof ProjectTaskDashboard];
          return (
            typeof fieldValue === 'string' &&
            fieldValue.toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
      setFilterProjectTasksData(filteredData);
    }
  };

  const clearFilter = () => {
    setFilterValue('');
    setFilterProjectTasksData(projectTasksLocal);
  };

  useEffect(() => {
    if (myEnumValue) {
      setIsLoadingDataTable(false);
      GenericGetResource(myEnumValue.route)
        .then(async (response) => {
          await setProjectTasksLocal((prev) => response.data.data);
          await setFilterProjectTasksData((prev) => response.data.data);
          setIsLoadingDataTable(true);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  }, [myEnumValue]);

  useEffect(() => {
    if (!projectGroupId) {
      setDataLoaded(true);
      return;
    }
    GenericGetResource(myEnumValue.route)
      .then((response) => {
        setProjectTasksLocal(response.data.data);
        setFilterProjectTasksData(response.data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && (
        <>
          <div className="d-flex justify-content-between">
            <Autocomplete
              size="small"
              options={comboEnum}
              defaultValue={defaultEnum}
              getOptionLabel={(option) => option.option_name}
              renderOption={(props, option) => (
                <div key={option.enum_id}>
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.option_name}
                  </Box>
                </div>
              )}
              sx={{ width: "20%", paddingRight: 1 }}
              isOptionEqualToValue={(option, value) =>
                option.enum_id === value.enum_id
              }
              onChange={(_, values) => {
                setMyEnumValue(
                  values ?? {
                    enum_id: 3, option_name: t("projecttaskdashboard.comboEnum.onlydone"), route: `/projecttaskdashboard?filter=${utf8ToBase64(`current_task_state_id=${doneState?.task_state_id}&user_group_id=${projectGroupId}`)}`,
                  }
                );
              }}
              renderInput={(params) => (
                <TextField {...params}
                  label={`${t("generic.filter")}`}
                  variant="filled" />
              )}
              clearOnEscape={false}
              clearIcon={null}
            />
            <DashBoardFilter
              onFilterChange={handleFilter}
              onClearFilter={clearFilter}
              filterValue={filterValue}
            />
          </div>
          {!isLoadingDataTable && <Spinner />}
          {isLoadingDataTable && filterProjectTasksData.length > 0 ? (
            filterProjectTasksData.map((task) => (
              <TaskCard
                key={task.project_task_id}
                task={task}
                color={color}
                isEdit={isEdit}
                handleUpdateTask={handleUpdateTask}
                taskStatesLocal={taskStatesLocal}
                projectStagesLocal={projectStagesLocal}
                projectsLocal={projectsLocal}
                parametersLocal={parametersLocal}
                pendingState={pendingState}
                doneState={doneState}
                setProjectProjectTask={setProjectProjectTask}
                setOpenDialogUpdateTask={setOpenDialogUpdateTask}
                setSelectedTaskId={setSelectedTaskId}
              />
            ))
          ) : (
            <Box
              sx={{
                justifyContent: "center",
                marginBlock: 2,
                display: "flex",
              }}
            ><Typography>{t('projecttaskdashboard.messages.no-tasks')}</Typography></Box>
          )}
        </>
      )}

      <DialogEntity
        content={
          <UpdateProjectTask
            permissions={resourceScreen}
            setOpenDialogUpdateTask={setOpenDialogUpdateTask}
            handleUpdateTask={handleUpdateTask}
            handleDeleteTask={handleDeleteTask}
            project_id={projectProjectTask?.project_id}
            projectTaskPayload={
              filterProjectTasksData?.find(
                (task) => task.project_task_id === selectedTaskId
              ) || {}
            }
            comboFieldTypes={comboFieldTypes}
          />
        }
        open={openDialogUpdateTask}
        setOpen={setOpenDialogUpdateTask}
        title={
          <Header
            title={t("projecttasks.title-view")}
            size="sm"
          />
        }
      />

      <SnackbarComponent />
    </>
  );
};
