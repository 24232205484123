import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDates } from "../../../../hooks/useDates";
import GenericPromises from "../../../../api/GenericPromises";
import { AuthContext } from "../../../../context/AuthContext";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { usePermissions } from "../../../../hooks/usePermissions";
import { ProjectGroup } from "../../../../interfaces/Projects/Catalogs/projectGroups";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { PrimaryButton } from "../../../../theme/buttons";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../components/Header";
import { AddProjectGroups } from "./add";
import { UpdateProjectGroups } from "./update";

type TableProjectGroupsProps = {
  project_id: number,
  project_name: string,
  isClosedProject: boolean,
  resourceScreenGroup: Menuitem | undefined
}

export const TableProjectGroups = ({
  project_id,
  project_name,
  isClosedProject,
  resourceScreenGroup,
}: TableProjectGroupsProps) => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { GenericGetResource } = GenericPromises();
  const { user } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [projectGroupsData, setProjectGroupsData] = useState<GridRowsProp>([]);
  const [projectGroupPayload, setProjectGroupPayload] = useState({});
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [columnsProjectGroups, setColumnsProjectGroups] = useState<GridColDef<ProjectGroup>[]>([
    { field: 'group_name', headerName: t("projectgroups.fields.group_name"), headerClassName: 'header-grid-table', flex: 1, },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-projectgroups");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-projectgroups-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<ProjectGroup, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) {

        }
        else {
          element.headerName = t(`customers.fields.${element.field}`);
        }
      }
      setColumnsProjectGroups(myJson);
    }
  }

  const handleAddRow = (newRow: ProjectGroup) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setProjectGroupsData((prevState) => [...projectGroupsData, myRow]);
  };

  const handleUpdateRow = (updateRow: ProjectGroup) => {
    if (projectGroupsData.length === 0) {
      return;
    }
    setProjectGroupsData((prevRows) => {
      const rowToUpdateIndex = projectGroupsData.findIndex(e => e.project_group_id === updateRow.project_group_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (projectGroupsData.length === 0) {
      return;
    }
    setProjectGroupsData((prevRows) => {
      const rowToDeleteIndex = projectGroupsData.findIndex(e => e.project_group_id === deleteRow);
      return [
        ...projectGroupsData.slice(0, rowToDeleteIndex),
        ...projectGroupsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    GenericGetResource(`/projectgroups/byprojectid/${project_id}`)
      .then(
        (response) => {
          setProjectGroupsData(response.data.data);
          loadColumnsOrder();
          loadPreferences();
          setDataLoaded(true);
        }).catch((error) => {
          showSnackBar(error.message, "error");
          setMessageSnack(error.message);
          setUnauthorized(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && resourceScreenGroup?.read &&
        <>
          <div className='screen-container'>
            {resourceScreenGroup?.create === true &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }} disabled={isClosedProject}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsProjectGroups}
                setColumns={setColumnsProjectGroups}
                data={projectGroupsData}
                entityId={"project_group_id"}
                entity={`ProjectGroups`}
                preferences={myPreferences}
                namePreferences={"grid-projectgroups"}
                nameOrderColumns={"grid-projectgroups-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setProjectGroupPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateProjectGroups project_name={project_name} projectGroupPayload={projectGroupPayload} setOpenDialogUpdate={setOpenDialogUpdate} permissions={resourceScreenGroup} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} isClosedProject={isClosedProject} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("projectgroups.title-view")} size='sm' />}
          />
          <DialogEntity
            content={<AddProjectGroups setOpenDialog={setOpenDialogAdd} project_id={project_id} project_name={project_name} AddNewRow={handleAddRow} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("projectgroups.title-view")} size='sm' />}
          />
          <SnackbarComponent />

        </>
      }
    </>

  )
}