import { Autocomplete, Backdrop, Box, Button, CircularProgress, ListItemButton, Popover, TextField, useTheme } from "@mui/material";
import { ButtonStyled, myStylePickers, PrimaryButton } from "../../../theme/buttons";
import { Header } from "../../../components/Header";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Project } from "../../../interfaces/Projects/projects";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Customer, CustomerLocation } from "../../../interfaces/Sales/Catalogs/Customers/customers";
import { UserCompanies } from "../../../interfaces/Security/userCompanies";
import { useContext, useEffect, useState } from "react";
import { Currency, ExchangeRateHistory } from "../../../interfaces/Commons/currencies";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDates } from "../../../hooks/useDates";
import { useParamsFilter } from "../../../hooks/useParamsFilter";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { usePermissions } from "../../../hooks/usePermissions";
import { AuthContext } from "../../../context/AuthContext";
import GenericPromises from "../../../api/GenericPromises";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { Company } from "../../../interfaces/Security/companies";
import { Menuitem } from "../../../interfaces/Security/menu";
import { SalesShippingDocument, SalesShippingDocumentDetail } from "../../../interfaces/Sales/ShippingDocuments/SalesShippingDocuments";
import SalesShippingDocumentTabs from "./salesShippingDocument";
import Swal from "sweetalert2";
import { AnnotationsSalesShippingDocument } from "./Tabs/annotations";
import { DocumentsSalesShippingDocuments } from "./Tabs/documents";
import { UpdateSalesShippingDocumentHeader } from "./updateSalesShippingDocumentHeader";
import { UpdateSalesShippingDocumentDetails } from "./Tabs/updateDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { DialogProcessInvoice } from "./components/DialogProcessInvoice";

export const ViewSalesShippingDocuments = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { TimeConverter } = useDates();
  const { GetParamsFromBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const { GenericPutResource, GenericGetResource, GenericGetReport, GenericDeleteResource, GenericGetResourceGeneric, GenericPostAction, GenericPostResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const theme = useTheme();
  const [dataLoadedHeader, setDataLoadedHeader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [openDialogCopySalesShippingDocument, setOpenDialogCopySalesShippingDocument] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [disabledPostCopy, setDisabledPostCopy] = useState(false);
  const [isLoadingCustomerLocations, setIsLoadingCustomerLocations] = useState(false);
  const [hasDefaultCustomer, setHasDefaultCustomer] = useState(true);
  const [openDialogProcessInvoice, setOpenDialogProcessInvoice] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [salesShippingDocumentLocal, setSalesShippingDocumentLocal] = useState<SalesShippingDocument>();
  const [company, setCompany] = useState<Company>();
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [defaultCustomer, setDefaultCustomer] = useState<Customer>();
  const [comboCustomerLocations, setComboCustomerLocations] = useState<CustomerLocation[]>([]);
  const [defaultCustomerLocation, setDefaultCustomerLocation] = useState<CustomerLocation>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [comboProjects, setComboProjects] = useState<Project[]>([]);
  const [defaultProject, setDefaultProject] = useState<Project>();
  const [resourceCopySalesShippingDocument, setResourceCopySalesShippingDocument] = useState<Menuitem>();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceScreenDetails, setResourceScreenDetails] = useState<Menuitem>();
  const [resourceScreenProcessInvoice, setResourceScreenProcessInvoice] = useState<Menuitem>();
  const [comboLocationsDelivery, setComboLocationsDelivery] = useState<CustomerLocation[]>([]);
  const [defaultLocationDelivery, setDefaultLocationDelivery] = useState<CustomerLocation>();
  const [resourceDelivery, setResourceDelivery] = useState<Menuitem>();
  const [hasSalesInvoices, setHasSalesInvoices] = useState(false)

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    trigger
  } = useForm<SalesShippingDocument>({});

  const onSubmit: SubmitHandler<SalesShippingDocument> = (data) => onPut(data);

  const onPut = async (data: SalesShippingDocument) => {
    setLoadingPut(true);

    let myCustomerLocation: CustomerLocation | undefined = await comboCustomerLocations.find((element: CustomerLocation) => element.customer_location_id === data.customer_location_id);


    if (data.sales_delivery_addresses_id && data.sales_delivery_addresses_id > 0) {
      await onPutDelivery(comboLocationsDelivery.find((element => element.customer_location_id === data.sales_delivery_addresses_id)));
    }

    let myCurrency = await comboCurrencies.find((item: Currency) => item.currency_code.toLowerCase() === data.currency_code?.toLowerCase());

    // GET company info
    const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");

    var date = new Date();
    if (typeof data.sales_shipping_document_date == 'string') {
      date = TimeConverter(data.sales_shipping_document_date) ?? new Date();
    }
    else if (data.sales_shipping_document_date) {
      let currentDate = new Date();
      let invoiceDate = new Date(data.sales_shipping_document_date);
      invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
      date = invoiceDate;
    }

    // get Exchange rate USD 
    let myExchangeRate: ExchangeRateHistory | undefined;

    if (myCurrency?.currency_code === "MXN") {
      myExchangeRate = { rate: 1 };
    }
    else {
      let myCurrencyExchange = await comboCurrencies.find((item) => (item.currency_code).toUpperCase() === myCurrency?.currency_code)

      await GenericGetResource(`/exchangeratehistory/bycurrencyid/${(myCurrencyExchange?.currency_id) ?? 0}`)
        .then((responseCurrency) => {
          let currentDate = data.sales_shipping_document_date;

          let myNewDate = new Date(currentDate ?? Date.now());
          if (myNewDate.getDay() === 1) {
            myNewDate.setDate(myNewDate?.getDate() - 3);
          }
          else if (myNewDate.getDay() === 0) {
            myNewDate.setDate(myNewDate?.getDate() - 2);
          }
          else {
            myNewDate.setDate(myNewDate?.getDate() - 1);
          }

          let myCurrencyValues: ExchangeRateHistory[] = responseCurrency.data.data;

          myExchangeRate = myCurrencyValues.find((item) => {
            // Convertir la cadena de fecha a un objeto Date
            const itemDate = new Date(item.rate_date!);
            const formattedDate1: Date = new Date(`${itemDate.getFullYear()}-${itemDate.getMonth() + 1}-${itemDate.getDate()}`);
            const formattedDate2: Date = new Date(`${myNewDate.getFullYear()}-${myNewDate.getMonth() + 1}-${myNewDate.getDate()}`);

            return (formattedDate1.getTime() === formattedDate2.getTime());
          });
        });
    }
    // si no existe mandar advertencia para cambiar los valores de la moneda
    if (!myExchangeRate) {
      Swal.fire({
        customClass: { container: "swalfire" },
        title: `${t("salesinvoices.info.add-exchangeratehistory")}`,
        confirmButtonColor: theme.palette.primary.main,
        icon: "warning",
        allowOutsideClick: false
      });

      setDefaultCurrency(comboCurrencies.find((currency: Currency) => currency.currency_id == salesShippingDocumentLocal?.currency_id));
      setValue("currency_id", comboCurrencies.find((currency: Currency) => currency.currency_id == salesShippingDocumentLocal?.currency_id)?.currency_id, { shouldValidate: true });
      setLoadingPut(false);
      return;
    }

    let myData = {
      sales_order_id: data.sales_order_id ?? null,
      sales_shipping_document_name: data.sales_shipping_document_name,
      sales_shipping_document_date: date ?? new Date(data.sales_shipping_document_date ?? new Date()),
      purchase_order: data.purchase_order ?? null,
      customer_id: data.customer_id,
      customer_business_name: myCustomerLocation?.business_name ?? data?.customer_business_name,
      customer_comercial_name: data.customer_comercial_name,
      customer_rfc: myCustomerLocation?.customer_rfc ?? data?.customer_rfc,
      customer_street_name: myCustomerLocation?.street ?? (data.customer_street_name ?? null),
      customer_location_id: myCustomerLocation?.customer_location_id ?? (data.customer_location_id ?? null),
      customer_postal_code: myCustomerLocation?.postal_code ?? (data.customer_postal_code ?? null),
      customer_city_id: 0,
      customer_city_name: myCustomerLocation?.city_name ?? (data.customer_city_name ?? null),
      customer_state_id: 0,
      customer_state_name: myCustomerLocation?.state_name ?? (data.customer_state_name ?? null),
      customer_state_abbr: myCustomerLocation?.state_abbr ?? (data.customer_state_abbr ?? null),
      customer_country_id: 0,
      customer_country_name: myCustomerLocation?.country_name ?? (data.customer_country_name ?? null),
      customer_country_code: myCustomerLocation?.country_code ?? (data.customer_country_code ?? null),
      subtotal_amount: data.subtotal_amount ?? 0,
      total_amount: data.total_amount ?? 0,
      tax_amount: data.tax_amount ?? null,
      currency_id: (data.currency_id && data.currency_id > 0) ? data.currency_id : null,
      currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
      currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
      project_id: data.project_id ?? null,
      payment_mode_id: null,
      payment_mode_code: null,
      payment_mode_description: null,
      payment_method_id: null,
      payment_method_code: null,
      payment_method_name: null,
      cfdi_id: null,
      cfdi_code: null,
      cfdi_description: null,
      discount_amount: data.discount_amount ?? null,
      customer_tax_regime_id: 0,
      customer_tax_regime_code: myCustomerLocation?.tax_regime_code ?? (data.customer_tax_regime_code ?? null),
      customer_tax_regime_description: myCustomerLocation?.tax_regime_description ?? (data.customer_tax_regime_description ?? null),
      id_consecutive: data.id_consecutive,
      notes: data.notes ?? null,
      retention_amount: data.retention_amount ?? null,
      payment_condition_id: data.payment_condition_id ?? null,
      payment_condition_days: null,
      payment_condition_name: null,
      payment_condition_description: null,
      payment_due_date: data.payment_due_date ?? null,
      customer_language: (data.customer_language ?? null),
      customer_email: myCustomerLocation?.customer_email ?? data.customer_email ?? null,
      customer_phone_number: myCustomerLocation?.customer_phone_number ?? data.customer_phone_number ?? null,
      date_mail_send: null,
      user_mail_send: null,
      company_business_name: responseCompany.data?.business_name ?? null,
      company_street: responseCompany.data?.street ?? null,
      company_city_id: responseCompany.data?.city_id ?? null,
      company_city_name: responseCompany.data?.city_name ?? null,
      company_city_code: responseCompany.data?.city_code ?? null,
      company_state_id: responseCompany.data?.state_id ?? null,
      company_state_name: responseCompany.data?.state_name ?? null,
      company_state_abbr: responseCompany.data?.state_abbr ?? null,
      company_country_id: responseCompany.data?.country_id ?? null,
      company_country_name: responseCompany.data?.country_name ?? null,
      company_country_code: responseCompany.data?.country_code ?? null,
      company_postal_code: responseCompany.data?.postal_code ?? null,
      company_rfc: responseCompany.data?.rfc ?? null,
      company_tax_regime_id: responseCompany.data?.tax_regime_id ?? null,
      company_tax_regime_code: responseCompany.data?.tax_regime_code ?? null,
      company_tax_regime_description: responseCompany.data?.tax_regime_description ?? null,
      company_email: responseCompany.data?.email ?? null,
      company_phone_number: responseCompany.data?.phone_number ?? null,
      company_account_alias: responseCompany.data?.primary_account_alias ?? null,
      company_primary_account_alias: responseCompany.data?.primary_account_alias ?? null,
      company_primary_bank_name: responseCompany.data?.primary_bank_name ?? null,
      company_primary_account_number: responseCompany.data?.primary_account_number ?? null,
      company_primary_interbank_key: responseCompany.data?.primary_interbank_key ?? null,
      company_primary_swift_code: responseCompany.data?.primary_swift_code ?? null,
      company_secondary_account_alias: responseCompany.data?.secondary_account_alias ?? null,
      company_secondary_bank_name: responseCompany.data?.secondary_bank_account ?? null,
      company_secondary_account_number: responseCompany.data?.secondary_account_number ?? null,
      company_secondary_interbank_key: responseCompany.data?.secondary_interbank_key ?? null,
      company_secondary_swift_code: responseCompany.data?.secondary_swift_code ?? null,
      url_files: data.url_files ?? null,
      pdf_file_id: data.pdf_file_id ?? null,
      exchange_rate: data.exchange_rate ?? 0,
    }
    GenericPutResource(`/salesshippingdocuments/${salesShippingDocumentLocal?.sales_shipping_document_id}`, myData)
      .then(async (responsePut) => {
        await reset({
          ...responsePut.data,
          sales_delivery_addresses_id: 0,
          currency_id: responsePut.data.currency_code ? 0 : null,
        });
        await setSalesShippingDocumentLocal(responsePut.data);
        setLoadingPut(false);
        setIsLoadingCustomerLocations(false);
        showSnackBar(t("generic.snackbar.update"), "success");
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setLoadingPut(false);
        setIsLoadingCustomerLocations(false);
      });
  }

  const onDelete = () => {
    GenericGetResource(`/salesshippingdocumentdetails/bysalesorderid/${salesShippingDocumentLocal?.sales_shipping_document_id}`)
      .then(async (responseDetails) => {
        for (let i = 0; i < responseDetails.data.data.length; i++) {
          const e: SalesShippingDocumentDetail = responseDetails.data.data[i];
          await GenericDeleteResource(`/salesshippingdocumentdetails/${e.sales_shipping_document_detail_id}`);
        }
        await GenericDeleteResource(`/salesshippingdocuments/${salesShippingDocumentLocal?.sales_shipping_document_id}`)
          .then(() => {
            setOpenDialog(false);
            navigate("/salesshippingdocuments", { state: { method: "delete" }, replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
            setOpenDialog(false);
          })
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      });
  }

  const onCopy = () => {
    GenericPostAction(`/salesshippingdocuments/copy/${salesShippingDocumentLocal?.sales_shipping_document_id}`, {}, false)
      .then(async (response) => {
        await navigate("/salesshippingdocuments", { state: { row: response.data } });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
      .finally(() => setDisabledPostCopy(false));
  }

  const onPutDelivery = async (salesDelivery: CustomerLocation | undefined) => {
    if (resourceDelivery?.read) {
      let mySalesDelivery = {
        business_name: salesDelivery?.business_name ?? null,
        comercial_name: null,
        street: salesDelivery?.street ?? null,
        postal_code: salesDelivery?.postal_code ?? null,
        city_id: salesDelivery?.city_id ?? null,
        city_name: salesDelivery?.city_name ?? null,
        state_id: salesDelivery?.state_id ?? null,
        state_name: salesDelivery?.state_name ?? null,
        state_abbr: salesDelivery?.state_abbr ?? null,
        country_id: salesDelivery?.country_id ?? null,
        country_name: salesDelivery?.country_name ?? null,
        country_code: salesDelivery?.country_code ?? null,
        rfc: salesDelivery?.customer_rfc ?? null,
        tax_regime_id: salesDelivery?.tax_regime_id ?? null,
        tax_regime_code: salesDelivery?.tax_regime_code ?? null,
        tax_regime_description: salesDelivery?.tax_regime_description ?? null,
        email: salesDelivery?.customer_email ?? null,
        phone_number: salesDelivery?.customer_phone_number ?? null,
      }

      if (salesShippingDocumentLocal?.sales_delivery_addresses_id) {
        await GenericPutResource(`/salesdeliveryaddresses/${salesShippingDocumentLocal?.sales_delivery_addresses_id}`, mySalesDelivery)
      }
      else {
        await GenericPostResource(`/salesdeliveryaddresses`, mySalesDelivery).then(async (response) => {
          let mySalesShippingDocumentLocal = { sales_delivery_addresses_id: response.data.sales_delivery_addresses_id }
          await GenericPutResource(`/salesshippingdocuments/${salesShippingDocumentLocal?.sales_shipping_document_id}`, mySalesShippingDocumentLocal)
        })
      }
    }
  }

  const onChangeTotalValues = async () => {
    try {
      const response = await GenericGetResource(`/salesshippingdocuments/${salesShippingDocumentLocal?.sales_shipping_document_id}`);

      setValue("total_amount", response.data.total_amount);
      setValue("subtotal_amount", response.data.subtotal_amount);
      setValue("discount_amount", response.data.discount_amount);
      setValue("tax_amount", response.data.tax_amount);
      setValue("retention_amount", response.data.retention_amount);
    } catch (error: any) {
      showSnackBar(error.message, 'error');
    }
  }

  useEffect(() => {
    let salesOrderId;
    let myCustomerId: any;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      salesOrderId = myParams.get("sales_shipping_document_id");
      myCustomerId = myParams.get("customer_id") ?? '';
      setCustomerId((prev) => parseInt(myCustomerId ?? 0));
    }
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salesshippingdocuments.title-view")}`;
    });
    if (location.state === null && (!salesOrderId || !myCustomerId)) return navigate("/salesshippingdocuments", { state: {}, replace: true });

    GetResourceByUrl(`/salesshippingdocuments/processinvoice`)
      .then((responsePermission) => {
        setResourceScreenProcessInvoice((prev) => responsePermission);
      });

    // permission resource screen
    GetResourceByUrl(`/salesshippingdocuments`)
      .then((responsePermission) => {
        setIsEdit(!responsePermission?.update);
        setResourceScreen(responsePermission);
      });

    GetResourceByUrl(`/salesshippingdocumentdetails`)
      .then((responsePermission) => {
        setResourceScreenDetails(responsePermission);
      });

    GetResourceByUrl(`/salesdeliveryaddresses`)
      .then((responsePermission) => {
        setResourceDelivery(responsePermission);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resourceScreen && resourceScreen.read) {
      let salesOrderId;
      let myCustomerId: any;
      if (location.search.length > 0 && location.state === null) {
        let myParams = GetParamsFromBase64();
        salesOrderId = myParams.get("sales_shipping_document_id");
        myCustomerId = myParams.get("customer_id") ?? '';
      }
      // set data local, reset and dataloaded header true
      GenericGetResource(`/salesshippingdocuments/${(location.state !== null) ? location.state.row.sales_shipping_document_id : salesOrderId}`)
        .then(async (response) => {
          setSalesShippingDocumentLocal(response.data);
          await reset({
            ...response.data,
            sales_delivery_addresses_id: 0,
            currency_id: response.data.currency_code ? 0 : null,
          });
          setDefaultDate(new Date(response.data.sales_shipping_document_date));
          let myHasSalesInvoices: boolean = response.data.has_sales_invoice;
          if (myHasSalesInvoices) {
            setIsEdit(true);
            setHasSalesInvoices(true);
          }
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });

      //load permissions
      GetResourceByUrl(`/salesshippingdocuments/copy`)
        .then((responsePermission) => {
          setResourceCopySalesShippingDocument(responsePermission);
        })
    }
  }, [resourceScreen]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const myCompanyResponse = await GenericGetResourceGeneric("/companies", "/gcompanies").catch((error) => {
          showSnackBar(error.message, 'error');
          return null;
        });
        if (myCompanyResponse) {
          setCompany(myCompanyResponse.data.data[0]);
        }

        const myProjectsResponse = await GenericGetResource("/projects").catch((error) => {
          showSnackBar(error.message, 'error');
          return null;
        });
        if (myProjectsResponse) {
          const myComboProjects: Project[] = myProjectsResponse.data.data;
          setComboProjects(myComboProjects);

          // Setear el proyecto predeterminado
          const defaultProject = myComboProjects.find((item) => item.project_id === salesShippingDocumentLocal?.project_id);
          await setDefaultProject(defaultProject);
        }

        const myCurrenciesResponse = await GenericGetResource("/currencies").catch((error) => {
          showSnackBar(error.message, 'error');
          return null;
        });
        if (myCurrenciesResponse) {
          const myComboCurrencies: Currency[] = myCurrenciesResponse.data.data;
          setComboCurrencies(myComboCurrencies);
        }

        // Manejar datos históricos
        if (salesShippingDocumentLocal?.customer_postal_code) {
          const myHistoricalCustomerLocation: CustomerLocation = {
            customer_location_id: 0,
            business_name: getValues("customer_business_name"),
            street: getValues("customer_street_name"),
            city_name: getValues("customer_city_name"),
            state_name: getValues("customer_state_name"),
            state_abbr: getValues("customer_state_abbr"),
            country_name: getValues("customer_country_name"),
            country_code: getValues("customer_country_code"),
          };
          setDefaultCustomerLocation(myHistoricalCustomerLocation);
        }

        if (salesShippingDocumentLocal?.currency_code) {
          const myHistoricalCurrency: Currency = {
            currency_id: 0,
            currency_code: getValues("currency_code") ?? '',
            currency_description: getValues("currency_description") ?? '',
          };
          setDefaultCurrency(myHistoricalCurrency);
        }

        if (salesShippingDocumentLocal?.sales_delivery_addresses_id) {
          GenericGetResource(`/salesdeliveryaddresses/${salesShippingDocumentLocal?.sales_delivery_addresses_id}`).then(async (responseDelivery) => {
            if (responseDelivery.data && responseDelivery.data.business_name) {
              let myDeleveryLocation: CustomerLocation = {
                customer_location_id: 0,
                business_name: responseDelivery.data.business_name,
                street: responseDelivery.data.street,
                city_name: responseDelivery.data.city_name,
                state_name: responseDelivery.data.state_name,
                state_abbr: responseDelivery.data.state_abbr,
                country_name: responseDelivery.data.country_name,
                country_code: responseDelivery.data.country_code,
              }
              await reset({ sales_delivery_addresses_id: 0 }, { keepValues: true });
              setDefaultLocationDelivery((prev) => myDeleveryLocation);
            }
          })
        }

        if (!comboCustomerLocations.length) {
          const myCustomerLocationsResponse = await GenericGetResource(`/customerlocations/bycustomerid/${salesShippingDocumentLocal?.customer_id}`)
            .catch((error) => {
              showSnackBar(error.message, 'error');
              return null;
            });
          if (myCustomerLocationsResponse) {
            const myComboCustomerLocations: CustomerLocation[] = myCustomerLocationsResponse.data.data;
            setComboCustomerLocations(myComboCustomerLocations);
            let myComboDeliveryLocations = myComboCustomerLocations.filter((element) => element.delivery_address === true)
            setComboLocationsDelivery(myComboDeliveryLocations);
          }
        }

        setDataLoadedHeader(true);
      } catch (error) {
        showSnackBar("Error al cargar los datos.", "error");
      }
    };

    if (salesShippingDocumentLocal) {
      loadData();
    }
  }, [salesShippingDocumentLocal]);


  useEffect(() => {
    if (dataLoadedHeader) {
      let myProjectId = "";
      let myCustomerId = "";
      if (location.search.length > 0 && location.state === null) {
        let myParams = GetParamsFromBase64();
        myProjectId = myParams.get("project_id") ?? '';
        myCustomerId = myParams.get("customer_id") ?? '';
      }
      //Revisar si tiene un default Customer
      new Promise(async () => {
        let responseUserCompanies = await GenericGetResourceGeneric(`/usercompanies/byuserid/${user?.user_id}`, "/gusercompanies");
        let myCustomerDefault = responseUserCompanies.data.data.find((element: UserCompanies) => element.schema_name === user?.schema);
        if (myCustomerDefault && myCustomerDefault.customer_default_id) {
          setHasDefaultCustomer(true);
        }
        else if (!isEdit) {
          // load customers
          GenericGetResource(`/customers`)
            .then((responseCustomers) => {
              let myComboCustomers: Customer[] = responseCustomers.data.data;
              setComboCustomers(myComboCustomers);
              setDefaultCustomer(myComboCustomers.find((item) => item.customer_id === getValues("customer_id")));
              setHasDefaultCustomer(false);
            });
        }
      });
    }

  }, [dataLoadedHeader]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          {isBackdrop &&
            <Backdrop
              sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
              open={isBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          <Header
            title={t("salesshippingdocuments.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button aria-describedby={id1} onClick={handleClick1} disabled={loadingPut}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (salesShippingDocumentLocal?.has_sales_invoice) {
                          showSnackBar(t("salesshippingdocuments.info.has-sales-invoice"), "warning");
                        }
                        else {
                          setOpenDialogProcessInvoice(true);
                        }
                      }}
                      disabled={!resourceScreenProcessInvoice?.create}
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 200,
                      }}
                    >
                      {t("salesshippingdocuments.actions.processinvoice")}
                      <ReceiptIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { setOpenDialogCopySalesShippingDocument(true); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }} disabled={!resourceCopySalesShippingDocument?.create}>
                      {t("salesshippingdocuments.actions.copy")}<ContentCopyIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='px-1 pt-1'>
                  <Button aria-describedby={id2} onClick={handleClick2} disabled={loadingPut}>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableScrollLock={false}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton onClick={() => { /* onGeneratePDF(); */ }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }}>
                      {t("salesshippingdocuments.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='ps-2'>
                  {resourceScreen?.delete &&
                    <ButtonStyled disabled={loadingPut || isEdit} variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                  }
                </div>
                <div className='pt-1'>
                  <Button disabled={loadingPut} variant="text" onClick={() => { navigate("/salesshippingdocuments", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Arriba de la card */}
                <div className='d-flex w-100 m-2'>
                  <div className="d-flex flex-row-reverse w-50">
                    <Box sx={{ width: "35%" }}>
                      {dataLoadedHeader &&
                        <Controller
                          name="project_id"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", paddingRight: 2 }}
                              options={comboProjects}
                              getOptionLabel={(option) => `${option.project_name}`}
                              defaultValue={defaultProject}
                              renderOption={(props, option: Project) => (
                                <div key={option.project_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.project_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.project_id === value.project_id}
                              onChange={(_, values) => {
                                field.onChange(values?.project_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("projects.title-view")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.project_id}
                                  helperText={GetError(errors.project_id?.type)}
                                />
                              )}
                              disabled={isEdit}
                            />
                          )}
                        />
                      }
                    </Box>
                  </div>
                  <div className="d-flex w-50 flex-row-reverse">
                    <div className='w-50'>
                      {dataLoadedHeader &&
                        <Controller
                          name="sales_shipping_document_date"
                          control={control}
                          rules={{}}
                          render={({ field }) =>
                            <DatePicker
                              open={openDatePicker}
                              onClose={() => setOpenDatePicker(false)}
                              ref={field.ref}
                              label={`${t("salesshippingdocuments.fields.sales_shipping_document_date")}`}
                              onChange={field.onChange}
                              slotProps={{
                                popper: {
                                  sx: myStylePickers
                                },
                                textField: {
                                  variant: "filled",
                                  size: "small",
                                  fullWidth: true,
                                  onClick: () => setOpenDatePicker(true),
                                  onBeforeInput: disableKeyboardEntry,
                                  sx: { paddingRight: 2 },
                                  error: !!errors.sales_shipping_document_date,
                                  helperText: GetError(errors.sales_shipping_document_date?.type),
                                }
                              }}
                              defaultValue={dayjs(TimeConverter(defaultDate.toString())) ?? dayjs()}
                              format="DD/MM/YYYY"
                              disableFuture
                              disabled={isEdit}
                            />
                          }
                        />
                      }
                    </div>
                    <div className='w-50'>
                      <TextField
                        variant="filled"
                        label={`${t("salesshippingdocuments.fields.sales_shipping_document_name")}`}
                        value={salesShippingDocumentLocal?.sales_shipping_document_name}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="purchase_order"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("salesshippingdocuments.fields.purchase_order")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.purchase_order}
                            helperText={GetError(errors.purchase_order?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-card-view'>
                  {!dataLoadedHeader && <Spinner isBox={false} />}
                  {dataLoadedHeader &&
                    <UpdateSalesShippingDocumentHeader
                      loading={loadingPut}
                      control={control}
                      errors={errors}
                      salesShippingDocumentLocal={salesShippingDocumentLocal}
                      defaultCurrency={defaultCurrency}
                      defaultCustomerLocation={defaultCustomerLocation}
                      comboCustomerLocations={comboCustomerLocations}
                      comboCurrencies={comboCurrencies}
                      getValues={getValues}
                      setValue={setValue}
                      isEdit={isEdit}
                      isLoadingCustomerLocations={isLoadingCustomerLocations}
                      hasDefaultCustomer={hasDefaultCustomer}
                      comboCustomers={comboCustomers}
                      defaultCustomer={defaultCustomer}
                      comboLocationsDelivery={comboLocationsDelivery}
                      defaultLocationDelivery={defaultLocationDelivery}
                      resourceDelivery={resourceDelivery}
                    />
                  }
                </div>
              </form>
              <div>
                <SalesShippingDocumentTabs
                  Update={
                    <>
                      {!resourceScreenDetails && <Spinner isBox={false} />}
                      {resourceScreenDetails && resourceScreenDetails.read &&
                        <UpdateSalesShippingDocumentDetails
                          control={control}
                          reset={reset}
                          salesShippingDocumentLocal={salesShippingDocumentLocal}
                          resourceScreenDetails={resourceScreenDetails}
                          onPut={onPut}
                          setValue={setValue}
                          trigger={trigger}
                          getValues={getValues}
                          dataLoadedHeader={dataLoadedHeader}
                          loadingPut={loadingPut}
                          onChangeTotalValues={onChangeTotalValues}
                          hasSalesInvoices={hasSalesInvoices}
                        />
                      }
                    </>
                  }
                  Annotations={
                    <>
                      {dataLoadedHeader &&
                        <AnnotationsSalesShippingDocument
                          control={control}
                          errors={errors}
                          isEdit={isEdit}
                        />
                      }
                    </>
                  }
                  Documents={
                    <>
                      {dataLoadedHeader &&
                        <DocumentsSalesShippingDocuments
                          getValues={getValues}
                          isEdit={isEdit}
                          control={control}
                          errors={errors}
                          onPut={onPut}
                        />
                      }
                    </>
                  }
                />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("salesshippingdocuments.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogEntity
        open={openDialogCopySalesShippingDocument}
        setOpen={setOpenDialogCopySalesShippingDocument}
        title={<Header title={t("salesinvoices.actions.copy")} size='sm' />}
        content={
          <>
            <div className="d-flex flex-row-reverse mt-4">
              <PrimaryButton disabled={disabledPostCopy} onClick={() => onCopy()}>{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenDialogCopySalesShippingDocument(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
      />
      {dataLoadedHeader &&
        <DialogProcessInvoice
          open={openDialogProcessInvoice}
          setOpenDialog={setOpenDialogProcessInvoice}
          customerId={getValues("customer_id") ?? 0}
          salesShippingDocumentIds={[salesShippingDocumentLocal?.sales_shipping_document_id ?? 0]}
        />
      }
      <SnackbarComponent />
    </>
  )
}
