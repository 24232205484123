import { Control, Controller, FieldErrors, UseFormGetValues, } from "react-hook-form"
import { Company } from "../../../interfaces/Security/companies"
import { useTranslation } from "react-i18next"
import { DatePicker } from '@mui/x-date-pickers';
import { FormsErrors } from "../../../hooks/Forms/FormsErrors"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Autocomplete, Box, Button, Switch, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import { ButtonLoading, myStylePickers } from "../../../theme/buttons";
import { PurchaseInvoice } from "../../../interfaces/Purchases/Invoices/purchaseInvoices"
import { SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers"
import dayjs from "dayjs";
import { useDates } from "../../../hooks/useDates";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { BankAccount } from "../../../interfaces/Commons/bankAccounts";

type InvoiceHeaderProps = {
  control: Control<PurchaseInvoice>,
  errors: FieldErrors<PurchaseInvoice>,
  purchaseInvoiceLocal: PurchaseInvoice | undefined,
  company: Company | undefined,
  comboSupplierLocations: SupplierLocation[],
  defaultSupplierLocation: SupplierLocation | undefined,
  defaultSupplierBankAccount: SupplierBankAccount | undefined,
  comboSupplierBankAccounts: SupplierBankAccount[],
  getValues: UseFormGetValues<PurchaseInvoice>,
  locationEdit: boolean,
  setLocationEdit: Dispatch<SetStateAction<boolean>>,
  isEdit?: boolean,
  isNotExchange?: boolean,
  setIsNotExchange: Dispatch<SetStateAction<boolean>>,
  loading: boolean,
  disableKeyboardEntry: (e: any) => void,
  defaultDate: Date,
  defaultDateSupplier: Date | undefined,
  GetExchangeRateFromDates: (currency_id: number, datePurchaseInvoice: Date | undefined, dateSupplierInvoice: Date | undefined) => Promise<void>,
  comboBankAccountsGeneral: BankAccount[],
  defaultBankAccountFinal: BankAccount | undefined
}

export const UpdatePurchaseInvoicesHeader = ({
  control,
  errors,
  purchaseInvoiceLocal,
  company,
  defaultSupplierBankAccount,
  comboSupplierBankAccounts,
  defaultSupplierLocation,
  comboSupplierLocations,
  getValues,
  locationEdit,
  setLocationEdit,
  isEdit,
  isNotExchange,
  setIsNotExchange,
  GetExchangeRateFromDates,
  loading,
  disableKeyboardEntry,
  defaultDate,
  defaultDateSupplier,
  comboBankAccountsGeneral,
  defaultBankAccountFinal
}: InvoiceHeaderProps) => {
  const [t] = useTranslation("global");
  const { setFormatNumber } = useFormatNumber();
  const { GetError } = FormsErrors();
  const { TimeConverter } = useDates();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePicker2, setOpenDatePicker2] = useState(false);
  const [supplierBankAccount, setSupplierBankAccount] = useState<SupplierBankAccount>();
  const [finalBankAccount, setFinalBankAccount] = useState<BankAccount>();

  const editLocation = () => {
    (locationEdit == true) ? setLocationEdit(false) : setLocationEdit(true)
  }

  useEffect(() => {
    setIsNotExchange(false)
  }, [locationEdit, defaultSupplierLocation, isNotExchange]);

  useEffect(() => {
    setSupplierBankAccount(defaultSupplierBankAccount);
    setFinalBankAccount(defaultBankAccountFinal)
  }, []);

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <div className='d-flex w-50'>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{purchaseInvoiceLocal?.supplier_business_name}</Typography>
              <Typography>{purchaseInvoiceLocal?.supplier_rfc}</Typography>
              <Typography>{getValues("supplier_street_name")} {(getValues("supplier_city_name")) ? "," : ""} {getValues("supplier_city_name")} {getValues("supplier_state_name") ? "," : ""} {getValues("supplier_state_name")}{getValues("supplier_country_name") ? "," : ""} {getValues("supplier_country_name")}<Button size="small" sx={{ flex: 1 }} onClick={editLocation}><EditIcon /></Button></Typography>
            </div>
            <div className="d-flex">
              {locationEdit &&
                <Controller
                  name="supplier_location_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ maxWidth: "100%", minWidth: "80%" }}
                      options={comboSupplierLocations}
                      defaultValue={defaultSupplierLocation}
                      getOptionLabel={(option) => `${option.business_name}, ${option.supplier_rfc} - ${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                      renderOption={(props, option: SupplierLocation) => (
                        <div key={option.supplier_location_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.business_name} - {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.supplier_location_id === value.supplier_location_id}
                      onChange={(_, values) => {
                        field.onChange(values?.supplier_location_id || null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("purchaseinvoices.fields.address")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.supplier_location_id}
                          helperText={GetError(errors.supplier_location_id?.type)}
                        />
                      )}
                      disabled={isEdit}
                    />
                  )}
                />
              }
            </div>
          </Box>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{company?.business_name}</Typography>
              <Typography>{company?.rfc}</Typography>
              <Typography>{company?.phone_number}</Typography>
              <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
            </div>
          </Box>
        </div>
        {/* Right Box*/}
        {!isNotExchange &&
          <Box sx={{ width: "25%" }} className="d-flex custom-card-view">
            <Box className="w-100 d-flex flex-column">
              <Typography fontWeight={"bold"} fontSize={15} marginRight={1}>
                {t("purchaseinvoices.fields.account_deposit")}:
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.account_alias")}:
                  </Typography>
                  {supplierBankAccount?.account_alias}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.bank_references")}:
                  </Typography>
                  {supplierBankAccount?.bank_references}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.bank_name")}:
                  </Typography>
                  {supplierBankAccount?.bank_name}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("currencies.title-view")}:
                  </Typography>
                  {(supplierBankAccount?.currency_description) ? supplierBankAccount?.currency_description + "-" + supplierBankAccount?.currency_code : ""}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.card_number")}:
                  </Typography>
                  {supplierBankAccount?.card_number}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.account_number")}:
                  </Typography>
                  {supplierBankAccount?.account_number}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.interbank_key")}:
                  </Typography>
                  {supplierBankAccount?.interbank_key}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.swift_code")}:
                  </Typography>
                  {supplierBankAccount?.swift_code}
                </Typography>
              </Box>
              <Controller
                name="supplier_bank_account_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    options={comboSupplierBankAccounts}
                    defaultValue={defaultSupplierBankAccount}
                    getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}${option.currency_code ? ` - ${option.currency_code}` : ``}`}
                    renderOption={(props, option: SupplierBankAccount) => (
                      <div key={option.supplier_bank_account_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.account_alias} - {option.bank_name} {(option.currency_code ? ` - ${option.currency_code}` : '')}
                        </Box>
                      </div>
                    )}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) => option.supplier_bank_account_id === value.supplier_bank_account_id}
                    onChange={async (_, values) => {
                      field.onChange(values?.supplier_bank_account_id || null);
                      await setSupplierBankAccount(values ?? undefined);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("payments.fields.supplierbankaccount")}`}
                        variant="filled"
                        value={field.value}
                        size="small"
                        error={!!errors.supplier_bank_account_id}
                        helperText={GetError(errors.supplier_bank_account_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                  />
                }
              />
            </Box>
          </Box>
        }
        {!isNotExchange &&
          <Box sx={{ width: "25%", marginLeft: 2 }} className="d-flex custom-card-view">
            <Box className="w-100 d-flex flex-column">
              <Typography fontWeight={"bold"} fontSize={15} marginRight={1}>
                {t("purchaseinvoices.fields.account_pay")}:
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.account_alias")}:
                  </Typography>
                  {finalBankAccount?.account_alias}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.bank_references")}:
                  </Typography>
                  {finalBankAccount?.bank_references}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.bank_name")}:
                  </Typography>
                  {finalBankAccount?.bank_name}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("currencies.title-view")}:
                  </Typography>
                  {(finalBankAccount?.currency_description) ? finalBankAccount?.currency_description + "-" + finalBankAccount?.currency_code : ""}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.card_number")}:
                  </Typography>
                  {finalBankAccount?.card_number}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.account_number")}:
                  </Typography>
                  {finalBankAccount?.account_number}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.interbank_key")}:
                  </Typography>
                  {finalBankAccount?.interbank_key}
                </Typography>
                <Typography fontFamily={"sans-serif"} fontSize={12} sx={{ display: "flex" }} paddingRight={1}>
                  <Typography fontWeight={"bold"} fontSize={12} marginRight={1}>
                    {t("purchaseinvoices.fields.swift_code")}:
                  </Typography>
                  {finalBankAccount?.swift_code}
                </Typography>
              </Box>
              <Controller
                name="intermediary_bank_account_id"
                control={control}
                rules={{}}
                render={({ field }) =>
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    options={comboBankAccountsGeneral}
                    defaultValue={defaultBankAccountFinal}
                    getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}${option.currency_code ? ` - ${option.currency_code}` : ``}`}
                    renderOption={(props, option: BankAccount) => (
                      <div key={option.bank_account_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.account_alias} - {option.bank_name} {(option.currency_code ? ` - ${option.currency_code}` : '')}
                        </Box>
                      </div>
                    )}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) => option.bank_account_id === value.bank_account_id}
                    onChange={async (_, values) => {
                      field.onChange(values?.bank_account_id || null);
                      await setFinalBankAccount(values ?? undefined);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("payments.fields.finalbankaccount")}`}
                        variant="filled"
                        value={field.value}
                        size="small"
                        error={!!errors.intermediary_bank_account_id}
                        helperText={GetError(errors.intermediary_bank_account_id?.type)}
                      />
                    )}
                    disabled={isEdit}
                  />
                }
              />
            </Box>
          </Box>
        }
      </div>
      <div className="d-flex w-100">
        <div className="w-50"></div>
        <div className='d-flex flex-column w-50'>
          {/* first Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 9 }}>
              {!isNotExchange &&
                <Controller
                  name="purchase_invoice_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      ref={field.ref}
                      label={`${t("purchaseinvoices.fields.purchase_invoice_date")}`}
                      onChange={(day, _) => {
                        field.onChange(day)
                        GetExchangeRateFromDates(getValues("currency_id") ?? 0, day?.toDate(), getValues("supplier_sale_invoice_date"))
                      }}
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          sx: { paddingRight: 2 },
                          error: !!errors.purchase_invoice_date,
                          helperText: GetError(errors.purchase_invoice_date?.type),
                        }
                      }}
                      defaultValue={dayjs(TimeConverter(defaultDate.toString())) ?? dayjs()}
                      format="DD/MM/YYYY"
                      disableFuture
                      disabled={isEdit}
                    />
                  }
                />
              }
            </Box>
            <Box sx={{ flex: 9 }}>
              {!isNotExchange &&
                <Controller
                  name="supplier_sale_invoice_date"
                  control={control}
                  rules={{}}
                  render={({ field }) =>
                    <DatePicker
                      open={openDatePicker2}
                      onClose={() => setOpenDatePicker2(false)}
                      ref={field.ref}
                      label={`${t("purchaseinvoices.fields.supplier_sale_invoice_date")}`}
                      onChange={(day, _) => {
                        field.onChange(day)
                        GetExchangeRateFromDates(getValues("currency_id") ?? 0, getValues("purchase_invoice_date"), day?.toDate());
                      }}
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenDatePicker2(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.supplier_sale_invoice_date,
                          helperText: GetError(errors.supplier_sale_invoice_date?.type),
                        }
                      }}
                      defaultValue={defaultDateSupplier ? dayjs(TimeConverter(defaultDateSupplier.toString())) : undefined}
                      format="DD/MM/YYYY"
                      disableFuture
                      disabled={isEdit}
                    />
                  }
                />
              }
            </Box>
            <Box sx={{ flex: 11 }} className="d-flex flex-column mx-2" >
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("purchaseinvoices.fields.tax_amount")}: {setFormatNumber({ value: getValues("tax_amount") ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("purchaseinvoices.fields.retention_amount")}: {setFormatNumber({ value: getValues("retention_amount") ?? 0 })}</Typography>
            </Box>
          </div>
          {/* second Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 11 }} className='d-flex flex-column mx-2'>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("purchaseinvoices.fields.discount_amount")}:{setFormatNumber({ value: getValues("discount_amount") ?? 0 })}</Typography>
            </Box>
          </div>
          {/* Fourth row */}
          <div className="d-flex mt-2">
            <Box sx={{ flex: 11 }} className='d-flex flex-column mx-2'>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("purchaseinvoices.fields.subtotal_amount")}: {setFormatNumber({ value: getValues("subtotal_amount") ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("purchaseinvoices.fields.total_amount")}: {setFormatNumber({ value: getValues("total_amount") ?? 0 })}</Typography>
            </Box>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-2">
        <div className="w-50">
          <ButtonLoading isLoading={loading} type="submit" size="small" className="d-flex align-self-end w-100" variant="outlined" disabled={isEdit}>{t("generic.buttons.save")}</ButtonLoading>
        </div>
        <div className="w-50"></div>
        <div className="w-25">
          <Controller
            name="is_accounted"
            control={control}
            render={({ field }) =>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                <Typography>{t("purchaseinvoices.fields.is_accounted")}</Typography>
                <Switch
                  ref={field.ref}
                  onChange={(_, e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  disabled={true}
                  checked={field.value}
                  defaultChecked={purchaseInvoiceLocal?.is_accounted} />
              </Box>
            }
          />
        </div>
      </div>
    </>
  )
}
