import { Route, Routes } from 'react-router-dom'
import { TablePurchasePreorders } from './table'
import { AddPurchasePreorders } from './add'
import { ViewPurchasePreorders } from './view'

export const BrowserPurchasePreorders = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePurchasePreorders />} />
      <Route path={"/add"} element={<AddPurchasePreorders />} />
      <Route path={"/view"} element={<ViewPurchasePreorders />} />
    </Routes>
  )
}