import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../api/GenericPromises";
import { useLocation, useNavigate } from "react-router-dom";
import { useDates } from "../../../../hooks/useDates";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { PrimaryButton } from "../../../../theme/buttons";
import DataTable from "../../../../components/Tables/GridTableMaterialUI/DataTable";
import { Permission } from "../../../../interfaces/Security/permissions";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useBase64 } from "../../../../hooks/useBase64";
import { Autocomplete, Box, TextField } from "@mui/material";

interface EnumInterface {
  enum_id: number,
  option_name: string,
  route: string
}

export const TablePermission = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [permissionsData, setPermissionsData] = useState<GridRowsProp>([]);
  const [permissionScreen, setPermissionScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const { utf8ToBase64 } = useBase64();
  const [comboEnum, setComboEnum] = useState<EnumInterface[]>([]);
  const [myEnumValue, setMyEnumValue] = useState<EnumInterface>();
  const [enumLoaded, setEnumLoaded] = useState(false);
  const [columns, setColumns] = useState<GridColDef<Permission>[]>([
    { field: 'resource_name', headerName: t("resources.fields.resource_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'resource_route', headerName: t("resources.fields.resource_route"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'create', headerName: t("permissions.fields.create"), headerClassName: 'header-grid-table', flex: 1, align: 'center', headerAlign: 'center',
      renderCell(params) {
        if (params.value) {
          return <><AddCircleOutlineIcon /></>
        }
        else {
          return <></>
        }
      },
    },
    {
      field: 'read', headerName: t("permissions.fields.read"), headerClassName: 'header-grid-table', flex: 1, align: 'center', headerAlign: 'center',
      renderCell(params) {
        if (params.value) {
          return <><AutoStoriesIcon /></>
        }
        else {
          return <></>
        }
      },
    },
    {
      field: 'update', headerName: t("permissions.fields.update"), headerClassName: 'header-grid-table', flex: 1, align: 'center', headerAlign: 'center',
      renderCell(params) {
        if (params.value) {
          return <><EditIcon /></>
        }
        else {
          return <></>
        }
      },
    },
    {
      field: 'delete', headerName: t("permissions.fields.delete"), headerClassName: 'header-grid-table', flex: 1, align: 'center', headerAlign: 'center',
      renderCell(params) {
        if (params.value) {
          return <><DeleteIcon /></>
        }
        else {
          return <></>
        }
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);


  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          setMessageSnack(t("generic.snackbar.delete"));
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-permissions");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-permissions-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<Permission, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`permissions.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("permissions.title")}`;
    })
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/groups`)
      .then(
        (response) => {
          if (response.data.data && response.data.data.length > 0) {
            let myComboEnum = response.data.data.map((element: any) => ({
              enum_id: element.group_id,
              option_name: element.group_name,
              route: `/permissions?filter=${utf8ToBase64(`group_id=${element.group_id}`)}`,
            }));
            let myEnum = myComboEnum[0];
            setMyEnumValue(myEnum);
            setComboEnum(myComboEnum);
          }
          GetResourceByUrl(`/permissions`)
            .then((response1) => {
              setPermissionScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, 'error');
              setUnauthorized(false);
              setDataLoaded(true);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myEnumValue) {
      setEnumLoaded(false);
      GenericGetResource(myEnumValue.route)
        .then((response) => {
          setPermissionsData(response.data.data);
          setEnumLoaded(true);
        }
        ).catch((error) => {
          setMessageSnack(error.message);
          showSnackBar(error.message, 'error');
          setUnauthorized(false);
          setEnumLoaded(true);
        });
    }
  }, [myEnumValue]);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && permissionScreen?.read && <div className='screen-container'>
        <Header
          title={t("permissions.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              {permissionScreen?.create === true &&
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state, group_id: { row: myEnumValue?.enum_id }  }, }) }}>{t("generic.buttons.add")}</PrimaryButton>
              }
              <div className='w-25'>
                <Autocomplete
                  size="small"
                  options={comboEnum}
                  defaultValue={myEnumValue}
                  getOptionLabel={(option) => option.option_name}
                  renderOption={(props, option) => (
                    <div key={option.enum_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.option_name}
                      </Box>
                    </div>
                  )}
                  sx={{ width: "100%", paddingRight: 1, paddingTop: 1 }}
                  isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                  onChange={(_, values) => {
                    setMyEnumValue(values ?? comboEnum[0]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("generic.filter")}`}
                      variant="filled"
                    />
                  )}
                  clearOnEscape={false}
                  clearIcon={null}
                />
              </div>
            </div>
          }
        />

        <div className="d-flex justify-content-center">
          {enumLoaded ?
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={permissionsData}
              entityId={"permissions_id"}
              entity={`Permissions`}
              preferences={myPreferences}
              namePreferences={"grid-permissions"}
              nameOrderColumns={"grid-permissions-columns"} />
            :
            <Spinner />
          }
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
