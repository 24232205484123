import { useState, useEffect, useCallback } from "react";
import GenericPromises from "../../api/GenericPromises";
import { useFormulaEvaluator } from "../useFormulaEvaluator";
import { Parameter } from "../../interfaces/Commons/parameters";
import { Project } from "../../interfaces/Projects/projects";
import { ProjectExtraField } from "../../interfaces/Projects/Catalogs/projectExtraFields";

export const useCalculateDateByFormula = () => {
    const { GenericGetResource } = GenericPromises();
    const { calculateDate, replaceVariables } = useFormulaEvaluator();


    const calculateDateByFormula = useCallback(async (
        field_type_id: number | undefined,
        date: string | undefined,
        parameters: Parameter[],
        projectExtraFieldsData?: ProjectExtraField[],
        project?: Project
    ) => {
        if (!date) {
            return null;
        }

        try {
            if (field_type_id === 4 && typeof date === "string") {
                let extraFieldsData = projectExtraFieldsData;

                if (!extraFieldsData && project) {
                    const extraFieldsResponse = await GenericGetResource(
                        `/projectextrafields/byprojectid/${project.project_id}`
                    );
                    extraFieldsData = extraFieldsResponse.data.data;
                }

                if (!extraFieldsData) {
                    return null; 
                }

                const formulaVariables = replaceVariables(
                    date,
                    extraFieldsData,
                    parameters
                );

                const calculatedDate = calculateDate(formulaVariables);
                return typeof calculatedDate === "number"
                    && new Date(calculatedDate).toString()
            } else {
                return new Date(date).toString();
            }
        } catch (err) {
            return null;
        }
    }, []);


    return {
        calculateDateByFormula,
    };
};
