import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../../hooks/Forms/FormsErrors";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTasks";
import { ProjectGroup } from "../../../../../../interfaces/Projects/Catalogs/projectGroups";
import { Group } from "../../../../../../interfaces/Security/groups";
import { TaskState } from "../../../../../../interfaces/Projects/Catalogs/taskStates";
import { useBase64 } from "../../../../../../hooks/useBase64";
import { FieldType } from "../../../../../../interfaces/Projects/Catalogs/fieldTypes";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { myStylePickers, PrimaryButton } from "../../../../../../theme/buttons";
import { GenericDialog } from "../../../../../../components/Dialogs/Dialog";
import { Menuitem } from "../../../../../../interfaces/Security/menu";

type UpdateProjectTaskProps = {
  permissions: Menuitem | undefined;
  setOpenDialogUpdateTask: Dispatch<SetStateAction<boolean>>;
  handleUpdateTask: (updateRow: ProjectTask) => void;
  handleDeleteTask: (deleteRow: number) => void;
  project_id: number | undefined;
  projectTaskPayload: ProjectTask;
  comboFieldTypes: FieldType[];
};

export const UpdateProjectTask = ({
  permissions,
  setOpenDialogUpdateTask,
  handleUpdateTask,
  handleDeleteTask,
  project_id,
  projectTaskPayload,
  comboFieldTypes
}: UpdateProjectTaskProps) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericPostResource,
    GenericGetResourceGeneric,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboProjectGroups, setComboProjectGroups] = useState<ProjectGroup[]>(
    []
  );
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [defaultGroup, setDefaultGroup] = useState<Group>();
  const { utf8ToBase64 } = useBase64();
  const [isTypeFormulaStartedDate, setIsTypeFormulaStartedDate] = useState(false);
  const [isTypeFormulaDueDate, setIsTypeFormulaDueDate] = useState(false);
  const [defaultProjectTaskStartedDate, setDefaultProjectTaskStartedDate] = useState<string | Date>()
  const [defaultStartedDateFieldTypeId, setDefaultStartedDateFieldTypeId] = useState<FieldType>()
  const [defaultProjectTaskDueDate, setDefaultProjectTaskDueDate] = useState<string | Date>()
  const [defaultDueDateFieldTypeId, setDefaultDueDateFieldTypeId] = useState<FieldType>();
  const isEdit = !permissions?.update;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProjectTask>();

  const startedDateFieldTypeId = useWatch({
    control,
    name: "project_task_started_date_field_type_id"
  });

  const projectTaskStartedDate = useWatch({
    control,
    name: "project_task_started_date"
  });

  const dueDateFieldTypeId = useWatch({
    control,
    name: "project_task_due_date_field_type_id"
  });


  const onSubmit: SubmitHandler<ProjectTask> = (data) => onPut(data);

  const onPut = (data: ProjectTask) => {
    let myData = {
      project_stage_id: data.project_stage_id,
      project_group_id: data.project_group_id,
      current_task_state_id: data.current_task_state_id,
      MS_task_id: null,
      MS_due_date_time: null,
      MS_start_date_time: null,
      MS_priority: null,
      MS_applied_categories: null,
      MS_description: null,
      MS_show_in_planner: null,
      project_task_name: data.project_task_name,
      project_task_description: data.project_task_description,
      project_task_started_date: data.project_task_started_date,
      project_task_due_date: data.project_task_due_date,
      project_task_notes: data.project_task_notes,
      project_task_started_date_field_type_id: data.project_task_started_date_field_type_id,
      project_task_due_date_field_type_id: data.project_task_due_date_field_type_id
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projecttask/${projectTaskPayload.project_task_id}`,
      myData
    )
      .then((response) => {
        let myUpdateData: ProjectTask = response.data;
        handleUpdateTask(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        setOpenDialogUpdateTask(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(`/projecttask/${projectTaskPayload.project_task_id}`)
      .then(() => {
        setOpenDialog(false);
        if (projectTaskPayload.project_task_id) {
          handleDeleteTask(projectTaskPayload.project_task_id);
        }

        setOpenDialogUpdateTask(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddProjectGroups = (group_id: number) => {
    const existingObject = comboProjectGroups.find(
      (projectGroup) =>
        projectGroup.group_id === group_id &&
        projectGroup.project_id === project_id
    );

    if (existingObject) {
      return existingObject;
    } else {
      let myData = {
        group_id: group_id,
        project_id: project_id,
      };

      setLoadingPost(true);
      GenericPostResource("/projectgroups", myData)
        .then((response) => {
          setComboProjectGroups([...comboProjectGroups, response.data]);
          return response.data;
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/projectgroups"),
      GenericGetResourceGeneric("/groups", "/ggroups"),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectGroups(responses[0].data.data);
        setComboGroups(responses[1].data.data);
        reset({
          project_stage_id: projectTaskPayload.project_stage_id,
          project_group_id: projectTaskPayload.project_group_id,
          current_task_state_id: projectTaskPayload.current_task_state_id,
          MS_task_id: projectTaskPayload.MS_task_id,
          MS_due_date_time: projectTaskPayload.MS_due_date_time,
          MS_start_date_time: projectTaskPayload.MS_start_date_time,
          MS_priority: projectTaskPayload.MS_priority,
          MS_applied_categories: projectTaskPayload.MS_applied_categories,
          MS_description: projectTaskPayload.MS_description,
          MS_show_in_planner: projectTaskPayload.MS_show_in_planner,
          project_task_name: projectTaskPayload.project_task_name,
          project_task_description: projectTaskPayload.project_task_description,
          project_task_started_date: projectTaskPayload.project_task_started_date,
          project_task_due_date: projectTaskPayload.project_task_due_date,
          project_task_notes: projectTaskPayload.project_task_notes,
          project_task_started_date_field_type_id: projectTaskPayload.project_task_started_date_field_type_id,
          project_task_due_date_field_type_id: projectTaskPayload.project_task_due_date_field_type_id,
        });



        const projectGroup = responses[0].data.data.find(
          (projectGroup: ProjectGroup) =>
            projectGroup.project_group_id ===
            projectTaskPayload.project_group_id
        );

        if (projectGroup) {
          const group = responses[1].data.data.find(
            (group: any) => group.group_id === projectGroup.group_id
          );

          if (group) {
            setDefaultGroup(group);
          }
        }

        setDefaultProjectTaskStartedDate(
          projectTaskPayload.project_task_started_date
        );

        const startedDateFieldTypeId = comboFieldTypes.find(
          (fielType: FieldType) =>
            fielType.field_type_id === projectTaskPayload.project_task_started_date_field_type_id
        )

        setDefaultStartedDateFieldTypeId(startedDateFieldTypeId);

        if (startedDateFieldTypeId?.field_type_name.toLowerCase() == 'formula') {
          setIsTypeFormulaStartedDate(true);
        }

        setDefaultProjectTaskDueDate(
          projectTaskPayload.project_task_due_date
        );

        const dueDateFieldTypeId = comboFieldTypes.find(
          (fielType: FieldType) =>
            fielType.field_type_id === projectTaskPayload.project_task_due_date_field_type_id
        )

        setDefaultDueDateFieldTypeId(dueDateFieldTypeId);

        if (dueDateFieldTypeId?.field_type_name.toLowerCase() == 'formula') {
          setIsTypeFormulaDueDate(true);
        }

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="project_group_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      getOptionLabel={(option) => `${option.group_name}`}
                      defaultValue={defaultGroup}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.group_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.group_id === value.group_id
                      }
                      onChange={(_, values) => {
                        if (values?.group_id) {
                          let projectGroup = handleAddProjectGroups(
                            values?.group_id
                          );
                          field.onChange(
                            projectGroup?.project_group_id
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttasks.fields.project_group"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_group_id}
                          helperText={GetError(
                            errors.project_group_id?.type
                          )}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_task_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttasks.fields.project_task_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_name}
                      helperText={GetError(
                        errors.project_task_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-100">
                <Controller
                  name="project_task_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttasks.fields.project_task_description"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_description}
                      helperText={GetError(
                        errors.project_task_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>

              <div className="d-flex mt-1 pt-3">
                <div className="w-50">
                  <Controller
                    name="project_task_started_date_field_type_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        options={comboFieldTypes}
                        defaultValue={defaultStartedDateFieldTypeId}
                        getOptionLabel={(option) => `${option.field_type_name}`}
                        renderOption={(props, option: FieldType) => (
                          <div key={option.field_type_id}>
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.field_type_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.field_type_id === value.field_type_id
                        }
                        onChange={async (_, values) => {
                          field.onChange(values?.field_type_id || null);
                          if (
                            comboFieldTypes.find(
                              (item) =>
                                item.field_type_id === values?.field_type_id &&
                                item.field_type_name.toLowerCase() === "formula"
                            )
                          ) {
                            setIsTypeFormulaStartedDate(true);
                          } else {
                            setIsTypeFormulaStartedDate(false);
                          }
                          setValue("project_task_started_date", "", {
                            shouldValidate: true,
                          });
                          setValue("project_task_due_date", "", {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t(
                              "projecttasks.fields.project_task_started_date_field_type_id"
                            )}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.project_task_started_date_field_type_id}
                            helperText={GetError(errors.project_task_started_date_field_type_id?.type)}
                            disabled={isEdit}
                          />
                        )}
                      />
                    )}
                  />
                </div>
                {isTypeFormulaStartedDate ? (
                  <div className="w-50">
                    <Controller
                      name="project_task_started_date"
                      control={control}
                      rules={{ maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t(
                            "projecttasks.fields.project_task_started_date"
                          )}`}
                          ref={field.ref}
                          value={field.value || ""}
                          defaultValue={defaultProjectTaskStartedDate}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            setValue("project_task_due_date", "", {
                              shouldValidate: true,
                            });
                          }}
                          error={!!errors.project_task_started_date}
                          helperText={GetError(
                            errors.project_task_started_date?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={!startedDateFieldTypeId || isEdit ? true : false}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div className="w-50">
                    <Controller
                      name="project_task_started_date"
                      control={control}
                      rules={{}}
                      render={({ field }) => (
                        <DatePicker
                          open={openStartDateDatePicker}
                          onClose={() => setOpenStartDateDatePicker(false)}
                          ref={field.ref}
                          label={`${t(
                            "projecttasks.fields.project_task_started_date"
                          )}`}
                          onChange={(values: any) => {
                            field.onChange(values?.toDate() || null);
                            setValue("project_task_due_date", "", {
                              shouldValidate: true,
                            });
                          }}
                          value={field.value ? dayjs(field.value) : null}
                          slotProps={{
                            popper: {
                              sx: myStylePickers
                            },
                            textField: {
                              variant: "filled",
                              size: "small",
                              fullWidth: true,
                              onClick: () => setOpenStartDateDatePicker(true),
                              onBeforeInput: disableKeyboardEntry,
                              error:
                                !!errors.project_task_started_date,
                              helperText: GetError(
                                errors.project_task_started_date?.type
                              ),
                            },
                          }}
                          format="DD/MM/YYYY"
                          disabled={!startedDateFieldTypeId || isEdit ? true : false}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex mt-1 pt-3">
                <div className="w-50">
                  <Controller
                    name="project_task_due_date_field_type_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        options={comboFieldTypes}
                        defaultValue={defaultDueDateFieldTypeId}
                        getOptionLabel={(option) => `${option.field_type_name}`}
                        renderOption={(props, option: FieldType) => (
                          <div key={option.field_type_id}>
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.field_type_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.field_type_id === value.field_type_id
                        }
                        onChange={async (_, values) => {
                          field.onChange(values?.field_type_id || null);
                          if (
                            comboFieldTypes.find(
                              (item) =>
                                item.field_type_id === values?.field_type_id &&
                                item.field_type_name.toLowerCase() === "formula"
                            )
                          ) {
                            setIsTypeFormulaDueDate(true);
                          } else {
                            setIsTypeFormulaDueDate(false);
                          }
                          setValue("project_task_due_date", "", {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t(
                              "projecttasks.fields.project_task_due_date_field_type_id"
                            )}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.project_task_due_date_field_type_id}
                            helperText={GetError(errors.project_task_due_date_field_type_id?.type)}
                            disabled={isEdit}
                          />
                        )}
                      />
                    )}
                  />
                </div>
                {isTypeFormulaDueDate ? (
                  <div className="w-50">
                    <Controller
                      name="project_task_due_date"
                      control={control}
                      rules={{ maxLength: 50 }}
                      render={({ field }) => (
                        <TextField
                          variant="filled"
                          label={`${t(
                            "projecttasks.fields.project_task_due_date"
                          )}`}
                          ref={field.ref}
                          value={field.value || ""}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                          error={!!errors.project_task_due_date}
                          helperText={GetError(
                            errors.project_task_due_date?.type
                          )}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={!projectTaskStartedDate || !dueDateFieldTypeId || isEdit ? true : false}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div className="w-50">
                    <Controller
                      name="project_task_due_date"
                      control={control}
                      rules={{}}
                      render={({ field }) => (
                        <DatePicker
                          open={openEndDateDatePicker}
                          onClose={() => setOpenEndDateDatePicker(false)}
                          ref={field.ref}
                          label={`${t(
                            "projecttasks.fields.project_task_due_date"
                          )}`}
                          onChange={(values: any) => {
                            field.onChange(values?.toDate() || null);
                          }}
                          value={field.value ? dayjs(field.value) : null}
                          slotProps={{
                            popper: {
                              sx: myStylePickers
                            },
                            textField: {
                              variant: "filled",
                              size: "small",
                              fullWidth: true,
                              onClick: () => setOpenEndDateDatePicker(true),
                              onBeforeInput: disableKeyboardEntry,
                              error:
                                !!errors.project_task_due_date,
                              helperText: GetError(
                                errors.project_task_due_date?.type
                              ),
                            },
                          }}
                          minDate={dayjs(projectTaskStartedDate)}
                          format="DD/MM/YYYY"
                          disabled={!projectTaskStartedDate || !dueDateFieldTypeId || isEdit ? true : false}
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="d-flex mt-1 pt-3">
                <div className="w-100">
                  <Controller
                    name="project_task_notes"
                    control={control}
                    rules={{ maxLength: 2000 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t("projecttasks.fields.project_task_notes")}`}
                        ref={field.ref}
                        value={field.value}
                        multiline
                        minRows={10}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_task_notes}
                        helperText={GetError(errors.project_task_notes?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isEdit}
                      />
                    )}
                  />
                </div>
              </div>
            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => setOpenDialogUpdateTask(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions?.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions?.delete ? (
                <PrimaryButton onClick={() => setOpenDialog(true)}>
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttasks.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  );
};
