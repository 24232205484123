import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { InvoiceDocumentType, SalesInvoice } from "../../../../../interfaces/Sales/Invoices/salesinvoices";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import GenericPromises from "../../../../../api/GenericPromises";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";

type CopySalesInvoiceProps = {
  setOpenDialogCopySalesInvoice: Dispatch<SetStateAction<boolean>>,
  comboInvoiceDocumentTypes: InvoiceDocumentType[],
  defaultInvoiceDocumentType: InvoiceDocumentType | undefined,
  salesInvoiceLocal: SalesInvoice | undefined,
}

export const CopySalesInvoice = ({
  setOpenDialogCopySalesInvoice,
  comboInvoiceDocumentTypes,
  defaultInvoiceDocumentType,
  salesInvoiceLocal,
}: CopySalesInvoiceProps) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { GenericGetResource, GenericPostAction } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [myInvoiceDocumentType, setMyInvoiceDocumentType] = useState<InvoiceDocumentType>();
  const [disabledPost, setDisabledPost] = useState(false);

  const onCopy = async () => {
    if (myInvoiceDocumentType !== null) {

      setDisabledPost(true);
      const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
      let myData = {
        sales_invoice_name: responseConsecutive.data.data[0].Consecutive,
        sales_invoice_date: salesInvoiceLocal?.sales_invoice_date,
        purchase_order: salesInvoiceLocal?.purchase_order,
        customer_id: salesInvoiceLocal?.customer_id,
        customer_business_name: salesInvoiceLocal?.customer_business_name,
        customer_comercial_name: salesInvoiceLocal?.customer_comercial_name,
        customer_rfc: salesInvoiceLocal?.customer_rfc,
        customer_street_name: salesInvoiceLocal?.customer_street_name,
        customer_location_id: salesInvoiceLocal?.customer_location_id,
        customer_postal_code: salesInvoiceLocal?.customer_postal_code,
        customer_city_id: 0,
        customer_city_name: salesInvoiceLocal?.customer_city_name,
        customer_state_id: 0,
        customer_state_name: salesInvoiceLocal?.customer_state_name,
        customer_state_abbr: salesInvoiceLocal?.customer_state_abbr ?? null,
        customer_country_id: 0,
        customer_country_name: salesInvoiceLocal?.customer_country_name,
        customer_country_code: salesInvoiceLocal?.customer_country_code ?? null,
        subtotal_amount: salesInvoiceLocal?.subtotal_amount,
        total_amount: salesInvoiceLocal?.total_amount,
        tax_amount: salesInvoiceLocal?.tax_amount,
        currency_id: salesInvoiceLocal?.currency_id,
        currency_code: salesInvoiceLocal?.currency_code,
        currency_description: salesInvoiceLocal?.currency_description,
        project_id: null,
        invoice_doc_type_id: myInvoiceDocumentType?.invoice_doc_type_id,
        invoice_doc_status_id: 1,
        payment_mode_id: null,
        payment_mode_code: salesInvoiceLocal?.payment_mode_code,
        payment_mode_description: salesInvoiceLocal?.payment_mode_description,
        payment_method_id: null,
        payment_method_code: salesInvoiceLocal?.payment_method_code,
        payment_method_name: salesInvoiceLocal?.payment_method_name,
        cfdi_id: null,
        cfdi_code: salesInvoiceLocal?.cfdi_code,
        cfdi_description: salesInvoiceLocal?.cfdi_description,
        discount_amount: salesInvoiceLocal?.discount_amount,
        original_string_SAT: null,
        certificate_number_SAT: null,
        certificate_number_CFDI: null,
        uuid: null,
        seal_SAT: null,
        seal_CFDI: null,
        signing_date: null,
        cancellation_date: null,
        cancellation_reason: null,
        cancellation_reason_description: null,
        cancellation_response_code: null,
        customer_tax_regime_id: 0,
        customer_tax_regime_code: salesInvoiceLocal?.customer_tax_regime_code,
        customer_tax_regime_description: salesInvoiceLocal?.customer_tax_regime_description,
        includes_transportation_document: salesInvoiceLocal?.includes_transportation_document,
        includes_exterior_complement: salesInvoiceLocal?.includes_exterior_complement,
        is_accounted: false,
        id_consecutive: myInvoiceDocumentType?.consecutive_id,
        notes: salesInvoiceLocal?.notes,
        retention_amount: salesInvoiceLocal?.retention_amount,
        payment_condition_id: salesInvoiceLocal?.payment_condition_id,
        payment_condition_days: salesInvoiceLocal?.payment_condition_days,
        payment_condition_name: salesInvoiceLocal?.payment_condition_name,
        payment_condition_description: salesInvoiceLocal?.payment_condition_description,
        payment_due_date: salesInvoiceLocal?.payment_due_date,
        customer_language: salesInvoiceLocal?.customer_language,
        customer_email: salesInvoiceLocal?.customer_email,
        customer_phone_number: salesInvoiceLocal?.customer_phone_number,
        sales_invoice_QR: null,
        sales_invoice_XML: null,
        cancelled_sales_invoice_XML: null,
        sales_invoice_pdf: null,
        exchange_rate: salesInvoiceLocal?.exchange_rate,
        date_mail_send: null,
        user_mail_send: null,
        company_business_name : salesInvoiceLocal?.company_business_name,
        company_street : salesInvoiceLocal?.company_street,
        company_city_id : salesInvoiceLocal?.company_city_id,
        company_city_name : salesInvoiceLocal?.company_city_name,
        company_city_code : salesInvoiceLocal?.company_city_code,
        company_state_id : salesInvoiceLocal?.company_state_id,
        company_state_name : salesInvoiceLocal?.company_state_name,
        company_state_abbr : salesInvoiceLocal?.company_state_abbr,
        company_country_id : salesInvoiceLocal?.company_country_id,
        company_country_name : salesInvoiceLocal?.company_country_name,
        company_country_code : salesInvoiceLocal?.company_country_code,
        company_postal_code : salesInvoiceLocal?.company_postal_code,
        company_rfc : salesInvoiceLocal?.company_rfc,
        company_tax_regime_id : salesInvoiceLocal?.company_tax_regime_id,
        company_tax_regime_code : salesInvoiceLocal?.company_tax_regime_code,
        company_tax_regime_description : salesInvoiceLocal?.company_tax_regime_description,
        company_email : salesInvoiceLocal?.company_email,
        company_phone_number : salesInvoiceLocal?.company_phone_number,
        company_account_alias : salesInvoiceLocal?.company_account_alias,
        company_primary_account_alias: salesInvoiceLocal?.company_primary_account_alias,
        company_primary_bank_name: salesInvoiceLocal?.company_primary_bank_name,
        company_primary_account_number: salesInvoiceLocal?.company_primary_account_number,
        company_primary_interbank_key: salesInvoiceLocal?.company_primary_interbank_key,
        company_primary_swift_code: salesInvoiceLocal?.company_primary_swift_code,
        company_secondary_account_alias: salesInvoiceLocal?.company_secondary_account_alias,
        company_secondary_bank_name: salesInvoiceLocal?.company_secondary_bank_name,
        company_secondary_account_number: salesInvoiceLocal?.company_secondary_account_number,
        company_secondary_interbank_key: salesInvoiceLocal?.company_secondary_interbank_key,
        company_secondary_swift_code: salesInvoiceLocal?.company_secondary_swift_code,
        sales_delivery_addresses_id: salesInvoiceLocal?.sales_delivery_addresses_id,
      }
      GenericPostAction(`/salesinvoices/copy/${salesInvoiceLocal?.sales_invoice_id}`, myData, false)
        .then(async (response) => {
          await navigate("/salesinvoices", { state: { row: response.data } });
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        })
        .finally(() => setDisabledPost(false));
    }
    else {
      showSnackBar(t("salesinvoices.info.required-doc-type"), "error");
    }
  }

  const handleChange = (e: any, value: any) => {
    setMyInvoiceDocumentType(value);
  }

  useEffect(() => {
    setMyInvoiceDocumentType(defaultInvoiceDocumentType);
    setDataLoaded(true);
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box minWidth={400} className="d-flex flex-column m-1">
          <div className="d-flex w-100">
            <Autocomplete
              value={myInvoiceDocumentType || null}
              size="small"
              sx={{ flex: 2 }}
              options={comboInvoiceDocumentTypes}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option: InvoiceDocumentType) => (
                <div key={option.invoice_doc_type_id}>
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.name}
                  </Box>
                </div>
              )}
              isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("invoicedocumenttypes.title-view")}`}
                  variant="filled"
                  value={myInvoiceDocumentType?.name}
                />
              )}
            />
          </div>
          <div className="d-flex flex-row-reverse mt-4">
            <PrimaryButton disabled={disabledPost} onClick={() => onCopy()}>{t("generic.buttons.accept")}</PrimaryButton>
            <Button variant='outlined' onClick={() => setOpenDialogCopySalesInvoice(false)}>{t("generic.buttons.cancel")}</Button>
          </div>
          <SnackbarComponent />
        </Box>
      }
    </>
  )
}
