import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { Customer } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { DialogEntity } from '../../../../../components/Dialogs/DialogEntity';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../../components/Header';
import { Box, Button, TextField } from '@mui/material';
import { ButtonLoading } from '../../../../../theme/buttons';

type DialogProcessInvoiceProps = {
  open: boolean,
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
  customerId: number,
  salesShippingDocumentIds: number[],
}

export const DialogProcessInvoice = ({
  open,
  setOpenDialog,
  customerId,
  salesShippingDocumentIds,
}: DialogProcessInvoiceProps) => {
  const [t] = useTranslation("global");
  const { GenericGetResource, GenericPostResource } = GenericPromises();
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [customerLocal, setCustomerLocal] = useState<Customer>();

  const onProcessInvoice = () => {
    setIsLoading(true);
    let myData = {
      salesShippingDocumentIds: salesShippingDocumentIds,
      groupInvoicesBy: (salesShippingDocumentIds.length > 1) ? (customerLocal?.group_invoices_by ?? "Customer") : "Customer",
    }
    GenericPostResource(`/salesshippingdocuments/processinvoice`, myData)
      .then((responsePost) => {
        navigate(`/salesinvoices`, { state: { method: "add" } });
        if (customerLocal?.group_shippingdocuments_by === "CustomerSalesShippingDocument" && salesShippingDocumentIds.length > 1) {
          navigate(`/salesinvoices`, { state: { method: "add" } });
        }
        else {
          navigate(`/salesinvoices/view`, { state: { row: responsePost.data } });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackBar(error.message, 'error');
      });
  }

    useEffect(() => {
  
      const loadData = async () => {
        await GenericGetResource(`/customers/${customerId}`)
          .then((responseCustomer) => {
            setCustomerLocal(responseCustomer.data);
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
          });
        setDataLoaded(true);
      }
  
      setCustomerLocal(undefined);
      setDataLoaded(false);
      if (open) {
        loadData();
      }
    }, [open]);
  
    useEffect(() => { }, [salesShippingDocumentIds]);

  return (
    <>
      <DialogEntity
        open={open}
        title={<Header title={t("salesshippingdocuments.actions.processinvoice")} size="sm" />}
        content={
          <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
              <Box sx={{}} minWidth={1000}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"}>
                    <TextField
                      size="small"
                      value={`${customerLocal ? `${customerLocal?.customer_identifier} - ${customerLocal?.business_name}` : ""}`}
                      variant="filled"
                      InputProps={{ readOnly: true }}
                      label={t("customers.title-view")}
                      sx={{ width: "50%", paddingRight: 1 }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                  <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="btn my-1" onClick={onProcessInvoice}>{t("generic.buttons.accept")}</ButtonLoading>
                  <Button sx={{ marginBlock: .5 }} variant='outlined' disabled={isLoading} onClick={() => setOpenDialog(false)}>{t("generic.buttons.cancel")}</Button>
                </Box>
              </Box>
            }
          </>
        }
      />
      <SnackbarComponent />
    </>
  )
}
