import { useTranslation } from "react-i18next";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { ButtonLoading } from "../../../../../theme/buttons";
import GenericPromises from "../../../../../api/GenericPromises";
import { Customer } from "../../../../../interfaces/Sales/Catalogs/Customers/customers";
import { CreateProjectFromSalesOrder } from "../../helpers/createProjectFromSalesOrder";
import { Header } from "../../../../../components/Header";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Project } from "../../../../../interfaces/Projects/projects";
import { useNavigate } from "react-router-dom";

type DialogProcessOrderProps = {
  open: boolean,
  setOpenDialog: Dispatch<SetStateAction<boolean>>,
  customerId: number,
  salesPreorderIds: number[],
}

export const DialogProcessOrder = ({
  open,
  setOpenDialog,
  customerId,
  salesPreorderIds,
}: DialogProcessOrderProps) => {
  const [t] = useTranslation("global");
  const { GenericGetResourceGeneric, GenericGetResource, GenericPostResource } = GenericPromises();
  const { SnackbarComponent, showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [canCreateProject, setCanCreateProject] = useState(false);
  const [customerLocal, setCustomerLocal] = useState<Customer>();
  const [myProjectLocal, setMyProjectLocal] = useState<Project>({ customer_id: customerId, project_is_closed: false });

  const requiredFieldsProject: (keyof Project)[] = [
    "project_name",
    "project_type_id",
    "project_manager_id",
    "project_start_date",
    "project_end_date",
  ];

  const isValidProject = (project: Project): boolean => {
    return requiredFieldsProject.every((field) => project[field] !== undefined && project[field] !== null);
  };

  const ValidateProcessOrder = (): Boolean => {
    if (canCreateProject && !isValidProject(myProjectLocal)) {
      showSnackBar(t("salespreorders.dialog.processorder.info.missing-project-data"), "warning");
      return true;
    }
    return false;
  }

  const onProcessOrder = () => {
    if (ValidateProcessOrder()) return;
    else {
      setIsLoading(true);
      let myData = {
        salesPreordersIds: salesPreorderIds,
        newProject: canCreateProject ? myProjectLocal : {},
        groupSalesOrderBy: (salesPreorderIds.length > 1) ? (customerLocal?.group_salesorders_by ?? "Customer") : "Customer",
      }
      GenericPostResource(`/salespreorders/processorder`, myData)
        .then((responsePost) => {
          if (customerLocal?.group_salesorders_by === "CustomerQuotation" && salesPreorderIds.length > 1) {
            navigate(`/salesorders`, { state: { method: "add" } });
          }
          else {
            navigate(`/salesorders/view`, { state: { row: responsePost.data } });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          showSnackBar(error.message, 'error');
        });
    }
  }

  useEffect(() => {

    const loadData = async () => {
      await GenericGetResourceGeneric(`/companyconfigurations/bycompanyid/1`, `/gcompanyconfigurations`)
        .then((responseCompanyConfigurations) => {
          setCanCreateProject(responseCompanyConfigurations.data.data[0].can_create_project_from_sales_order);
          setMyProjectLocal({ customer_id: customerId, project_is_closed: false });
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
      await GenericGetResource(`/customers/${customerId}`)
        .then((responseCustomer) => {
          setCustomerLocal(responseCustomer.data);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        });
      setDataLoaded(true);
    }

    setCustomerLocal(undefined);
    setDataLoaded(false);
    if (open) {
      loadData();
    }
  }, [open]);

  useEffect(() => { }, [salesPreorderIds]);

  return (
    <>
      <DialogEntity
        open={open}
        title={<Header title={t("salespreorders.dialog.processorder.title-view")} size="sm" />}
        content={
          <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
              <Box sx={{}} minWidth={1000}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"}>
                    <TextField
                      size="small"
                      value={`${customerLocal ? `${customerLocal?.customer_identifier} - ${customerLocal?.business_name}` : ""}`}
                      variant="filled"
                      InputProps={{ readOnly: true }}
                      label={t("customers.title-view")}
                      sx={{ width: "50%", paddingRight: 1 }}
                    />
                  </Box>
                  {canCreateProject &&
                    <CreateProjectFromSalesOrder
                      myProjectLocal={myProjectLocal}
                      setMyProjectLocal={setMyProjectLocal}
                      customerId={customerId}
                      customerIdentifier={customerLocal?.customer_identifier ?? ""}
                    />
                  }
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                  <ButtonLoading isLoading={isLoading} variant="outlined" type="button" className="btn my-1" onClick={onProcessOrder}>{t("generic.buttons.accept")}</ButtonLoading>
                  <Button sx={{ marginBlock: .5 }} variant='outlined' disabled={isLoading} onClick={() => setOpenDialog(false)}>{t("generic.buttons.cancel")}</Button>
                </Box>
              </Box>
            }
          </>
        }
      />
      <SnackbarComponent />
    </>
  );
}
