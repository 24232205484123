import { Route, Routes } from 'react-router-dom'
import { TableSalesShippingDocuments } from './table'
import { AddSalesShippingDocuments } from './add'
import { ViewSalesShippingDocuments } from './view'

export const BrowserSalesShippingDocuments = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableSalesShippingDocuments />} />
      <Route path={"/add"} element={<AddSalesShippingDocuments />} />
      <Route path={"/view"} element={<ViewSalesShippingDocuments />} />
    </Routes>
  )
}