import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../../api/GenericPromises";
import { ProjectStageTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTemplate";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { Header } from "../../../../../components/Header";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { AddProjectStagesTemplate } from "./add";
import { AuthContext } from "../../../../../context/AuthContext";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { UpdateProjectStagesTemplate } from "./update";
import { defaultTheme } from "../../../../../theme/theme";
import { StagesContainer } from '../../../../../theme/projects/Containers/stagesContainer';
import { CreateButton } from "../../../../../theme/projects/Buttons/createButton";
import { StageButton } from "../../../../../theme/projects/Buttons/stageButton";
import { useColors } from "../../../../../theme/hooks/useColors";
import { TableProjectTaskTemplate } from "../ProjectTaskTemplate/table";
import { FieldType } from "../../../../../interfaces/Projects/Catalogs/fieldTypes";

type TableProjectStagesTemplateProps = {
  project_template_id: number | undefined;
  comboFieldTypes: FieldType[]
}

export const TableProjectStagesTemplate = ({
  project_template_id,
  comboFieldTypes
}: TableProjectStagesTemplateProps) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [openDialogAddStage, setOpenDialogAddStage] = useState(false);
  const [openDialogUpdateStage, setOpenDialogUpdateStage] = useState(false);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>();
  const [projectStagesTemplateLocal, setProjectStagesTemplateLocal] = useState<ProjectStageTemplate[]>([]);
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const [color, setColor] = useState<number[]>();
  const { HexToRGB } = useColors();

  const onDelete = async () => {
    GenericDeleteResource(`/projectstemplate/${project_template_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/projectstemplate", {
          state: { method: "delete" },
          replace: true,
        });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddStage = (newRow: ProjectStageTemplate) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectStagesTemplateLocal((prevState) => {
      return sortByConsecutive([...projectStagesTemplateLocal, myRow]);
    });
  };

  const handleUpdateStage = (updateRow: ProjectStageTemplate) => {
    if (projectStagesTemplateLocal.length === 0) {
      return;
    }
    setProjectStagesTemplateLocal((prevRows) => {
      const rowToUpdateIndex = projectStagesTemplateLocal.findIndex(
        (e) =>
          e.project_stage_template_id === updateRow.project_stage_template_id
      );

      return sortByConsecutive(
        prevRows.map((row, index) =>
          index === rowToUpdateIndex ? updateRow : row
        )
      );
    });
  };

  const handleDeleteStage = (deleteRow: number) => {
    if (projectStagesTemplateLocal.length === 0) {
      return;
    }

    setProjectStagesTemplateLocal((prevRows) => {
      const rowToDeleteIndex = projectStagesTemplateLocal.findIndex(
        (e) => e.project_stage_template_id === deleteRow
      );

      const updatedData = [
        ...projectStagesTemplateLocal.slice(0, rowToDeleteIndex),
        ...projectStagesTemplateLocal.slice(rowToDeleteIndex + 1),
      ];

      return sortByConsecutive(updatedData);
    });
  };

  const compareOrderHint = (a: string, b: string): number => {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const sortByConsecutive = (data: ProjectStageTemplate[]) => {
    return data.sort((a: any, b: any) =>
      compareOrderHint(
        a.project_stage_template_consecutive,
        b.project_stage_template_consecutive
      )
    );
  };

  useEffect(() => {
    GenericGetResource(
      `/projectstagestemplate/byprojecttemplateid/${project_template_id}`
    )
      .then(async (response) => {
        setProjectStagesTemplateLocal(sortByConsecutive(response.data.data));

        setColor(HexToRGB(defaultTheme.palette.primary.main));

        GetResourceByUrl(`/projectstagestemplate`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <>
          <div className="d-flex justify-content-center mt-4">
            <StagesContainer>
              {projectStagesTemplateLocal.sort((a, b) => Number(a.project_stage_template_consecutive) - Number(b.project_stage_template_consecutive))
                .map((stage) => (
                  <Grid
                    item
                    key={stage.project_stage_template_id}
                    xs={8}
                    md={5}
                    style={{ flexShrink: 0 }}
                  >
                    <StageButton
                      color={color}
                      onClick={() => {
                        setSelectedStageId(stage.project_stage_template_id);
                        setOpenDialogUpdateStage(true);
                      }}>
                      {stage.project_stage_template_name}
                    </StageButton>
                    <TableProjectTaskTemplate
                      project_stage_template_id={
                        stage.project_stage_template_id
                      }
                      project_template_id={stage.project_template_id}
                      comboFieldTypes={comboFieldTypes}
                    />
                  </Grid>
                ))}
              {resourceScreen?.create == true && (
                <Grid item xs={8} md={5} style={{ flexShrink: 0 }}>
                  <CreateButton
                    onClick={() => {
                      setOpenDialogAddStage(true);
                    }}
                    color={color}
                  >
                    {t("projectstagestemplate.actions.create")}
                  </CreateButton>
                </Grid>
              )}
            </StagesContainer>
          </div>
        </>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttypes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />

      <DialogEntity
        content={
          <AddProjectStagesTemplate
            handleAddStage={handleAddStage}
            setOpenDialogAddStage={setOpenDialogAddStage}
            project_template_id={project_template_id}
            comboFieldTypes={comboFieldTypes}
          />
        }
        open={openDialogAddStage}
        setOpen={setOpenDialogAddStage}
        title={
          <Header title={t("projectstagestemplate.title-view")} size="sm" />
        }
      />

      <DialogEntity
        content={
          <UpdateProjectStagesTemplate
            project_template_id={project_template_id}
            permissions={resourceScreen}
            setOpenDialogUpdateStage={setOpenDialogUpdateStage}
            handleUpdateStage={handleUpdateStage}
            handleDeleteStage={handleDeleteStage}
            projectStageTemplatePayload={projectStagesTemplateLocal?.find((stage) => stage.project_stage_template_id === selectedStageId) || {}}
            comboFieldTypes={comboFieldTypes}
            />
        }
        open={openDialogUpdateStage}
        setOpen={setOpenDialogUpdateStage}
        title={
          <Header title={t("projectstagestemplate.title-view")} size="sm" />
        }
      />
      <SnackbarComponent />
    </>
  );
};
