import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectGroup } from "../../../../../../interfaces/Projects/Catalogs/projectGroups";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTasks";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../../../api/GenericPromises";
import { FormsErrors } from "../../../../../../hooks/Forms/FormsErrors";
import { Group } from "../../../../../../interfaces/Security/groups";
import { TaskState } from "../../../../../../interfaces/Projects/Catalogs/taskStates";
import { FieldType } from "../../../../../../interfaces/Projects/Catalogs/fieldTypes";
import { useBase64 } from "../../../../../../hooks/useBase64";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ButtonStyled,
  myStylePickers,
  PrimaryButton,
} from "../../../../../../theme/buttons";
import dayjs from "dayjs";

type AddProjectTaskProps = {
  setOpenDialogAddTask: Dispatch<SetStateAction<boolean>>;
  project_id: number | undefined;
  project_stage_id: number | undefined;
  handleAddTask: (newRow: ProjectTask) => void;
  comboFieldTypes: FieldType[];
};

export const AddProjectTask = ({
  setOpenDialogAddTask,
  project_id,
  project_stage_id,
  handleAddTask,
  comboFieldTypes
}: AddProjectTaskProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } =
    GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [comboProjectGroups, setComboProjectGroups] = useState<ProjectGroup[]>(
    []
  );
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [comboTaskStates, setComboTaskStates] = useState<TaskState[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [isTypeFormulaStartedDate, setIsTypeFormulaStartedDate] = useState(false);
  const [isTypeFormulaDueDate, setIsTypeFormulaDueDate] = useState(false);
  const { utf8ToBase64 } = useBase64();


  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProjectTask>({
    defaultValues: {
      project_task_id: undefined,
      project_stage_id: undefined,
      project_group_id: undefined,
      current_task_state_id: undefined,
      MS_task_id: undefined,
      MS_due_date_time: undefined,
      MS_start_date_time: undefined,
      MS_priority: undefined,
      MS_applied_categories: undefined,
      MS_description: undefined,
      MS_show_in_planner: undefined,
      project_task_name: undefined,
      project_task_description: undefined,
      project_task_started_date: undefined,
      project_task_due_date: undefined,
      project_task_notes: undefined,
      project_task_started_date_field_type_id: undefined,
      project_task_due_date_field_type_id: undefined
    },
  });

  const startedDateFieldTypeId = useWatch({
    control,
    name: "project_task_started_date_field_type_id"
  });

  const projectTaskStartedDate = useWatch({
    control,
    name: "project_task_started_date"
  });

  const dueDateFieldTypeId = useWatch({
    control,
    name: "project_task_due_date_field_type_id"
  });
  const onSubmit: SubmitHandler<ProjectTask> = (data) => onPost(data);

  const onPost = (data: ProjectTask) => {
    let myData = {
      project_stage_id: project_stage_id,
      project_group_id: data.project_group_id,
      current_task_state_id: comboTaskStates.find(
        (taskState) => taskState.task_state_name == "PENDING"
      )?.task_state_id,
      MS_task_id: null,
      MS_due_date_time: null,
      MS_start_date_time: null,
      MS_priority: null,
      MS_applied_categories: null,
      MS_description: null,
      MS_show_in_planner: null,
      project_task_name: data.project_task_name,
      project_task_description: data.project_task_description,
      project_task_started_date: data.project_task_started_date,
      project_task_due_date: data.project_task_due_date,
      project_task_notes: data.project_task_notes,
      project_task_started_date_field_type_id: data.project_task_started_date_field_type_id,
      project_task_due_date_field_type_id: data.project_task_due_date_field_type_id
    };
    setLoadingPost(true);
    GenericPostResource("/projecttask", myData)
      .then((response) => {
        let myDataProjecttaskstates = {
          previous_state_id: comboTaskStates.find(
            (taskState) => taskState.task_state_name == "PENDING"
          )?.task_state_id,
          new_state_id: comboTaskStates.find(
            (taskState) => taskState.task_state_name == "PENDING"
          )?.task_state_id,
          project_task_id: response.data.project_task_id,
          project_task_state_name: response.data.project_task_name,
          project_task_state_description: null,
        };
        GenericPostResource("/projecttaskstates", myDataProjecttaskstates)
          .then((response2) => {
            handleAddTask(response.data);
            setOpenDialogAddTask(false);
          })
          .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    setOpenDialogAddTask(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleAddProjectGroups = async (group: Group) => {
    const existingObject = comboProjectGroups.find(
      (projectGroup) =>
        projectGroup.group_id === group.group_id &&
        projectGroup.project_id === project_id
    );

    if (existingObject) {
      return existingObject;
    } else {
      let myData = {
        group_id: group.group_id,
        project_id: project_id,
        project_group_name: group.group_name,
      };

      return await GenericPostResource("/projectgroups", myData)
        .then((response) => {
          setComboProjectGroups([...comboProjectGroups, response.data]);
          return response.data;
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        });
    }
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/projectgroups"),
      GenericGetResource("/taskstates"),
      GenericGetResourceGeneric("/groups", "/ggroups"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectGroups(responses[0].data.data);
        setComboTaskStates(responses[1].data.data);
        setComboGroups(responses[2].data.data);

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_group_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      getOptionLabel={(option) => `${option.group_name}`}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.group_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.group_id === value.group_id
                      }
                      onChange={async (_, values) => {
                        if (values?.group_id) {
                          let projectGroup = await handleAddProjectGroups(
                            values
                          );
                          field.onChange(projectGroup?.project_group_id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projecttasks.fields.project_group")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_group_id}
                          helperText={GetError(errors.project_group_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_task_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t("projecttasks.fields.project_task_name")}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_name}
                      helperText={GetError(errors.project_task_name?.type)}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-100">
                <Controller
                  name="project_task_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projecttasks.fields.project_task_description"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_task_description}
                      helperText={GetError(
                        errors.project_task_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_task_started_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaStartedDate(true);
                        } else {
                          setIsTypeFormulaStartedDate(false);
                        }
                        setValue("project_task_started_date", "", {
                          shouldValidate: true,
                        });
                        setValue("project_task_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttasks.fields.project_task_started_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_task_started_date_field_type_id}
                          helperText={GetError(errors.project_task_started_date_field_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaStartedDate ? (
                <div className="w-50">
                  <Controller
                    name="project_task_started_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projecttasks.fields.project_task_started_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          setValue("project_task_due_date", "", {
                            shouldValidate: true,
                          });
                        }}
                        error={!!errors.project_task_started_date}
                        helperText={GetError(
                          errors.project_task_started_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!startedDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_task_started_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openStartDateDatePicker}
                        onClose={() => setOpenStartDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projecttasks.fields.project_task_started_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenStartDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_task_started_date,
                            helperText: GetError(
                              errors.project_task_started_date?.type
                            ),
                          },
                        }}
                        format="DD/MM/YYYY"
                        disabled={!startedDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_task_due_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaDueDate(true);
                        } else {
                          setIsTypeFormulaDueDate(false);
                        }
                        setValue("project_task_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projecttasks.fields.project_task_due_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_task_due_date_field_type_id}
                          helperText={GetError(errors.project_task_due_date_field_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaDueDate ? (
                <div className="w-50">
                  <Controller
                    name="project_task_due_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projecttasks.fields.project_task_due_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_task_due_date}
                        helperText={GetError(
                          errors.project_task_due_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!projectTaskStartedDate || !dueDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_task_due_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openEndDateDatePicker}
                        onClose={() => setOpenEndDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projecttasks.fields.project_task_due_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenEndDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_task_due_date,
                            helperText: GetError(
                              errors.project_task_due_date?.type
                            ),
                          },
                        }}
                        minDate={dayjs(projectTaskStartedDate)}
                        format="DD/MM/YYYY"
                        disabled={!projectTaskStartedDate || !dueDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton type="submit" variant="outlined" className="m-1">
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
