import GenericPromises from "../../../../../../api/GenericPromises";
import { useDates } from "../../../../../../hooks/useDates";
import { useFormulaEvaluator } from "../../../../../../hooks/useFormulaEvaluator";
import { ProjectExtraField } from "../../../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Project } from "../../../../../../interfaces/Projects/projects";
import { Supplier } from "../../../../../../interfaces/Purchases/Catalogs/suppliers";
import { useRequestPurchaseInvoice } from "../hookRequestPurchaseInvoice/hookRequestPurchaseInvoice";
import { PurchaseInvoice } from '../../../../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { ProjectQueue } from "../../../table";
import { useCallback, useEffect, useRef, useState } from "react";
import { Parameter } from "../../../../../../interfaces/Commons/parameters";

export const useCalculateFormulasProjects = () => {
  const { EvaluateFormula, fetchParameters } = useFormulaEvaluator();
  const { isDiferentDateToday } = useDates();
  const { onRequestPurchaseInvoice, PutPurchaseInvoiceDetails } = useRequestPurchaseInvoice();
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const itemDemurrageName = "Pago demoras";
  const itemStoragesName = "Pago de Almacenajes";
  const projectExtraFieldNameStorages = "ALMACENAJES";
  const projectExtraFieldNameDemurrages = "DEMORAS GENERADAS";
  const projectExtraFieldrelatedNameDemurrages = "DEMORAS";
  const projectExtraFieldNameConsignatory = "CONSIGNATARIO";
  const projectExtraFieldNameAA = "AA";
  const projectExtraFieldNameETA = "ETA";
  const projectExtraFieldNameEnteredEmpty = "INGRESÓ VACÍO";
  const projectExtraFieldNameCustomsCleared = "DESADUANADO";
  const projectExtraFieldNameTerminal = "TERMINAL";
  const projectExtraFieldNameShipping = "NAVIERA";

  const isCancelLoadingRef = useRef(false);

  const CancelProcess = useCallback(() => {
    isCancelLoadingRef.current = true;
  }, []);

  const CheckAndValidateFormulas = async (
    myProjects: any[],
    setMyProjectsQueueToFunctions: React.Dispatch<React.SetStateAction<ProjectQueue[]>>,
    isForced: boolean = false,
    setIsLoadingFunctions: React.Dispatch<React.SetStateAction<boolean>>,
    isCancelLoadingFunctions: boolean,
  ) => {
    isCancelLoadingRef.current = isCancelLoadingFunctions;
    let myComboParameters: Parameter[] = await fetchParameters();
    for (let i = 0; i < myProjects.length; i++) {
      const element = myProjects[i];

      if (isCancelLoadingRef.current) {
        break;
      }
      // si el proyecto ya está cerrado no hacer nada
      if (!element.project_is_closed) {
        if ((!element.project_date_update || isDiferentDateToday(element?.project_date_update)) || isForced) {
          await GenericGetResource(`/projectextrafields/byprojectid/${element.project_id ?? 0}`)
            .then(async (responseExtraFields) => {
              try {
                setIsLoadingFunctions((prev) => true);
                setMyProjectsQueueToFunctions((prev) => [...prev, { status: 'inprogress', data: element }]);
                await CheckAndValidateFormulasPerProject(element, responseExtraFields.data.data, isForced, myComboParameters)
                  .then(async () => {
                    let myPutData = {
                      project_date_update: new Date(),
                    }
                    await GenericPutResource(`/projects/${element.project_id}`, myPutData)
                      .then((responsePut) => {
                        element.project_date_update = responsePut.data.project_date_update;
                      });
                  });
                setMyProjectsQueueToFunctions((prev) =>
                  prev.map((project) =>
                    project.data.project_id === element.project_id
                      ? { ...project, status: 'done', data: element }
                      : project
                  )
                );
              }
              catch (error) {
                let myPutData = {
                  project_date_update: new Date(),
                }
                await GenericPutResource(`/projects/${element.project_id}`, myPutData)
                  .then((responsePut) => {
                    element.project_date_update = responsePut.data.project_date_update;
                  });
                setMyProjectsQueueToFunctions((prev) =>
                  prev.map((project) =>
                    project.data.project_id === element.project_id
                      ? { ...project, status: 'error', data: element }
                      : project
                  )
                );
              }
            });
        }
      }
    }
  }

  const CheckAndValidateFormulasPerProject = async (
    projectRow: Project | undefined,
    projectExtrafieldResponseData: ProjectExtraField[],
    isForced: boolean = false,
    myComboParameters: Parameter[],
    mySuppliersData?: Supplier[] | undefined,
  ) => {
    try {
      // si el proyecto ya está cerrado no hacer nada
      if (!projectRow?.project_is_closed) {
        // Check date update, si no tiene fecha o si no es igual a la fecha de hoy
        if ((!projectRow?.project_date_update || isForced || isDiferentDateToday(projectRow?.project_date_update))) {

          const now = new Date();
          const isoString = now.toISOString();
          const mySuppliers = (mySuppliersData) ? mySuppliersData : await GenericGetResource(`/suppliers`);

          // --- ALMACENAJES ---

          let myDaysStorage = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameStorages);
          // evaluar los días con almacenajes
          // Buscar agente aduanal y si no existe no hacer nada
          let myAA = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameAA);
          let myTerminal = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameTerminal);
          let myETA = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameETA);
          let mySupplierStorage = mySuppliers.data.data.find((item: any) => item.comercial_name === myAA?.project_extra_field_value);
          let myDateCustomsClearance = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameCustomsCleared);

          let AuxiliaryResponse: Number | null = 0;

          if (myDaysStorage?.project_extra_field_value && (myDaysStorage?.project_extra_field_value.length > 0)) {
            if ((myAA && myAA.project_extra_field_value) && (myTerminal && myTerminal.project_extra_field_value) &&
              (mySupplierStorage !== undefined) && (myETA && myETA.project_extra_field_value)) {
              await EvaluateFormula(myDaysStorage?.project_extra_field_value, projectExtrafieldResponseData, myComboParameters)
                .then(async (responseExtraField) => {
                  if (responseExtraField > 0) {
                    // que la validación tenga un id
                    let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemStoragesName);
                    // si existen almacenajes y no existe factura de compra solicitar factura de compra
                    if (((myPurchaseInvoiceDetails.length < 1) && (responseExtraField > 0))) {
                      if (mySupplierStorage) {
                        await onRequestPurchaseInvoice(myDaysStorage?.project_extra_field_name ?? '', responseExtraField, mySupplierStorage.supplier_id, myTerminal?.project_extra_field_value, projectRow?.project_id ?? 0, new Date(myDateCustomsClearance?.project_extra_field_value));
                      }
                    }
                    // si ya existe una factura de compra actualizar el item que tenga los almacenajes con el rango seleccionado
                    else {
                      await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myTerminal?.project_extra_field_value, myDaysStorage?.project_extra_field_name ?? '', new Date(myDateCustomsClearance?.project_extra_field_value));
                    }
                  }
                  else {
                    AuxiliaryResponse = null
                  }
                })
                .finally(async () => {
                  if (AuxiliaryResponse === null) {
                    // calcula el valor con hoy
                    await EvaluateFormula(`${myDaysStorage?.project_extra_field_value.replace(`{${projectExtraFieldNameCustomsCleared}}`, `new Date("${isoString}")`)}`, projectExtrafieldResponseData, myComboParameters)
                      .then(async (responseExtraField) => {
                        if (responseExtraField > 0) {
                          // update factura de compra con nuevo valor de almacenajes +1 día
                          let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemStoragesName);
                          if ((myPurchaseInvoiceDetails.length < 1) && responseExtraField > 0) {
                            await onRequestPurchaseInvoice(myDaysStorage?.project_extra_field_name ?? '', responseExtraField, mySupplierStorage.supplier_id, myTerminal?.project_extra_field_value, projectRow?.project_id ?? 0, new Date(isoString));
                          }
                          else {
                            await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myTerminal?.project_extra_field_value, myDaysStorage?.project_extra_field_name ?? '', new Date(isoString));
                          }
                        }
                      });
                  }
                });
            }
            else {
              throw new Error('Error Formule STORAGES');
            }
          }
          else {
            let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemStoragesName);
            if (myPurchaseInvoiceDetails.length > 0) {
              await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, 0, myTerminal?.project_extra_field_value, projectExtraFieldNameStorages ?? '', new Date(myDateCustomsClearance?.project_extra_field_value));
            }
          }

          // --- DEMORAS ---

          let myDaysDemurrages = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameDemurrages);
          let myConsignatory = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameConsignatory);
          let myShipping = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameShipping);
          let mySupplierDemurrages = mySuppliers.data.data.find((item: any) => item.comercial_name === myConsignatory?.project_extra_field_value);
          let myDateEnteredEmpty = projectExtrafieldResponseData.find((item) => item.project_extra_field_name === projectExtraFieldNameEnteredEmpty);

          AuxiliaryResponse = 0;

          if (myDaysDemurrages?.project_extra_field_value && (myDaysDemurrages?.project_extra_field_value.length > 0)) {
            if ((myDaysDemurrages && myDaysDemurrages.project_extra_field_value) && (myConsignatory && myConsignatory.project_extra_field_value)
              && (myShipping && myShipping.project_extra_field_value) && (mySupplierDemurrages !== undefined) && (myETA && myETA.project_extra_field_value)) {
              await EvaluateFormula(myDaysDemurrages?.project_extra_field_value, projectExtrafieldResponseData, myComboParameters)
                .then(async (responseExtraField) => {
                  if (responseExtraField > 0) {
                    let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemDemurrageName);
                    if (((myPurchaseInvoiceDetails.length < 1) && (responseExtraField > 0))) {
                      if (mySupplierDemurrages) {
                        await onRequestPurchaseInvoice(projectExtraFieldrelatedNameDemurrages ?? '', responseExtraField, mySupplierDemurrages.supplier_id, myShipping?.project_extra_field_value, projectRow?.project_id ?? 0, new Date(myDateEnteredEmpty?.project_extra_field_value));
                      }
                    }
                    else {
                      await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myShipping?.project_extra_field_value, projectExtraFieldrelatedNameDemurrages ?? '', new Date(myDateEnteredEmpty?.project_extra_field_value));
                    }
                  }
                  else {
                    AuxiliaryResponse = null
                  }
                })
                .finally(async () => {
                  if (AuxiliaryResponse === null) {
                    // calcula el valor con hoy
                    await EvaluateFormula(`${myDaysDemurrages?.project_extra_field_value.replace(`{${projectExtraFieldNameEnteredEmpty}}`, `new Date("${isoString}")`)}`, projectExtrafieldResponseData, myComboParameters)
                      .then(async (responseExtraField) => {
                        if (responseExtraField > 0) {
                          let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemDemurrageName);
                          if (((myPurchaseInvoiceDetails.length < 1) && (responseExtraField > 0))) {
                            if (mySupplierDemurrages) {
                              await onRequestPurchaseInvoice(projectExtraFieldrelatedNameDemurrages ?? '', responseExtraField, mySupplierDemurrages.supplier_id, myShipping?.project_extra_field_value, projectRow?.project_id ?? 0, new Date(isoString));
                            }
                          }
                          else {
                            await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, responseExtraField, myShipping?.project_extra_field_value, projectExtraFieldrelatedNameDemurrages ?? '', new Date(isoString));
                          }
                        }
                      });
                  }
                });
            }
            else {
              throw new Error('Error Formule Demurrage');
            }
          }
          else {
            let myPurchaseInvoiceDetails = await ValidatePurchaseInvoiceDetail(projectRow?.project_id ?? 0, itemDemurrageName);
            if (myPurchaseInvoiceDetails.length > 0) {
              await PutPurchaseInvoiceDetails(myPurchaseInvoiceDetails[0].purchase_invoice_id, myPurchaseInvoiceDetails, 0, myShipping?.project_extra_field_value, projectExtraFieldrelatedNameDemurrages ?? '', new Date(myDateEnteredEmpty?.project_extra_field_value));
            }
          }
        }
      }
    } catch (e) {
      throw new Error();
    }
  }

  const ValidatePurchaseInvoiceDetail = async (project_id: number, detailOfPurchaseInvoice: string): Promise<PurchaseInvoice[]> => {
    let myInvoiceDetails = await GenericGetResource(`/purchaseinvoicesdetails/validatepurchaseinvoicedetail/${project_id}/${detailOfPurchaseInvoice}`);
    return myInvoiceDetails.data.result;
  }

  return {
    CheckAndValidateFormulas,
    CheckAndValidateFormulasPerProject,
    CancelProcess,
  }
}
