import { Control, Controller, FieldErrors } from "react-hook-form";
import { PurchaseOrder } from "../../../interfaces/Purchases/Orders/purchaseOrders";
import { Company } from "../../../interfaces/Security/companies";
import { Supplier } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../api/GenericPromises";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { myStylePickers } from "../../../theme/buttons";
import dayjs from "dayjs";

type PurchaseOrderAddHeaderProps = {
  control: Control<PurchaseOrder, any>,
  errors: FieldErrors<PurchaseOrder>,
  isSupplierSelected?: boolean,
  onChangeSupplier?: (supplier_id: number | undefined) => void,
  company: Company | undefined,
  comboSuppliers: Supplier[],
  setMyConsecutive: Dispatch<SetStateAction<string>>,
  myConsecutive: string,
  isSelectedSupplierTextfield: boolean
  setIsSelectedSupplierTextfield: Dispatch<SetStateAction<boolean>>
}

export const PurchaseOrderAddHeader = ({
  control,
  errors,
  isSupplierSelected,
  onChangeSupplier,
  company,
  comboSuppliers,
  setMyConsecutive,
  myConsecutive,
  isSelectedSupplierTextfield,
  setIsSelectedSupplierTextfield
}: PurchaseOrderAddHeaderProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePicker2, setOpenDatePicker2] = useState(false);
  const { GenericGetResource } = GenericPromises();

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const GetAndSetConsecutive = async (consecutive_id?: number) => {
    if (consecutive_id) {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutive_id}`);
      setMyConsecutive((prev) => response.data.data[0].Consecutive);
    }
  }

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <Box className='custom-card-view w-25' sx={{ marginRight: 2 }}>
          <div>
            <Typography>{company?.business_name}</Typography>
            <Typography>{company?.rfc}</Typography>
            <Typography>{company?.phone_number}</Typography>
            <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
          </div>
          <div>
            <Controller
              name="supplier_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={comboSuppliers}
                  getOptionLabel={(option) => option.business_name}
                  renderOption={(props, option: Supplier) => (
                    <div key={option.supplier_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {(option.comercial_name) ? option.comercial_name + " - " : ""} {option.business_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.supplier_id === value.supplier_id}
                  onChange={(_, values) => {
                    field.onChange(values?.supplier_id || null)
                    if (values?.supplier_id !== null && values?.supplier_id !== undefined) {
                      onChangeSupplier && onChangeSupplier(values?.supplier_id);
                      setIsSelectedSupplierTextfield(true);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("suppliers.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.supplier_id}
                      helperText={GetError(errors.supplier_id?.type)}
                    />
                  )}
                  disabled={isSupplierSelected || isSelectedSupplierTextfield}
                />
              )}
            />
          </div>
        </Box>
        {/* Right Box*/}
        <div className=' d-flex flex-column w-75'>
          {/* First row */}
          <div className='d-flex w-100'>

            <div className='w-50'>
              <Controller
                name="purchase_order_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("purchaseorders.fields.purchase_order_name")}`}
                    ref={field.ref}
                    value={myConsecutive}
                    disabled={isSelectedSupplierTextfield}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.purchase_order_name}
                    helperText={GetError(errors.purchase_order_name?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="purchase_order_date"
                control={control}
                rules={{}}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    ref={field.ref}
                    label={`${t("purchaseorders.fields.purchase_order_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.purchase_order_date,
                        helperText: GetError(errors.purchase_order_date?.type),
                      }
                    }
                    }
                    defaultValue={dayjs()}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedSupplierTextfield}
                  />
                }
              />
            </div>

          </div>
          {/* Second Row */}
          <div className='d-flex w-100 mt-2'>
            <div className="w-50">
              <Controller
                name="sales_order"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    disabled={isSelectedSupplierTextfield}
                    label={`${t("purchaseorders.fields.sales_order")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.sales_order}
                    helperText={GetError(errors.sales_order?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                  />
                )}
              />
            </div>
            <div className="w-50">
              <Controller
                name="supplier_purchase_order_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker2}
                    onClose={() => setOpenDatePicker2(false)}
                    ref={field.ref}
                    label={`${t("purchaseorders.fields.supplier_purchase_order_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker2(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.supplier_purchase_order_date,
                        helperText: GetError(errors.supplier_purchase_order_date?.type),
                      }
                    }}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedSupplierTextfield}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}