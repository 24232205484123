import { useTranslation } from "react-i18next"
import { Box, Switch, TextField, Typography } from "@mui/material"
import { CancelledSalesInvoice } from "../../../../interfaces/Sales/Invoices/Cancellations/cancelledsalesinvoices";
import { useFormatNumber } from "../../../../hooks/useFormatNumber";
import { CustomerLocation } from "../../../../interfaces/Sales/Catalogs/Customers/customers";

type InvoiceHeaderProps = {
  SalesInvoiceLocal: CancelledSalesInvoice | undefined,
  defaultCustomerLocation: CustomerLocation | undefined,
  isDeaccounted: boolean,
}

export const UpdateCancelledInvoiceHeader = ({
  SalesInvoiceLocal,
  isDeaccounted
}: InvoiceHeaderProps) => {
  const [t] = useTranslation("global");
  const { setFormatNumber } = useFormatNumber();

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <div className='d-flex w-50'>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{t("salesinvoices.info.invoiced-to")}</Typography>
              <Typography>{SalesInvoiceLocal?.customer_business_name}</Typography>
              <Typography>{SalesInvoiceLocal?.customer_rfc}</Typography>
              <Typography>{SalesInvoiceLocal?.customer_tax_regime_code} - {SalesInvoiceLocal?.customer_tax_regime_description}</Typography>
              <Typography>{SalesInvoiceLocal?.customer_state_name}, {SalesInvoiceLocal?.customer_city_name}, {SalesInvoiceLocal?.customer_state_name}, {SalesInvoiceLocal?.customer_country_name}</Typography>
            </div>
          </Box>
          <Box className='custom-card-view w-100' sx={{ marginRight: 2 }}>
            <div>
              <Typography>{t("salesinvoices.info.send-to")}</Typography>
              <Typography>{SalesInvoiceLocal?.delivery_business_name}</Typography>
              <Typography>{SalesInvoiceLocal?.delivery_rfc}</Typography>
              <Typography>{SalesInvoiceLocal?.delivery_tax_regime_code} - {SalesInvoiceLocal?.delivery_tax_regime_description}</Typography>
              <Typography>{SalesInvoiceLocal?.delivery_state_name}, {SalesInvoiceLocal?.delivery_city_name}, {SalesInvoiceLocal?.delivery_state_name}, {SalesInvoiceLocal?.delivery_country_name}</Typography>
            </div>
          </Box>
        </div>

        {/* Right Box*/}
        <div className='d-flex flex-column w-50'>
          {/* first Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 9 }}>
              <TextField
                label={`${t("paymentmethods.title-view")}`}
                variant="filled"
                value={`${SalesInvoiceLocal?.payment_method_code} - ${SalesInvoiceLocal?.payment_method_name}`}
                size="small"
                sx={{ width: "100%", paddingRight: 1 }}
                InputProps={{
                  readOnly: true,
                  title: SalesInvoiceLocal?.payment_method_name
                }}
              />
            </Box>
            <Box sx={{ flex: 9 }}>
              <TextField
                label={`${t("paymentmodes.title-view")}`}
                variant="filled"
                value={`${SalesInvoiceLocal?.payment_mode_code} - ${SalesInvoiceLocal?.payment_mode_description}`}
                size="small"
                sx={{ width: "100%", paddingRight: 1 }}
                InputProps={{
                  readOnly: true,
                  title: `${SalesInvoiceLocal?.payment_mode_code} - ${SalesInvoiceLocal?.payment_mode_description}`
                }}
              />
            </Box>
            <Box sx={{ flex: 11 }} className="d-flex flex-column mx-2" >
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("salesinvoices.fields.tax_amount")}: {setFormatNumber({ value: SalesInvoiceLocal?.tax_amount ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("salesinvoices.fields.retention_amount")}: {setFormatNumber({ value: SalesInvoiceLocal?.retention_amount ?? 0 })}</Typography>
            </Box>
          </div>
          {/* second Row */}
          <div className='d-flex mt-2'>
            <Box sx={{ flex: 9 }}>

              <TextField
                label={`${t("currencies.title-view")}`}
                variant="filled"
                value={`${SalesInvoiceLocal?.currency_code} - ${SalesInvoiceLocal?.currency_description}`}
                size="small"
                sx={{ width: "100%", paddingRight: 1 }}
                InputProps={{
                  readOnly: true,
                  title: `${SalesInvoiceLocal?.currency_code} - ${SalesInvoiceLocal?.currency_description}`
                }}
              />

            </Box>
            <Box sx={{ flex: 9 }}>

              <TextField
                label={`${t("salesinvoices.fields.cfdi")}`}
                variant="filled"
                value={`${SalesInvoiceLocal?.cfdi_code} - ${SalesInvoiceLocal?.cfdi_description}`}
                size="small"
                sx={{ width: "100%", paddingRight: 1 }}
                InputProps={{
                  readOnly: true,
                  title: `${SalesInvoiceLocal?.cfdi_code} - ${SalesInvoiceLocal?.cfdi_description}`
                }}
              />

            </Box>
            <Box sx={{ flex: 11 }} className='d-flex flex-column mx-2'>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'} fontSize={15}>{t("salesinvoices.fields.discount_amount")}:{setFormatNumber({ value: SalesInvoiceLocal?.discount_amount ?? 0 })}</Typography>
            </Box>
          </div>
          {/* Fourth row */}
          <div className="d-flex mt-2">
            <Box sx={{ flex: 9 }}>
              <TextField
                label={`${t("paymentconditions.title-view")}`}
                variant="filled"
                value={`${SalesInvoiceLocal?.payment_condition_name || ""} ${SalesInvoiceLocal?.payment_condition_description || ""}`}
                size="small"
                sx={{ width: "63%", paddingRight: 1 }}
                InputProps={{
                  readOnly: true,
                  title: `${SalesInvoiceLocal?.payment_condition_name || ""} ${SalesInvoiceLocal?.payment_condition_description || ""}`
                }}
              />
            </Box>
            <div className="w-50 d-flex flex-column mx-2">
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("salesinvoices.fields.subtotal_amount")}: {setFormatNumber({ value: SalesInvoiceLocal?.subtotal_amount ?? 0 })}</Typography>
              <Typography noWrap alignSelf={'end'} fontWeight={'Bold'}>{t("salesinvoices.fields.total_amount")}: {setFormatNumber({ value: SalesInvoiceLocal?.total_amount ?? 0 })}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row mt-2">
        <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2 }}>
          <Typography>{t("salesinvoices.fields.is_accounted")}</Typography>
          {isDeaccounted ?
            <Switch defaultChecked={true} disabled />
            : <Switch defaultChecked={false} disabled />
          }
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: 2 }}>
          <Typography>{t("salesinvoices.fields.includes_transportation_document")}</Typography>
          <Switch
            defaultChecked={SalesInvoiceLocal?.includes_transportation_document} disabled />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{t("salesinvoices.fields.includes_exterior_complement")}</Typography>
          <Switch
            defaultChecked={SalesInvoiceLocal?.includes_exterior_complement} disabled />
        </Box>
      </div>
    </>
  )
}
