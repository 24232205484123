import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDates } from "../../../hooks/useDates";
import GenericPromises from "../../../api/GenericPromises";
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../interfaces/Security/menu";
import { usePermissions } from "../../../hooks/usePermissions";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { PrimaryButton } from "../../../theme/buttons";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import React from "react";
import { AuthContext } from "../../../context/AuthContext";
import { UserCompanies } from "../../../interfaces/Security/userCompanies";
import { useBase64 } from "../../../hooks/useBase64";
import { SalesOrder } from "../../../interfaces/Sales/Orders/salesOrders";
import { Button, ListItemButton, Popover } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import { DialogProcessShippingDocument } from "./components/DialogProcessShippingDocument";

export const TableSalesOrders = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [hiddenHeaderCheckBox, setHiddenHeaderCheckBox] = useState(true);
  const [openDialogProcessShippingDocument, setOpenDialogProcessShippingDocument] = useState(false);
  const [salesOrdersData, setSalesOrdersData] = useState<GridRowsProp>([]);
  const [salesOrdersDataAll, setSalesOrdersDataAll] = useState<GridRowsProp>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceScreenProcessShippingDocument, setResourceScreenProcessShippingDocument] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [idCustomerDefault, setIdCustomerDefault] = useState<number>();
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const { user } = React.useContext(AuthContext);
  const { utf8ToBase64 } = useBase64();
  const [columns, setColumns] = useState<GridColDef<SalesOrder>[]>([
    { field: 'sales_order_name', headerName: t("salesorders.fields.sales_order_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'customer_business_name', headerName: t("salesorders.fields.customer_business_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'sales_order_date', headerName: t("salesorders.fields.sales_order_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'purchase_order', headerName: t("salesorders.fields.purchase_order"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("salesorders.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: "left", headerAlign: "left",
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
      case "error":
        {
          showSnackBar(location.state.error, "error");
          break;
        }
    }
    navigate(".", { state: { ...location.state, method: undefined }, replace: true });
  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-salesorders");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const handleAddButton = async () => {
    navigate('add', {
      state: {
        ...location.state,
        customer_id: idCustomerDefault,
      },
    });
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-salesorders-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'sales_order_date' || element.field === 'date_mail_send') {
            element.headerName = t(`salesorders.fields.${element.field}`);
            element.valueGetter = function (params: GridValueGetterParams<SalesOrder, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
          else {
            element.headerName = t(`generic.${element.field}`);
          }
        }
        else {
          if ((element.field === 'total_amount') || (element.field === 'total_company_currency_amount')) {
            element.valueGetter = function (params: GridValueGetterParams<SalesOrder, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'currency_code') { element.headerName = t("currencies.title-view"); }
          else if (element.field === 'total_company_currency_amount') {
            element.headerName = t("companies.configuration.fields.total_company_currency_amount");
            element.valueGetter = function (params: GridValueGetterParams<SalesOrder, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'customer_business_name') { element.headerName = t(`salesorders.fields.customer_business_name`); }
          else {
            element.headerName = t(`salesorders.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

    const onRowSelection = (rowsIds: GridRowSelectionModel) => {
      if (rowsIds.length === 1) {
        const myRow = salesOrdersData.find(item => item.sales_order_id === rowsIds[0]);
        if (hiddenHeaderCheckBox) {
          setSalesOrdersDataAll([...salesOrdersData]);
        }
        setSalesOrdersData(salesOrdersData.filter(item =>
          item.customer_id === myRow?.customer_id && item.customer_business_name === myRow?.customer_business_name
        ));
        setHiddenHeaderCheckBox(false);
        setSelectedRows([{ ...myRow }]);
      } else if (rowsIds.length === 0) {
        setSalesOrdersData([...salesOrdersDataAll]);
        setHiddenHeaderCheckBox(true);
        setSelectedRows([]);
      } else if (rowsIds.length > 1) {
        const selectedData = salesOrdersData.filter(item =>
          rowsIds.includes(item.sales_order_id)
        );
        setSelectedRows((prev) => selectedData);
      }
    };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salesorders.title")}`;
    })

    if (location.state !== null) {
      if (location.state.method) {
        methodUsed();
      }
      else if (location.state.row && location.state.row.sales_order_id) {
        navigate("/salesorders/view", { state: { row: location.state.row } })
      }
    }

    GetResourceByUrl(`/salesorders`)
      .then((responsePermission) => {
        setResourceScreen((prev) => responsePermission);
        loadColumnsOrder();
        loadPreferences();
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setUnauthorized(false);
      });

    GetResourceByUrl(`/salesorders/processshippingdocument`)
      .then((responsePermission) => {
        setResourceScreenProcessShippingDocument((prev) => responsePermission);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resourceScreen?.read) {
      GenericGetResourceGeneric(`/usercompanies/byuserid/${user?.user_id}`, "/gusercompanies")
        .then((response) => {
          let myCustomerDefault = response.data.data.find((element: UserCompanies) => element.schema_name === user?.schema)
          let myRoute = `/salesorders`;
          if (myCustomerDefault && myCustomerDefault.customer_default_id) {
            myRoute = `/salesorders?filter=${utf8ToBase64(`customer_id=${myCustomerDefault.customer_default_id}`)}`;
            setIdCustomerDefault(myCustomerDefault.customer_default_id);
          }
          GenericGetResource(myRoute)
            .then(async (responseSalesOrders) => {
              await setSalesOrdersData(responseSalesOrders.data.data);
              setIsLoadingDataTable(true);
            }
            ).catch((error) => {
              showSnackBar(error.message, "error");
              setMessageSnack(error.message);
              setUnauthorized(false);
            });
        })
    }
  }, [resourceScreen]);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header
          title={t("salesorders.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              <Button
                aria-describedby={id1}
                onClick={handleClick1}
                variant='outlined'
                size="small"
                sx={{ marginBlock: .5 }}
              >
                <MoreHorizIcon />
              </Button>
              <Popover
                id={id1}
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: 200 }}
              >
                <ListItemButton
                  onClick={() => {
                    if (selectedRows.length === 0) {
                      showSnackBar(t("salesorders.info.select-rows"), "warning");
                    }
                    else if (selectedRows.some(row => row.has_sales_shipping_document === true)) {
                      showSnackBar(t("salesorders.info.has-sales-shipping-document"), "warning");
                    }
                    else {
                      setOpenDialogProcessShippingDocument(true);
                    }
                  }}
                  disabled={!resourceScreenProcessShippingDocument?.create}
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    minWidth: 200,
                  }}
                >
                  {t("salesorders.actions.processshippingdocuments")}
                  <FontAwesomeIcon icon={faFilePen} />
                </ListItemButton>
              </Popover>
              {resourceScreen?.create === true &&
                <PrimaryButton variant='outlined' className="m-1" onClick={handleAddButton}>{t("generic.buttons.add")}</PrimaryButton>
              }
            </div>
          }
        />
        <div className="d-flex justify-content-center">
          {!isLoadingDataTable && <Spinner />}
          {isLoadingDataTable &&
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={salesOrdersData}
              entityId={"sales_order_id"}
              entity={`SalesOrders`}
              preferences={myPreferences}
              namePreferences={"grid-salesorders"}
              nameOrderColumns={"grid-salesorders-columns"}
              selectRows
              hiddenHeaderCheckBox={hiddenHeaderCheckBox}
              onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
              countColumns={["total_company_currency_amount"]}
            />
          }
        </div>
        <SnackbarComponent />
        <DialogProcessShippingDocument
          open={openDialogProcessShippingDocument}
          setOpenDialog={setOpenDialogProcessShippingDocument}
          customerId={selectedRows.length ? selectedRows[0].customer_id : 0}
          salesOrderIds={selectedRows.map((item) => item.sales_order_id)}
        />
      </div>
      }
    </>
  )
}
