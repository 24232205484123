import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvoiceDocumentType } from '../../../interfaces/Sales/Invoices/salesinvoices';
import GenericPromises from '../../../api/GenericPromises';
import { Menuitem } from '../../../interfaces/Security/menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import InvoiceDetail from './invoiceDetail';
import { usePermissions } from '../../../hooks/usePermissions';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, ListItemButton, Popover, TextField } from '@mui/material';
import { Company } from '../../../interfaces/Security/companies';
import { PaymentMethod } from '../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../interfaces/Sales/Catalogs/paymentModes';
import { Currency } from '../../../interfaces/Commons/currencies';
import { CFDI } from '../../../interfaces/Sales/Catalogs/CFDIs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useFiles } from '../../../hooks/useFiles';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import { ButtonStyled } from '../../../theme/buttons';
import { PaymentCondition } from '../../../interfaces/Sales/Catalogs/paymentConditions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';
import { SalesInvoicePDF } from '../../../reports/salesInvoicePDF/SalesInvoicePDF';
import { DialogSendEmailSingular } from './helpers/dialogSendEmailSingular';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { PurchaseInvoice, PurchaseInvoiceDetail } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { UpdatePurchaseInvoices } from './update';
import { UpdatePurchaseInvoicesHeader } from './updateInvoiceHeader';
import { Supplier, SupplierLocation } from '../../../interfaces/Purchases/Catalogs/suppliers';
import { AnnotationsPurchaseInvoices } from './Tabs/annotations';
import { Language } from '../../../interfaces/Commons/languages';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { CopyPurchaseInvoice } from './actions/copySalesInvoice';
import { Project } from '../../../interfaces/Projects/projects';
import { FinancialPurchaseInvoices } from './Tabs/financial';
import { SupplierBankAccount } from '../../../interfaces/Purchases/Catalogs/supplierBankAccounts';
import { useCurrencies } from '../../../hooks/useCurrencies';
import { TaxRegime } from '../../../interfaces/Sales/Catalogs/taxRegimes';
import { BankAccount } from '../../../interfaces/Commons/bankAccounts';

export const ViewPurchaseInvoices = () => {
  const [rows, setRows] = useState<PurchaseInvoiceDetail[]>([]);
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { GetExchangeRateFromCurrencyCode } = useCurrencies();
  const { TimeConverter, GetDateCalculatedByAfterDays } = useDates();
  const [purchaseInvoiceLocal, setPurchaseInvoiceLocal] = useState<PurchaseInvoice>();
  const [dataloaded, setDataLoaded] = useState(false);
  const [isAccounted, setIsAccounted] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [comboInvoiceDocumentTypes, setComboInvoiceDocumentTypes] = useState<InvoiceDocumentType[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [defaultInvoiceDocumentType, setDefaultInvoiceDocumentType] = useState();
  const [openDialogCopyPurchaseInvoice, setOpenDialogCopyPurchaseInvoice] = useState(false);
  const [isDeletedInvoice, setIsDeletedInvoice] = useState(false);
  const [comboSupplierLocations, setComboSupplierLocations] = useState<SupplierLocation[]>([]);
  const [defaultSupplierLocation, setDefaultSupplierLocation] = useState<SupplierLocation>();
  const [myConsecutive, setMyConsecutive] = useState("");
  const { GetFiles, GetFileById, GetFileIfExists } = useFiles();
  const { GenericPutResource, GenericGet, GenericPostResource, GenericPatchResource, GenericPost, GenericGetResource, GenericPutAction, GenericGetReport, GenericDeleteResource, GenericGetResourceGeneric } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { GetResourceByUrl } = usePermissions();
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { GetParamsFromBase64 } = useParamsFilter();
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [IsChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [myLocalConsecutive, setMyLocalConsecutive] = useState("");
  const [myLocalInvoiceDocType, setMyLocalInvoiceDocType] = useState(0);
  const [locationEdit, setLocationEdit] = useState(false);
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>();
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [isNotExchange, setIsNotExchange] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [defaultDateSupplier, setDefaultDateSupplier] = useState<Date | undefined>();
  const [openEmail, setOpenEmail] = useState(false);
  const [dataPurchaseInvoiceSelected, setDataPurchaseInvoiceSelected] = useState<any>([]);
  const [loadingPut, setLoadingPut] = useState(false);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([])
  const [comboLanguages, setComboLanguages] = useState<Language[]>([])
  const [resourceSendEmail, setResourceSendEmail] = useState<Menuitem>();
  const [resourceCopySalesInvoice, setResourceCopySalesInvoice] = useState<Menuitem>();
  const [resourceAccounted, setResourceAccounted] = useState<Menuitem>();
  const [comboProjects, setComboProjects] = useState<Project[]>([]);
  const [defaultProject, setDefaultProject] = useState<Project>();
  const [comboSupplierBankAccounts, setComboSupplierBankAccounts] = useState<SupplierBankAccount[]>([]);
  const [defaultSupplierBankAccount, setDefaultSupplierBankAccount] = useState<SupplierBankAccount>();
  const [defaultExpirationDate, setDefaultExpirationDate] = useState<Date | undefined>()
  const [notes, setNotes] = useState<String>();
  const [comboBankAccountsGeneral, setComboBankAccountsGeneral] = useState<BankAccount[]>([])
  const [defaultBankAccountFinal, setDefaultBankAccountFinal] = useState<BankAccount>()

  const { generatePDF } = SalesInvoicePDF();


  const { GetError } = FormsErrors();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    trigger,
  } = useForm<PurchaseInvoice>({
    defaultValues: {
      purchase_invoice_id: undefined,
      purchase_invoice_name: undefined,
      purchase_invoice_date: undefined,
      supplier_sale_invoice_date: undefined,
      expiration_date: undefined,
      sales_order: undefined,
      supplier_id: undefined,
      supplier_business_name: undefined,
      supplier_comercial_name: undefined,
      supplier_rfc: undefined,
      supplier_street_name: undefined,
      supplier_location_id: undefined,
      supplier_postal_code: undefined,
      supplier_city_id: undefined,
      supplier_city_name: undefined,
      supplier_state_id: undefined,
      supplier_state_name: undefined,
      supplier_state_abbr: undefined,
      supplier_country_id: undefined,
      supplier_country_name: undefined,
      supplier_country_code: undefined,
      supplier_bank_account_id: undefined,
      supplier_account_alias: undefined,
      supplier_interbank_key: undefined,
      supplier_swift_code: undefined,
      supplier_bank_name: undefined,
      supplier_account_number: undefined,
      subtotal_amount: 0,
      total_amount: 0,
      tax_amount: 0,
      exchange_rate: 0,
      currency_id: 0,
      currency_code: undefined,
      currency_description: undefined,
      project_id: undefined,
      invoice_doc_type_id: undefined,
      invoice_doc_status_id: undefined,
      payment_mode_id: undefined,
      payment_mode_code: undefined,
      payment_mode_description: undefined,
      payment_method_id: undefined,
      payment_method_code: undefined,
      payment_method_name: undefined,
      cfdi_id: undefined,
      cfdi_code: undefined,
      cfdi_description: undefined,
      discount_amount: 0,
      supplier_tax_regime_id: undefined,
      supplier_tax_regime_code: undefined,
      supplier_tax_regime_description: undefined,
      is_accounted: undefined,
      id_consecutive: undefined,
      notes: undefined,
      retention_amount: 0,
      payment_condition_id: undefined,
      payment_condition_days: undefined,
      payment_condition_name: undefined,
      payment_condition_description: undefined,
      payment_due_date: undefined,
      supplier_language: undefined,
      supplier_email: undefined,
      supplier_phone_number: undefined,
      date_mail_send: undefined,
      user_mail_send: undefined,
      company_business_name: undefined,
      company_street: undefined,
      company_city_id: undefined,
      company_city_name: undefined,
      company_city_code: undefined,
      company_state_id: undefined,
      company_state_name: undefined,
      company_state_abbr: undefined,
      company_country_id: undefined,
      company_country_name: undefined,
      company_country_code: undefined,
      company_postal_code: undefined,
      company_rfc: undefined,
      company_tax_regime_id: undefined,
      company_tax_regime_code: undefined,
      company_tax_regime_description: undefined,
      company_email: undefined,
      company_phone_number: undefined,
      xml_file_id: undefined,
      qr_file: undefined,
      report_file_id: undefined,
      cancel_xml_file_id: undefined,
      intermediary_bank_account_id: undefined,
      intermediary_account_alias: undefined,
      intermediary_bank_name: undefined,
      intermediary_bank_references: undefined,
      intermediary_account_number: undefined,
      intermediary_bank_phone_number: undefined,
      intermediary_bank_card_number: undefined,
      intermediary_bank_interbank_key: undefined,
      intermediary_bank_swift_code: undefined,
      intermediary_bank_currency_id: undefined,
      intermediary_bank_currency_code: undefined,
      intermediary_bank_currency_description: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });

  const onSubmit: SubmitHandler<PurchaseInvoice> = (data) => onPut(data);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const onPut = async (data: PurchaseInvoice) => {
    await trigger()
      .then(async (responseTrigger) => {
        if (data.invoice_doc_type_id == null) {
          showSnackBar(t("purchaseinvoices.info.no-data"), "error");
          setOpenDialog(false);
        }
        else {
          setLoadingPut(true);
          let mySupplier = comboSuppliers.find((supplier: Supplier) => supplier.supplier_id === data.supplier_id);
          let myPaymentMode = comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === data.payment_mode_id);
          let myPaymentMethod = comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === data.payment_method_id);
          let myCFDI = comboCFDIs.find((item: CFDI) => item.cfdi_id === data.cfdi_id);
          let myCurrency = comboCurrencies.find((item: Currency) => item.currency_id === data.currency_id);
          let myInvoiceDocumentType = comboInvoiceDocumentTypes.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === data.invoice_doc_type_id);
          let myPaymentCondition = comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === data.payment_condition_id);
          let mySupplierLanguage = comboLanguages.find((language: Language) => language.language_id === mySupplier?.language_id)
          let mySupplierLocation = await comboSupplierLocations.find((element: SupplierLocation) => element.supplier_location_id === data.supplier_location_id);
          let myBankAccount = await comboSupplierBankAccounts.find((element: SupplierBankAccount) => element.supplier_bank_account_id === data.supplier_bank_account_id);
          let myIntermediaryBank = await comboBankAccountsGeneral.find((element: BankAccount) => element.bank_account_id === data.intermediary_bank_account_id)

          let myResponseConsecutive;
          if (myConsecutive !== purchaseInvoiceLocal?.purchase_invoice_name) {
            const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
            myResponseConsecutive = await responseConsecutive.data.data[0].Consecutive;
          }
          const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");
          const company = responseCompany.data ?? null;

          let mySupplierTaxRegime: TaxRegime | undefined = undefined;
          if (mySupplierLocation) {
            let myTaxRegimeResponse = await GenericGetResource(`/taxregimes/${mySupplierLocation.tax_regime_id}`);
            mySupplierTaxRegime = myTaxRegimeResponse.data;
          }

          var date = new Date();
          if (typeof data.purchase_invoice_date == 'string')
            date = TimeConverter(data.purchase_invoice_date) ?? new Date();
          else if (data.purchase_invoice_date) {
            let currentDate = new Date();
            let invoiceDate = new Date(data.purchase_invoice_date);
            invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
            date = invoiceDate;
          }

          var supplierDate = null;
          if (typeof data.supplier_sale_invoice_date == 'string')
            supplierDate = TimeConverter(data.supplier_sale_invoice_date) ?? new Date();
          else if (data.supplier_sale_invoice_date) {
            let currentDate = new Date();
            let invoiceDate = new Date(data.supplier_sale_invoice_date);
            invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
            supplierDate = invoiceDate;
          }

          var expirationDate = null;
          if (typeof data.expiration_date == 'string')
            expirationDate = TimeConverter(data.expiration_date) ?? new Date();
          else if (data.expiration_date) {
            let currentDate = new Date();
            let invoiceDate = new Date(data.expiration_date);
            invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
            expirationDate = invoiceDate;
          }

          let myData = {
            purchase_invoice_name: myResponseConsecutive,
            purchase_invoice_date: date ?? data.purchase_invoice_date ?? new Date(),
            supplier_sale_invoice_date: supplierDate ?? data.supplier_sale_invoice_date ?? null,
            expiration_date: expirationDate ?? data.expiration_date ?? null,
            sales_order: data.sales_order,
            supplier_id: data.supplier_id,
            supplier_business_name: mySupplierLocation?.business_name ?? (data?.supplier_business_name ?? null),
            supplier_comercial_name: mySupplier?.comercial_name ?? (data.supplier_comercial_name ?? null),
            supplier_rfc: mySupplierLocation?.supplier_rfc ?? (data?.supplier_rfc ?? null),
            supplier_location_id: mySupplierLocation?.supplier_location_id ?? (data.supplier_location_id ?? null),
            supplier_street_name: mySupplierLocation?.street ?? (data.supplier_street_name ?? null),
            supplier_postal_code: mySupplierLocation?.postal_code ?? (data.supplier_postal_code ?? null),
            supplier_city_id: mySupplierLocation?.city_id,
            supplier_city_name: mySupplierLocation?.city_name ?? (data.supplier_city_name ?? null),
            supplier_state_id: mySupplierLocation?.state_id ?? (data.supplier_state_id ?? null),
            supplier_state_name: mySupplierLocation?.state_name ?? (data.supplier_state_name ?? null),
            supplier_state_abbr: mySupplierLocation?.state_abbr ?? (data.supplier_state_abbr ?? null),
            supplier_country_id: mySupplierLocation?.country_id ?? (data.supplier_country_id ?? null),
            supplier_country_name: mySupplierLocation?.country_name ?? (data.supplier_country_name ?? null),
            supplier_country_code: mySupplierLocation?.country_code ?? (data.supplier_country_code ?? null),
            supplier_account_alias: myBankAccount?.account_alias ?? (data.supplier_account_alias ?? null),
            supplier_interbank_key: myBankAccount?.interbank_key ?? (data.supplier_interbank_key ?? null),
            supplier_swift_code: myBankAccount?.swift_code ?? (data.supplier_swift_code ?? null),
            supplier_bank_account_id: myBankAccount?.supplier_bank_account_id ?? (data.supplier_bank_account_id ?? null),
            supplier_bank_name: myBankAccount?.bank_name ?? (data.supplier_bank_name ?? null),
            supplier_account_number: myBankAccount?.account_number ?? (data.supplier_account_number ?? null),
            currency_id: myCurrency?.currency_id ?? (data.currency_id ?? null),
            currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
            currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
            invoice_doc_type_id: data.invoice_doc_type_id,
            invoice_doc_status_id: data.invoice_doc_status_id,
            payment_mode_code: myPaymentMode?.code ?? (data.payment_mode_code ?? null),
            payment_mode_description: myPaymentMode?.description ?? (data.payment_mode_description ?? null),
            payment_method_code: myPaymentMethod?.code ?? (data.payment_method_code ?? null),
            payment_method_name: myPaymentMethod?.description ?? (data.payment_method_name ?? null),
            cfdi_code: myCFDI?.code ?? (data.cfdi_code ?? null),
            cfdi_description: myCFDI?.description ?? (data.cfdi_description ?? null),
            supplier_tax_regime_code: mySupplierTaxRegime?.tax_regime_code ?? (data.supplier_tax_regime_code ?? null),
            supplier_tax_regime_description: mySupplierTaxRegime?.description ?? (data.supplier_tax_regime_description ?? null),
            id_consecutive: myInvoiceDocumentType?.consecutive_id ?? (data.id_consecutive ?? null),
            notes: data.notes,
            payment_condition_days: myPaymentCondition?.payment_condition_days ?? (data.payment_condition_days ?? null),
            payment_condition_name: myPaymentCondition?.payment_condition_name ?? (data.payment_condition_name ?? null),
            payment_condition_description: myPaymentCondition?.payment_condition_description ?? (data.payment_condition_description ?? null),
            payment_due_date: data.payment_due_date,
            supplier_language: mySupplierLanguage?.language_code ?? "ES",
            supplier_email: data.supplier_email,
            supplier_phone_number: data.supplier_phone_number,
            date_mail_send: data.date_mail_send,
            user_mail_send: data.user_mail_send,
            company_business_name: company.business_name ?? (data.company_business_name ?? null),
            company_street: company.street ?? (data.company_street ?? null),
            company_city_id: company.city_id ?? (data.company_city_id ?? null),
            company_city_name: company.city_name ?? (data.company_city_name ?? null),
            company_city_code: company.city_code ?? (data.company_city_code ?? null),
            company_state_id: company.state_id ?? (data.company_state_id ?? null),
            company_state_name: company.state_name ?? (data.company_state_name ?? null),
            company_state_abbr: company.state_abbr ?? (data.company_state_abbr ?? null),
            company_country_id: company.country_id ?? (data.company_country_id ?? null),
            company_country_name: company.country_name ?? (data.company_country_name ?? null),
            company_country_code: company.country_code ?? (data.company_country_code ?? null),
            company_postal_code: company.postal_code ?? (data.company_postal_code ?? null),
            company_rfc: company.rfc ?? (data.company_rfc ?? null),
            company_tax_regime_id: company.tax_regime_id ?? (data.company_tax_regime_id ?? null),
            company_tax_regime_code: company.tax_regime_code ?? (data.company_tax_regime_code ?? null),
            company_tax_regime_description: company.tax_regime_description ?? (data.company_tax_regime_description ?? null),
            company_email: company.email ?? (data.company_email ?? null),
            company_phone_number: company.phone_number ?? (data.company_phone_number ?? null),
            xml_file_id: data.xml_file_id,
            qr_file: data.qr_file,
            report_file_id: data.report_file_id,
            cancel_xml_file_id: data.cancel_xml_file_id,
            exchange_rate: (data.exchange_rate == null || data.exchange_rate == undefined || data.exchange_rate <= 1) ? 1 : data.exchange_rate,
            project_id: data.project_id ?? null,
            uuid: data.uuid ?? null,
            intermediary_bank_account_id: 0,
            intermediary_account_alias: myIntermediaryBank?.account_alias ?? null,
            intermediary_bank_name: myIntermediaryBank?.bank_name ?? null,
            intermediary_bank_references: myIntermediaryBank?.bank_references ?? null,
            intermediary_account_number: myIntermediaryBank?.account_number ?? null,
            intermediary_bank_phone_number: myIntermediaryBank?.phone_number ?? null,
            intermediary_bank_card_number: myIntermediaryBank?.card_number ?? null,
            intermediary_bank_interbank_key: myIntermediaryBank?.interbank_key ?? null,
            intermediary_bank_swift_code: myIntermediaryBank?.swift_code ?? null,
            intermediary_bank_currency_id: 0,
            intermediary_bank_currency_code: myIntermediaryBank?.currency_code  ?? null,
            intermediary_bank_currency_description: myIntermediaryBank?.currency_description  ?? null,
          }
          await GenericPutResource(`/purchaseinvoices/${purchaseInvoiceLocal?.purchase_invoice_id}`, myData)
            .then(async (response) => {
              setLoadingPut(false);
              setValue("tax_amount", response.data.tax_amount);
              setValue("purchase_invoice_name", response.data.purchase_invoice_name);
              setValue("retention_amount", response.data.retention_amount);
              setValue("subtotal_amount", response.data.subtotal_amount);
              setValue("discount_amount", response.data.discount_amount);
              setValue("total_amount", response.data.total_amount);
              setValue("supplier_street_name", response.data.supplier_street_name);
              setValue("supplier_city_name", response.data.supplier_city_name);
              setValue("supplier_state_name", response.data.supplier_state_name);
              setValue("supplier_state_abbr", response.data.supplier_state_abbr);
              setValue("supplier_country_name", response.data.supplier_country_name);
              setValue("supplier_country_code", response.data.supplier_country_code);
              setValue("invoice_doc_type_id", response.data.invoice_doc_type_id);
              setValue("purchase_invoice_name", response.data.purchase_invoice_name);
              setValue("purchase_invoice_id", response.data.purchase_invoice_id);
              setValue("purchase_invoice_name", response.data.purchase_invoice_name);
              setValue("currency_id", response.data.currency_id);
              setValue("currency_code", response.data.currency_code);
              setValue("currency_description", response.data.currency_description);
              setValue("payment_condition_id", response.data.payment_condition_id);
              setValue("payment_condition_days", response.data.payment_condition_days);
              setValue("payment_condition_name", response.data.payment_condition_name);
              setValue("supplier_business_name", response.data.supplier_business_name);
              setValue("supplier_rfc", response.data.supplier_rfc);
              setMyLocalInvoiceDocType(response.data.invoice_doc_type_id);
              reset({
                ...response.data,
                original_string_SAT: response.data.original_string_SAT,
                certificate_number_SAT: response.data.certificate_number_SAT,
                certificate_number_CFDI: response.data.certificate_number_CFDI,
                supplier_street_name: response.data.supplier_street_name,
                supplier_business_name: response.data.supplier_business_name,
                supplier_city_name: response.data.supplier_city_name,
                supplier_rfc: response.data.supplier_rfc,
                supplier_state_name: response.data.supplier_state_name,
                supplier_state_abbr: response.data.supplier_state_abbr,
                supplier_country_name: response.data.supplier_country_name,
                supplier_country_code: response.data.supplier_country_code,
                is_accounted: response.data.is_accounted,
                invoice_doc_type_id: response.data.invoice_doc_type_id,
                payment_condition_id: response.data.payment_condition_id,
                payment_condition_days: response.data.payment_condition_days,
                payment_condition_name: response.data.payment_condition_name,
                intermediary_bank_account_id: 0
              }, { keepValues: true })
              let mySupplierLocation: SupplierLocation = {
                supplier_location_id: 0,
                business_name: getValues("supplier_business_name"),
                supplier_rfc: getValues("supplier_rfc"),
                street: getValues("supplier_street_name"),
                city_name: getValues("supplier_city_name"),
                state_name: getValues("supplier_state_name"),
                state_abbr: getValues("supplier_state_abbr"),
                country_name: getValues("supplier_country_name"),
                country_code: getValues("supplier_country_code"),
              }
              setDefaultSupplierLocation((prev) => mySupplierLocation);
              setIsChangedTotalValue((prev) => !prev);
              setPurchaseInvoiceLocal((prev) => response.data);
              setLocationEdit(false);
              showSnackBar(t("generic.snackbar.update"), "success");
            }).catch((error) => {
              showSnackBar(error.message, "error");
              setLoadingPut(false);
            });
        }
      });
  }

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const onDelete = () => {
    GenericGetResource(`/purchaseinvoices/${purchaseInvoiceLocal?.purchase_invoice_id}`)
      .then((response) => {
        let myPurchaseInvoice = {
          invoice_doc_status_id: 2,
        }
        GenericPutResource(`/purchaseinvoices/${purchaseInvoiceLocal?.purchase_invoice_id}`, myPurchaseInvoice)
          .then(() => {
            setOpenDialog(false);
            navigate("/purchaseinvoices", { replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setOpenDialog(false);
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  }

  const GetExchangeRateFromDates = async (currency_id: number, datePurchaseInvoice: Date | undefined, dateSupplierInvoice: Date | undefined) => {
    if (!(datePurchaseInvoice)) {
      setValue("exchange_rate", 1)
    }
    let date = (dateSupplierInvoice) ? dateSupplierInvoice : datePurchaseInvoice;
    let ExchangeRate = await GetExchangeRateFromCurrencyCode(currency_id, date);
    if (ExchangeRate && ExchangeRate.rate && ExchangeRate.rate > 1) {
      setValue("exchange_rate", ExchangeRate?.rate ?? 1)
    }
    else {
      setValue("exchange_rate", 1)
    }
  }

  const getDataAndGeneratePDF = async (purchaseInvoiceId: number) => {
    try {
      const response = await GenericGetReport(`/purchaseinvoices/pdf/${purchaseInvoiceId}`, false);
      if (response.data.dataIssuingCompany.rpt_image) {
        const rptImageFile = await GenericGetResource(`/filessql/` + response.data.dataIssuingCompany.rpt_image);
        response.data.dataInvoiceGeneral.logoImage = rptImageFile?.data.data_file ?? undefined;
      }

      let qrString = getValues("qr_file");
      if (qrString) {
        const responseQR = await GetFileById(qrString);
        const base64Part = responseQR?.dataURL.split(';base64,')[1];
        const newBase64 = `data:image/png;base64,${base64Part}`;
        response.data.dataInvoiceGeneral.fiscalQr = newBase64 ?? undefined;
      }

      let pdfDoc = await generatePDF(response);
      return pdfDoc;
    } catch (error) {
      throw error;
    }
  };

  const savePDFToDatabase = async (purchaseInvoiceId: number, pdfDoc: any) => {
    try {
      const startIndex = pdfDoc.indexOf(',') + 1;
      const pdfInBase64 = pdfDoc.substring(startIndex);
      const myFilePDF = {
        entity: "PurchaseInvoices",
        recordId: purchaseInvoiceId,
        fileName: purchaseInvoiceLocal?.purchase_invoice_name,
        typeOfFile: "application/pdf",
        schema_name: localStorage.getItem("schemaName"),
        Content: pdfInBase64
      };
      const responsePdf = await GenericPost(`/files`, myFilePDF);
      return responsePdf.file_id;
    } catch (error) {
      throw error;
    }
  };

  const onGeneratePDF = async () => {
    try {
      if (!purchaseInvoiceLocal?.report_file_id) {
        const pdfDoc = await getDataAndGeneratePDF(getValues("purchase_invoice_id") ?? 0);
        if (getValues("is_accounted") == true) {
          const fileId = await savePDFToDatabase(getValues("purchase_invoice_id") ?? 0, pdfDoc);
          setValue("report_file_id", fileId);
          onPut(getValues());
          showSnackBar(t("purchaseinvoices.reports.save-PDF"), "success");
          return;
        }
        showSnackBar(t("purchaseinvoices.reports.generated-PDF"), 'success');
      } else {

        const pdfFile = await GetFileIfExists(purchaseInvoiceLocal.report_file_id);
        const downloadLink = document.createElement('a');
        const fileName = `${purchaseInvoiceLocal.purchase_invoice_name}.pdf`;
        downloadLink.href = pdfFile?.dataURL ?? '';
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(pdfFile);
        showSnackBar(t("purchaseinvoices.reports.downloaded-PDF"), "success");
      }
    } catch (error: any) {
      showSnackBar(t("purchaseinvoices.info.error-gnereate-pdf"), "error");
      setOpenDialog(false);
      setValue("report_file_id", undefined);
      onPut(getValues());
      await getDataAndGeneratePDF(getValues("purchase_invoice_id") ?? 0);
    } finally {
      setOpenDialog(false);
    }
  };

  const onDeaccounted = async () => {
    await GenericPutAction(`/purchaseinvoices/isaccounted/${purchaseInvoiceLocal?.purchase_invoice_id}`, { is_accounted: false, notes: null }, false)
      .then(async () => {
        setIsAccounted((prev) => false);
        reset({ is_accounted: false, notes: undefined }, { keepValues: true });
        setValue("is_accounted", false);
        setValue('notes', ' ', { shouldValidate: true });
        setIsBackdrop(false);
        showSnackBar(t("generic.snackbar.update"), "success");
      })
      .catch((error) => {
        setIsAccounted(true);
        setIsBackdrop(false);
        showSnackBar(t(error.response.data), "error");
      });
  }

  const onAccounted = () => {
    trigger()
      .then(async (responseTrigger) => {
        if (responseTrigger) {
          let missingData = false;
          if ((defaultPaymentMethod?.code === undefined && getValues("payment_method_code") === null) ||
            (defaultPaymentMode?.code === undefined && getValues("payment_mode_code") === null))
            missingData = true;
          await GenericGetResource(`/purchaseinvoicesdetails/bypurchaseinvoiceid/${purchaseInvoiceLocal?.purchase_invoice_id}`)
            .then(async (response) => {
              if (response.data.total > 0 && missingData === false) {
                setValue("is_accounted", true);
                let myStringNotes = getValues("notes") ? `${getValues("notes")?.toString()}-` : "";
                for (let i = 0; i < response.data.data.length; i++) {
                  const element = response.data.data[i];
                  myStringNotes += `${element.item_description},`;
                }
                let myUpdateData = {
                  is_accounted: true,
                  notes: myStringNotes,
                }
                await GenericPutAction(`/purchaseinvoices/isaccounted/${purchaseInvoiceLocal?.purchase_invoice_id}`, myUpdateData, false)
                  .then(async () => {
                    reset({
                      is_accounted: true,
                      notes: myStringNotes,
                    }, { keepValues: true })
                    setValue('notes', myStringNotes, { shouldValidate: true });
                    setIsAccounted((prev) => getValues("is_accounted") ?? false);
                    await PostRelatedPaymentsDetails()
                      .catch(async (error) => {
                        setValue("is_accounted", false);
                        await GenericPutAction(`/purchaseinvoices/isaccounted/${purchaseInvoiceLocal?.purchase_invoice_id}`, { is_accounted: true, notes: getValues("notes"), }, false)
                          .then(async () => {
                            reset({
                              is_accounted: false,
                              notes: getValues("notes"),
                            }, { keepValues: true })
                            setIsAccounted((prev) => getValues("is_accounted") ?? false);
                            showSnackBar(error.message, 'error');
                            await setIsBackdrop((prev) => false);
                          })
                        await setIsBackdrop((prev) => false);
                      });
                    await setIsBackdrop((prev) => false);
                  }).catch(async (error) => {
                    setValue("is_accounted", false);
                    showSnackBar(error.message, "error");
                    await setIsBackdrop((prev) => false);
                  });
              }
              else if (missingData) {
                showSnackBar(t("purchaseinvoices.info.no-data"), "warning");
                await setIsBackdrop((prev) => false);
              }
              else if (response.data.total === 0) {
                showSnackBar(t("purchaseinvoices.info.no_details"), "warning");
                await setIsBackdrop((prev) => false);
              }
            }).catch(async (error) => {
              await setIsBackdrop((prev) => false);
              showSnackBar(error.message, "error");
            });
        }
        else {
          await setIsBackdrop((prev) => false);
        }
      })
  }

  const GetAndSetConsecutive = async (consecutive_id?: number) => {
    if (consecutive_id) {
      const response = await GenericGetResource(`/consecutives/byConsecutiveId/${consecutive_id}`);
      setMyConsecutive((prev) => response.data.data[0].Consecutive);
    }
    else {
      setMyConsecutive((prev) => myLocalConsecutive);
    }
  }

  const PostRelatedPaymentsDetails = async () => {

    var expirationDate = new Date();
    if (typeof getValues("expiration_date") == 'string')
      expirationDate = TimeConverter(getValues("expiration_date")?.toString()) ?? new Date();
    else if (getValues("expiration_date")) {
      let currentDate = new Date();
      let invoiceDate = new Date(getValues("expiration_date") ?? new Date());
      invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
      expirationDate = invoiceDate;
    }

    try {
      let myPaymentDetail = {
        current_payment_status_id: 1,
        payment_id: null,
        payment_deadline: expirationDate,
        payment_date: null,
        tax_id: null,
        tax_code: null,
        is_authorized: false,
        tax_description: null,
        tax_percent: null,
        retention_id: null,
        retention_code: null,
        retention_description: null,
        retention_percent: null,
        currency_id: null,
        currency_code: null,
        currency_description: null,
        exchange_rate: getValues("exchange_rate"),
        payment_method_id: null,
        payment_method_code: null,
        payment_method_description: null,
        related_total_tax_amount: 0,
        related_total_retention_amount: 0,
        total_tax_amount: 0,
        total_retention_amount: 0,
        subtotal_amount: 0,
        total_amount: 0
      }
      await GenericPostResource(`/paymentsdetails`, myPaymentDetail)
        .then(async (response) => {
          let myRelatedPaymentDetail = {
            payment_detail_id: response.data.payment_detail_id,
            purchase_invoice_id: getValues("purchase_invoice_id"),
            purchase_invoice_name: getValues("purchase_invoice_name"),
            currency_id: getValues("currency_id"),
            currency_code: getValues("currency_code"),
            currency_description: getValues("currency_description"),
            equivalence: getValues("exchange_rate"),
            partiality_number: 1,
            previous_amount: getValues("total_amount"),
            payment_amount: 0,
            amount_outstanding: getValues("total_amount"),
            tax_object_id: null,
            tax_object_code: null,
            tax_object_description: null,
            tax_id: null,
            tax_code: null,
            tax_description: null,
            tax_percent: 0,
            retention_id: null,
            retention_code: null,
            retention_description: null,
            retention_percent: 0,
            total_tax_amount: getValues("tax_amount") ?? 0,
            total_retention_amount: getValues("retention_amount") ?? 0,
          }
          myPaymentDetail.related_total_tax_amount = getValues("tax_amount") ?? 0;
          myPaymentDetail.related_total_retention_amount = getValues("retention_amount") ?? 0;
          let myPromises = [
            GenericPostResource(`/relatedpaymentsdetails`, myRelatedPaymentDetail),
            GenericPutResource(`/paymentsdetails/${response.data.payment_detail_id}`, myPaymentDetail),
          ];

          await Promise.all(myPromises)
            .then(async (responses) => {
              let myNewStatus = {
                new_payment_status_id: 1,
                previous_payment_status_id: 1,
                payment_detail_id: response.data.payment_detail_id,
              }
              await GenericPostResource(`/paymentdetailstatuses`, myNewStatus)
                .catch(async (error) => {
                  await GenericDeleteResource(`/relatedpaymentsdetails/${responses[0].data.related_payment_detail_id}`);
                  throw error;
                });
            })
            .catch(async (error) => {
              await GenericDeleteResource(`/paymentsdetails/${response.data.payment_detail_id}`)
              showSnackBar(error.message, 'error');
              setValue("is_accounted", false);
              await onPut(getValues());
              reset({
                is_accounted: false,
              }, { keepValues: true })
              setIsAccounted((prev) => getValues("is_accounted") ?? false);
            })
        })
    }
    catch (error) {
      throw error
    }
  }

  const onSendEmail = () => {
    if (purchaseInvoiceLocal) {
      let salesInvoicesData = []
      salesInvoicesData.push({
        data: purchaseInvoiceLocal,
        status: (purchaseInvoiceLocal.supplier_email) ? "Waiting" : "Error",
        status_description: (purchaseInvoiceLocal.supplier_email) ? "generic.send_email.waiting" :
          "generic.send_email.no_email"
      })
      setDataPurchaseInvoiceSelected(salesInvoicesData);
      setOpenEmail(true);
    }
  };

  useEffect(() => {
    let purchaseInvoiceId;
    let supplierId;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      purchaseInvoiceId = myParams.get("purchase_invoice_id");
      supplierId = myParams.get("supplier_id");
    }
    let myPromises = [
      GenericGetResource("/currencies"),
      GenericGetResource("/paymentmethods"),
      GenericGetResource("/paymentmodes"),
      GenericGetResource("/cfdi"),
      GenericGet("/invoicedocumenttypes"),
      GenericGetResource(`/supplierlocations/bysupplierid/${(location.state && location.state.row.supplier_id) ?? supplierId}`),
      GenericGetResource(`/purchaseinvoices/${(location.state && location.state.row.purchase_invoice_id) ?? purchaseInvoiceId}`),
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResource("/paymentconditions"),
      GenericGetResource("/suppliers"),
      GenericGetResource("/languages"),
      GenericGetResource("/projects"),
      GenericGetResource(`/supplierbankaccounts/bysupplierid/${(location.state && location.state.row.supplier_id) ?? supplierId}`),
      GenericGetResource("/bankaccounts"),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        setComboSuppliers(responses[9].data.data);
        setComboLanguages(responses[10].data.data);
        setComboCurrencies(responses[0].data.data);
        setComboPaymentMethods(responses[1].data.data);
        setComboPaymentModes(responses[2].data.data);
        setComboCFDIs(responses[3].data.data);
        setComboInvoiceDocumentTypes(responses[4].data.data.filter((element: InvoiceDocumentType) => element.is_type_for_sale == false));
        setComboSupplierLocations(responses[5].data.data);
        setDefaultDate(new Date(responses[6].data.purchase_invoice_date));
        setDefaultDateSupplier(responses[6].data.supplier_sale_invoice_date ? new Date(responses[6].data.supplier_sale_invoice_date) : undefined);
        setDefaultExpirationDate(responses[6].data.expiration_date ? new Date(responses[6].data.expiration_date) : undefined);
        setCompany((prev) => responses[7].data.data[0]);
        setComboPaymentConditions(responses[8].data.data);
        setComboProjects(responses[11].data.data);
        setDefaultProject((prev) => responses[11].data.data.find((item: any) => item.project_id === responses[6].data.project_id));
        let myComboSupplierBankAccounts: SupplierBankAccount[] = responses[12].data.data;
        setComboSupplierBankAccounts(responses[12].data.data);
        setComboBankAccountsGeneral(responses[13].data.data)

        await reset({
          ...responses[6].data,
          payment_method_id: (responses[6].data.payment_method_code) ? 0 : null,
          payment_mode_id: (responses[6].data.payment_mode_code) ? 0 : null,
          cfdi_id: (responses[6].data.cfdi_code) ? 0 : null,
          tax_id: (responses[6].data.tax_name) ? 0 : null,
          supplier_bank_account_id: (responses[6].data.supplier_bank_name) ? responses[6].data.supplier_bank_account_id : null,
          invoice_doc_type_id: responses[6].data.invoice_doc_type_id
        });

        if (responses[6].data.invoice_doc_status_id === 2) {
          setIsDeletedInvoice((prev) => true);
        }

        setMyConsecutive((prev) => responses[6].data.purchase_invoice_name);

        if (getValues("currency_code")) {
          let myCurrency: Currency = {
            currency_id: getValues("currency_id") ?? 0,
            currency_code: getValues("currency_code") ?? '',
            currency_description: getValues("currency_description") ?? '',
          }
          await reset({ currency_id: 0 }, { keepValues: true });
          setDefaultCurrency((prev) => myCurrency);
        }
        let mySupplierBankAccountCatalog = myComboSupplierBankAccounts.find((item) => item.supplier_bank_account_id === responses[6].data.supplier_bank_account_id);
        if (getValues("supplier_bank_name")) {
          let mySupplierBankAccount: SupplierBankAccount = {
            supplier_bank_account_id: responses[6].data.supplier_bank_account_id,
            account_alias: getValues("supplier_account_alias"),
            account_number: getValues("supplier_account_number"),
            bank_name: getValues("supplier_bank_name"),
            interbank_key: getValues("supplier_interbank_key"),
            swift_code: getValues("supplier_swift_code"),
            currency_code: mySupplierBankAccountCatalog?.currency_code,
            currency_description: mySupplierBankAccountCatalog?.currency_description,
          }
          setDefaultSupplierBankAccount((prev) => mySupplierBankAccount);
          await reset({ supplier_bank_account_id: responses[6].data.supplier_bank_account_id ?? 0 }, { keepValues: true });
        }
        else if (mySupplierBankAccountCatalog) {
          setDefaultSupplierBankAccount((prev) => mySupplierBankAccountCatalog);
          await reset({ supplier_bank_account_id: responses[6].data.supplier_bank_account_id ?? 0 }, { keepValues: true });
        }
        if (getValues("intermediary_bank_name")) {
          let myBankAccount: BankAccount = {
            bank_account_id: 0,
            account_alias: getValues("intermediary_account_alias"),
            bank_name: getValues("intermediary_bank_name"),
            card_number: getValues("intermediary_bank_card_number"),
            account_number: getValues("intermediary_account_number"),
            interbank_key: getValues("intermediary_bank_interbank_key"),
            swift_code: getValues("intermediary_bank_swift_code"),
            phone_number: getValues("intermediary_bank_phone_number"),
            currency_id: getValues("intermediary_bank_currency_id"),
            currency_code: getValues("intermediary_bank_currency_code"),
            currency_description: getValues("intermediary_bank_currency_description"),
            bank_references: getValues("intermediary_bank_references"),
          }
          await reset({ intermediary_bank_account_id: 0 }, { keepValues: true });
          setDefaultBankAccountFinal((prev) => myBankAccount);
        }
        if (getValues("payment_method_code")) {
          let myPaymentMethod: PaymentMethod = {
            payment_method_id: 0,
            code: getValues("payment_method_code"),
            name: getValues("payment_method_name")
          }
          await reset({ payment_method_id: 0 }, { keepValues: true });
          setDefaultPaymentMethod((prev) => myPaymentMethod);
        }
        if (getValues("payment_mode_code")) {
          let myPaymentMode: PaymentMode = {
            payment_mode_id: 0,
            code: getValues("payment_mode_code"),
            description: getValues("payment_mode_description")
          }
          await reset({ payment_mode_id: 0 }, { keepValues: true });
          setDefaultPaymentMode((prev) => myPaymentMode);
        }
        if (getValues("supplier_street_name")) {
          let mySupplierLocation: SupplierLocation = {
            supplier_id: 0,
            business_name: getValues("supplier_business_name"),
            supplier_rfc: getValues("supplier_rfc"),
            street: getValues("supplier_street_name"),
            city_name: getValues("supplier_city_name"),
            state_name: getValues("supplier_state_name"),
            state_abbr: getValues("supplier_state_abbr"),
            country_name: getValues("supplier_country_name"),
            country_code: getValues("supplier_country_code")
          }
          setDefaultSupplierLocation((prev) => mySupplierLocation);
        }
        if (getValues("cfdi_code")) {
          let myCFDI: CFDI = {
            cfdi_id: 0,
            code: getValues("cfdi_code") ?? '',
            description: getValues("cfdi_description") ?? '',
          }
          await reset({ cfdi_id: 0 }, { keepValues: true });
          setDefaultCFDI((prev) => myCFDI);
        }
        if (getValues("payment_condition_days")) {
          let myPaymentCondition: PaymentCondition = {
            payment_condition_id: 0,
            payment_condition_days: getValues("payment_condition_days") ?? 0,
            payment_condition_description: getValues("payment_condition_description") ?? '',
            payment_condition_name: getValues("payment_condition_name") ?? '',
          }
          await reset({ payment_condition_id: 0 }, { keepValues: true });
          setDefaultPaymentConditions((prev) => myPaymentCondition);
        }

        setDefaultInvoiceDocumentType((prev) => responses[4].data.data.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === responses[6].data.invoice_doc_type_id));
        setMyLocalConsecutive((prev) => responses[6].data.sales_invoice_name ?? "");
        setMyLocalInvoiceDocType((prev) => responses[6].data.invoice_doc_type_id ?? 0);
        setPurchaseInvoiceLocal(responses[6].data)
        if (responses[6].data.is_accounted) {
          setIsAccounted((prev) => true);
        }
        //permissions for actions
        let myActionsPermissions = [
          GetResourceByUrl("/purchaseinvoices"),
          GetResourceByUrl("/purchaseinvoices/copy"),
          GetResourceByUrl("/purchaseinvoices/isaccounted"),
          GetResourceByUrl("/purchaseinvoices/sendemail"),
        ];

        Promise.all(myActionsPermissions)
          .then((responses1) => {
            responses1[0] && setResourceScreen(responses1[0]);
            responses1[1] && setResourceCopySalesInvoice(responses1[1]);
            responses1[2] && setResourceAccounted(responses1[2]);
            responses1[3] && setResourceSendEmail(responses1[3]);

            // Si tiene acceso a la factura
            setIsEdit((prev) => (responses[6].data.invoice_doc_status_id === 2) ? true : !responses1[2].update);

            setDataLoaded(true);
          });
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPut(false);
      });
  }, []);


  useEffect(() => { }, [isEdit]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("purchaseinvoices.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button variant='outlined' aria-describedby={id1} onClick={handleClick1} disabled={loadingPut}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => { setOpenDialogCopyPurchaseInvoice(true); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={!resourceCopySalesInvoice?.create}>
                      {t("salesinvoices.actions.copy")}<ContentCopyIcon />
                    </ListItemButton>
                    <ListItemButton onClick={() => { onSendEmail(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={(!resourceSendEmail?.create)}>
                      {t("salesinvoices.actions.sendemail")}<EmailIcon />
                    </ListItemButton>
                    <ListItemButton sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }} disabled={true}>
                      {t("salesinvoices.actions.pay")}<RequestQuoteIcon />
                    </ListItemButton>
                    <ListItemButton onClick={async () => {
                      handleClose1();
                      await setIsBackdrop((prev) => true);
                      await onPut(getValues())
                      await onAccounted();
                    }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}
                      disabled={(!resourceAccounted?.update || loadingPut || (isAccounted) ? true : isEdit)}>
                      {t("salesinvoices.actions.accounted")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                    <ListItemButton onClick={async () => { handleClose1(); await setIsBackdrop((prev) => true); await onDeaccounted(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}
                      disabled={(!resourceAccounted?.update || loadingPut || (!isAccounted) ? true : isEdit)}>
                      {t("salesinvoices.actions.deaccount")}<AccountBalanceWalletIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='px-1 pt-1'>
                  <Button variant='outlined' aria-describedby={id2} onClick={handleClick1} disabled>
                    <PrintIcon />
                  </Button>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableScrollLock={false}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton onClick={() => { onGeneratePDF(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }} disabled={/*TODO:Traer permiso de generar pdf*/true}>
                      {t("purchaseinvoices.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='ps-2'>
                  {resourceScreen?.delete &&
                    <ButtonStyled disabled={isDeletedInvoice || loadingPut || (isAccounted) ? true : isEdit} variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                  }
                </div>
                <div className='pt-1'>
                  <Button disabled={loadingPut} variant="outlined" onClick={() => { navigate("/purchaseinvoices", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Arriba de la card */}
                <div className='d-flex w-100 m-2 flex-row-reverse'>
                  <div className="w-75   d-flex flex-row-reverse mx-2 ">
                    <div className='w-25'>
                      <Controller
                        name="invoice_doc_type_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboInvoiceDocumentTypes}
                            getOptionLabel={(option) => option.name}
                            defaultValue={defaultInvoiceDocumentType}
                            renderOption={(props, option: InvoiceDocumentType) => (
                              <div key={option.invoice_doc_type_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.invoice_doc_type_id === value.invoice_doc_type_id}
                            onChange={async (_, values) => {
                              if (values?.invoice_doc_type_id !== myLocalInvoiceDocType) {
                                await setMyLocalInvoiceDocType(values?.invoice_doc_type_id || 0);
                                await GetAndSetConsecutive(values?.consecutive_id);
                              }
                              else {
                                await GetAndSetConsecutive(undefined);
                              }
                              field.onChange(values?.invoice_doc_type_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("invoicedocumenttypes.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.invoice_doc_type_id}
                                helperText={GetError(errors.invoice_doc_type_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("purchaseinvoices.fields.purchase_invoice_name")}`}
                        value={myConsecutive}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                    <div className='w-25'>
                      <Controller
                        name="sales_order"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("purchaseinvoices.fields.sales_order")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.sales_order}
                            helperText={GetError(errors.sales_order?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="project_id"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboProjects}
                            getOptionLabel={(option) => `${option.project_name}`}
                            defaultValue={defaultProject}
                            renderOption={(props, option: Project) => (
                              <div key={option.project_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.project_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.project_id === value.project_id}
                            onChange={(_, values) => {
                              field.onChange(values?.project_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("projects.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.project_id}
                                helperText={GetError(errors.project_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-card-view'>
                  <div>
                    <UpdatePurchaseInvoicesHeader
                      loading={loadingPut}
                      control={control}
                      errors={errors}
                      disableKeyboardEntry={disableKeyboardEntry}
                      defaultDate={defaultDate}
                      defaultDateSupplier={defaultDateSupplier}
                      defaultSupplierBankAccount={defaultSupplierBankAccount}
                      comboSupplierBankAccounts={comboSupplierBankAccounts}
                      purchaseInvoiceLocal={purchaseInvoiceLocal}
                      company={company}
                      defaultSupplierLocation={defaultSupplierLocation}
                      comboSupplierLocations={comboSupplierLocations}
                      getValues={getValues}
                      isEdit={(isAccounted) ? true : isEdit}
                      locationEdit={locationEdit}
                      setLocationEdit={setLocationEdit}
                      isNotExchange={isNotExchange}
                      setIsNotExchange={setIsNotExchange}
                      GetExchangeRateFromDates={GetExchangeRateFromDates}
                      comboBankAccountsGeneral={comboBankAccountsGeneral}
                      defaultBankAccountFinal={defaultBankAccountFinal}
                    />
                  </div>
                </div>
              </form>
              <div>
                <InvoiceDetail
                  Update={
                    <UpdatePurchaseInvoices
                      rows={rows}
                      setRows={setRows}
                      myLocalInvoiceDocType={myLocalInvoiceDocType}
                      control={control}
                      reset={reset}
                      purchaseInvoicePayload={purchaseInvoiceLocal}
                      isEdit={isAccounted}
                      onPut={onPut}
                      setIsChangedTotalValue={setIsChangedTotalValue}
                      setValue={setValue}
                      trigger={trigger}
                      getValues={getValues}
                      dataLoaded={dataloaded}
                      loadingPut={loadingPut}
                    />
                  }
                  Annotations={
                    <AnnotationsPurchaseInvoices
                      control={control}
                      errors={errors}
                      isEdit={(isAccounted) ? true : isEdit}
                    />
                  }
                  Financial={
                    <FinancialPurchaseInvoices
                      defaultPaymentMethod={defaultPaymentMethod}
                      defaultPaymentMode={defaultPaymentMode}
                      defaultCurrency={defaultCurrency}
                      defaultCFDI={defaultCFDI}
                      defaultPaymentConditions={defaultPaymentConditions}
                      setDefaultPaymentMethod={setDefaultPaymentMethod}
                      setDefaultPaymentMode={setDefaultPaymentMode}
                      setDefaultCurrency={setDefaultCurrency}
                      setDefaultCFDI={setDefaultCFDI}
                      setDefaultPaymentConditions={setDefaultPaymentConditions}
                      comboCurrencies={comboCurrencies}
                      comboPaymentMethods={comboPaymentMethods}
                      comboPaymentModes={comboPaymentModes}
                      comboCFDIs={comboCFDIs}
                      comboPaymentConditions={comboPaymentConditions}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      isEdit={(isAccounted) ? true : isEdit}
                      getValues={getValues}
                      defaultExpirationDate={defaultExpirationDate}
                      GetExchangeRateFromDates={GetExchangeRateFromDates}
                    />
                  }
                />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("purchaseinvoices.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogSendEmailSingular
        open={openEmail}
        setOpen={setOpenEmail}
        data={dataPurchaseInvoiceSelected}
        getValues={getValues}
        setValue={setValue}
        onPut={onPut}
      />
      <DialogEntity
        open={openDialogCopyPurchaseInvoice}
        setOpen={setOpenDialogCopyPurchaseInvoice}
        title={<Header title={t("purchaseinvoices.actions.copy")} size='sm' />}
        content={
          <>
            <CopyPurchaseInvoice
              setOpenDialogCopyPurchaseInvoice={setOpenDialogCopyPurchaseInvoice}
              comboInvoiceDocumentTypes={comboInvoiceDocumentTypes}
              defaultInvoiceDocumentType={comboInvoiceDocumentTypes.find((item) => item.invoice_doc_type_id === (getValues("invoice_doc_type_id") ?? 1))}
              purchaseInvoiceLocal={purchaseInvoiceLocal}
            />
          </>
        }
      />
      <SnackbarComponent />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
