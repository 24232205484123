import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDates } from "../../../hooks/useDates";
import GenericPromises from "../../../api/GenericPromises";
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Menuitem } from "../../../interfaces/Security/menu";
import { usePermissions } from "../../../hooks/usePermissions";
import { PurchasePreorder } from "../../../interfaces/Purchases/Preorders/purchasePreorders";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import { PrimaryButton } from "../../../theme/buttons";

export const TablePurchasePreorders = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [purchasePreordersData, setPurchasePreordersData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [dataPurchasePreorderSelected, setDataPurchasePreorderSelected] = useState<GridRowsProp>([]);
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([]);
  const [columns, setColumns] = useState<GridColDef<PurchasePreorder>[]>([
    { field: 'purchase_preorder_name', headerName: t("purchasepreorders.fields.purchase_preorder_name"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'purchase_preorder_date', headerName: t("purchasepreorders.fields.purchase_preorder_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'supplier_business_name', headerName: t("purchasepreorders.fields.supplier_business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'sales_order', headerName: t("purchasepreorders.fields.sales_order"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'total_amount', headerName: t("purchasepreorders.fields.total_amount"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: "left", headerAlign: "left",
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? setFormatNumberFromTable(params.value ?? 0) : undefined;
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-purchasepreorders");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-purchasepreorders-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          if (element.field === 'purchase_preorder_date' || element.field === 'date_mail_send') {
            element.headerName = t(`purchasepreorders.fields.${element.field}`);
            element.valueGetter = function (params: GridValueGetterParams<PurchasePreorder, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
          else {
            element.headerName = t(`generic.${element.field}`);
            element.valueGetter = function (params: GridValueGetterParams<PurchasePreorder, any, GridTreeNodeWithRender>) {
              return TimeConverter(params.value);
            };
          }
        }
        else {
          if (element.field === 'total_amount' || element.field === 'total_company_currency_amount') {
            element.valueGetter = function (params: GridValueGetterParams<PurchasePreorder, any, GridTreeNodeWithRender>) {
              return params.value ? setFormatNumberFromTable(params.value ?? 0) : undefined;
            }
          }
          else
            if (element.field === 'currency_code') { element.headerName = t("currencies.title-view"); }
            else if (element.field === 'total_company_currency_amount') {
              element.headerName = t("companies.configuration.fields.total_company_currency_amount");
              element.valueGetter = function (params: GridValueGetterParams<PurchasePreorder, any, GridTreeNodeWithRender>) {
                return setFormatNumberFromTable(params.value ?? 0);
              }
            }
            else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
            else if (element.field === 'supplier_business_name') { element.headerName = t(`purchasepreorders.fields.supplier_business_name`); }
            else {
              element.headerName = t(`purchasepreorders.fields.${element.field}`);
            }
        }
      }
      setColumns(myJson);
    }
  }

  const onSelectedRows = (rowsIds: any) => {
    let selectedPurchasePreorders = rowsIds.map((element: number) => purchasePreordersData.find((elementFind) => elementFind["purchase_preorder_id"] === element));
    setDataPurchasePreorderSelected(selectedPurchasePreorders);
  };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("purchasepreorders.title")}`;
    })

    if (location.state !== null) {
      if (location.state.method) {
        methodUsed();
      }
      else if (location.state.row && location.state.row.purchase_preorder_id) {
        navigate("/purchasepreorders/view", { state: { row: location.state.row } })
      }
    }

    let myResources = [
      GenericGetResource(`/purchasepreorders`)
    ];

    Promise.all(myResources)
      .then(async (responses) => {
        setPurchasePreordersData(responses[0].data.data);

        await GetResourceByUrl(`/purchasepreorders`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });

        loadColumnsOrder();
        loadPreferences();
        setDataLoaded(true);
      }
      ).catch((error) => {
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (purchasePreordersData) {
      setIsLoadingDataTable(true);
    }
  }, [purchasePreordersData]);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header
          title={t("purchasepreorders.title")}
          child={
            <div className='d-flex flex-row-reverse my-1'>
              {resourceScreen?.create === true &&
                <PrimaryButton variant='outlined' className="m-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
              }
            </div>
          }
        />
        <div className="d-flex justify-content-center">
          {!isLoadingDataTable && <Spinner />}
          {isLoadingDataTable &&
            <DataTable
              columns={columns}
              setColumns={setColumns}
              data={purchasePreordersData}
              entityId={"purchase_preorder_id"}
              entity={`PurchasePreorders`}
              preferences={myPreferences}
              namePreferences={"grid-purchasepreorders"}
              nameOrderColumns={"grid-purchasepreorders-columns"}
              selectRows={true}
              countColumns={["total_company_currency_amount"]}
              onRowSelectionModelChange={(rowsIds) => onSelectedRows(rowsIds)}
              initFilter={defaultFilter}
            />
          }
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
