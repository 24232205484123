import { Route, Routes } from 'react-router-dom'
import { TablePurchaseOrders } from './table'
import { AddPurchaseOrder } from './add'
import { ViewPurchaseOrder } from './view'

export const BrowserPurchaseOrders = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePurchaseOrders />} />
      <Route path={"/add"} element={<AddPurchaseOrder />} />
      <Route path={"/view"} element={<ViewPurchaseOrder />} />
    </Routes>
  )
}