import { Link } from 'react-router-dom';
import "./index.css";

export const Recovery = () => {

    return (
        <div className="background" >
            <div className='d-flex flex-column align-items-center'>
                <img className="img-fluid" style={{ width: '20rem', height: '20rem' }} src={require("../../../assets/img/logo.webp")} alt="Logo" />
                <div className='header'>
                    <h1 className='title-cala text-white d-flex flex-column justify-content-center align-items-center'>CALA</h1>
                    <div className='box-login p-4 d-flex flex-column' >
                        <div className="input-group input-group-lg my-3">
                            <input type="text" className='form-control' placeholder='Correo' />
                        </div>
                        <button type="button" className="btn btn-primary btn-lg my-3">Enviar</button>
                    </div>
                    <Link to={"/"}>
                        <h6 className='text-white mt-3 d-flex flex-column justify-content-center align-items-center'>Inicia sesión</h6>
                    </Link>
                </div>
            </div>
        </div>
    )
}
