import Axios from "axios";

const baseURL = process.env.REACT_APP_HOST_API;

const sxNetCalaApi = Axios.create({
    baseURL: baseURL, headers: {
        "Accept": "*/*",
        "Content-Type": "application/json"
    }
});

export default sxNetCalaApi;