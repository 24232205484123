import { useEffect, useState } from "react";
import { PurchasePreorder, PurchasePreorderDetail } from "../../../interfaces/Purchases/Preorders/purchasePreorders";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrencies } from "../../../hooks/useCurrencies";
import { useDates } from "../../../hooks/useDates";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Currency } from "../../../interfaces/Commons/currencies";
import { PaymentMethod } from "../../../interfaces/Sales/Catalogs/paymentMethods";
import { PaymentMode } from "../../../interfaces/Sales/Catalogs/paymentModes";
import { CFDI } from "../../../interfaces/Sales/Catalogs/CFDIs";
import { Supplier, SupplierLocation } from "../../../interfaces/Purchases/Catalogs/suppliers";
import { Menuitem } from "../../../interfaces/Security/menu";
import { usePermissions } from "../../../hooks/usePermissions";
import { useParamsFilter } from "../../../hooks/useParamsFilter";
import { Company } from "../../../interfaces/Security/companies";
import { PaymentCondition } from "../../../interfaces/Sales/Catalogs/paymentConditions";
import GenericPromises from "../../../api/GenericPromises";
import { Language } from "../../../interfaces/Commons/languages";
import { Project } from "../../../interfaces/Projects/projects";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { BankAccount } from "../../../interfaces/Commons/bankAccounts";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { TaxRegime } from "../../../interfaces/Sales/Catalogs/taxRegimes";
import { GenericDialog } from "../../../components/Dialogs/Dialog";
import { Autocomplete, Backdrop, Box, Button, CircularProgress, ListItemButton, Popover, TextField } from "@mui/material";
import { Header } from "../../../components/Header";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import PrintIcon from '@mui/icons-material/Print';
import { ButtonStyled, PrimaryButton } from "../../../theme/buttons";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { UpdatePurchasePreorderHeader } from "./updateHeader";
import PurchasePreorderTabs from "./purchasePreorderTabs";
import { AnnotationsPurchasePreorders } from "./Tabs/annotations";
import { FinancialPurchasePreorders } from "./Tabs/financial";
import { UpdatePurchasePreorderDetails } from "./Tabs/updateDetails";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";

export const ViewPurchasePreorders = () => {
  const [rows, setRows] = useState<PurchasePreorderDetail[]>([]);
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const { GetExchangeRateFromCurrencyCode } = useCurrencies();
  const { TimeConverter, GetDateCalculatedByAfterDays } = useDates();
  const [purchasePreorderLocal, setPurchasePreorderLocal] = useState<PurchasePreorder>();
  const [dataloaded, setDataLoaded] = useState(false);
  const [isAccounted, setIsAccounted] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [disabledPostCopy, setDisabledPostCopy] = useState(false);
  const [openDialogCopyPurchasePreorder, setOpenDialogCopyPurchasePreorder] = useState(false);
  const [comboSupplierLocations, setComboSupplierLocations] = useState<SupplierLocation[]>([]);
  const [defaultSupplierLocation, setDefaultSupplierLocation] = useState<SupplierLocation>();
  const [myConsecutive, setMyConsecutive] = useState("");
  const { GenericPutResource, GenericPostAction, GenericDeleteResource, GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { GetResourceByUrl } = usePermissions();
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { GetParamsFromBase64 } = useParamsFilter();
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [IsChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [myLocalConsecutive, setMyLocalConsecutive] = useState("");
  const [locationEdit, setLocationEdit] = useState(false);
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>();
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [isNotExchange, setIsNotExchange] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [defaultDateSupplier, setDefaultDateSupplier] = useState<Date | undefined>();
  const [loadingPut, setLoadingPut] = useState(false);
  const [comboSuppliers, setComboSuppliers] = useState<Supplier[]>([]);
  const [comboLanguages, setComboLanguages] = useState<Language[]>([]);
  const [resourceCopyPurchasePreorder, setResourceCopyPurchasePreorder] = useState<Menuitem>();
  const [comboProjects, setComboProjects] = useState<Project[]>([]);
  const [defaultProject, setDefaultProject] = useState<Project>();
  const [comboSupplierBankAccounts, setComboSupplierBankAccounts] = useState<SupplierBankAccount[]>([]);
  const [defaultSupplierBankAccount, setDefaultSupplierBankAccount] = useState<SupplierBankAccount>();
  const [defaultExpirationDate, setDefaultExpirationDate] = useState<Date | undefined>();
  const [comboBankAccountsGeneral, setComboBankAccountsGeneral] = useState<BankAccount[]>([]);
  const [defaultBankAccountFinal, setDefaultBankAccountFinal] = useState<BankAccount>();

  const { GetError } = FormsErrors();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? 'simple-popover' : undefined;
  const id2 = open2 ? 'simple-popover' : undefined;

  const {
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    trigger,
  } = useForm<PurchasePreorder>({});

  const onSubmit: SubmitHandler<PurchasePreorder> = (data) => onPut(data);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const onCopy = () => {
    GenericPostAction(`/purchasepreorders/copy/${purchasePreorderLocal?.purchase_preorder_id}`, {}, false)
      .then(async (response) => {
        await navigate("/purchasepreorders", { state: { row: response.data } });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
      .finally(() => setDisabledPostCopy(false));
  }

  const onPut = async (data: PurchasePreorder) => {
    await trigger()
      .then(async (responseTrigger) => {

        // showSnackBar(t("purchasepreorders.info.no-data"), "error");
        // setOpenDialog(false);


        setLoadingPut(true);
        let mySupplier = comboSuppliers.find((supplier: Supplier) => supplier.supplier_id === data.supplier_id);
        let myPaymentMode = comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === data.payment_mode_id);
        let myPaymentMethod = comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === data.payment_method_id);
        let myCFDI = comboCFDIs.find((item: CFDI) => item.cfdi_id === data.cfdi_id);
        let myCurrency = comboCurrencies.find((item: Currency) => item.currency_id === data.currency_id);
        let myPaymentCondition = comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === data.payment_condition_id);
        let mySupplierLanguage = comboLanguages.find((language: Language) => language.language_id === mySupplier?.language_id)
        let mySupplierLocation = await comboSupplierLocations.find((element: SupplierLocation) => element.supplier_location_id === data.supplier_location_id);
        let myBankAccount = await comboSupplierBankAccounts.find((element: SupplierBankAccount) => element.supplier_bank_account_id === data.supplier_bank_account_id);

        const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");
        const company = responseCompany.data ?? null;

        let mySupplierTaxRegime: TaxRegime | undefined = undefined;
        if (mySupplierLocation) {
          let myTaxRegimeResponse = await GenericGetResource(`/taxregimes/${mySupplierLocation.tax_regime_id}`);
          mySupplierTaxRegime = myTaxRegimeResponse.data;
        }

        var date = new Date();
        if (typeof data.purchase_preorder_date == 'string')
          date = TimeConverter(data.purchase_preorder_date) ?? new Date();
        else if (data.purchase_preorder_date) {
          let currentDate = new Date();
          let invoiceDate = new Date(data.purchase_preorder_date);
          invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
          date = invoiceDate;
        }

        var supplierDate = null;
        if (typeof data.supplier_purchase_preorder_date == 'string')
          supplierDate = TimeConverter(data.supplier_purchase_preorder_date) ?? new Date();
        else if (data.supplier_purchase_preorder_date) {
          let currentDate = new Date();
          let invoiceDate = new Date(data.supplier_purchase_preorder_date);
          invoiceDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0, 0);
          supplierDate = invoiceDate;
        }

        let myData = {
          purchase_preorder_name: data.purchase_preorder_name,
          purchase_preorder_date: date ?? data.purchase_preorder_date ?? new Date(),
          supplier_purchase_preorder_date: supplierDate ?? data.supplier_purchase_preorder_date ?? null,
          sales_order: data.sales_order,
          supplier_id: data.supplier_id,
          supplier_business_name: mySupplierLocation?.business_name ?? (data?.supplier_business_name ?? null),
          supplier_comercial_name: mySupplier?.comercial_name ?? (data.supplier_comercial_name ?? null),
          supplier_rfc: mySupplierLocation?.supplier_rfc ?? (data?.supplier_rfc ?? null),
          supplier_location_id: mySupplierLocation?.supplier_location_id ?? (data.supplier_location_id ?? null),
          supplier_street_name: mySupplierLocation?.street ?? (data.supplier_street_name ?? null),
          supplier_postal_code: mySupplierLocation?.postal_code ?? (data.supplier_postal_code ?? null),
          supplier_city_id: mySupplierLocation?.city_id,
          supplier_city_name: mySupplierLocation?.city_name ?? (data.supplier_city_name ?? null),
          supplier_state_id: mySupplierLocation?.state_id ?? (data.supplier_state_id ?? null),
          supplier_state_name: mySupplierLocation?.state_name ?? (data.supplier_state_name ?? null),
          supplier_state_abbr: mySupplierLocation?.state_abbr ?? (data.supplier_state_abbr ?? null),
          supplier_country_id: mySupplierLocation?.country_id ?? (data.supplier_country_id ?? null),
          supplier_country_name: mySupplierLocation?.country_name ?? (data.supplier_country_name ?? null),
          supplier_country_code: mySupplierLocation?.country_code ?? (data.supplier_country_code ?? null),
          supplier_account_alias: myBankAccount?.account_alias ?? (data.supplier_account_alias ?? null),
          supplier_interbank_key: myBankAccount?.interbank_key ?? (data.supplier_interbank_key ?? null),
          supplier_swift_code: myBankAccount?.swift_code ?? (data.supplier_swift_code ?? null),
          supplier_bank_account_id: myBankAccount?.supplier_bank_account_id ?? (data.supplier_bank_account_id ?? null),
          supplier_bank_name: myBankAccount?.bank_name ?? (data.supplier_bank_name ?? null),
          supplier_account_number: myBankAccount?.account_number ?? (data.supplier_account_number ?? null),
          currency_id: myCurrency?.currency_id ?? (data.currency_id ?? null),
          currency_code: myCurrency?.currency_code ?? (data.currency_code ?? null),
          currency_description: myCurrency?.currency_description ?? (data.currency_description ?? null),
          payment_mode_code: myPaymentMode?.code ?? (data.payment_mode_code ?? null),
          payment_mode_description: myPaymentMode?.description ?? (data.payment_mode_description ?? null),
          payment_method_code: myPaymentMethod?.code ?? (data.payment_method_code ?? null),
          payment_method_name: myPaymentMethod?.description ?? (data.payment_method_name ?? null),
          cfdi_code: myCFDI?.code ?? (data.cfdi_code ?? null),
          cfdi_description: myCFDI?.description ?? (data.cfdi_description ?? null),
          supplier_tax_regime_code: mySupplierTaxRegime?.tax_regime_code ?? (data.supplier_tax_regime_code ?? null),
          supplier_tax_regime_description: mySupplierTaxRegime?.description ?? (data.supplier_tax_regime_description ?? null),
          id_consecutive: data.id_consecutive,
          notes: data.notes,
          payment_condition_days: myPaymentCondition?.payment_condition_days ?? (data.payment_condition_days ?? null),
          payment_condition_name: myPaymentCondition?.payment_condition_name ?? (data.payment_condition_name ?? null),
          payment_condition_description: myPaymentCondition?.payment_condition_description ?? (data.payment_condition_description ?? null),
          payment_due_date: data.payment_due_date,
          supplier_language: mySupplierLanguage?.language_code ?? "ES",
          supplier_email: data.supplier_email,
          supplier_phone_number: data.supplier_phone_number,
          date_mail_send: data.date_mail_send,
          user_mail_send: data.user_mail_send,
          company_business_name: company.business_name ?? (data.company_business_name ?? null),
          company_street: company.street ?? (data.company_street ?? null),
          company_city_id: company.city_id ?? (data.company_city_id ?? null),
          company_city_name: company.city_name ?? (data.company_city_name ?? null),
          company_city_code: company.city_code ?? (data.company_city_code ?? null),
          company_state_id: company.state_id ?? (data.company_state_id ?? null),
          company_state_name: company.state_name ?? (data.company_state_name ?? null),
          company_state_abbr: company.state_abbr ?? (data.company_state_abbr ?? null),
          company_country_id: company.country_id ?? (data.company_country_id ?? null),
          company_country_name: company.country_name ?? (data.company_country_name ?? null),
          company_country_code: company.country_code ?? (data.company_country_code ?? null),
          company_postal_code: company.postal_code ?? (data.company_postal_code ?? null),
          company_rfc: company.rfc ?? (data.company_rfc ?? null),
          company_tax_regime_id: company.tax_regime_id ?? (data.company_tax_regime_id ?? null),
          company_tax_regime_code: company.tax_regime_code ?? (data.company_tax_regime_code ?? null),
          company_tax_regime_description: company.tax_regime_description ?? (data.company_tax_regime_description ?? null),
          company_email: company.email ?? (data.company_email ?? null),
          company_phone_number: company.phone_number ?? (data.company_phone_number ?? null),
          url_files: data.url_files,
          exchange_rate: (data.exchange_rate == null || data.exchange_rate == undefined || data.exchange_rate <= 1) ? 1 : data.exchange_rate,
          project_id: data.project_id ?? null,
          invoice_doc_type_id: 0,
          invoice_doc_status_id: 0,
        }
        await GenericPutResource(`/purchasepreorders/${purchasePreorderLocal?.purchase_preorder_id}`, myData)
          .then(async (response) => {
            setLoadingPut(false);
            setValue("tax_amount", response.data.tax_amount);
            setValue("purchase_preorder_name", response.data.purchase_preorder_name);
            setValue("retention_amount", response.data.retention_amount);
            setValue("subtotal_amount", response.data.subtotal_amount);
            setValue("discount_amount", response.data.discount_amount);
            setValue("total_amount", response.data.total_amount);
            setValue("supplier_street_name", response.data.supplier_street_name);
            setValue("supplier_city_name", response.data.supplier_city_name);
            setValue("supplier_state_name", response.data.supplier_state_name);
            setValue("supplier_state_abbr", response.data.supplier_state_abbr);
            setValue("supplier_country_name", response.data.supplier_country_name);
            setValue("supplier_country_code", response.data.supplier_country_code);
            setValue("purchase_preorder_id", response.data.purchase_preorder_id);
            setValue("currency_id", response.data.currency_id);
            setValue("currency_code", response.data.currency_code);
            setValue("currency_description", response.data.currency_description);
            setValue("payment_condition_id", response.data.payment_condition_id);
            setValue("payment_condition_days", response.data.payment_condition_days);
            setValue("payment_condition_name", response.data.payment_condition_name);
            setValue("supplier_business_name", response.data.supplier_business_name);
            setValue("supplier_rfc", response.data.supplier_rfc);
            reset({
              ...response.data,
              original_string_SAT: response.data.original_string_SAT,
              certificate_number_SAT: response.data.certificate_number_SAT,
              certificate_number_CFDI: response.data.certificate_number_CFDI,
              supplier_street_name: response.data.supplier_street_name,
              supplier_business_name: response.data.supplier_business_name,
              supplier_city_name: response.data.supplier_city_name,
              supplier_rfc: response.data.supplier_rfc,
              supplier_state_name: response.data.supplier_state_name,
              supplier_state_abbr: response.data.supplier_state_abbr,
              supplier_country_name: response.data.supplier_country_name,
              supplier_country_code: response.data.supplier_country_code,
              payment_condition_id: response.data.payment_condition_id,
              payment_condition_days: response.data.payment_condition_days,
              payment_condition_name: response.data.payment_condition_name,
            }, { keepValues: true })
            let mySupplierLocation: SupplierLocation = {
              supplier_location_id: 0,
              business_name: getValues("supplier_business_name"),
              supplier_rfc: getValues("supplier_rfc"),
              street: getValues("supplier_street_name"),
              city_name: getValues("supplier_city_name"),
              state_name: getValues("supplier_state_name"),
              state_abbr: getValues("supplier_state_abbr"),
              country_name: getValues("supplier_country_name"),
              country_code: getValues("supplier_country_code"),
            }
            setDefaultSupplierLocation((prev) => mySupplierLocation);
            setIsChangedTotalValue((prev) => !prev);
            setPurchasePreorderLocal((prev) => response.data);
            setLocationEdit(false);
            showSnackBar(t("generic.snackbar.update"), "success");
          }).catch((error) => {
            showSnackBar(error.message, "error");
            setLoadingPut(false);
          });

      });
  }

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const onDelete = () => {
    GenericGetResource(`/purchasepreorderdetails/bypurchasepreorderid/${purchasePreorderLocal?.purchase_preorder_id}`)
      .then(async (responseDetails) => {
        for (let i = 0; i < responseDetails.data.data.length; i++) {
          const e: PurchasePreorderDetail = responseDetails.data.data[i];
          await GenericDeleteResource(`/purchasepreorderdetails/${e.purchase_preorder_detail_id}`);
        }
        await GenericDeleteResource(`/purchasepreorders/${purchasePreorderLocal?.purchase_preorder_id}`)
          .then(() => {
            setOpenDialog(false);
            navigate("/purchasepreorders", { state: { method: "delete" }, replace: true });
          })
          .catch((error) => {
            showSnackBar(error.message, 'error');
            setOpenDialog(false);
          })
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setOpenDialog(false);
      });
  }

  const GetExchangeRateFromDates = async (currency_id: number, datePurchaseInvoice: Date | undefined, dateSupplierInvoice: Date | undefined) => {
    if (!(datePurchaseInvoice)) {
      setValue("exchange_rate", 1)
    }
    let date = (dateSupplierInvoice) ? dateSupplierInvoice : datePurchaseInvoice;
    let ExchangeRate = await GetExchangeRateFromCurrencyCode(currency_id, date);
    if (ExchangeRate && ExchangeRate.rate && ExchangeRate.rate > 1) {
      setValue("exchange_rate", ExchangeRate?.rate ?? 1)
    }
    else {
      setValue("exchange_rate", 1)
    }
  }

  useEffect(() => {
    let purchasePreorderId;
    let supplierId;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      purchasePreorderId = myParams.get("purchase_preorder_id");
      supplierId = myParams.get("supplier_id");
    }
    let myPromises = [
      GenericGetResource("/currencies"),
      GenericGetResource("/paymentmethods"),
      GenericGetResource("/paymentmodes"),
      GenericGetResource("/cfdi"),
      GenericGetResource(`/supplierlocations/bysupplierid/${(location.state && location.state.row.supplier_id) ?? supplierId}`),
      GenericGetResource(`/purchasepreorders/${(location.state && location.state.row.purchase_preorder_id) ?? purchasePreorderId}`),
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResource("/paymentconditions"),
      GenericGetResource("/suppliers"),
      GenericGetResource("/languages"),
      GenericGetResource("/projects"),
      GenericGetResource(`/supplierbankaccounts/bysupplierid/${(location.state && location.state.row.supplier_id) ?? supplierId}`),
      GenericGetResource("/bankaccounts"),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        setComboSuppliers(responses[9].data.data);
        setComboLanguages(responses[10].data.data);
        setComboCurrencies(responses[0].data.data);
        setComboPaymentMethods(responses[1].data.data);
        setComboPaymentModes(responses[2].data.data);
        setComboCFDIs(responses[3].data.data);
        setComboSupplierLocations(responses[4].data.data);
        setDefaultDate(new Date(responses[5].data.purchase_preorder_date));
        setDefaultDateSupplier(responses[5].data.supplier_purchase_preorder_date ? new Date(responses[5].data.supplier_purchase_preorder_date) : undefined);
        setCompany((prev) => responses[6].data.data[0]);
        setComboPaymentConditions(responses[7].data.data);
        setComboProjects(responses[10].data.data);
        setDefaultProject((prev) => responses[10].data.data.find((item: any) => item.project_id === responses[5].data.project_id));
        let myComboSupplierBankAccounts: SupplierBankAccount[] = responses[11].data.data;
        setComboSupplierBankAccounts(responses[11].data.data);
        setComboBankAccountsGeneral(responses[12].data.data)

        await reset({
          ...responses[5].data,
          payment_method_id: (responses[5].data.payment_method_code) ? 0 : null,
          payment_mode_id: (responses[5].data.payment_mode_code) ? 0 : null,
          cfdi_id: (responses[5].data.cfdi_code) ? 0 : null,
          tax_id: (responses[5].data.tax_name) ? 0 : null,
          supplier_bank_account_id: (responses[5].data.supplier_bank_name) ? responses[5].data.supplier_bank_account_id : null,
        });

        setMyConsecutive((prev) => responses[5].data.purchase_preorder_name);

        if (getValues("currency_code")) {
          let myCurrency: Currency = {
            currency_id: getValues("currency_id") ?? 0,
            currency_code: getValues("currency_code") ?? '',
            currency_description: getValues("currency_description") ?? '',
          }
          await reset({ currency_id: 0 }, { keepValues: true });
          setDefaultCurrency((prev) => myCurrency);
        }
        let mySupplierBankAccountCatalog = myComboSupplierBankAccounts.find((item) => item.supplier_bank_account_id === responses[5].data.supplier_bank_account_id);
        if (getValues("supplier_bank_name")) {
          let mySupplierBankAccount: SupplierBankAccount = {
            supplier_bank_account_id: responses[5].data.supplier_bank_account_id,
            account_alias: getValues("supplier_account_alias"),
            account_number: getValues("supplier_account_number"),
            bank_name: getValues("supplier_bank_name"),
            interbank_key: getValues("supplier_interbank_key"),
            swift_code: getValues("supplier_swift_code"),
            currency_code: mySupplierBankAccountCatalog?.currency_code,
            currency_description: mySupplierBankAccountCatalog?.currency_description,
          }
          setDefaultSupplierBankAccount((prev) => mySupplierBankAccount);
          await reset({ supplier_bank_account_id: responses[5].data.supplier_bank_account_id ?? 0 }, { keepValues: true });
        }
        else if (mySupplierBankAccountCatalog) {
          setDefaultSupplierBankAccount((prev) => mySupplierBankAccountCatalog);
          await reset({ supplier_bank_account_id: responses[5].data.supplier_bank_account_id ?? 0 }, { keepValues: true });
        }
        if (getValues("payment_method_code")) {
          let myPaymentMethod: PaymentMethod = {
            payment_method_id: 0,
            code: getValues("payment_method_code"),
            name: getValues("payment_method_name")
          }
          await reset({ payment_method_id: 0 }, { keepValues: true });
          setDefaultPaymentMethod((prev) => myPaymentMethod);
        }
        if (getValues("payment_mode_code")) {
          let myPaymentMode: PaymentMode = {
            payment_mode_id: 0,
            code: getValues("payment_mode_code"),
            description: getValues("payment_mode_description")
          }
          await reset({ payment_mode_id: 0 }, { keepValues: true });
          setDefaultPaymentMode((prev) => myPaymentMode);
        }
        if (getValues("supplier_street_name")) {
          let mySupplierLocation: SupplierLocation = {
            supplier_id: 0,
            business_name: getValues("supplier_business_name"),
            supplier_rfc: getValues("supplier_rfc"),
            street: getValues("supplier_street_name"),
            city_name: getValues("supplier_city_name"),
            state_name: getValues("supplier_state_name"),
            state_abbr: getValues("supplier_state_abbr"),
            country_name: getValues("supplier_country_name"),
            country_code: getValues("supplier_country_code")
          }
          setDefaultSupplierLocation((prev) => mySupplierLocation);
        }
        if (getValues("cfdi_code")) {
          let myCFDI: CFDI = {
            cfdi_id: 0,
            code: getValues("cfdi_code") ?? '',
            description: getValues("cfdi_description") ?? '',
          }
          await reset({ cfdi_id: 0 }, { keepValues: true });
          setDefaultCFDI((prev) => myCFDI);
        }
        if (getValues("payment_condition_days")) {
          let myPaymentCondition: PaymentCondition = {
            payment_condition_id: 0,
            payment_condition_days: getValues("payment_condition_days") ?? 0,
            payment_condition_description: getValues("payment_condition_description") ?? '',
            payment_condition_name: getValues("payment_condition_name") ?? '',
          }
          await reset({ payment_condition_id: 0 }, { keepValues: true });
          setDefaultPaymentConditions((prev) => myPaymentCondition);
        }

        setMyLocalConsecutive((prev) => responses[5].data.purchase_preorder_name ?? "");
        setPurchasePreorderLocal(responses[5].data)
        //permissions for actions
        let myActionsPermissions = [
          GetResourceByUrl("/purchasepreorders"),
          GetResourceByUrl("/purchasepreorders/copy"),
        ];

        Promise.all(myActionsPermissions)
          .then((responses1) => {
            responses1[0] && setResourceScreen(responses1[0]);

            setIsEdit((prev) => (!responses1[0].update));

            setResourceCopyPurchasePreorder(()=>(responses1[1]))

            setDataLoaded(true);
          });
      }).catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPut(false);
      });
  }, []);


  useEffect(() => { }, [isEdit]);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("purchasepreorders.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                  <Button variant='outlined' aria-describedby={id1} onClick={handleClick1} disabled={loadingPut}>
                    <MoreHorizIcon />
                  </Button>
                  <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    sx={{ minWidth: 200 }}
                  >
                    <ListItemButton onClick={() => {setOpenDialogCopyPurchasePreorder(true);}} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 200, }} disabled={!(resourceCopyPurchasePreorder?.create ?? false)}>
                      {t("salesinvoices.actions.copy")}<ContentCopyIcon />
                    </ListItemButton>
                  </Popover>
                </div>
                <div className='px-1 pt-1'>
                  <Button variant='outlined' aria-describedby={id2} onClick={handleClick1} disabled>
                    <PrintIcon />
                  </Button>
                  {/* <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableScrollLock={false}
                    sx={{ minWidth: 150 }}
                  >
                    <ListItemButton onClick={() => { onGeneratePDF(); }} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', minWidth: 150 }} disabled={/*TODO:Traer permiso de generar pdftrue}>
                      {t("purchasepreorders.reports.pdf")}<PictureAsPdfIcon />
                    </ListItemButton>
                  </Popover> */}
                </div>
                <div className='ps-2'>
                  {resourceScreen?.delete &&
                    <ButtonStyled disabled={loadingPut || (isAccounted) ? true : isEdit} variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                  }
                </div>
                <div className='pt-1'>
                  <Button disabled={loadingPut} variant="outlined" onClick={() => { navigate("/purchasepreorders", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
                </div>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Arriba de la card */}
                <div className='d-flex w-100 m-2 flex-row-reverse'>
                  <div className="w-75   d-flex flex-row-reverse mx-2 ">
                    <div className='w-25'>
                      <TextField
                        variant="filled"
                        label={`${t("purchasepreorders.fields.purchase_preorder_name")}`}
                        value={myConsecutive}
                        size="small"
                        sx={{ width: "100%", paddingRight: 2 }}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                    <div className='w-25'>
                      <Controller
                        name="sales_order"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <TextField
                            variant="filled"
                            label={`${t("purchasepreorders.fields.sales_order")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.sales_order}
                            helperText={GetError(errors.sales_order?.type)}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className="w-25">
                      <Controller
                        name="project_id"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%", paddingRight: 2 }}
                            options={comboProjects}
                            getOptionLabel={(option) => `${option.project_name}`}
                            defaultValue={defaultProject}
                            renderOption={(props, option: Project) => (
                              <div key={option.project_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.project_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.project_id === value.project_id}
                            onChange={(_, values) => {
                              field.onChange(values?.project_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("projects.title-view")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.project_id}
                                helperText={GetError(errors.project_id?.type)}
                              />
                            )}
                            disabled={(isAccounted) ? true : isEdit}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-card-view'>
                  <div>
                    <UpdatePurchasePreorderHeader
                      loading={loadingPut}
                      control={control}
                      errors={errors}
                      disableKeyboardEntry={disableKeyboardEntry}
                      defaultDate={defaultDate}
                      defaultDateSupplier={defaultDateSupplier}
                      defaultSupplierBankAccount={defaultSupplierBankAccount}
                      comboSupplierBankAccounts={comboSupplierBankAccounts}
                      purchasePreorderLocal={purchasePreorderLocal}
                      company={company}
                      defaultSupplierLocation={defaultSupplierLocation}
                      comboSupplierLocations={comboSupplierLocations}
                      getValues={getValues}
                      isEdit={(isAccounted) ? true : isEdit}
                      locationEdit={locationEdit}
                      setLocationEdit={setLocationEdit}
                      isNotExchange={isNotExchange}
                      setIsNotExchange={setIsNotExchange}
                      GetExchangeRateFromDates={GetExchangeRateFromDates}
                      comboBankAccountsGeneral={comboBankAccountsGeneral}
                      defaultBankAccountFinal={defaultBankAccountFinal}
                    />
                  </div>
                </div>
              </form>
              <div>
                <PurchasePreorderTabs
                  Update={
                    <UpdatePurchasePreorderDetails
                      rows={rows}
                      setRows={setRows}
                      reset={reset}
                      purchasePreorderLocal={purchasePreorderLocal}
                      isEdit={isAccounted}
                      onPut={onPut}
                      setIsChangedTotalValue={setIsChangedTotalValue}
                      setValue={setValue}
                      getValues={getValues}
                      loadingPut={loadingPut}
                    />
                  }
                  Annotations={
                    <AnnotationsPurchasePreorders
                      control={control}
                      errors={errors}
                      isEdit={(isAccounted) ? true : isEdit}
                    />
                  }
                  Financial={
                    <FinancialPurchasePreorders
                      defaultPaymentMethod={defaultPaymentMethod}
                      defaultPaymentMode={defaultPaymentMode}
                      defaultCurrency={defaultCurrency}
                      defaultCFDI={defaultCFDI}
                      defaultPaymentConditions={defaultPaymentConditions}
                      setDefaultPaymentMethod={setDefaultPaymentMethod}
                      setDefaultPaymentMode={setDefaultPaymentMode}
                      setDefaultCurrency={setDefaultCurrency}
                      setDefaultCFDI={setDefaultCFDI}
                      setDefaultPaymentConditions={setDefaultPaymentConditions}
                      comboCurrencies={comboCurrencies}
                      comboPaymentMethods={comboPaymentMethods}
                      comboPaymentModes={comboPaymentModes}
                      comboCFDIs={comboCFDIs}
                      comboPaymentConditions={comboPaymentConditions}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      isEdit={(isAccounted) ? true : isEdit}
                      getValues={getValues}
                      defaultExpirationDate={defaultExpirationDate}
                      GetExchangeRateFromDates={GetExchangeRateFromDates}
                    />
                  }
                />
              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("purchasepreorders.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogEntity
        open={openDialogCopyPurchasePreorder}
        setOpen={setOpenDialogCopyPurchasePreorder}
        title={<Header title={t("purchasepreorders.actions.copy")} size='sm' />}
        content={
          <>
            <div className="d-flex flex-row-reverse mt-4">
              <PrimaryButton disabled={disabledPostCopy} onClick={() => onCopy()}>{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenDialogCopyPurchasePreorder(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
      />
      <SnackbarComponent />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
