import { useEffect, useState } from "react"
import { Autocomplete, Avatar, Box, Button, CircularProgress, Grow, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { PrimaryButton } from "../../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Customer, CustomerBankAccount, CustomerEnum } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { emailRegex, rfcRegex } from "../../../../../constants/Regex";
import { ImageDataComponent } from "../../../../../components/Images/ImageData";
import { useFiles } from "../../../../../hooks/useFiles";
import { Divider } from "../../../../../components/Commons/Divider";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import GenericPromises from "../../../../../api/GenericPromises";
import { Language } from "../../../../../interfaces/Commons/languages";
import { Currency } from "../../../../../interfaces/Commons/currencies";
import { useBase64 } from "../../../../../hooks/useBase64";

export const UpdateCustomer = ({ ...props }) => {
  const {
    customerPayload,
    loadingPost,
    imageFile,
    imageLoaded,
    comboCustomers,
    control,
    errors,
    onPostImage,
    isNotMexican,
    comboLanguages,
    defaultLanguage
  } = props;
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const { DecodeFileToBase64 } = useFiles();
  const { GenericGetResource } = GenericPromises();
  const [defaultGroupBy, setDefaultGroupBy] = useState<Customer>();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { utf8ToBase64 } = useBase64();
  const [customerSalesOrderEnum, setCustomerSalesOrderEnum] = useState<CustomerEnum[]>([]);
  const [customerBackOrderEnum, setCustomerBackOrderEnum] = useState<CustomerEnum[]>([]);
  const [customerSalesShippingDocumentEnum, setCustomerSalesShippingDocumentEnum] = useState<CustomerEnum[]>([]);
  const [customerSalesInvoiceEnum, setCustomerSalesInvoiceEnum] = useState<CustomerEnum[]>([]);
  const [defaultCustomerSalesOrderEnum, setDefaultCustomerSalesOrderEnum] = useState<CustomerEnum>();
  const [defaultCustomerBackOrderEnum, setDefaultCustomerBackOrderEnum] = useState<CustomerEnum>();
  const [defaultCustomerSalesShippingDocumentEnum, setDefaultCustomerSalesShippingDocumentEnum] = useState<CustomerEnum>();
  const [defaultCustomerSalesInvoiceEnum, setDefaultCustomerSalesInvoiceEnum] = useState<CustomerEnum>();
  const [dataEnumsLoaded, setDataEnumsLoaded] = useState(false);
  const [hideDetail, setHideDetail] = useState(true);
  const isEdit = !props.isEdit;

  const [openCustomerBankAccounts, setOpenCustomerBankAccounts] = useState(false);
  const loading = openCustomerBankAccounts && props.comboCustomerBankAccount.length === 0;


  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      GenericGetResource(`/customerbankaccounts/bycustomerid/${customerPayload.customer_id}`)
        .then((response) => {
          props.setComboCustomerBankAccount(response.data.data);
        })

    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!openCustomerBankAccounts) {
      props.setComboCustomerBankAccount([]);
    }
  }, [openCustomerBankAccounts]);

  useEffect(() => {
    GenericGetResource(`/customerbankaccounts/bycustomerid/${customerPayload.customer_id}`)
      .then((response) => {
        let combo: CustomerBankAccount[] = response.data.data;
        props.setDefaultPrimaryBankAccount(combo.find((item) => item.customer_bank_account_id === customerPayload.primary_bank_account));
      });
    let myPromises = [
      GenericGetResource(`/enums?filter=${utf8ToBase64(`enum_name=CustomerSalesOrderEnum`)}`),
      GenericGetResource(`/enums?filter=${utf8ToBase64(`enum_name=CustomerBackOrderEnum`)}`),
      GenericGetResource(`/enums?filter=${utf8ToBase64(`enum_name=CustomerSalesShippingDocumentEnum`)}`),
      GenericGetResource(`/enums?filter=${utf8ToBase64(`enum_name=CustomerSalesInvoiceEnum`)}`),
    ];

    Promise.allSettled(myPromises)
      .then((results) => {
        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            switch (index) {
              case 0:
                let myComboSalesOrders = result.value.data.data[0].enum_value;
                setCustomerSalesOrderEnum(myComboSalesOrders);
                setDefaultCustomerSalesOrderEnum(myComboSalesOrders.find((item: any) => item.name === customerPayload.group_salesorders_by));
                break;
              case 1:
                let myComboBackOrders = result.value.data.data[0].enum_value;
                setCustomerBackOrderEnum(myComboBackOrders);
                setDefaultCustomerBackOrderEnum(myComboBackOrders.find((item: any) => item.name === customerPayload.group_backorders_by));
                break;
              case 2:
                let myComboSalesShippingDocuments = result.value.data.data[0].enum_value;
                setCustomerSalesShippingDocumentEnum(myComboSalesShippingDocuments);
                setDefaultCustomerSalesShippingDocumentEnum(myComboSalesShippingDocuments.find((item: any) => item.name === customerPayload.group_shippingdocuments_by));
                break;
              case 3:
                let myComboSalesInvoices = result.value.data.data[0].enum_value;
                setCustomerSalesInvoiceEnum(myComboSalesInvoices);
                setDefaultCustomerSalesInvoiceEnum(myComboSalesInvoices.find((item: any) => item.name === customerPayload.group_invoices_by));
                break;
              default:
                break;
            }
          } else {
            showSnackBar(`Error in ${index}: ${result.reason}`, "error");
          }
        });

        setDataEnumsLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      <div className='d-flex justify-content-center mt-3'>

        <div className='custom-card-view w-75'>

          <Button variant='outlined' className="d-flex justify-content-between w-100" onClick={() => setHideDetail(!hideDetail)}>
            <Typography fontWeight={"Bold"} fontSize={22}>{customerPayload.customer_identifier} - {customerPayload.comercial_name ? customerPayload.comercial_name : customerPayload.comercial_name} - {customerPayload.customer_rfc}</Typography>
            <>
              {!hideDetail && <KeyboardArrowLeftIcon />}
              {hideDetail && <ExpandMoreIcon />}
            </>
          </Button>

          {hideDetail &&
            <Grow in={hideDetail}>
              <div>
                <div className="custom-card-row d-flex justify-content-between">

                  <div className='w-100'>

                    <div className='d-flex'>
                      <div className="w-50">
                        <Controller
                          name="customer_identifier"
                          control={control}
                          rules={{ maxLength: 100 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.customer_identifier")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2,
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.customer_identifier}
                              helperText={GetError(errors.customer_identifier?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="comercial_name"
                          control={control}
                          rules={{ required: true, maxLength: 50 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.comercial_name")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.comercial_name}
                              helperText={GetError(errors.comercial_name?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={isEdit}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='d-flex pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="business_name"
                          control={control}
                          rules={{ required: true, maxLength: 50 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.business_name")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.business_name}
                              helperText={GetError(errors.business_name?.type)}
                              size="small"
                              sx={{ paddingRight: 2 }}
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="customer_rfc"
                          control={control}
                          rules={{
                            required: true, maxLength: 13,
                            pattern: (isNotMexican) ? undefined : rfcRegex
                          }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.customer_rfc")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => {
                                field.onChange(event.target.value)
                              }}
                              error={!!errors.customer_rfc}
                              helperText={GetError(errors.customer_rfc?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='d-flex pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="customer_email"
                          control={control}
                          rules={{ maxLength: 1000, pattern: emailRegex }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.customer_email")}`}
                              ref={field.ref}
                              sx={{
                                paddingRight: 2,
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.customer_email}
                              helperText={GetError(errors.customer_email?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="customer_phone_number"
                          control={control}
                          rules={{ maxLength: 20 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.customer_phone_number")}`}
                              ref={field.ref}
                              type='number'
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.customer_phone_number}
                              helperText={GetError(errors.customer_phone_number?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }
                              }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='d-flex pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="customer_web"
                          control={control}
                          rules={{ maxLength: 100 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("customers.fields.customer_web")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2,
                              }}
                              value={field.value}
                              type='url'
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.customer_web}
                              helperText={GetError(errors.customer_web?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="language_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", }}
                              options={comboLanguages}
                              defaultValue={defaultLanguage}
                              getOptionLabel={(option: Language) => `${option.language_code} - ${option.language_description}`}
                              renderOption={(props, option: Language) => (
                                <div key={option.language_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.language_code} - {option.language_description}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.language_id === value.language_id}
                              onChange={(_, values) => {
                                field.onChange(values?.language_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("languages.title-view")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.language_id}
                                  helperText={GetError(errors.language_id?.type)}
                                  disabled={isEdit}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className='d-flex pt-3'>

                      <div className='w-50'>

                        <Controller
                          name="tax_regime"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("taxregimes.title-view")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.tax_regime}
                              helperText={GetError(errors.tax_regime?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                              disabled={true}
                              InputLabelProps={{ shrink: field.value ? true : false }}
                            />
                          }
                        />

                      </div>
                      <div className='w-50'>
                        <Controller
                          name="group_by"
                          control={control}
                          rules={{}}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboCustomers}
                              defaultValue={defaultGroupBy}
                              getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                              renderOption={(props, option: Customer) => (
                                <div key={option.customer_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                              onChange={(_, values) => {
                                field.onChange(values?.group_by || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("customers.fields.group_by")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.group_by}
                                  disabled={isEdit}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="d-flex w-100 pt-3">
                      <div className="w-50">
                        <Controller
                          name="primary_bank_account"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              open={openCustomerBankAccounts}
                              onOpen={() => {
                                setOpenCustomerBankAccounts(true);
                              }}
                              onClose={() => {
                                setOpenCustomerBankAccounts(false);
                              }}
                              defaultValue={props.defaultPrimaryBankAccount}
                              options={props.comboCustomerBankAccount}
                              getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}`}
                              renderOption={(props, option: CustomerBankAccount) => (
                                <div key={option.customer_bank_account_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.account_alias} - {option.bank_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.customer_bank_account_id === value.customer_bank_account_id}
                              onChange={(_, values) => {
                                field.onChange(values?.customer_bank_account_id || null);
                                let myCurrency: Currency = {
                                  currency_id: values?.currency_id,
                                  currency_code: values?.currency_code,
                                  currency_description: values?.currency_description,
                                  currency_symbol: values?.currency_symbol,
                                }
                                props.setDefaultCurrency(myCurrency)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("customers.fields.primary_bank_account")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.primary_bank_account}
                                  helperText={GetError(errors.primary_bank_account?.type)}
                                  sx={{ paddingRight: 2 }}
                                  disabled={isEdit}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className='my-2'>
                      <Divider />
                    </div>

                    {dataEnumsLoaded &&
                      <>
                        <div className='w-100'>
                          <Controller
                            name="group_salesorders_by"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={customerSalesOrderEnum}
                                getOptionLabel={(option) => t(option.translation)}
                                defaultValue={defaultCustomerSalesOrderEnum}
                                renderOption={(props, option) => (
                                  <div key={option.name}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {t(option.translation)}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                onChange={(_, values) => {
                                  field.onChange(values?.name || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("customers.fields.group_salesorders_by")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.group_salesorders_by}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>

                        <div className='w-100 pt-3'>

                          <Controller
                            name="group_backorders_by"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={customerBackOrderEnum}
                                getOptionLabel={(option) => t(option.translation)}
                                defaultValue={defaultCustomerBackOrderEnum}
                                renderOption={(props, option) => (
                                  <div key={option.name}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {t(option.translation)}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                onChange={(_, values) => {
                                  field.onChange(values?.name || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("customers.fields.group_backorders_by")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.group_backorders_by}
                                  />
                                )}
                              />
                            )}
                          />

                        </div>

                        <div className='w-100 pt-3'>

                          <Controller
                            name="group_shippingdocuments_by"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={customerSalesShippingDocumentEnum}
                                getOptionLabel={(option) => t(option.translation)}
                                defaultValue={defaultCustomerSalesShippingDocumentEnum}
                                renderOption={(props, option) => (
                                  <div key={option.name}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {t(option.translation)}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                onChange={(_, values) => {
                                  field.onChange(values?.name || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("customers.fields.group_shippingdocuments_by")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.group_shippingdocuments_by}
                                  />
                                )}
                              />
                            )}
                          />

                        </div>

                        <div className='d-flex pt-3'>

                          <Controller
                            name="group_invoices_by"
                            control={control}
                            rules={{}}
                            render={({ field }) => (
                              <Autocomplete
                                ref={field.ref}
                                size="small"
                                sx={{ width: "100%" }}
                                options={customerSalesInvoiceEnum}
                                getOptionLabel={(option) => t(option.translation)}
                                defaultValue={defaultCustomerSalesInvoiceEnum}
                                renderOption={(props, option) => (
                                  <div key={option.name}>
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      {t(option.translation)}
                                    </Box>
                                  </div>
                                )}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                onChange={(_, values) => {
                                  field.onChange(values?.name || null)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("customers.fields.group_invoices_by")}`}
                                    variant="filled"
                                    value={field.value}
                                    error={!!errors.group_invoices_by}
                                  />
                                )}
                              />
                            )}
                          />

                        </div>
                      </>
                    }
                    <div className='d-flex pt-3'>
                      <div className='w-50'>

                      </div>
                    </div>

                  </div>

                  <Avatar className='mx-4' sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 190, width: 190 }} variant='rounded' alt={""}>
                    <Button component="label">
                      {imageLoaded &&
                        <ImageDataComponent url={imageFile.dataURL} />
                      }
                      {!imageLoaded &&
                        <img alt='profile-photo' width={190} height={190} src={require("../../../../../assets/img/profile.webp")} />
                      }
                      <input type="file" accept="image/*" hidden
                        onChange={async (e) => {
                          await DecodeFileToBase64(e).then(async (response) => {
                            onPostImage(response);
                          });
                        }
                        }
                      />
                    </Button>
                  </Avatar>

                </div>

                {!isEdit &&
                  <div className='d-flex flex-row-reverse mt-4'>
                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                  </div>
                }

              </div>

            </Grow>
          }

        </div>
      </div>
      <SnackbarComponent />
    </>
  )
}
