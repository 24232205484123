import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { StageButton } from '../../../../../../theme/projects/Buttons/stageButton';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import { TableProjectTask } from '../ProjectTask/table';
import { ProjectStage } from '../../../../../../interfaces/Projects/Catalogs/projectStages';
import { ProjectExtraField } from '../../../../../../interfaces/Projects/Catalogs/projectExtraFields';
import { Parameter } from '../../../../../../interfaces/Commons/parameters';
import GenericPromises from '../../../../../../api/GenericPromises';
import useSnackBar from '../../../../../../components/Commons/SnackBar/useSnackBar';
import { useTranslation } from 'react-i18next';
import { DialogEntity } from '../../../../../../components/Dialogs/DialogEntity';
import { Header } from '../../../../../../components/Header';
import { ConfirmChangeProjectStageStatusDialog } from './dialogs/confirmChangeProjectStageStatusDialog';
import { ProjectTask } from '../../../../../../interfaces/Projects/Catalogs/projectTasks';
import { FieldType } from '../../../../../../interfaces/Projects/Catalogs/fieldTypes';

interface StageItemProps {
  isEdit: boolean,
  stage: ProjectStage;
  projectExtraFieldsData: ProjectExtraField[];
  parameters: Parameter[];
  color: number[] | undefined;
  setSelectedStage: React.Dispatch<React.SetStateAction<ProjectStage | undefined>>;
  setOpenDialogUpdateStage: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshTasks: Dispatch<SetStateAction<boolean>>;
  comboFieldTypes: FieldType[];
}

export const StageItem = ({
  isEdit,
  stage,
  projectExtraFieldsData,
  parameters,
  color,
  setSelectedStage,
  setOpenDialogUpdateStage,
  setRefreshTasks,
  comboFieldTypes,
}: StageItemProps) => {

  const {
    GenericPutResource,
  } = GenericPromises();
  const [onChecked, setOnChecked] = useState<boolean>(stage.is_done);
  const [loadingPut, setLoadingPut] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [t] = useTranslation("global");
  const [allTasksCompleted, setAllTasksCompleted] = useState(false)
  const [dialogConfirmChangeStageState, setDialogConfirmChangeStageState] = useState(false);
  const [taskUpdated, setTaskUpdated] = useState<ProjectTask[]>();
  const [resetProjecTasks, setResetProjecTasks] = useState(false)

  const handleStageCompletion = () => {
    setLoadingPut(true);
    let myData = {
      is_done: !onChecked
    };
    GenericPutResource(
      `/projectstages/isdone/${stage.project_stage_id}`,
      myData
    )
      .then((response) => {
        setTaskUpdated(response.data);
        setOnChecked(!onChecked)
        showSnackBar(t("projectstages.messages.state_updated"), "success");
      })
      .catch((error) => {
        if (error.response.data.message) {
          showSnackBar(t(error.response.data.message), "info");
        } else {
          showSnackBar(error.message, "error");
        }
      })
      .finally(() => {
        setLoadingPut(false);
        setDialogConfirmChangeStageState(false);
      });
  }


  return (
    <>
      <Grid
        item
        key={stage.project_stage_id}
        xs={8}
        md={5}
        style={{ flexShrink: 0 }}
      >
        <StageButton
          color={color}
          onClick={() => {
            setSelectedStage(stage);
            setOpenDialogUpdateStage(true);
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={onChecked}
                onChange={(e) => {
                  e.stopPropagation();
                  setOpenDialogUpdateStage(false);
                  setDialogConfirmChangeStageState(true)
                }}
                icon={<CircleOutlined />}
                checkedIcon={<CheckCircle />}
                sx={{
                  color: "primary.main",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label={<></>}
            disabled={loadingPut}
          />
          {stage.project_stage_name}
        </StageButton>
        <TableProjectTask
          project_stage_id={stage.project_stage_id}
          project_id={stage.project_id}
          projectExtraFieldsData={projectExtraFieldsData}
          parameters={parameters}
          isDoneProjectStage={onChecked}
          allTasksCompleted={allTasksCompleted}
          setDialogConfirmChangeStageState={setDialogConfirmChangeStageState}
          taskUpdated={taskUpdated}
          resetProjecTasks={resetProjecTasks}
          setResetProjecTasks={setResetProjecTasks}
          comboFieldTypes={comboFieldTypes}
        />
      </Grid>
      <DialogEntity
        open={dialogConfirmChangeStageState}
        title={<Header title={t("projectstages.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmChangeProjectStageStatusDialog
            isEdit={isEdit}
            loadingPut={loadingPut}
            setDialogConfirmChangeStageState={setDialogConfirmChangeStageState}
            handleStageCompletion={handleStageCompletion}
            stageName={stage.project_stage_name}
          />
        }
      />
      <SnackbarComponent />
    </>
  )
}
