"use strict";

import { create } from "zustand";

export const useMenuStore = create((set) => ({
    menuState: undefined, 
    updateMenuState: (menuState: any) => set(() => ({ menuState })),
    resetMenuState: () => set({ menu: undefined })
}));


// Ejemplo para authContext.tsx
export const useGroupsStore = create((set) => ({
    menuState: undefined, 
    groupsState: undefined, 
    updateGroupsState: (groupsState: any) => set(() => ({ groupsState })),
    resetGroupsState: () => set({ menu: undefined }),
    updateMenuState: (menuState: any) => set(() => ({ menuState })),
    resetMenuState: () => set({ menu: undefined })
}));

export const useMenuStorExample = create((set) => ({
    menuState: undefined, 
    updateMenuState: (menuState: any) => set(() => ({ menuState })),
    resetMenuState: () => set({ menu: undefined })
}));