import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { useFiles } from "../../../hooks/useFiles";
import GenericPromises from "../../../api/GenericPromises";
import { useEffect, useState } from "react";
import { Supplier } from "../../../interfaces/Purchases/Catalogs/suppliers";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Autocomplete, Avatar, Box, Button, CircularProgress, Grow, TextField, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller } from "react-hook-form";
import { emailRegex, rfcRegex } from "../../../constants/Regex";
import { Language } from "../../../interfaces/Commons/languages";
import { TaxRegime } from "../../../interfaces/Sales/Catalogs/taxRegimes";
import { Divider } from "../../../components/Commons/Divider";
import { ImageDataComponent } from "../../../components/Images/ImageData";
import { PrimaryButton } from "../../../theme/buttons";
import { SupplierBankAccount } from "../../../interfaces/Purchases/Catalogs/supplierBankAccounts";
import { Currency } from "../../../interfaces/Commons/currencies";

export const UpdateSupplier = ({ ...props }) => {
  const {
    supplierPayload,
    loadingPost,
    imageFile,
    imageLoaded,
    comboSuppliers,
    comboTaxRegimes,
    control,
    errors,
    onPostImage,
    defaultTaxRegime,
    supplier,
    isNotMexican,
    comboLanguages,
    defaultLanguage
  } = props;
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const { DecodeFileToBase64 } = useFiles();
  const { GenericGetResource } = GenericPromises();
  const [defaultGroupBy, setDefaultGroupBy] = useState<Supplier>();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [hideDetail, setHideDetail] = useState(true);
  const isEdit = !props.isEdit;
  const [openSupplierBankAccounts, setOpenSupplierBankAccounts] = useState(false);
  const loading = openSupplierBankAccounts && props.comboSupplierBankAccount.length === 0;

  const genericCombo = [
    { id: 1, name: "Cliente" },
    { id: 2, name: "Artículo" }
  ];

  const purchaseordersCombo = [
    ...genericCombo,
    { id: 3, name: "Cotización" }
  ];

  const documentsCombo = [
    ...genericCombo,
    { id: 4, name: "Pedido" }
  ];

  const invoiceCombo = [
    ...genericCombo,
    { id: 5, name: "Documento de envío" }
  ];

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      GenericGetResource(`/supplierbankaccounts/bysupplierid/${supplierPayload.supplier_id}`)
        .then((response) => {
          props.setComboSupplierBankAccount(response.data.data);
        })

    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!openSupplierBankAccounts) {
      props.setComboSupplierBankAccount([]);
    }
  }, [openSupplierBankAccounts]);

  useEffect(() => {
    GenericGetResource(`/supplierbankaccounts/bysupplierid/${supplierPayload.supplier_id}`)
      .then((response) => {
        let combo: SupplierBankAccount[] = response.data.data;
        props.setDefaultPrimaryBankAccount(combo.find((item) => item.supplier_bank_account_id === supplierPayload.primary_bank_account));
      })
  }, []);

  return (
    <>
      <div className='d-flex justify-content-center mt-3'>

        <div className='custom-card-view w-75'>

          <Button variant='outlined' className="d-flex justify-content-between w-100" onClick={() => setHideDetail(!hideDetail)}>
            <Typography fontWeight={"Bold"} fontSize={22}>{supplierPayload.business_name} - {supplierPayload.supplier_rfc}</Typography>
            <>
              {!hideDetail && <KeyboardArrowLeftIcon />}
              {hideDetail && <ExpandMoreIcon />}
            </>
          </Button>

          {hideDetail &&
            <Grow in={hideDetail}>
              <div>
                <div className="custom-card-row d-flex justify-content-between">

                  <div className='w-100'>

                    <div className='d-flex'>

                      <div className='w-50'>
                        <Controller
                          name="comercial_name"
                          control={control}
                          rules={{ maxLength: 50 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("suppliers.fields.comercial_name")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2,
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.comercial_name}
                              helperText={GetError(errors.comercial_name?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={isEdit}
                            />
                          }
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="business_name"
                          control={control}
                          rules={{ required: true, maxLength: 50 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("suppliers.fields.business_name")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.business_name}
                              helperText={GetError(errors.business_name?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='d-flex pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="supplier_rfc"
                          control={control}
                          rules={{
                            required: true, maxLength: 13,
                            pattern: (isNotMexican) ? undefined : rfcRegex
                          }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("suppliers.fields.supplier_rfc")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => {
                                field.onChange(event.target.value)
                              }}
                              error={!!errors.supplier_rfc}
                              helperText={GetError(errors.supplier_rfc?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="supplier_email"
                          control={control}
                          rules={{ maxLength: 1000, pattern: emailRegex }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("suppliers.fields.supplier_email")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.supplier_email}
                              helperText={GetError(errors.supplier_email?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='d-flex pt-3'>
                      <div className='w-50'>
                        <Controller
                          name="supplier_phone_number"
                          control={control}
                          rules={{ maxLength: 20 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("suppliers.fields.supplier_phone_number")}`}
                              ref={field.ref}
                              type='number'
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.supplier_phone_number}
                              helperText={GetError(errors.supplier_phone_number?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{
                                paddingRight: 2,
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }
                              }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="supplier_web"
                          control={control}
                          rules={{ maxLength: 100 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("suppliers.fields.supplier_web")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              value={field.value}
                              type='url'
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.supplier_web}
                              helperText={GetError(errors.supplier?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                            />
                          }
                        />
                      </div>

                    </div>

                    <div className='d-flex pt-3'>

                      <div className='w-50'>
                        <Controller
                          name="language_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%", paddingRight: 2 }}
                              options={comboLanguages}
                              defaultValue={defaultLanguage}
                              getOptionLabel={(option: Language) => `${option.language_code} - ${option.language_description}`}
                              renderOption={(props, option: Language) => (
                                <div key={option.language_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.language_code} - {option.language_description}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.language_id === value.language_id}
                              onChange={(_, values) => {
                                field.onChange(values?.language_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("languages.title-view")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.language_id}
                                  helperText={GetError(errors.language_id?.type)}
                                  disabled={isEdit}
                                />
                              )}
                            />
                          )}
                        />
                      </div>

                      <div className='w-50'>
                        <Controller
                          name="tax_regime"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("taxregimes.title-view")}`}
                              ref={field.ref}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.tax_regime}
                              helperText={GetError(errors.tax_regime?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              disabled={true}
                              InputLabelProps={{ shrink: field.value ? true : false }}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className='d-flex pt-3'>
                      <div className="w-50">
                        <Controller
                          name="primary_bank_account"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) =>
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              open={openSupplierBankAccounts}
                              onOpen={() => {
                                setOpenSupplierBankAccounts(true);
                              }}
                              onClose={() => {
                                setOpenSupplierBankAccounts(false);
                              }}
                              defaultValue={props.defaultPrimaryBankAccount}
                              options={props.comboSupplierBankAccount}
                              getOptionLabel={(option) => `${option.account_alias} - ${option.bank_name}`}
                              renderOption={(props, option: SupplierBankAccount) => (
                                <div key={option.supplier_bank_account_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.account_alias} - {option.bank_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.supplier_bank_account_id === value.supplier_bank_account_id}
                              onChange={(_, values) => {
                                field.onChange(values?.supplier_bank_account_id || null);
                                let myCurrency: Currency = {
                                  currency_id: values?.currency_id,
                                  currency_code: values?.currency_code,
                                  currency_description: values?.currency_description,
                                  currency_symbol: values?.currency_symbol,
                                }
                                props.setDefaultCurrency(myCurrency)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("suppliers.fields.primary_bank_account")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.primary_bank_account}
                                  helperText={GetError(errors.primary_bank_account?.type)}
                                  sx={{ paddingRight: 2 }}
                                  disabled={isEdit}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="group_by"
                          control={control}
                          rules={{}}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboSuppliers}
                              defaultValue={defaultGroupBy}
                              getOptionLabel={(option) => option.business_name ?? ''}
                              renderOption={(props, option: Supplier) => (
                                <div key={option.supplier_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.business_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.supplier_id === value.supplier_id}
                              onChange={(_, values) => {
                                field.onChange(values?.group_by || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${t("suppliers.fields.group_by")}`}
                                  variant="filled"
                                  value={field.value}
                                  error={!!errors.group_by}
                                  disabled={isEdit}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className='my-2'>
                      <Divider />
                    </div>

                    <div className='w-100'>
                      <Controller
                        name="group_salesorders_by"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            options={purchaseordersCombo}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                              <div key={option.id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, values) => {
                              field.onChange(values?.id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("suppliers.fields.group_purchaseorders_by")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.group_purchaseorders_by}
                              />
                            )}
                          />
                        )}
                      />
                    </div>

                    <div className='w-100 pt-3'>

                      <Controller
                        name="group_shippingdocuments_by"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            options={documentsCombo}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                              <div key={option.id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, values) => {
                              field.onChange(values?.id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("suppliers.fields.group_shippingdocuments_by")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.group_shippingdocuments_by}
                              />
                            )}
                          />
                        )}
                      />

                    </div>

                    <div className='d-flex pt-3'>

                      <Controller
                        name="group_invoices_by"
                        control={control}
                        rules={{}}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            options={invoiceCombo}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                              <div key={option.id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, values) => {
                              field.onChange(values?.id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("suppliers.fields.group_invoices_by")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.group_invoices_by}
                              />
                            )}
                          />
                        )}
                      />

                    </div>

                  </div>

                  <Avatar className='mx-4' sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 190, width: 190 }} variant='rounded' alt={""}>
                    <Button component="label">
                      {imageLoaded &&
                        <ImageDataComponent url={imageFile.dataURL} />
                      }
                      {!imageLoaded &&
                        <img alt='profile-photo' width={190} height={190} src={require("../../../assets/img/profile.webp")} />
                      }
                      <input type="file" accept="image/*" hidden
                        onChange={async (e) => {
                          await DecodeFileToBase64(e).then(async (response) => {
                            onPostImage(response);
                          });
                        }
                        }
                      />
                    </Button>
                  </Avatar>

                </div>

                {!isEdit &&
                  <div className='d-flex flex-row-reverse mt-4'>
                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                  </div>
                }

              </div>

            </Grow>
          }

        </div>
      </div>
      <SnackbarComponent />
    </>
  )
}
