import { Avatar, Button, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { useFiles } from "../../../../hooks/useFiles";
import { FileSQL } from "../../../../interfaces/Commons/files";
import { Company } from "../../../../interfaces/Security/companies";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { Divider } from "../../../../components/Commons/Divider";
import ColorPicker, { PrimaryButton } from "../../../../theme/buttons";

type DesignProps = {
    companyPayload: Company | undefined,
    setCompanyLocal: Dispatch<SetStateAction<Company | undefined>>,
    isEdit: boolean,
}

export const Desing = ({
    companyPayload,
    isEdit,
    setCompanyLocal,
}: DesignProps) => {
    const [t] = useTranslation("global");
    const { GenericPostResource, GenericPutResource, GenericGetResource, GenericDeleteResource } = GenericPromises();
    const { DecodeFileSQLToBase64 } = useFiles();
    const [imageLoadedIcon, setimageLoadedIcon] = useState<FileSQL>({});
    const [imageLoadedTopBar, setImageLoadedTopBar] = useState<FileSQL>({});
    const [imageLoadedRpt, setImageLoadedRpt] = useState<FileSQL>({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changedPhoto, setChangedPhoto] = useState(false);
    const [colorPrimary, setColorPrimary] = useState("")
    const [colorSecondary, setColorSecondary] = useState("")
    const [loadingPost, setLoadingPost] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const limitsImage = {
        //alto x ancho
        iconMaxHeight: 300, iconMaxWidth: 300,
        iconMinHeight: 64, iconMinWidth: 64,

        topBarmaxHeight: 4000, topBarMaxWidth: 5000,
        topBarMinHeight: 400, topBarMinWidth: 1000,

        rptMaxHeight: 4000, rptMaxWidth: 5000,
        rptMinHeight: 400, rptMinWidth: 1000,
    };

    const filterImage = async (file: globalThis.File, maxWidth: number, maxHeight: number, minxWidth: number, minHeight: number,) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                if (image.width <= maxWidth && image.height <= maxHeight && image.width >= minxWidth && image.height >= minHeight) {
                    resolve(true);
                } else {
                    showSnackBar(t("companies.dialog.nofile"), "error");
                    resolve(false);
                }
            };
            image.onerror = () => {
                reject(t("companies.dialog.nofile"));
            };
        });
    }

    const onPostColor = () => {
        setLoadingPost(true)
        let myData = {
            primary_color: colorPrimary,
            secondary_color: colorSecondary,
        }
        GenericPutResource(`/companies/${companyPayload?.company_id ?? 0}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success")
                setLoadingPost(false);
            }).catch((error) => {
                showSnackBar(error.message, "error");
                setLoadingPost(false);
            });
    }

    const onPostImage = (image: FileSQL, type: string) => {
        let myData = {
            file_name: image.file_name,
            type_of_file: image.type_of_file,
            data_file: image.data_file,
        }
        GenericPostResource(`/filessql`, myData)
            .then(async (response) => {
                await UpdateIdPhotoCompany(response.data, type);
                setChangedPhoto((prev) => !prev);
                showSnackBar(t("generic.snackbar.update_photo"), "success");
            })
            .catch((error) => {
                showSnackBar(error.message, 'error');
            })
    }

    const UpdateIdPhotoCompany = async (image: FileSQL, type: string) => {
        // diccionario para imagenes o accesos entidad Company
        let myDictionary: { [key: string]: string } = {
            "Icon": "icon_image",
            "TopBar": "top_bar_image",
            "Rpt": "rpt_image"
        };
        let key = myDictionary[type as string] as keyof Company;
        let myData = {
            [key]: image.file_sql_id ?? null,
        }
        if (!companyPayload?.[key]) {
            // si exite un id para esa foto eliminarla y actualizar registro en company
            await GenericPutResource(`/companies/${companyPayload?.company_id ?? 0}`, myData)
                .then(async () => {
                    await setCompanyLocal((prev) => {
                        if (!prev) { return undefined; }
                        return {
                            ...prev,
                            [key]: image.file_sql_id
                        };
                    });
                });
        }
        else {
            await GenericDeleteResource(`/filessql/${companyPayload?.[key]}`)
                .then(async () => {
                    await GenericPutResource(`/companies/${companyPayload?.company_id ?? 0}`, myData)
                        .then(async () => {
                            await setCompanyLocal((prev) => {
                                if (!prev) { return undefined; }
                                return {
                                    ...prev,
                                    [key]: image.file_sql_id
                                };
                            });
                        });
                })
                .catch((error) => {
                    showSnackBar(error.message, 'error');
                });
        }

    }

    useEffect(() => {
        GenericGetResource(`/companies/${companyPayload?.company_id}`)
            .then(async (responseCompany) => {
                // todo: get files sql if exists with id
                if (responseCompany.data.icon_image) {
                    await GenericGetResource(`/filessql/${responseCompany.data.icon_image}`)
                        .then((responseIcon) => {
                            setimageLoadedIcon(responseIcon.data);
                        });
                }
                if (responseCompany.data.top_bar_image) {
                    await GenericGetResource(`/filessql/${responseCompany.data.top_bar_image}`)
                        .then((responseTopBar) => {
                            setImageLoadedTopBar(responseTopBar.data);
                        });
                }
                if (responseCompany.data.rpt_image) {
                    await GenericGetResource(`/filessql/${responseCompany.data.rpt_image}`)
                        .then((responseRpt) => {
                            setImageLoadedRpt(responseRpt.data);
                        });
                }
                setColorPrimary(responseCompany.data.primary_color);
                setColorSecondary(responseCompany.data.secondary_color);
                setDataLoaded(true);
            })
            .catch((error) => {
                showSnackBar(error.message, "error");
            });
    }, [changedPhoto]);

    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>
                        <Typography variant="h6">
                            {t("companies.configuration.imagen_companies")}
                        </Typography>
                        <Divider />
                        <div className="d-flex justify-content-between">
                            <div className='pb-2'>
                                <Typography sx={{ ml: 2 }}>{t("companies.fields.icon_image")}</Typography>
                                <Avatar className='mx-3' sx={{ bgcolor: '#eeeeee', border: '1px solid grey', height: 60, width: 60 }} variant='rounded' alt={""}>
                                    <Button component="label" disabled={!isEdit}>
                                        {!imageLoadedIcon.file_sql_id &&
                                            <img alt='icon' width={"100%"} height={"100%"} src={require("../../../../assets/img/profile.webp")} />
                                        }
                                        {imageLoadedIcon.file_sql_id &&
                                            <img width={"100%"} height={"100%"} alt="" src={imageLoadedIcon.data_file} />
                                        }
                                        <input type="file" accept=".ico" hidden
                                            onChange={async (e) => {
                                                let file = e.target.files;
                                                if (file && file.length > 0 && await filterImage(file[0], limitsImage.iconMaxWidth, limitsImage.iconMaxHeight, limitsImage.iconMinWidth, limitsImage.iconMinHeight)) {
                                                    await DecodeFileSQLToBase64(e).then(async (response) => {
                                                        onPostImage(response, "Icon");
                                                    });
                                                }
                                            }
                                            }
                                        />
                                    </Button>
                                </Avatar>
                                <Typography sx={{ ml: 2, fontSize: 10 }}>{t("companies.desing.limit_size")}: {limitsImage.iconMinHeight} x {limitsImage.iconMinWidth}px - {limitsImage.iconMaxHeight} x {limitsImage.iconMaxWidth}px</Typography>
                            </div>
                            <div className='pb-2'>
                                <Typography sx={{ ml: 2 }}>{t("companies.fields.top_bar_image")}</Typography>
                                <Avatar className='mx-3' sx={{ bgcolor: '#eeeeee', border: '1px solid grey', height: 90, width: 300 }} variant='rounded' alt={""}>
                                    <Button component="label" disabled={!isEdit} onClick={() => { }}>
                                        {!imageLoadedTopBar.data_file &&
                                            <img alt='logo' width={"100%"} height={"100%"} src={require("../../../../assets/img/profile.webp")} />
                                        }
                                        {imageLoadedTopBar.data_file &&
                                            <img width={"100%"} height={"100%"} alt="" src={imageLoadedTopBar.data_file} />
                                        }
                                        <input type="file" accept="image/*" hidden
                                            onChange={async (e) => {
                                                let file = e.target.files;
                                                if (file && file.length > 0 && await filterImage(file[0], limitsImage.topBarMaxWidth, limitsImage.topBarmaxHeight, limitsImage.topBarMinWidth, limitsImage.topBarMinHeight)) {
                                                    await DecodeFileSQLToBase64(e)
                                                        .then(async (response) => {
                                                            onPostImage(response, "TopBar");
                                                        });
                                                }
                                            }
                                            }
                                        />
                                    </Button>
                                </Avatar>
                                <Typography sx={{ ml: 2, fontSize: 10 }}>{t("companies.desing.limit_size")}: {limitsImage.topBarMinHeight} x {limitsImage.topBarMinWidth}px - {limitsImage.topBarmaxHeight} x {limitsImage.topBarMaxWidth}px</Typography>
                            </div>
                            <div className='pb-2'>
                                <Typography sx={{ ml: 2 }}>{t("companies.fields.rpt_image")}</Typography>
                                <Avatar className='mx-3' sx={{ bgcolor: '#eeeeee', border: '1px solid grey', height: 90, width: 300 }} variant='rounded' alt={""}>
                                    <Button component="label" disabled={!isEdit}>
                                        {!imageLoadedRpt.data_file &&
                                            <img alt='report' width={"100%"} height={"100%"} src={require("../../../../assets/img/profile.webp")} />
                                        }
                                        {imageLoadedRpt.data_file &&
                                            <img width={"100%"} height={"100%"} alt="" src={imageLoadedRpt.data_file} />
                                        }
                                        <input type="file" accept="image/*" hidden
                                            onChange={async (e) => {
                                                let file = e.target.files;
                                                if (file && file.length > 0 && await filterImage(file[0], limitsImage.rptMaxWidth, limitsImage.rptMaxHeight, limitsImage.rptMinWidth, limitsImage.rptMinHeight)) {
                                                    await DecodeFileSQLToBase64(e).then(async (response) => {
                                                        onPostImage(response, "Rpt");
                                                    });
                                                }
                                            }
                                            }
                                        />
                                    </Button>
                                </Avatar>
                                <Typography sx={{ ml: 2, fontSize: 10 }}>{t("companies.desing.limit_size")}: {limitsImage.rptMinHeight} x {limitsImage.rptMinWidth}px - {limitsImage.rptMaxHeight} x {limitsImage.rptMaxWidth}px</Typography>
                            </div>
                        </div>
                        <Typography variant="h6">
                            {t("companies.configuration.color_company")}
                        </Typography>
                        <Divider />
                        <div className="d-flex justify-content-between">
                            <div className="w-50">
                                <Typography variant="body1" sx={{ marginTop: 0.5 }} gutterBottom>
                                    <b>{t("companies.desing.color_primary")}</b>
                                </Typography>
                                <div className="ms-2">
                                    <ColorPicker
                                        color={colorPrimary}
                                        setColor={setColorPrimary}
                                    />
                                </div>
                            </div>
                            <div className="w-50">
                                <Typography variant="body1" sx={{ marginTop: 0.5 }} gutterBottom>
                                    <b>{t("companies.desing.color_secondary")}</b>
                                </Typography>
                                <div className="ms-2">
                                    <ColorPicker
                                        color={colorSecondary}
                                        setColor={setColorSecondary}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row-reverse mt-2'>
                            <PrimaryButton
                                type='submit'
                                variant='outlined'
                                className='m-1'
                                onClick={onPostColor}
                                disabled={loadingPost}
                            >
                                {t("generic.buttons.update")}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}