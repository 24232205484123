import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Project } from "../../../interfaces/Projects/projects";
import GenericPromises from "../../../api/GenericPromises";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Grid,
  Grow,
  TextField,
  Typography,
} from "@mui/material";
import { ProjectType } from "../../../interfaces/Projects/Catalogs/projectTypes";
import { Customer } from "../../../interfaces/Sales/Catalogs/Customers/customers";
import { User } from "../../../interfaces/Security/users";
import { myStylePickers, PrimaryButton } from "../../../theme/buttons";
import { useDates } from "../../../hooks/useDates";
import dayjs from "dayjs";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormatNumber } from "../../../hooks/useFormatNumber";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ProjectExtraFieldsValues } from "./projectExtraFieldsValues";
import { ProjectExtraField } from "../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Menuitem } from "../../../interfaces/Security/menu";
import { AuthContext } from "../../../context/AuthContext";
import { DialogEntity } from "../../../components/Dialogs/DialogEntity";
import { ConfirmDialog } from "./components/dialogs/confirmDialog";
import { Header } from "../../../components/Header";
import { DatePicker } from "@mui/x-date-pickers";
import { usePermissions } from "../../../hooks/usePermissions";

type UpdateProjectProps = {
  isEditProps: boolean;
  projectPayload: Project;
  totalSales: number;
  totalPurchases: number;
  totalAmount: number;
  projectExtraFieldsData: ProjectExtraField[];
  setProjectExtraFieldsData: Dispatch<SetStateAction<ProjectExtraField[]>>;
  resourceProjectExtraFields: Menuitem | undefined;
  resourceClosedProject: Menuitem | undefined;
  setIsClosedProject: Dispatch<SetStateAction<boolean>>;
  isClosedProject: boolean;
  setProjectUserClosed: Dispatch<SetStateAction<String | undefined>>;
  setProjectUserDateClosed: Dispatch<SetStateAction<Date | undefined>>;
  isUpdateProjectExtraFields: boolean;
  setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>;
  showSnackBar: (message: string, severity: AlertColor) => void;
  dialogConfirmCloseProject: boolean;
  setDialogConfirmCloseProject: Dispatch<SetStateAction<boolean>>;

};

interface UpdateProjectHandle {
  onCloseProject?: () => void;
}

export const UpdateProject = forwardRef<UpdateProjectHandle, UpdateProjectProps>(({
  isEditProps,
  projectPayload,
  totalSales,
  totalPurchases,
  totalAmount,
  projectExtraFieldsData,
  setProjectExtraFieldsData,
  resourceProjectExtraFields,
  setIsClosedProject,
  isClosedProject,
  setProjectUserClosed,
  setProjectUserDateClosed,
  isUpdateProjectExtraFields,
  setIsUpdateProjectExtraFields,
  showSnackBar,
  dialogConfirmCloseProject,
  setDialogConfirmCloseProject
}: UpdateProjectProps, ref) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericGetResource,
    GenericGetResourceGeneric,
    GenericPutAction,
  } = GenericPromises();
  const { GetError } = FormsErrors();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { GetResourceByUrl } = usePermissions();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hideDetail, setHideDetail] = useState(true);
  const isEdit = !isEditProps;
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [defaultProjectType, setDefaultProjectType] = useState<ProjectType>();
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [defaultCustomer, setDefaultCustomer] = useState<Customer>();
  const [comboUsers, setComboUsers] = useState<User[]>([]);
  const [defaultUser, setDefaultUser] = useState<User>();
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [defaultStartDate, setDefaultStartDate] = useState<Date>();
  const [defaultEndDate, setDefaultEndDate] = useState<Date>();
  const { TimeConverter } = useDates();
  const [loadEndDateFirstTime, setLoadEndDateFirstTime] = useState(true);
  const currencyTarget = "MXN";
  const [originalProjectName, setOriginalProjectName] = useState<string | undefined>();
  const [showBalancePermission, setShowBalancePermission] = useState<Menuitem>();

  const { user } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
    trigger,
    getValues
  } = useForm<Project>({
    defaultValues: {
      project_type_id: undefined,
      customer_id: undefined,
      project_manager_id: undefined,
      project_name: undefined,
      project_description: undefined,
      project_comments: undefined,
      project_start_date: undefined,
      project_end_date: undefined,
      MS_project_id: undefined,
      MS_etag: undefined,
      project_is_closed: undefined,
      project_user_closed: undefined,
      project_user_date_closed: undefined,
    },
  });

  const onSubmit: SubmitHandler<Project> = (data) => onPut(data);

  const onPut = (data: Project) => {
    let myData = {
      project_type_id: data.project_type_id,
      customer_id: data.customer_id,
      project_manager_id: data.project_manager_id,
      project_name: data.project_name,
      project_description: data.project_description ?? null,
      project_comments: data.project_comments ?? null,
      project_start_date: data.project_start_date,
      project_end_date: data.project_end_date,
      MS_project_id: data.MS_project_id ?? null,
      MS_etag: data.MS_etag ?? null,
      project_is_closed: data.project_is_closed ?? null,
      project_user_closed: data.project_user_closed ?? null,
      project_user_date_closed: data.project_user_date_closed ?? null,
    };

    setLoadingPost(true);
    GenericPutResource(`/projects/${projectPayload.project_id}`, myData)
      .then((response) => {
        setOriginalProjectName(data.project_name);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(t(error.response.data), "error");
        setValue("project_name", originalProjectName);
        setLoadingPost(false);
      });
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const onCloseProject = () => {
    trigger().then(async (responseTrigger) => {
      if (responseTrigger) {
        setValue("project_is_closed", true);
        await GenericPutAction(
          `/projects/isclosed/${projectPayload?.project_id}`,
          {
            project_is_closed: true,
            project_user_closed: user?.user_name,
            project_user_date_closed: TimeConverter(new Date().toISOString()),
          },
          false
        )
          .then(async (response) => {
            setIsClosedProject(response.data.project_is_closed);
            setProjectUserClosed(response.data.project_user_closed);
            setProjectUserDateClosed(response.data.project_user_date_closed);
            reset(
              {
                project_is_closed: true,
                project_user_closed: response.data.project_user_closed,
                project_user_date_closed:
                  response.data.project_user_date_closed,
              },
              { keepValues: true }
            );
            setDialogConfirmCloseProject(false);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      }
    });
  };

  useImperativeHandle(ref, () => ({
    onCloseProject
  }));

  useEffect(() => {
    if (!loadEndDateFirstTime) {
      setDefaultEndDate(undefined);
      setValue("project_end_date", undefined);
      reset({ project_end_date: undefined });
      return;
    }
    setValue("project_end_date", projectPayload?.project_end_date);
  }, [defaultStartDate]);

  useEffect(() => { }, [
    totalSales,
    totalPurchases,
    totalAmount,
    isClosedProject,
    isUpdateProjectExtraFields,
  ]);

  useEffect(() => {

    GetResourceByUrl(`/projects/view/showbalance`)
      .then((responsePermission) => {
        setShowBalancePermission(responsePermission);
      });

    let myPromises = [
      GenericGetResource("/projecttypes"),
      GenericGetResource("/customers"),
      GenericGetResourceGeneric("/users", "/gusers"),
    ];

    Promise.all(myPromises)
      .then((responses) => {
        setComboProjectTypes(responses[0].data.data);
        setComboCustomers(responses[1].data.data);
        setComboUsers(responses[2].data.data);

        if (projectPayload.project_is_closed) {
          setIsClosedProject((prev) => true);
        }

        if (projectPayload.project_user_closed) {
          setProjectUserClosed((prev) => projectPayload.project_user_closed);
        }

        if (projectPayload.project_user_date_closed) {
          setProjectUserDateClosed(projectPayload.project_user_date_closed);
        }

        reset({
          project_type_id: projectPayload.project_type_id,
          customer_id: projectPayload.customer_id,
          project_manager_id: projectPayload.project_manager_id,
          project_name: projectPayload.project_name,
          project_description: projectPayload.project_description ?? undefined,
          project_comments: projectPayload.project_comments ?? undefined,
          project_start_date: projectPayload.project_start_date,
          project_end_date: projectPayload.project_end_date,
          MS_project_id: projectPayload.MS_project_id ?? undefined,
          MS_etag: projectPayload.MS_etag ?? undefined,
          project_is_closed: projectPayload.project_is_closed,
          project_user_closed: projectPayload.project_user_closed ?? undefined,
          project_user_date_closed:
            projectPayload.project_user_date_closed ?? undefined,
        });

        setDefaultProjectType(
          responses[0].data.data.find(
            (projectType: ProjectType) =>
              projectType.project_type_id === projectPayload.project_type_id
          )
        );
        setDefaultCustomer(
          responses[1].data.data.find(
            (customer: Customer) =>
              customer.customer_id === projectPayload.customer_id
          )
        );
        setDefaultUser(
          responses[2].data.data.find(
            (user: User) => user.user_id === projectPayload.project_manager_id
          )
        );
        setDefaultStartDate(projectPayload?.project_start_date);
        setDefaultEndDate(projectPayload?.project_end_date);
        setOriginalProjectName(projectPayload.project_name);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded && (
        <div className="d-flex flex-column justify-content-center">
          <div className="custom-card-view w-100 mt-3">
            <Button
              variant="outlined"
              className="d-flex justify-content-between w-100"
              onClick={() => setHideDetail(!hideDetail)}
            >
              <Typography fontWeight={"Bold"} fontSize={22}>
                {projectPayload.project_name} - {projectPayload.business_name}
              </Typography>
              <>
                {!hideDetail && <KeyboardArrowLeftIcon />}
                {hideDetail && <ExpandMoreIcon />}
              </>
            </Button>

            {hideDetail && (
              <Grow in={hideDetail}>
                <div>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                    <div className="custom-card-row d-flex justify-content-between">
                      <div className="w-50">
                        <div className="d-flex w-100">
                          <div className="w-50">
                            <Controller
                              name="project_type_id"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%", paddingRight: 2 }}
                                  options={comboProjectTypes}
                                  defaultValue={defaultProjectType}
                                  getOptionLabel={(option) =>
                                    `${option.project_type_name} - ${option.project_type_description}`
                                  }
                                  renderOption={(
                                    props,
                                    option: ProjectType
                                  ) => (
                                    <div key={option.project_type_id}>
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option.project_type_name} -{" "}
                                        {option.project_type_description}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.project_type_id ===
                                    value.project_type_id
                                  }
                                  onChange={(_, values) => {
                                    field.onChange(
                                      values?.project_type_id || null
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t(
                                        "projects.fields.project_type"
                                      )}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.project_type_id}
                                      helperText={GetError(
                                        errors.project_type_id?.type
                                      )}
                                      disabled={isEdit}
                                    />
                                  )}
                                  readOnly={isClosedProject}
                                />
                              )}
                            />
                          </div>
                          <div className="w-50">
                            <Controller
                              name="customer_id"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%" }}
                                  options={comboCustomers}
                                  defaultValue={defaultCustomer}
                                  getOptionLabel={(option) =>
                                    `${option.customer_identifier
                                      ? option.customer_identifier
                                      : "XXX"
                                    } - ${option.comercial_name
                                      ? option.comercial_name
                                      : option.business_name
                                    }`
                                  }
                                  renderOption={(props, option: Customer) => (
                                    <div key={option.customer_id}>
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option.customer_identifier
                                          ? option.customer_identifier
                                          : "XXX"}{" "}
                                        -{" "}
                                        {option.comercial_name
                                          ? option.comercial_name
                                          : option.business_name}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.customer_id === value.customer_id
                                  }
                                  onChange={(_, values) => {
                                    field.onChange(values?.customer_id || null);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t("projects.fields.customer")}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.customer_id}
                                      helperText={GetError(
                                        errors.customer_id?.type
                                      )}
                                      disabled={isEdit}
                                      InputProps={{ readOnly: isClosedProject }}
                                    />
                                  )}
                                  readOnly={isClosedProject}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex w-100 pt-3">
                          <div className="w-50">
                            <Controller
                              name="project_manager_id"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Autocomplete
                                  ref={field.ref}
                                  size="small"
                                  sx={{ width: "100%", paddingRight: 2 }}
                                  options={comboUsers}
                                  defaultValue={defaultUser}
                                  getOptionLabel={(option) =>
                                    `${option.user_name}`
                                  }
                                  renderOption={(props, option: User) => (
                                    <div key={option.user_id}>
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option.user_name}
                                      </Box>
                                    </div>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.user_id === value.user_id
                                  }
                                  onChange={(_, values) => {
                                    field.onChange(values?.user_id || null);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`${t(
                                        "projects.fields.project_manager"
                                      )}`}
                                      variant="filled"
                                      value={field.value}
                                      error={!!errors.project_manager_id}
                                      helperText={GetError(
                                        errors.project_manager_id?.type
                                      )}
                                      disabled={isEdit}
                                      InputProps={{ readOnly: isClosedProject }}
                                    />
                                  )}
                                  readOnly={isClosedProject}
                                />
                              )}
                            />
                          </div>

                          <div className="w-50">
                            <Controller
                              name="project_name"
                              control={control}
                              rules={{ required: true, maxLength: 50 }}
                              render={({ field }) => (
                                <TextField
                                  variant="filled"
                                  label={`${t("projects.fields.project_name")}`}
                                  ref={field.ref}
                                  value={field.value}
                                  onChange={(event) => {
                                    field.onChange(event.target.value);
                                  }}
                                  error={!!errors.project_name}
                                  helperText={GetError(
                                    errors.project_name?.type
                                  )}
                                  size="small"
                                  style={{ width: "100%" }}
                                  disabled={isEdit}
                                  InputProps={{ readOnly: isClosedProject }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex w-100 pt-3">
                          <div className="w-50">
                            <Controller
                              name="project_description"
                              control={control}
                              rules={{ maxLength: 200 }}
                              render={({ field }) => (
                                <TextField
                                  variant="filled"
                                  label={`${t(
                                    "projects.fields.project_description"
                                  )}`}
                                  ref={field.ref}
                                  value={field.value}
                                  onChange={(event) => {
                                    field.onChange(event.target.value);
                                  }}
                                  error={!!errors.project_description}
                                  helperText={GetError(
                                    errors.project_description?.type
                                  )}
                                  size="small"
                                  style={{ width: "100%" }}
                                  sx={{ paddingRight: 2 }}
                                  disabled={isEdit}
                                  InputProps={{ readOnly: isClosedProject }}
                                />
                              )}
                            />
                          </div>
                          <div className="w-50">
                            <Controller
                              name="project_start_date"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <DatePicker
                                  open={
                                    isClosedProject
                                      ? !isClosedProject
                                      : openStartDateDatePicker
                                  }
                                  onClose={() =>
                                    setOpenStartDateDatePicker(false)
                                  }
                                  ref={field.ref}
                                  label={`${t(
                                    "projects.fields.project_start_date"
                                  )}`}
                                  value={
                                    defaultStartDate
                                      ? dayjs(defaultStartDate)
                                      : null
                                  }
                                  onChange={(values: any) => {
                                    field.onChange(values?.toDate());
                                    setDefaultStartDate(values?.toDate());
                                    setLoadEndDateFirstTime(false);
                                  }}
                                  slotProps={{
                                    popper: {
                                      sx: myStylePickers
                                    },
                                    textField: {
                                      variant: "filled",
                                      size: "small",
                                      fullWidth: true,
                                      onClick: () =>
                                        setOpenStartDateDatePicker(true),
                                      onBeforeInput: disableKeyboardEntry,
                                      error: !!errors.project_start_date,
                                      helperText: GetError(
                                        errors.project_start_date?.type
                                      ),
                                    },
                                  }}
                                  defaultValue={
                                    defaultStartDate
                                      ? dayjs(
                                        TimeConverter(
                                          defaultStartDate.toString()
                                        )
                                      )
                                      : undefined
                                  }
                                  format="DD/MM/YYYY"
                                  disabled={isEdit}
                                  readOnly={isClosedProject}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="d-flex w-100 pt-3">
                          <div className="w-50">
                            <Controller
                              name="project_end_date"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <DatePicker
                                  open={
                                    isClosedProject
                                      ? !isClosedProject
                                      : openEndDateDatePicker
                                  }
                                  onClose={() =>
                                    setOpenEndDateDatePicker(false)
                                  }
                                  ref={field.ref}
                                  label={`${t(
                                    "projects.fields.project_end_date"
                                  )}`}
                                  value={
                                    defaultEndDate
                                      ? dayjs(defaultEndDate)
                                      : null
                                  }
                                  onChange={(values: any) => {
                                    field.onChange(values?.toDate());
                                    setDefaultEndDate(values?.toDate());
                                  }}
                                  slotProps={{
                                    popper: {
                                      sx: myStylePickers
                                    },
                                    textField: {
                                      variant: "filled",
                                      size: "small",
                                      fullWidth: true,
                                      onClick: () =>
                                        setOpenEndDateDatePicker(true),
                                      onBeforeInput: disableKeyboardEntry,
                                      error: !!errors.project_end_date,
                                      helperText: GetError(
                                        errors.project_end_date?.type
                                      ),
                                    },
                                  }}
                                  defaultValue={
                                    defaultEndDate
                                      ? dayjs(
                                        TimeConverter(
                                          defaultEndDate.toString()
                                        )
                                      )
                                      : undefined
                                  }
                                  minDate={dayjs(defaultStartDate)}
                                  format="DD/MM/YYYY"
                                  sx={{ paddingRight: 2 }}
                                  disabled={isEdit}
                                  readOnly={isClosedProject}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="d-flex w-100 pt-3">
                          <div className="w-100">
                            <Controller
                              name="project_comments"
                              control={control}
                              rules={{ maxLength: 1000 }}
                              render={({ field }) => (
                                <TextField
                                  variant="filled"
                                  label={`${t(
                                    "projects.fields.project_comments"
                                  )}`}
                                  ref={field.ref}
                                  value={field.value}
                                  multiline
                                  minRows={5}
                                  onChange={(event) => {
                                    field.onChange(event.target.value);
                                  }}
                                  error={!!errors.project_comments}
                                  helperText={GetError(
                                    errors.project_comments?.type
                                  )}
                                  size="small"
                                  style={{ width: "100%" }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <Box
                        sx={{ marginLeft: 2 }}
                        className="custom-card-view is-child-card w-50"
                        overflow={"scroll"}
                        maxHeight={400}
                      >
                        {!isUpdateProjectExtraFields && <Spinner isBox={false} />}
                        {isUpdateProjectExtraFields &&
                          <ProjectExtraFieldsValues
                            projectExtraFieldsData={projectExtraFieldsData}
                            setProjectExtraFieldsData={setProjectExtraFieldsData}
                            resourceScreen={resourceProjectExtraFields}
                            isClosedProject={isClosedProject}
                            setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
                            showSnackBar={showSnackBar}
                          />
                        }
                      </Box>
                    </div>

                    {!isEdit && (
                      <div className="d-flex flex-row-reverse justify-content-between mt-4">
                        <div className="d-flex flex-row-reverse">
                          <PrimaryButton
                            type="submit"
                            variant="outlined"
                            className="m-1"
                            disabled={isClosedProject ? isClosedProject : loadingPost}
                          >
                            {t("generic.buttons.update")}
                          </PrimaryButton>
                        </div>

                        {showBalancePermission?.read &&
                          <Grid
                            container
                            display={"flex"}
                            width={"auto"}
                            className="custom-card-view is-child-card"
                          >
                            <Grid item marginInline={1}>
                              <Typography fontWeight={"bold"} color={"royalblue"}>
                                {t("projects.totals.sales")}:{" "}
                                {setFormatNumberFromTable(totalSales)}{" "}
                                {currencyTarget}
                              </Typography>
                            </Grid>
                            <Grid item marginRight={1} color={"tomato"}>
                              <Typography fontWeight={"bold"}>
                                {t("projects.totals.purchases")}:{" "}
                                {setFormatNumberFromTable(totalPurchases)}{" "}
                                {currencyTarget}
                              </Typography>
                            </Grid>
                            <Grid item marginRight={1}>
                              <Typography fontWeight={"bold"}>
                                {t("projects.totals.total")}:{" "}
                                {setFormatNumberFromTable(totalAmount)}{" "}
                                {currencyTarget}
                              </Typography>
                            </Grid>
                            <Grid item marginRight={1} display={"flex"}>
                              <Typography
                                fontWeight={"bold"}
                                color={`${((totalSales - totalPurchases) /
                                  totalPurchases) *
                                  100 <
                                  0
                                  ? "red"
                                  : "green"
                                  }`}
                              >
                                %
                                {isNaN(
                                  Math.round(
                                    ((totalSales - totalPurchases) /
                                      totalPurchases) *
                                    100 *
                                    100
                                  ) / 100
                                )
                                  ? 0
                                  : Math.round(
                                    ((totalSales - totalPurchases) /
                                      totalPurchases) *
                                    100 *
                                    100
                                  ) / 100}
                              </Typography>
                              <Typography
                                fontWeight={"bold"}
                                color={`${((totalSales - totalPurchases) /
                                  totalPurchases) *
                                  100 <
                                  0
                                  ? "red"
                                  : "green"
                                  }`}
                              >
                                {(isNaN(
                                  ((totalSales - totalPurchases) /
                                    totalPurchases) *
                                  100
                                )
                                  ? 0
                                  : ((totalSales - totalPurchases) /
                                    totalPurchases) *
                                  100) < 0 ? (
                                  <ArrowDownwardIcon />
                                ) : (
                                  <ArrowUpwardIcon />
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      </div>
                    )}
                  </form>
                </div>
              </Grow>
            )}
          </div>
        </div>
      )}
      <DialogEntity
        open={dialogConfirmCloseProject}
        title={<Header title={t("projects.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            getValues={getValues}
            isEdit={isEdit}
            loadingPost={loadingPost}
            setDialogConfirmCloseProject={setDialogConfirmCloseProject}
            onCloseProject={onCloseProject}
          />
        }
      />
    </>
  );
});
