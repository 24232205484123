import { Route, Routes } from 'react-router-dom'
import { TablePurchaseShippingDocuments } from './table'
import { AddPurchaseShippingDocuments } from './add'
import { ViewPurchaseShippingDocuments } from './view'

export const BrowserPurchaseShippingDocuments = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TablePurchaseShippingDocuments />} />
      <Route path={"/add"} element={<AddPurchaseShippingDocuments />} />
      <Route path={"/view"} element={<ViewPurchaseShippingDocuments />} />
    </Routes>
  )
}