import { Control, Controller, FieldErrors } from "react-hook-form";
import { PurchaseOrder } from "../../../../interfaces/Purchases/Orders/purchaseOrders";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

type AnnotationsPurchaseOrdersProps = {
  control: Control<PurchaseOrder>,
  errors: FieldErrors<PurchaseOrder>,
  isEdit?: boolean,
}

export const AnnotationsPurchaseOrders = ({
  control,
  errors,
  isEdit,
}: AnnotationsPurchaseOrdersProps) => {
  const { GetError } = FormsErrors();
  const [t] = useTranslation("global");

  return (
    <>
      <div className='w-100'>
        <Controller
          name="notes"
          control={control}
          rules={{}}
          render={({ field }) => (
            <TextField
              variant="filled"
              label={`${t("purchaseorders.fields.notes")}`}
              ref={field.ref}
              value={field.value}
              multiline
              minRows={5}
              onChange={(event) => { field.onChange(event.target.value) }}
              error={!!errors.notes}
              helperText={GetError(errors.notes?.type)}
              size="small"
              sx={{ width: "100%", paddingRight: 2 }}
              disabled={isEdit}
            />
          )}
        />
      </div>
    </>
  )
}