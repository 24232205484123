import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { Menuitem } from "../../../../../../interfaces/Security/menu";
import GenericPromises from "../../../../../../api/GenericPromises";
import { AuthContext } from "../../../../../../context/AuthContext";
import { usePermissions } from "../../../../../../hooks/usePermissions";
import { defaultTheme } from "../../../../../../theme/theme";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { Box, Button, Grid } from "@mui/material";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../../../components/Header";
import { useColors } from "../../../../../../theme/hooks/useColors";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTasks";
import { CreateButton } from "../../../../../../theme/projects/Buttons/createButton";
import { AddProjectTask } from "./add";
import { UpdateProjectTask } from "./update";
import { TaskItem } from "../components/taskItem";
import { TaskState } from "../../../../../../interfaces/Projects/Catalogs/taskStates";
import { ProjectExtraField } from "../../../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Parameter } from "../../../../../../interfaces/Commons/parameters";
import { FieldType } from "../../../../../../interfaces/Projects/Catalogs/fieldTypes";

type TableProjectTaskProps = {
  project_stage_id: number | undefined,
  project_id: number | undefined
  projectExtraFieldsData: ProjectExtraField[]
  parameters: Parameter[];
  isDoneProjectStage: boolean;
  allTasksCompleted: boolean;
  setDialogConfirmChangeStageState: React.Dispatch<React.SetStateAction<boolean>>;
  taskUpdated: ProjectTask[] | undefined;
  resetProjecTasks: boolean;
  setResetProjecTasks:React.Dispatch<React.SetStateAction<boolean>>;
  comboFieldTypes: FieldType[]
}

export const TableProjectTask = ({
  project_stage_id,
  project_id,
  projectExtraFieldsData,
  parameters,
  isDoneProjectStage,
  allTasksCompleted,
  setDialogConfirmChangeStageState,
  taskUpdated,
  resetProjecTasks,
  setResetProjecTasks,
  comboFieldTypes
}: TableProjectTaskProps) => {
  const [t] = useTranslation("global");
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [openDialogAddTask, setOpenDialogAddTask] = useState(false);
  const [openDialogUpdateTask, setOpenDialogUpdateTask] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
  const [projectTaskLocal, setProjectTaskLocal] = useState<ProjectTask[]>([]);
  const [color, setColor] = useState<number[]>();
  const { HexToRGB } = useColors();
  const { user } = useContext(AuthContext);
  const { GetResourceByUrl } = usePermissions();
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [pendingState, setPendingState] = useState<TaskState>()
  const [doneState, setDoneState] = useState<TaskState>();



  const handleAddTask = (newRow: ProjectTask) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectTaskLocal((prevState) => {
      return [...projectTaskLocal, myRow];
    });
  };

  const handleUpdateTask = (updateRow: ProjectTask) => {
    if (projectTaskLocal.length === 0) {
      return;
    }
    setProjectTaskLocal((prevRows) => {
      const rowToUpdateIndex = projectTaskLocal.findIndex(
        (e) =>
          e.project_task_id ===
          updateRow.project_task_id
      );

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row
      );
    });
  };

  const handleDeleteTask = (deleteRow: number) => {
    if (projectTaskLocal.length === 0) {
      return;
    }

    setProjectTaskLocal((prevRows) => {
      const rowToDeleteIndex = projectTaskLocal.findIndex(
        (e) => e.project_task_id === deleteRow
      );

      const updatedData = [
        ...projectTaskLocal.slice(0, rowToDeleteIndex),
        ...projectTaskLocal.slice(rowToDeleteIndex + 1),
      ];

      return updatedData;
    });
  };

  useEffect(() => {
    GenericGetResource(
      `/projecttask/byprojectstageid/${project_stage_id}`
    )
      .then(async (response) => {
        setProjectTaskLocal(response.data.data);
        setColor(HexToRGB(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projecttask`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });

    GenericGetResource(`/taskstates`)
      .then(async (response) => {
        setTaskStates(response.data.data);
        setPendingState(response.data.data.find(
          (state: TaskState) =>
            state.task_state_name == "PENDING"
        ));
        setDoneState(response.data.data.find(
          (state: TaskState) =>
            state.task_state_name == "DONE"
        ));
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });

  }, []);

  useEffect(() => {
    GenericGetResource(
      `/projecttask/byprojectstageid/${project_stage_id}`
    )
      .then(async (response) => {
        setProjectTaskLocal(response.data.data);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, [allTasksCompleted])


  useEffect(() => {

  }, [isDoneProjectStage])

  useEffect(() => {
    if (Array.isArray(taskUpdated)) {
      taskUpdated.forEach((task) => {
        taskUpdated.forEach((task: ProjectTask) => handleUpdateTask(task))
      });
    }
  }, [taskUpdated])

  useEffect(() => {
    if (resetProjecTasks) {
      const pendingState = taskStates.find(
        (state: TaskState) =>
          state.task_state_name = 'PENDING'
      );

      if (pendingState) {
        setProjectTaskLocal((prevRows) =>
          prevRows.map((task) => ({
            ...task,
            current_task_state_id: pendingState.task_state_id,
          }))
        );
      }
    setResetProjecTasks(false);
    }
  }, [resetProjecTasks])


  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <div className="d-flex mt-4">
          <Grid container spacing={2}
            sx={{
              ...(isDoneProjectStage && { pointerEvents: "none", opacity: 0.5 }),
            }}
          >
            <Grid item xs={12}>
              <CreateButton color={color} onClick={() => { setOpenDialogAddTask(true); }}>
                <AddIcon /> {t("projecttasks.actions.create")}
              </CreateButton>
            </Grid>
            {projectTaskLocal.map((task) => (
              <TaskItem key={task.project_task_id}
                task={task}
                color={color}
                setSelectedTaskId={setSelectedTaskId}
                setOpenDialogUpdateTask={setOpenDialogUpdateTask}
                isEdit={!resourceScreen?.update}
                handleUpdateTask={handleUpdateTask}
                taskStates={taskStates}
                pendingState={pendingState}
                doneState={doneState}
                projectExtraFieldsData={projectExtraFieldsData}
                parameters={parameters}
                projectTaskLocal={projectTaskLocal}
                setDialogConfirmChangeStageState={setDialogConfirmChangeStageState}
                resetProjecTasks={resetProjecTasks}
              />
            ))}
          </Grid>
        </div>
      )}

      <DialogEntity
        content={
          <AddProjectTask
            setOpenDialogAddTask={setOpenDialogAddTask}
            project_id={project_id}
            project_stage_id={project_stage_id}
            handleAddTask={handleAddTask}
            comboFieldTypes={comboFieldTypes}
          />
        }
        open={openDialogAddTask}
        setOpen={setOpenDialogAddTask}
        title={
          <Header
            title={t("projecttasks.title-view")}
            size="sm"
          />
        }
      />

      <DialogEntity
        content={
          <UpdateProjectTask
            permissions={resourceScreen}
            setOpenDialogUpdateTask={setOpenDialogUpdateTask}
            handleUpdateTask={handleUpdateTask}
            handleDeleteTask={handleDeleteTask}
            project_id={project_id}
            projectTaskPayload={
              projectTaskLocal?.find(
                (task) => task.project_task_id === selectedTaskId
              ) || {}
            }
            comboFieldTypes={comboFieldTypes}
          />
        }
        open={openDialogUpdateTask}
        setOpen={setOpenDialogUpdateTask}
        title={
          <Header
            title={t("projecttasks.title-view")}
            size="sm"
          />
        }
      />
      <SnackbarComponent />
    </>
  );
};
