import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { AuthContext } from '../../../context/AuthContext';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../interfaces/Security/menu';
import { WarehouseItem } from '../../../interfaces/Inventory/warehouseItems';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { PrimaryButton } from '../../../theme/buttons';
import DataTable from '../../../components/Tables/GridTableMaterialUI/DataTable';
import { UpdateItemWarehouses } from './update';
import { Header } from '../../../components/Header';
import { AddItemWarehouses } from './add';
import { usePermissions } from '../../../hooks/usePermissions';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../hooks/useDates';

export const TableItemWarehouses = ({ ...props }) => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { user } = useContext(AuthContext);
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [warehouseItemsData, setWarehouseItemsData] = useState<GridRowsProp>([]);
  const [warehouseItemLocationPayload, setWarehouseItemPayload] = useState({});
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [columnsWarehouseItems, setColumnsWarehouseItems] = useState<GridColDef<WarehouseItem>[]>([
    { field: 'warehouse_code', headerName: t("warehouses.fields.warehouse_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'warehouse_description', headerName: t("warehouses.fields.warehouse_description"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'stock', headerName: t("warehouseitems.fields.stock"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state, t]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-itemwarehouses");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-itemwarehouses-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<WarehouseItem, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`customers.fields.${element.field}`);
        }
      }
      setColumnsWarehouseItems(myJson);
    }
  }

  const handleAddRow = (newRow: WarehouseItem) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setWarehouseItemsData((prevState) => [...warehouseItemsData, myRow]);
  };

  const handleUpdateRow = (updateRow: WarehouseItem) => {
    if (warehouseItemsData.length === 0) {
      return;
    }
    setWarehouseItemsData((prevRows) => {
      const rowToUpdateIndex = warehouseItemsData.findIndex(e => e.warehouse_item_id === updateRow.warehouse_item_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (warehouseItemsData.length === 0) {
      return;
    }
    setWarehouseItemsData((prevRows) => {
      const rowToDeleteIndex = warehouseItemsData.findIndex(e => e.warehouse_item_id === deleteRow);
      return [
        ...warehouseItemsData.slice(0, rowToDeleteIndex),
        ...warehouseItemsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/warehouseitems/byitemid/${props.item_id}`)
      .then(
        (response) => {
          setWarehouseItemsData(response.data.data);

          GetResourceByUrl(`/warehouseitems`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, 'error');
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && resourceScreen?.read &&
        <>
          <div className='screen-container'>
            {resourceScreen?.create === true &&
              <div className='d-flex flex-row-reverse my-1'>
                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
              </div>
            }
            <div className="d-flex justify-content-center">
              <DataTable
                columns={columnsWarehouseItems}
                setColumns={setColumnsWarehouseItems}
                data={warehouseItemsData}
                entityId={"warehouse_item_id"}
                entity={`WarehouseItems`}
                preferences={myPreferences}
                namePreferences={"grid-itemwarehouses"}
                nameOrderColumns={"grid-itemwarehouses-columns"}
                isChildren={true}
                setOpenDialog={setOpenDialogUpdate}
                setDataRow={setWarehouseItemPayload}
              />
            </div>
          </div>
          <DialogEntity
            content={<UpdateItemWarehouses warehouseItemLocationPayload={warehouseItemLocationPayload} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} />}
            open={openDialogUpdate}
            setOpen={setOpenDialogUpdate}
            title={<Header title={t("warehouseitems.relation")} size='sm' />}
          />
          <DialogEntity
            content={<AddItemWarehouses setOpenDialog={setOpenDialogAdd} item_id={props.item_id} AddNewRow={handleAddRow} item_code={props.item_code} item_description={props.item_description} />}
            open={openDialogAdd}
            setOpen={setOpenDialogAdd}
            title={<Header title={t("warehouseitems.relation")} size='sm' />}
          />
          <SnackbarComponent />
        </>
      }
    </>
  )
}
