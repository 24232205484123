import GenericPromises from '../../../../../../api/GenericPromises';
import { PurchaseInvoice, PurchaseInvoiceDetail } from '../../../../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { Currency } from '../../../../../../interfaces/Commons/currencies';
import { PaymentMethod } from '../../../../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../../../../interfaces/Sales/Catalogs/paymentModes';
import { Supplier, SupplierLocation } from '../../../../../../interfaces/Purchases/Catalogs/suppliers';
import { CFDI } from '../../../../../../interfaces/Sales/Catalogs/CFDIs';
import { Language } from '../../../../../../interfaces/Commons/languages';
import { SupplierBankAccount } from '../../../../../../interfaces/Purchases/Catalogs/supplierBankAccounts';
import { useCurrencies } from '../../../../../../hooks/useCurrencies';
import { TaxRegime } from '../../../../../../interfaces/Sales/Catalogs/taxRegimes';
import { Retention } from '../../../../../../interfaces/Sales/Catalogs/retentions';
import { Tax } from '../../../../../../interfaces/Sales/Catalogs/taxes';
import { Item } from '../../../../../../interfaces/Commons/items';
import { Unit } from '../../../../../../interfaces/Commons/units';
import { Parameter } from '../../../../../../interfaces/Commons/parameters';
import { useBase64 } from '../../../../../../hooks/useBase64';
import { PaymentDetail, RelatedPaymentDetail } from '../../../../../../interfaces/Purchases/Payments/payments';

type PurchaseItem = {
  quantity: number;
  price: number;
  data: Item;
  currency_code: string;
}

export const useRequestPurchaseInvoice = () => {
  const {
    GenericGetResource,
    GenericGetResourceGeneric,
    GenericPostDocument,
    GenericPostResource,
    GenericPutResource,
    GenericDeleteResource,
  } = GenericPromises();
  const { GetExchangeRateFromCurrencyCode, GetAndPostExchangeRateFromCurrencyCodeAndDate } = useCurrencies();
  const { utf8ToBase64 } = useBase64();
  const myInvoiceDocumentTypeId = 3;

  const DeletePurchaseInvoice = async (purchase_invoice_id: number, purchaseInvoiceDetails: PurchaseInvoiceDetail[]) => {
    await GenericGetResource(`/relatedpaymentsdetails/bypurchaseinvoiceid/${purchase_invoice_id}`)
      .then(async (responseRelated) => {
        await GenericGetResource(`/paymentdetailstatuses/bypaymentdetailid/${(responseRelated.data.data[0] && responseRelated.data.data[0].payment_detail_id) ?? 0}`)
          .then(async (responseStatuses) => {
            await GenericDeleteResource(`/paymentdetailstatuses/${(responseStatuses.data.data[0] && responseStatuses.data.data[0].payment_detail_status_id) ?? 0}`);
          });
        await GenericGetResource(`/paymentsdetails/${(responseRelated.data.data[0] && responseRelated.data.data[0].payment_detail_id) ?? 0}`)
          .then(async () => {
            await GenericDeleteResource(`/relatedpaymentsdetails/${(responseRelated.data.data[0] && responseRelated.data.data[0].payment_detail_id) ?? 0}`);
            await GenericDeleteResource(`/paymentsdetails/${(responseRelated.data.data[0] && responseRelated.data.data[0].payment_detail_id) ?? 0}`);
          })
        for (let i = 0; i < purchaseInvoiceDetails.length; i++) {
          await GenericDeleteResource(`/purchaseinvoicesdetails/${purchaseInvoiceDetails[i].purchase_invoice_detail_id ?? 0}`);
        }
        await GenericDeleteResource(`/purchaseinvoices/${purchase_invoice_id}`);
      });
  }

  const PutPurchaseInvoiceDetails = async (
    purchase_invoice_id: number,
    purchaseInvoiceDetails: PurchaseInvoiceDetail[],
    newQuantity: number,
    namefromPriceParams: string,
    detailName: string,
    calculationDate: Date,
  ): Promise<PurchaseInvoiceDetail[]> => {
    let updatedPurchaseInvoices: PurchaseInvoiceDetail[] = [];
    let myItemDetails = await GetItemIdsByDaysAndName(newQuantity, detailName, namefromPriceParams);

    if (!myItemDetails || myItemDetails.length < 1) {
      await DeletePurchaseInvoice(purchase_invoice_id, purchaseInvoiceDetails);
      return updatedPurchaseInvoices;
    }
    else {
      let existingDescriptionsMap = new Map<string, PurchaseInvoiceDetail>();
      purchaseInvoiceDetails.forEach(detail => {
        if (detail?.item_description) {
          existingDescriptionsMap.set(detail.item_description.trim().toLowerCase(), detail);
        }
      });

      for (let i = 0; i < myItemDetails.length; i++) {
        let newItem = myItemDetails[i];

        let newItemDescription = newItem.data.item_description?.trim().toLowerCase();

        if (newItemDescription && existingDescriptionsMap.has(newItemDescription)) {
          let existingDetail = existingDescriptionsMap.get(newItemDescription);
          if (existingDetail) {
            let myNewPutDetail = {
              quantity: newItem.quantity,
              price_by_unit: newItem.price,
              total_amount: newItem.quantity * newItem.price,
              subtotal_amount: existingDetail.total_amount,
            }

            await GenericPutResource(`/purchaseinvoicesdetails/${existingDetail.purchase_invoice_detail_id}`, myNewPutDetail)
              .then((response) => {
                updatedPurchaseInvoices.push(response.data);
              });
          }
        } else {

          let myTaxesResponse = await GenericGetResource(`/taxes`);
          let myTaxesData: Tax[] = myTaxesResponse.data.data;
          let myRetentionsResponse = await GenericGetResource(`/retentions`);
          let myRetentionsData: Retention[] = myRetentionsResponse.data.data;
          let myUnitsResponse = await GenericGetResource(`/units`);
          let myUnitsData: Unit[] = myUnitsResponse.data.data;

          let myTax = myTaxesData.find((item) => item.tax_id === newItem.data.tax_id);
          let myRetention = myRetentionsData.find((item) => item.retention_id === newItem.data.retention_id);
          let myUnit = myUnitsData.find((item) => item.unit_id === newItem.data.unit_id);

          let newPurchaseInvoiceDetail = {
            purchase_invoice_id: purchase_invoice_id,
            quantity: newItem.quantity,
            price_by_unit: newItem.price,
            discount: 0,
            subtotal_tax_amount: null,
            subtotal_amount: newItem.quantity * newItem.price,
            total_amount: newItem.quantity * newItem.price,
            tax_id: myTax?.tax_id ?? null,
            tax_name: myTax?.tax_name ?? null,
            tax_percent: myTax?.tax_percent ?? null,
            tax_code: myTax?.tax_code ?? null,
            name_item_family: null,
            name_item_type: null,
            item_weight: newItem.data.weight ?? null,
            item_description: newItem.data.item_description,
            item_code: newItem.data.item_code,
            item_id: newItem.data.item_id,
            unit_id: myUnit?.unit_id ?? null,
            unit_code: myUnit?.unit_code ?? null,
            unit_description: myUnit?.unit_description ?? null,
            unit_symbol: myUnit?.unit_symbol ?? null,
            international_unit_symbol: myUnit?.international_unit_symbol ?? null,
            tax_object: newItem.data.tax_object,
            product_service_key: newItem.data.product_service_key,
            retention_id: myRetention?.retention_id ?? null,
            retention_code: myRetention?.retention_code ?? null,
            retention_name: myRetention?.retention_name ?? null,
            retention_percent: myRetention?.retention_percent ?? null,
          };

          await GenericPostResource("/purchaseinvoicesdetails", newPurchaseInvoiceDetail)
            .then((response) => {
              updatedPurchaseInvoices.push(response.data);
            });
        }

      }

      let newDescriptionsSet = new Set<string>();
      myItemDetails.forEach(newItem => {
        newDescriptionsSet.add(newItem.data.item_description.trim().toLowerCase());
      });

      for (let i = 0; i < purchaseInvoiceDetails.length; i++) {
        let existingDetailDescription = purchaseInvoiceDetails[i].item_description?.trim().toLowerCase();

        // Verificamos que existingDetailDescription no sea undefined
        if (existingDetailDescription && !newDescriptionsSet.has(existingDetailDescription)) {
          await GenericDeleteResource(`/purchaseinvoicesdetails/${purchaseInvoiceDetails[i].purchase_invoice_detail_id}`);
        }
      }
      await PutPaymentsDetails(purchase_invoice_id);
      await PutDateAndExchangeRatePurchaseInvoice(purchase_invoice_id, calculationDate, myItemDetails[0].currency_code);

      return updatedPurchaseInvoices;
    }
  };

  const PutPaymentsDetails = async (purchase_invoice_id: number) => {
    let myPurchaseInvoiceResponse = await GenericGetResource(`/purchaseinvoices/${purchase_invoice_id}`);
    let myPurchaseInvoiceData: PurchaseInvoice = myPurchaseInvoiceResponse.data;

    // Obtener los detalles relacionados
    let myPaymentDetailsResponse = await GenericGetResource(`/paymentsdetails/bypurchaseinvoiceid/${purchase_invoice_id}`);
    let myPaymentDetailsData: PaymentDetail[] = myPaymentDetailsResponse.data.data;

    const alreadyPaidAmount = myPaymentDetailsData
      .filter(item =>
        item.current_payment_status_id === 2 || // Pagos realizados
        (item.current_payment_status_id === 1 && item.is_authorized) // No pagados pero autorizados
      )
      .reduce((acc, curr) => {
        // Sumar payment_amount para pagos realizados y amount_outstanding para autorizados no pagados
        const amount = curr.current_payment_status_id === 2
          ? (curr.payment_amount || 0) // Pagos realizados
          : (curr.amount_outstanding || 0); // Autorizados no pagados
        return acc + amount;
      }, 0);

    // Calcular el saldo pendiente
    const amountOutstanding = (myPurchaseInvoiceData.total_amount ?? 0) - alreadyPaidAmount;
    // Verificar si hay un detalle no pagado y no autorizado
    let myPaymentDetailNoPay = myPaymentDetailsData.find(
      (item) => item.current_payment_status_id === 1 && item.is_authorized === false
    );

    if (myPaymentDetailNoPay) {
      // Actualizar el detalle existente no autorizado
      console.log("Actualizando detalle existente no autorizado...");
      const totalTaxAmount = ((myPurchaseInvoiceData.total_amount ?? 0) * (myPaymentDetailNoPay.tax_percent ?? 0)) / 100;
      const totalRetentionAmount = ((myPurchaseInvoiceData.total_amount ?? 0) * (myPaymentDetailNoPay.retention_percent ?? 0)) / 100;

      const myPutRelatedPaymentDetail = {
        previous_amount: alreadyPaidAmount,
        amount_outstanding: amountOutstanding > 0 ? amountOutstanding : 0,
        total_tax_amount: totalTaxAmount,
        total_retention_amount: totalRetentionAmount,
      };

      const myRelatedPaymentDetailsResponse = await GenericGetResource(`/relatedpaymentsdetails/bypaymentdetailid/${myPaymentDetailNoPay.payment_detail_id}`);
      const myRelatedPaymentDetail: RelatedPaymentDetail = myRelatedPaymentDetailsResponse.data.data[0];

      await GenericPutResource(`/relatedpaymentsdetails/${myRelatedPaymentDetail.related_payment_detail_id ?? 0}`, myPutRelatedPaymentDetail)
        .then(async responseRelatedPaymentsDetails => {
          const myPutPaymentDetail = {
            related_total_tax_amount: totalTaxAmount,
            related_total_retention_amount: totalRetentionAmount,
          };
          await GenericPutResource(`/paymentsdetails/${responseRelatedPaymentsDetails.data.payment_detail_id ?? 0}`, myPutPaymentDetail);
        });
    } else {
      // Verificar si existe un detalle autorizado pero no pagado
      const authorizedButUnpaidDetail = myPaymentDetailsData.find(
        (item) => item.current_payment_status_id === 1 && item.is_authorized === true
      );

      if (authorizedButUnpaidDetail && amountOutstanding > 0) {
        // Crear un nuevo detalle para el saldo pendiente
        await CreateNewPaymentDetail(myPurchaseInvoiceData, myPaymentDetailsData, amountOutstanding);
      }
    }
  };

  const CreateNewPaymentDetail = async (
    myPurchaseInvoiceData: PurchaseInvoice,
    myPaymentDetailsData: PaymentDetail[],
    amountOutstanding: number // Pasar el monto pendiente directamente
  ) => {
    // Validar si el monto pendiente es mayor a 0
    if (amountOutstanding <= 0) {
      console.log("No hay saldo pendiente para crear un nuevo PaymentDetail.");
      return;
    }

    // Crear el nuevo PaymentDetail
    const newPaymentDetail = {
      current_payment_status_id: 1, // Estado "no pagado"
      payment_id: null,
      payment_deadline: null,
      payment_date: null,
      tax_id: null,
      tax_code: null,
      is_authorized: false,
      tax_description: null,
      tax_percent: null,
      retention_id: null,
      retention_code: null,
      retention_description: null,
      retention_percent: null,
      currency_id: null,
      currency_code: null,
      currency_description: null,
      exchange_rate: myPurchaseInvoiceData.exchange_rate,
      related_total_tax_amount: 0,
      related_total_retention_amount: 0,
      total_tax_amount: 0,
      total_retention_amount: 0,
      subtotal_amount: 0,
      total_amount: 0, 
    };

    await GenericPostResource(`/paymentsdetails`, newPaymentDetail)
      .then(async response => {
        // Crear el RelatedPaymentDetail vinculado al nuevo PaymentDetail
        const newRelatedPaymentDetail = {
          payment_detail_id: response.data.payment_detail_id,
          purchase_invoice_id: myPurchaseInvoiceData.purchase_invoice_id,
          purchase_invoice_name: myPurchaseInvoiceData.purchase_invoice_name,
          currency_id: myPurchaseInvoiceData.currency_id,
          currency_code: myPurchaseInvoiceData.currency_code,
          currency_description: myPurchaseInvoiceData.currency_description,
          equivalence: myPurchaseInvoiceData.exchange_rate,
          partiality_number: myPaymentDetailsData.length + 1, // Incrementar el número de parcialidad
          previous_amount: amountOutstanding, // No hay monto previo, ya que es un nuevo detalle
          payment_amount: 0,
          amount_outstanding: amountOutstanding, // Saldo pendiente es igual al monto total
          tax_object_id: null,
          tax_object_code: null,
          tax_object_description: null,
          tax_id: null,
          tax_code: null,
          tax_description: null,
          tax_percent: 0,
          retention_id: null,
          retention_code: null,
          retention_description: null,
          retention_percent: 0,
          total_tax_amount: myPurchaseInvoiceData.tax_amount ?? 0,
          total_retention_amount: myPurchaseInvoiceData.retention_amount ?? 0,
        };

        const myPromises = [
          GenericPostResource(`/relatedpaymentsdetails`, newRelatedPaymentDetail),
          GenericPutResource(`/paymentsdetails/${response.data.payment_detail_id}`, {
            ...newPaymentDetail,
            related_total_tax_amount: myPurchaseInvoiceData.tax_amount ?? 0,
            related_total_retention_amount: myPurchaseInvoiceData.retention_amount ?? 0,
          }),
        ];

        await Promise.all(myPromises)
          .then(async ([relatedResponse]) => {
            // Registrar el nuevo estado del detalle de pago
            const newStatus = {
              new_payment_status_id: 1, // Estado inicial "no pagado"
              previous_payment_status_id: 1, // No hay estado previo porque es un nuevo detalle
              payment_detail_id: response.data.payment_detail_id,
            };

            await GenericPostResource(`/paymentdetailstatuses`, newStatus)
              .catch(async error => {
                // Eliminar el relatedPaymentDetail si falla el estado
                await GenericDeleteResource(`/relatedpaymentsdetails/${relatedResponse.data.related_payment_detail_id}`);
                throw error;
              });
          })
          .catch(async error => {
            // Eliminar el PaymentDetail si algo falla
            await GenericDeleteResource(`/paymentsdetails/${response.data.payment_detail_id}`);
            throw error;
          });
      })
      .catch(async error => {
        throw error;
      });
  };


  const GetItemIdsByDaysAndName = async (newQuantity: number, detailName: string, namefromPriceParams: string): Promise<PurchaseItem[]> => {
    let myNewItemsData: PurchaseItem[] = [];

    let mySupplierItemsRelatedDetailNameResponse = await GenericGetResource(`/items?filter=${utf8ToBase64(`item_description≈${detailName}`)}`);
    let mySupplierItemsRelatedDetailName: Item[] = mySupplierItemsRelatedDetailNameResponse.data.data;

    let myParameterResponse = await GenericGetResource(`/parameters?filter=${utf8ToBase64(`field_type_name≈Shipping-cost-storage&field_type_name≈Shipping-cost-demurrage`)}`);
    let myParameters: Parameter[] = myParameterResponse.data.data;

    const myFilteredParams = myParameters.filter((item) => item.parameter_value?.split(",")[0] === namefromPriceParams);

    const filteredParams = myFilteredParams
      .filter((param) => param.parameter_name?.includes(namefromPriceParams))
      .map(param => {
        const matchDays = param.parameter_name?.match(/\+(\d+)/);
        const days = matchDays ? parseInt(matchDays[1], 10) : 0;
        const priceMatch = param.parameter_value?.split(',')[1]?.trim();
        const price = priceMatch ? parseFloat(priceMatch.split('-')[0].trim()) : 0;

        return { ...param, days, price };
      })
      .sort((a, b) => a.days - b.days);

    let remainingQuantity = newQuantity;
    let totalQuantity = 0;
    let selectedItem: Item | undefined;
    let currencyCode = '';
    let lastValidPrice = 0;
    let lastValidDays = 0;

    for (let i = 0; i < filteredParams.length; i++) {
      const currentParam = filteredParams[i];
      const nextParam = filteredParams[i + 1];

      const daysForThisRange = nextParam
        ? Math.min(remainingQuantity, nextParam.days - currentParam.days)
        : remainingQuantity;

      if (daysForThisRange > 0) {
        if (!selectedItem) {
          selectedItem = mySupplierItemsRelatedDetailName.find((item) => {
            if (detailName === "DEMORAS") {
              return item.item_description.toLowerCase().includes(detailName.toLowerCase());
            }
            return item.item_description.toLowerCase().includes(currentParam.parameter_name?.replace("Costo ", "").toLowerCase() ?? '');
          });
        }

        if (selectedItem) {
          totalQuantity += daysForThisRange;
          lastValidPrice = currentParam.price;
          lastValidDays = currentParam.days;
          currencyCode = currentParam.parameter_value?.split(',')[1]?.split('-')[1]?.trim() || '';
        }

        remainingQuantity -= daysForThisRange;
      }

      if (remainingQuantity <= 0) break;
    }

    if (selectedItem) {
      const daysToConcat = lastValidDays === 0 ? '1' : `${lastValidDays}`;
      const concatenatedDescription = `${selectedItem.item_description} ${daysToConcat}+`;

      myNewItemsData.push({
        data: { ...selectedItem, item_description: concatenatedDescription },
        price: lastValidPrice,
        quantity: totalQuantity,
        currency_code: currencyCode,
      });
    }

    return myNewItemsData;
  };



  const PostPurchaseInvoiceDetails = async (purchase_invoice_id: number, newQuantity: number, detailName: string, namefromPriceParams: string, calculationDate: Date): Promise<PurchaseInvoiceDetail[]> => {
    try {

      let myPurchaseInvoices: PurchaseInvoiceDetail[] = [];

      let myTaxesResponse = await GenericGetResource(`/taxes`);
      let myTaxesData: Tax[] = myTaxesResponse.data.data;
      let myRetentionsResponse = await GenericGetResource(`/retentions`);
      let myRetentionsData: Retention[] = myRetentionsResponse.data.data;
      let myUnitsResponse = await GenericGetResource(`/units`);
      let myUnitsData: Unit[] = myUnitsResponse.data.data;

      let myItemDetails = await GetItemIdsByDaysAndName(newQuantity, detailName, namefromPriceParams);

      if (!myItemDetails || myItemDetails.length < 1) {
        await GenericDeleteResource(`/purchaseinvoices/${purchase_invoice_id}`);
        return myPurchaseInvoices;
      }

      for (let i = 0; i < myItemDetails.length; i++) {
        const element = myItemDetails[i];

        let myTax = myTaxesData.find((item) => item.tax_id === element.data.tax_id);
        let myRetention = myRetentionsData.find((item) => item.retention_id === element.data.retention_id);
        let myUnit = myUnitsData.find((item) => item.unit_id === element.data.unit_id);

        let myPurchaseInvoicesDetail = {
          quantity: element.quantity,
          price_by_unit: element.price,
          discount: 0,
          subtotal_tax_amount: null,
          subtotal_amount: 0,
          total_amount: element.quantity * element.price,
          tax_id: myTax?.tax_id ?? null,
          tax_name: myTax?.tax_name ?? null,
          tax_percent: myTax?.tax_percent ?? null,
          tax_code: myTax?.tax_code ?? null,
          name_item_family: null,
          name_item_type: null,
          item_weight: element.data.weight ?? null,
          item_description: element.data.item_description,
          item_code: element.data.item_code,
          item_id: element.data.item_id,
          unit_id: myUnit?.unit_id,
          unit_code: myUnit?.unit_code,
          unit_description: myUnit?.unit_description,
          unit_symbol: myUnit?.unit_symbol,
          international_unit_symbol: myUnit?.international_unit_symbol,
          tax_object: element.data.tax_object,
          product_service_key: element.data.product_service_key,
          retention_id: myRetention?.retention_id ?? null,
          retention_code: myRetention?.retention_code ?? null,
          retention_name: myRetention?.retention_name ?? null,
          retention_percent: myRetention?.retention_percent ?? null,
          purchase_invoice_id: purchase_invoice_id,
        }
        await GenericPostResource("/purchaseinvoicesdetails", myPurchaseInvoicesDetail)
          .then((responsePurchaseInvoiceDetail) => {
            myPurchaseInvoices.push(responsePurchaseInvoiceDetail.data);
          });
      }
      await PutDateAndExchangeRatePurchaseInvoice(purchase_invoice_id, calculationDate, myItemDetails[0].currency_code);
      return myPurchaseInvoices;
    }
    catch (error) {
      throw error;
    }
  }

  const PostPaymentsDetails = async (purchase_invoice_id: number): Promise<PurchaseInvoice> => {
    // get purchaseInvoice with update totals to post payment details
    let myPurchaseInvoiceResponse = await GenericGetResource(`/purchaseinvoices/${purchase_invoice_id ?? 0}`);
    let myPurchaseInvoiceData: PurchaseInvoice = myPurchaseInvoiceResponse.data;

    let myPaymentDetail = {
      current_payment_status_id: 1,
      payment_id: null,
      payment_deadline: null,
      payment_date: null,
      tax_id: null,
      tax_code: null,
      is_authorized: false,
      tax_description: null,
      tax_percent: null,
      retention_id: null,
      retention_code: null,
      retention_description: null,
      retention_percent: null,
      currency_id: null,
      currency_code: null,
      currency_description: null,
      exchange_rate: myPurchaseInvoiceData.exchange_rate,
      payment_method_id: null,
      payment_method_code: null,
      payment_method_description: null,
      related_total_tax_amount: 0,
      related_total_retention_amount: 0,
      total_tax_amount: 0,
      total_retention_amount: 0,
      subtotal_amount: 0,
      total_amount: 0,
    }
    await GenericPostResource(`/paymentsdetails`, myPaymentDetail)
      .then(async (response) => {
        let myRelatedPaymentDetail = {
          payment_detail_id: response.data.payment_detail_id,
          purchase_invoice_id: myPurchaseInvoiceData.purchase_invoice_id,
          purchase_invoice_name: myPurchaseInvoiceData.purchase_invoice_name,
          currency_id: myPurchaseInvoiceData.currency_id,
          currency_code: myPurchaseInvoiceData.currency_code,
          currency_description: myPurchaseInvoiceData.currency_description,
          equivalence: myPurchaseInvoiceData.exchange_rate,
          partiality_number: 1,
          previous_amount: myPurchaseInvoiceData.total_amount,
          payment_amount: 0,
          amount_outstanding: myPurchaseInvoiceData.total_amount,
          tax_object_id: null,
          tax_object_code: null,
          tax_object_description: null,
          tax_id: null,
          tax_code: null,
          tax_description: null,
          tax_percent: 0,
          retention_id: null,
          retention_code: null,
          retention_description: null,
          retention_percent: 0,
          total_tax_amount: myPurchaseInvoiceData.tax_amount ?? 0,
          total_retention_amount: myPurchaseInvoiceData.retention_amount ?? 0,
        }
        myPaymentDetail.related_total_tax_amount = myPurchaseInvoiceData.tax_amount ?? 0;
        myPaymentDetail.related_total_retention_amount = myPurchaseInvoiceData.retention_amount ?? 0;
        let myPromises = [
          GenericPostResource(`/relatedpaymentsdetails`, myRelatedPaymentDetail),
          GenericPutResource(`/paymentsdetails/${response.data.payment_detail_id}`, myPaymentDetail),
        ];

        await Promise.all(myPromises)
          .then(async (responses) => {
            let myNewStatus = {
              new_payment_status_id: 1,
              previous_payment_status_id: 1,
              payment_detail_id: response.data.payment_detail_id,
            }
            await GenericPostResource(`/paymentdetailstatuses`, myNewStatus)
              .catch(async (error) => {
                await GenericDeleteResource(`/relatedpaymentsdetails/${responses[0].data.related_payment_detail_id}`);
                throw error;
              });
          })
          .catch(async (error) => {
            await GenericDeleteResource(`/paymentsdetails/${response.data.payment_detail_id}`);
            let myPutData = {
              is_accounted: false,
            }
            await GenericPutResource(`/purchaseinvoices/${purchase_invoice_id}`, myPutData);
          })
      })

    let myPurchaseInvoice: PurchaseInvoice = { purchase_invoice_id: 0 };
    return myPurchaseInvoice;
  }

  const onRequestPurchaseInvoice = async (
    detailName: string,
    newQuantity: number,
    supplier_id: number,
    namefromPriceParams: string,
    project_id: number,
    calculationDate: Date,
  ): Promise<PurchaseInvoice | undefined> => {
    try {
      let mySupplierResponse = await GenericGetResource(`/suppliers/${supplier_id}`);
      let mySupplier: Supplier = mySupplierResponse.data;

      if (mySupplier) {
        // get supplier data
        let myPaymentModeResponse = await GenericGetResource(`/paymentmodes/${mySupplier.default_payment_mode_id ?? 0}`);
        let myPaymentMode: PaymentMode = myPaymentModeResponse && myPaymentModeResponse.data;
        let myPaymentMethodResponse = await GenericGetResource(`/paymentmethods/${mySupplier.default_payment_method_id ?? 0}`);
        let myPaymentMethod: PaymentMethod = myPaymentMethodResponse && myPaymentMethodResponse.data;
        let myCFDIResponse = await GenericGetResource(`/cfdi/${mySupplier.default_cfdi_id ?? 0}`);
        let myCFDI: CFDI = myCFDIResponse && myCFDIResponse.data;
        let mySupplierLanguageResponse = await GenericGetResource(`/languages/${mySupplier.language_id ?? 0}`);
        let mySupplierLanguage: Language = mySupplierLanguageResponse && mySupplierLanguageResponse.data;
        let myBankAccountResponse = await GenericGetResource(`/supplierbankaccounts/${mySupplier.primary_bank_account ?? 0}`);
        let myBankAccount: SupplierBankAccount = myBankAccountResponse && myBankAccountResponse.data;
        let mySupplierTaxRegimeResponse = await GenericGetResource(`/taxregimes/${mySupplier.tax_regime_id ?? 0}`);
        let mySupplierTaxRegime: TaxRegime = mySupplierTaxRegimeResponse && mySupplierTaxRegimeResponse.data;
        let myResponseSupplierLocations = await GenericGetResource(`/supplierlocations/bysupplierid/${mySupplier.supplier_id ?? 0}`);
        let myComboSupplierLocations: SupplierLocation[] = myResponseSupplierLocations.data.data;
        let mySupplierLocation: SupplierLocation | undefined = myComboSupplierLocations.find((item) => item.fiscal_address === true);

        if (!myPaymentMode.payment_mode_id || !myPaymentMethod.payment_method_id || !myCFDI.cfdi_id || !mySupplierLanguage.language_id || !myBankAccount.supplier_bank_account_id || !mySupplierTaxRegime.tax_regime_id || !mySupplierLocation?.supplier_location_id) {
          return undefined;
        }

        let myInvoiceDocumentType = await GenericGetResource(`/invoicedocumenttypes/${myInvoiceDocumentTypeId ?? 0}`);
        let myexchangeRate = 1;
        const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType.data.consecutive_id ?? 0}`);
        const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");
        const company = responseCompany.data ?? null;

        // generate purchaseinvoice
        let myData = {
          invoice_doc_status_id: 1,
          purchase_invoice_name: responseConsecutive.data.data[0].Consecutive,
          purchase_invoice_date: new Date(),
          supplier_sale_invoice_date: null,
          expiration_date: null,
          sales_order: null,
          supplier_id: mySupplier.supplier_id,
          supplier_business_name: mySupplierLocation?.business_name ?? null,
          supplier_comercial_name: mySupplier?.comercial_name ?? null,
          supplier_rfc: mySupplierLocation?.supplier_rfc ?? null,
          supplier_location_id: mySupplierLocation?.supplier_location_id ?? null,
          supplier_street_name: mySupplierLocation?.street ?? null,
          supplier_postal_code: mySupplierLocation?.postal_code ?? null,
          supplier_city_id: mySupplierLocation?.city_id ?? null,
          supplier_city_name: mySupplierLocation?.city_name ?? null,
          supplier_state_id: mySupplierLocation?.state_id ?? null,
          supplier_state_name: mySupplierLocation?.state_name ?? null,
          supplier_state_abbr: mySupplierLocation?.state_abbr ?? null,
          supplier_country_id: mySupplierLocation?.country_id ?? null,
          supplier_country_name: mySupplierLocation?.country_name ?? null,
          supplier_country_code: mySupplierLocation?.country_code ?? null,
          supplier_bank_account_id: myBankAccount?.supplier_bank_account_id ?? null,
          supplier_account_alias: myBankAccount?.account_alias ?? null,
          supplier_interbank_key: myBankAccount?.interbank_key ?? null,
          supplier_swift_code: myBankAccount?.swift_code ?? null,
          supplier_bank_name: myBankAccount?.bank_name ?? null,
          supplier_account_number: myBankAccount?.account_number ?? null,
          subtotal_amount: 0,
          total_amount: 0,
          tax_amount: 0,
          project_id: project_id,
          currency_id: null,
          currency_code: null,
          currency_description: null,
          invoice_doc_type_id: myInvoiceDocumentTypeId,
          payment_mode_code: myPaymentMode?.code ?? null,
          payment_mode_description: myPaymentMode?.description ?? null,
          payment_method_code: myPaymentMethod?.code ?? null,
          payment_method_name: myPaymentMethod?.description ?? null,
          cfdi_code: myCFDI?.code ?? null,
          cfdi_description: myCFDI?.description ?? null,
          discount_amount: 0,
          supplier_tax_regime_code: mySupplierTaxRegime?.tax_regime_code,
          supplier_tax_regime_description: null,
          is_accounted: true,
          id_consecutive: myInvoiceDocumentType?.consecutive_id ?? null,
          notes: null,
          retention_amount: 0,
          payment_condition_days: null,
          payment_condition_name: null,
          payment_condition_description: null,
          payment_due_date: null,
          supplier_language: mySupplierLanguage?.language_code ?? "ES",
          supplier_email: mySupplier.supplier_email,
          supplier_phone_number: mySupplier.supplier_phone_number,
          date_mail_send: null,
          user_mail_send: null,
          company_business_name: company.business_name ?? null,
          company_street: company.street ?? null,
          company_city_id: company.city_id ?? null,
          company_city_name: company.city_name ?? null,
          company_city_code: company.city_code ?? null,
          company_state_id: company.state_id ?? null,
          company_state_name: company.state_name ?? null,
          company_state_abbr: company.state_abbr ?? null,
          company_country_id: company.country_id ?? null,
          company_country_name: company.country_name ?? null,
          company_country_code: company.country_code ?? null,
          company_postal_code: company.postal_code ?? null,
          company_rfc: company.rfc ?? null,
          company_tax_regime_id: company.tax_regime_id ?? null,
          company_tax_regime_code: company.tax_regime_code ?? null,
          company_tax_regime_description: company.tax_regime_description ?? null,
          company_email: company.email ?? null,
          company_phone_number: company.phone_number ?? null,
          xml_file_id: null,
          qr_file: null,
          report_file_id: null,
          cancel_xml_file_id: null,
          exchange_rate: myexchangeRate,
        }
        await GenericPostDocument("/purchaseinvoices/add", myData, true)
          .then(async (response) => {
            await PostPurchaseInvoiceDetails(response.data.purchase_invoice_id, newQuantity, detailName, namefromPriceParams, calculationDate)
              .then(async (responsePurchaseInvoiceDetails) => {
                if (responsePurchaseInvoiceDetails.length) {
                  let myPutData = {
                    notes: responsePurchaseInvoiceDetails.map(detail => detail.item_description).join(',')
                  }
                  let myResponsePut = await GenericPutResource(`/purchaseinvoices/${response.data.purchase_invoice_id ?? 0}`, myPutData);
                  await PostPaymentsDetails(response.data.purchase_invoice_id);
                  return myResponsePut.data;
                }
              });
          });
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  }

  const PutDateAndExchangeRatePurchaseInvoice = async (purchase_invoice_id: number, updateDate: Date, myCurrencyCode: string) => {
    const myResponseCurrencies = await GenericGetResource(`/currencies`);
    let myCurrencies: Currency[] = myResponseCurrencies.data.data;
    let myexchangeRate = 1;
    let myCurrencyPurchaseInvoice = myCurrencies.find((item) => item.currency_code === myCurrencyCode);
    if (myCurrencyCode !== "MXN") {
      const myCurrencyId = myCurrencyPurchaseInvoice?.currency_id;
      // get currency info 
      updateDate.setDate(updateDate.getDate() + 1);
      const responseExchangeRate = await GetExchangeRateFromCurrencyCode(myCurrencyId ?? 0, updateDate);
      updateDate.setDate(updateDate.getDate() - 1);
      if (responseExchangeRate?.rate !== -1) {
        myexchangeRate = responseExchangeRate?.rate ?? 1;
      }
      else {
        const myResponseExchangeRate = await GetAndPostExchangeRateFromCurrencyCodeAndDate(myCurrencyId ?? 0, updateDate);
        if (myResponseExchangeRate.rate > 1) {
          myexchangeRate = myResponseExchangeRate.rate;
        }
        else {
          myexchangeRate = 1;
        }
      }
    }
    // update purchase invoice
    let myUpdateData = {
      exchange_rate: myexchangeRate,
      purchase_invoice_date: updateDate,
      currency_id: myCurrencyPurchaseInvoice?.currency_id ?? null,
      currency_code: myCurrencyPurchaseInvoice?.currency_code ?? null,
      currency_description: myCurrencyPurchaseInvoice?.currency_description ?? null,
    }
    return await GenericPutResource(`/purchaseinvoices/${purchase_invoice_id}`, myUpdateData);
  }

  return {
    onRequestPurchaseInvoice,
    PutPurchaseInvoiceDetails,
    PutDateAndExchangeRatePurchaseInvoice,
  }
}
