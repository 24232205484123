import { useTranslation } from 'react-i18next';
import { SalesShippingDocument } from '../../../../interfaces/Sales/ShippingDocuments/SalesShippingDocuments';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { TextField } from '@mui/material';

type AnnotationsSalesShippingDocumentProps = {
  control: Control<SalesShippingDocument>,
  errors: FieldErrors<SalesShippingDocument>,
  isEdit?: boolean,
}

export const AnnotationsSalesShippingDocument = ({
  control,
  errors,
  isEdit,
}: AnnotationsSalesShippingDocumentProps) => {
  const { GetError } = FormsErrors();
  const [t] = useTranslation("global");

  return (
    <>
      <div className='w-100'>
        <Controller
          name="notes"
          control={control}
          rules={{}}
          render={({ field }) => (
            <TextField
              variant="filled"
              label={`${t("salesshippingdocuments.fields.notes")}`}
              ref={field.ref}
              value={field.value}
              multiline
              minRows={5}
              onChange={(event) => { field.onChange(event.target.value) }}
              error={!!errors.notes}
              helperText={GetError(errors.notes?.type)}
              size="small"
              sx={{ width: "100%", paddingRight: 2 }}
              disabled={isEdit}
            />
          )}
        />
      </div>
    </>
  )
}