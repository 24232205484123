import React, { useEffect, useState } from "react";
import { TaskAccordion } from "../components/taskAccordion";
import { addDays, isAfter, isBefore, isToday, isTomorrow } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDates } from "../../../../hooks/useDates";
import { ProjectTaskDashboard } from "../../../../interfaces/Projects/projectTaskDashboard";
import { useFormulaEvaluator } from "../../../../hooks/useFormulaEvaluator";
import GenericPromises from "../../../../api/GenericPromises";
import { TaskState } from "../../../../interfaces/Projects/Catalogs/taskStates";
import { ProjectStage } from "../../../../interfaces/Projects/Catalogs/projectStages";
import { Project } from "../../../../interfaces/Projects/projects";
import { Parameter } from "../../../../interfaces/Commons/parameters";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { UpdateProjectTask } from "../../Catalogs/ProjectDetails/Details/ProjectTask/update";
import { Menuitem } from "../../../../interfaces/Security/menu";
import { FieldType } from "../../../../interfaces/Projects/Catalogs/fieldTypes";
import { Header } from "../../../../components/Header";

interface TableProjectTaskAccordionsProps {
  projectTasksLocal: ProjectTaskDashboard[];
  color: number[] | undefined;
  isEdit: boolean;
  taskStatesLocal: TaskState[];
  projectStagesLocal: ProjectStage[];
  projectsLocal: Project[];
  parametersLocal: Parameter[];
  handleUpdateTask: (updateTask: ProjectTaskDashboard) => void;
  handleDeleteTask: (deleteRow: number) => void;
  pendingState: TaskState | undefined;
  doneState: TaskState | undefined;
  resourceScreen: Menuitem;
  comboFieldTypes: FieldType[]
}

export const TableProjectTaskAccordions = ({
  projectTasksLocal,
  color,
  isEdit,
  taskStatesLocal,
  projectStagesLocal,
  projectsLocal,
  parametersLocal,
  handleUpdateTask,
  handleDeleteTask,
  pendingState,
  doneState,
  resourceScreen,
  comboFieldTypes
}: TableProjectTaskAccordionsProps) => {
  const [todaysProjectTasks, setTodaysProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [tomorrowsProjectTasks, setTomorrowsProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [earlierProjectTasks, setEarlierProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [laterProjectTasks, setLaterProjectTasks] = useState<ProjectTaskDashboard[]>([]);
  const [noDueDateTasks, setNoDueDateTasks] = useState<ProjectTaskDashboard[]>([]);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { GenericGetResource } = GenericPromises();
  const [openDialogUpdateTask, setOpenDialogUpdateTask] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>();
    const [projectProjectTask, setProjectProjectTask] = useState<Project>();
  const { calculateDate, fetchParameters, replaceVariables } = useFormulaEvaluator();

  const fetchProjectId = async (projectStageId: number): Promise<number> => {
    const response = await GenericGetResource(`/projectstages/${projectStageId}`);
    return response.data.project_id;
  };

  const fetchProjectExtraFields = async (projectId: number): Promise<any> => {
    const response = await GenericGetResource(
      `/projectextrafields/byprojectid/${projectId}`
    );
    return response.data.data;
  };


  const categorizeTasks = async (tasks: ProjectTaskDashboard[]) => {
    const today = new Date();
    const yesterday = addDays(today, -1);
    const tomorrow = addDays(today, 1);

    const todaysTasks: ProjectTaskDashboard[] = [];
    const tomorrowsTasks: ProjectTaskDashboard[] = [];
    const earlierTasks: ProjectTaskDashboard[] = [];
    const laterTasks: ProjectTaskDashboard[] = [];
    const noDueDateTasksList: ProjectTaskDashboard[] = [];

    const myParameters = await fetchParameters();

    for (const task of tasks) {
      let dueDate;
      if (task.project_task_due_date) {
        if (task.project_task_due_date_field_type_id === 4 && task.project_stage_id) {
          try {
            const project_id = await fetchProjectId(task.project_stage_id);
            const extraFields = await fetchProjectExtraFields(project_id);
            const response = calculateDate(
              replaceVariables(task.project_task_due_date, extraFields, myParameters)
            );
            if (typeof response === "number") {
              dueDate = new Date(response);
            }
          } catch (error) {
            console.error("Error evaluating formula for task:", error);
          }
        } else {
          dueDate = TimeConverter(task.project_task_due_date.toString());
        }
      }

      if (dueDate) {
        if (isToday(dueDate)) {
          todaysTasks.push(task);
        } else if (isTomorrow(dueDate)) {
          tomorrowsTasks.push(task);
        } else if (isBefore(dueDate, yesterday)) {
          earlierTasks.push(task);
        } else if (isAfter(dueDate, tomorrow)) {
          laterTasks.push(task);
        }
      } else {
        noDueDateTasksList.push(task);
      }
    }

    setTodaysProjectTasks(todaysTasks);
    setTomorrowsProjectTasks(tomorrowsTasks);
    setEarlierProjectTasks(earlierTasks);
    setLaterProjectTasks(laterTasks);
    setNoDueDateTasks(noDueDateTasksList);
  };


  useEffect(() => {
    categorizeTasks(projectTasksLocal);
  }, [projectTasksLocal]);

  return (
    <>
      <TaskAccordion title={t("projecttaskdashboard.accordions.earlier")}
        tasks={earlierProjectTasks}
        color={color}
        isEdit={isEdit}
        expanded={expandedAccordion === "earlier"}
        setExpandedAccordion={setExpandedAccordion}
        identifier="earlier"
        taskStatesLocal={taskStatesLocal}
        projectStagesLocal={projectStagesLocal}
        projectsLocal={projectsLocal}
        parametersLocal={parametersLocal}
        handleUpdateTask={handleUpdateTask}
        pendingState={pendingState}
        doneState={doneState}
        setProjectProjectTask={setProjectProjectTask}
        setOpenDialogUpdateTask={setOpenDialogUpdateTask}
        setSelectedTaskId={setSelectedTaskId}
      />
      <TaskAccordion title={t("projecttaskdashboard.accordions.today")}
        tasks={todaysProjectTasks}
        color={color}
        isEdit={isEdit}
        expanded={expandedAccordion === "today"}
        setExpandedAccordion={setExpandedAccordion}
        identifier="today"
        taskStatesLocal={taskStatesLocal}
        projectStagesLocal={projectStagesLocal}
        projectsLocal={projectsLocal}
        parametersLocal={parametersLocal}
        handleUpdateTask={handleUpdateTask}
        pendingState={pendingState}
        doneState={doneState}
        setProjectProjectTask={setProjectProjectTask}
        setOpenDialogUpdateTask={setOpenDialogUpdateTask}
        setSelectedTaskId={setSelectedTaskId}
      />
      <TaskAccordion title={t("projecttaskdashboard.accordions.tomorrow")}
        tasks={tomorrowsProjectTasks}
        color={color}
        isEdit={isEdit}
        expanded={expandedAccordion === "tomorrow"}
        setExpandedAccordion={setExpandedAccordion}
        identifier="tomorrow"
        taskStatesLocal={taskStatesLocal}
        projectStagesLocal={projectStagesLocal}
        projectsLocal={projectsLocal}
        parametersLocal={parametersLocal}
        handleUpdateTask={handleUpdateTask}
        pendingState={pendingState}
        doneState={doneState}
        setProjectProjectTask={setProjectProjectTask}
        setOpenDialogUpdateTask={setOpenDialogUpdateTask}
        setSelectedTaskId={setSelectedTaskId}
      />
      <TaskAccordion title={t("projecttaskdashboard.accordions.later")}
        tasks={laterProjectTasks}
        color={color}
        isEdit={isEdit}
        expanded={expandedAccordion === "later"}
        setExpandedAccordion={setExpandedAccordion}
        identifier="later"
        taskStatesLocal={taskStatesLocal}
        projectStagesLocal={projectStagesLocal}
        projectsLocal={projectsLocal}
        parametersLocal={parametersLocal}
        handleUpdateTask={handleUpdateTask}
        pendingState={pendingState}
        doneState={doneState}
        setProjectProjectTask={setProjectProjectTask}
        setOpenDialogUpdateTask={setOpenDialogUpdateTask}
        setSelectedTaskId={setSelectedTaskId}
      />
      <TaskAccordion title={t("projecttaskdashboard.accordions.no-date")}
        tasks={noDueDateTasks}
        color={color}
        isEdit={isEdit}
        expanded={expandedAccordion === "no-date"}
        setExpandedAccordion={setExpandedAccordion}
        identifier="no-date"
        taskStatesLocal={taskStatesLocal}
        projectStagesLocal={projectStagesLocal}
        projectsLocal={projectsLocal}
        parametersLocal={parametersLocal}
        handleUpdateTask={handleUpdateTask}
        pendingState={pendingState}
        doneState={doneState}
        setProjectProjectTask={setProjectProjectTask}
        setOpenDialogUpdateTask={setOpenDialogUpdateTask}
        setSelectedTaskId={setSelectedTaskId}
      />
      <DialogEntity
        content={
          <UpdateProjectTask
            permissions={resourceScreen}
            setOpenDialogUpdateTask={setOpenDialogUpdateTask}
            handleUpdateTask={handleUpdateTask}
            handleDeleteTask={handleDeleteTask}
            project_id={projectProjectTask?.project_id}
            projectTaskPayload={
              projectTasksLocal?.find(
                (task) => task.project_task_id === selectedTaskId
              ) || {}
            }
            comboFieldTypes={comboFieldTypes}
          />
        }
        open={openDialogUpdateTask}
        setOpen={setOpenDialogUpdateTask}
        title={
          <Header
            title={t("projecttasks.title-view")}
            size="sm"
          />
        }
      />
    </>
  );
};
