import { Project } from "../../interfaces/Projects/projects";

export const useProjectStageColor = () => {

    const parseDate = (value?: string | Date | null): Date | null => {
        if (!value) return null;
        return new Date(value);
    };

    const determineStageColor = (
        startDate: Date | null,
        dueDate: Date | null,
        currentStage: Project
    ): string | null => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!startDate || !dueDate) return null;
        if(today < startDate) return 'gray';
        if(startDate <= today && dueDate > today && !currentStage.is_done) return "yellow";
        if(currentStage.is_done) return "green";
        if(today >= dueDate && !currentStage.is_done) return "red";
        return null;
      
    };

    const setProjectStageData = (currentStage: Project) => {

        const startDate = parseDate(currentStage.project_stage_started_date);
        const dueDate = parseDate(currentStage.project_stage_due_date);

        return determineStageColor(
            startDate,
            dueDate,
            currentStage
        );
    }

    const getProjectStageColor = async (stage: Project) => {

        let color=null;
        color = setProjectStageData(stage);
        return color;
    };

    return {
        getProjectStageColor,
    };
};
