import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../api/GenericPromises";
import { useCallback, useEffect, useState } from "react";
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from "@mui/x-data-grid";
import { Header } from "../../../components/Header";
import { PrimaryButton } from "../../../theme/buttons";
import { usePermissions } from "../../../hooks/usePermissions";
import { BankAccount } from "../../../interfaces/Commons/bankAccounts";
import { Menuitem } from "../../../interfaces/Security/menu";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import DataTable from "../../../components/Tables/GridTableMaterialUI/DataTable";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { useDates } from "../../../hooks/useDates";

export const TableBankAcounts = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [bankAccountsData, setBankAccountsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { GetResourceByUrl } = usePermissions();
  const [columns, setColumns] = useState<GridColDef<BankAccount>[]>([
    { field: 'account_alias', headerName: t("bankaccounts.fields.account_alias"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'bank_name', headerName: t("bankaccounts.fields.bank_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'card_number', headerName: t("bankaccounts.fields.card_number"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'account_number', headerName: t("bankaccounts.fields.account_number"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'interbank_key', headerName: t("bankaccounts.fields.interbank_key"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'swift_code', headerName: t("bankaccounts.fields.swift_code"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'bank_references', headerName: t("bankaccounts.fields.bank_references"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'phone_number', headerName: t("bankaccounts.fields.phone_number"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const methodUsed = useCallback(() => {
    switch (location.state.method) {
      case "add":
        {
          showSnackBar(t("generic.snackbar.add"), "success");
          break;
        }
      case "delete":
        {
          showSnackBar(t("generic.snackbar.delete"), "success");
          break;
        }
    }

  }, [location.state]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-bankaccounts");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-bankaccounts-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] == 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<BankAccount, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        if (element.field) { }
        else {
          element.headerName = t(`bankaccounts.fields.${element.field}`);
        }
      }
      setColumns(myJson);
    }
  }

  useEffect(() => {
    if (location.state !== null && location.state.method) methodUsed();
    GenericGetResource(`/bankaccounts`)
      .then(
        (response) => {
          setBankAccountsData(response.data.data);

          GetResourceByUrl(`/bankaccounts`)
            .then((response1) => {
              setResourceScreen((prev) => response1);
              loadColumnsOrder();
              loadPreferences();
              setDataLoaded(true);
            })
            .catch((error) => {
              setMessageSnack(error.message);
              showSnackBar(error.message, 'error');
              setUnauthorized(false);
            });
        }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, 'error');
        setUnauthorized(false);
      });
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container'>
        <Header title={t("bankaccounts.title")} />
        {resourceScreen?.create === true &&
          <div className='d-flex flex-row-reverse my-1'>
            <PrimaryButton variant='outlined' className="my-1" onClick={() => { navigate("add", { state: { ...location.state } }) }}>{t("generic.buttons.add")}</PrimaryButton>
          </div>
        }
        <div className="d-flex justify-content-center">
          <DataTable columns={columns} setColumns={setColumns} data={bankAccountsData} entityId={"bank_account_id"} entity={`BankAccount`} preferences={myPreferences} namePreferences={"grid-bankaccounts"} nameOrderColumns={"grid-bankaccounts-columns"} />
        </div>
        <SnackbarComponent />
      </div>
      }
    </>
  )
}
