import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../../components/Header';
import DataTable from '../../../../../components/Tables/GridTableMaterialUI/DataTable';
import { PrimaryButton } from '../../../../../theme/buttons';
import { AuthContext } from '../../../../../context/AuthContext';
import { DialogEntity } from '../../../../../components/Dialogs/DialogEntity';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../../../hooks/useDates';
import { CustomerBankAccount } from '../../../../../interfaces/Sales/Catalogs/Customers/customers';
import { AddCustomerBankAccounts } from './add';
import { UpdateCustomerBankAccounts } from './update';

export const TableCustomerBankAccounts = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const { TimeConverter } = useDates();
  const [numTemp, setNumTemp] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [customerBankAccountsData, setCustomerBankAccountsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const [customerBankAccountPayload, setCustomerBankAccountPayload] = useState({});
  const [shouldLoadBankAccounts, setShouldLoadBankAccounts] = useState(true);
  const [columns, setColumns] = useState<GridColDef<CustomerBankAccount>[]>([
    {
      field: 'account_alias', headerName: t("bankaccounts.fields.account_alias"), headerClassName: 'header-grid-table', flex: 1,
    },
    {
      field: 'bank_name', headerName: t("bankaccounts.fields.bank_name"), headerClassName: 'header-grid-table', flex: 1,
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-customerbankaccounts");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-customerbankaccounts-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] == 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CustomerBankAccount, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === "main_location") {
            element.valueGetter = function (params: GridValueGetterParams<CustomerBankAccount, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field !== "last_update_user") {
            element.headerName = t(`bankaccounts.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  const handleAddRow = (newRow: CustomerBankAccount) => {
    setShouldLoadBankAccounts(true);
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setCustomerBankAccountsData((prevState) => [...customerBankAccountsData, myRow]);
    if (props.add) {
      setNumTemp(numTemp + 1);
    }
  };

  const handleUpdateRow = (updateRow: CustomerBankAccount) => {
    setShouldLoadBankAccounts(true);
    if (customerBankAccountsData.length === 0) {
      return;
    }
    setCustomerBankAccountsData((prevRows) => {
      const rowToUpdateIndex = customerBankAccountsData.findIndex(e => e.customer_bank_account_id === updateRow.customer_bank_account_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    setShouldLoadBankAccounts(true);
    if (customerBankAccountsData.length === 0) {
      return;
    }
    if(customerBankAccountsData.length <= 1 ){
      showSnackBar(t("bankaccounts.error.least_one_bank_account"), "error");
    }
    setCustomerBankAccountsData((prevRows) => {
      const rowToDeleteIndex = customerBankAccountsData.findIndex(e => e.customer_bank_account_id === deleteRow);
      return [
        ...customerBankAccountsData.slice(0, rowToDeleteIndex),
        ...customerBankAccountsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    if (shouldLoadBankAccounts === true) {
      if (!props.add) {
        GenericGetResource("/customerbankaccounts/bycustomerid/" + props.customer_id)
          .then(
            (response) => {
              setCustomerBankAccountsData(response.data.data);

              GetResourceByUrl("/customerbankaccounts")
                .then((response) => {
                  setResourceScreen((prev) => response);
                  loadColumnsOrder();
                  loadPreferences();
                  setDataLoaded(true);
                  setShouldLoadBankAccounts(false);
                })
                .catch((error) => {
                  showSnackBar(error.message, "error");
                  setMessageSnack(error.message);
                  setUnauthorized(false);
                });
            }
          ).catch((error) => {
            showSnackBar(error.message, "error");
            setMessageSnack(error.message);
            setUnauthorized(false);
          });
      }
      else {
        if (props.BankAccountsData.length > 0 && customerBankAccountsData.length < 1) {
          setCustomerBankAccountsData(props.BankAccountsData);
          setNumTemp(props.BankAccountsData.length + 1);
        }
        else {
          props.setBankAccountsData(customerBankAccountsData);
          setNumTemp(customerBankAccountsData.length + 1);
        }
        GetResourceByUrl(`/customerbankaccounts`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            loadColumnsOrder();
            loadPreferences();
            setDataLoaded(true);
            setShouldLoadBankAccounts(false);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      }
    }
  }, [handleDeleteRow, handleAddRow, handleUpdateRow]);

  return (
    <div className='d-flex w-100 justify-content-center'>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && resourceScreen?.read && <div className='screen-container w-100'>
        {resourceScreen?.create === true &&
          <div className='d-flex flex-row-reverse my-1'>
            <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
          </div>
        }
        <div className="d-flex justify-content-center">
          <DataTable
            columns={columns}
            setColumns={setColumns}
            data={customerBankAccountsData}
            entityId={"customer_bank_account_id"}
            entity={`CustomerBankAccounts`}
            preferences={myPreferences}
            namePreferences={"grid-customerbankaccounts"}
            nameOrderColumns={"grid-customerbankaccounts-columns"}
            isChildren={true}
            setOpenDialog={setOpenDialogUpdate}
            setDataRow={setCustomerBankAccountPayload}
          />
        </div>
        <DialogEntity
          content={<AddCustomerBankAccounts numTemp={numTemp} add={props.add} openDialog={openDialogAdd} setOpenDialog={setOpenDialogAdd} customer_id={props.customer_id} AddNewRow={handleAddRow} />}
          open={openDialogAdd}
          setOpen={setOpenDialogAdd}
          title={<Header title={t("customerbankaccounts.title-view")} size='md' />}
        />
        <DialogEntity
          content={<UpdateCustomerBankAccounts numTemp={numTemp} add={props.add} customerBankAccountPayload={customerBankAccountPayload} customer_id={props.customer_id} setOpenDialog={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} />}
          open={openDialogUpdate}
          setOpen={setOpenDialogUpdate}
          title={<Header title={t("customerbankaccounts.title-view")} size='md' />}
        />
        <SnackbarComponent />
      </div>
      }
    </div>
  )
}