import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { AuthContext } from '../../../../../context/AuthContext';
import { useDates } from '../../../../../hooks/useDates';
import { Menuitem } from '../../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { ProjectExtraFieldTemplate } from '../../../../../interfaces/Projects/Catalogs/Templates/projectExtraFieldsTemplate';
import { GridColDef, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { PrimaryButton } from '../../../../../theme/buttons';
import DataTable from '../../../../../components/Tables/GridTableMaterialUI/DataTable';
import { DialogEntity } from '../../../../../components/Dialogs/DialogEntity';
import { AddProjectExtraFieldsTemplate } from './add';
import { Header } from '../../../../../components/Header';
import { UpdateProjectExtraFieldsTemplate } from './update';


type TableProjectExtraFieldsTemplateProps = {
    projectExtraFieldsTemplateData: ProjectExtraFieldTemplate[],
    setProjectExtraFieldsTemplateData: Dispatch<SetStateAction<ProjectExtraFieldTemplate[]>>,
    project_template_id: number,
}

export const TableProjectExtraFieldsTemplate = ({
    projectExtraFieldsTemplateData,
    setProjectExtraFieldsTemplateData,
    project_template_id,
}: TableProjectExtraFieldsTemplateProps) => {
    const [t] = useTranslation("global");
    const location = useLocation();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { user } = useContext(AuthContext);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { TimeConverter } = useDates();
    const [unauthorized, setUnauthorized] = useState(true);
    const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
    const [openDialogAdd, setOpenDialogAdd] = useState(false);
    const [projectExtraFieldTemplatePayload, setProjectExtraFieldTemplatePayload] = useState({});
    const [resourceScreen, setResourceScreen] = useState<Menuitem>();
    const [messageSnack, setMessageSnack] = useState("");
    const [myPreferences, setMyPreferences] = useState({});
    const { GetResourceByUrl } = usePermissions();
    const [columnsProjectExtraFieldsTemplate, setColumnsProjectExtraFieldsTemplate] = useState<GridColDef<ProjectExtraFieldTemplate>[]>([
        { field: 'project_extra_field_template_name', headerName: t("projectextrafieldstemplate.fields.project_extra_field_template_name"), headerClassName: 'header-grid-table', flex: 1, },
        { field: 'field_type_name', headerName: t("projectextrafieldstemplate.fields.field_type"), headerClassName: 'header-grid-table', flex: 1, },
        { field: 'project_extra_field_template_order', headerName: t("projectextrafieldstemplate.fields.project_extra_field_template_order"), headerClassName: 'header-grid-table', flex: 1, },
        {
            field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
        { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
    ]);

    const methodUsed = useCallback(() => {
        switch (location.state.method) {
            case "add":
                {
                    showSnackBar(t("generic.snackbar.add"), "success");
                    break;
                }
            case "delete":
                {
                    showSnackBar(t("generic.snackbar.delete"), "success");
                    break;
                }
        }

    }, [location.state, t]);

    const loadPreferences = async () => {
        let myPreferences = await localStorage.getItem("grid-projectextrafieldstemplate-items");
        if (myPreferences != null) {
            setMyPreferences(JSON.parse(myPreferences));
        }
    }

    const loadColumnsOrder = async () => {
        let myOrderColumns = await localStorage.getItem("grid-projectextrafieldstemplate-columns");
        if (myOrderColumns != null) {
            let myJson = JSON.parse(myOrderColumns);
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index];
                if (element['type'] === 'date') {
                    element.headerName = t(`generic.${element.field}`);
                    element.valueGetter = function (params: GridValueGetterParams<ProjectExtraFieldTemplate, any, GridTreeNodeWithRender>) {
                        return TimeConverter(params.value);
                    };
                }
                if (element.field) {

                }
                else {
                    element.headerName = t(`projectextrafieldstemplate.fields.${element.field}`);
                }
            }
            setColumnsProjectExtraFieldsTemplate(myJson);
        }
    }

    const handleAddRow = (newRow: ProjectExtraFieldTemplate) => {
        let myRow = {
            ...newRow,
            creation_date: new Date(),
            last_update_user: user?.user_email,
            last_update_date: new Date()
        }
        setProjectExtraFieldsTemplateData((prevState) => [...projectExtraFieldsTemplateData, myRow]);
    };

    const handleUpdateRow = (updateRow: ProjectExtraFieldTemplate) => {
        if (projectExtraFieldsTemplateData.length === 0) {
            return;
        }
        setProjectExtraFieldsTemplateData((prevRows) => {
            const rowToUpdateIndex = projectExtraFieldsTemplateData.findIndex(e => e.project_extra_field_template_id === updateRow.project_extra_field_template_id);

            return prevRows.map((row, index) =>
                index === rowToUpdateIndex ? updateRow : row,
            );
        });
    };

    const handleDeleteRow = (deleteRow: number) => {
        if (projectExtraFieldsTemplateData.length === 0) {
            return;
        }
        setProjectExtraFieldsTemplateData((prevRows) => {
            const rowToDeleteIndex = projectExtraFieldsTemplateData.findIndex(e => e.project_extra_field_template_id === deleteRow);
            return [
                ...projectExtraFieldsTemplateData.slice(0, rowToDeleteIndex),
                ...projectExtraFieldsTemplateData.slice(rowToDeleteIndex + 1),
            ];
        });
    };

    useEffect(() => {
        if (location.state !== null && location.state.method) methodUsed();

        GetResourceByUrl(`/projectextrafieldstemplate`)
            .then((response1) => {
                setResourceScreen((prev) => response1);
                loadColumnsOrder();
                loadPreferences();
                setDataLoaded(true);
            })
            .catch((error) => {
                setMessageSnack(error.message);
                showSnackBar(error.message, 'error');
                setUnauthorized(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../../assets/img/error.webp")} /> <h2>{messageSnack}</h2> </div>}
            {unauthorized && !dataLoaded && <Spinner isBox={false} />}
            {dataLoaded && resourceScreen?.read &&
                <>
                    <div className='screen-container'>
                        {resourceScreen?.create === true &&
                            <div className='d-flex flex-row-reverse my-1'>
                                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
                            </div>
                        }
                        <div className="d-flex justify-content-center">
                            <DataTable
                                columns={columnsProjectExtraFieldsTemplate}
                                setColumns={setColumnsProjectExtraFieldsTemplate}
                                data={projectExtraFieldsTemplateData}
                                entityId={"project_extra_field_template_id"}
                                entity={`ProjectExtraFieldsTemplate`}
                                preferences={myPreferences}
                                namePreferences={"grid-projectextrafieldstemplate"}
                                nameOrderColumns={"grid-projectextrafieldstemplate-columns"}
                                isChildren={true}
                                setOpenDialog={setOpenDialogUpdate}
                                setDataRow={setProjectExtraFieldTemplatePayload}
                            />
                        </div>
                    </div>
                    <DialogEntity
                        content={<UpdateProjectExtraFieldsTemplate projectExtraFieldTemplatePayload={projectExtraFieldTemplatePayload} setOpenDialogUpdate={setOpenDialogUpdate} permissions={resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} />}
                        open={openDialogUpdate}
                        setOpen={setOpenDialogUpdate}
                        title={<Header title={t("projectextrafieldstemplate.title-view")} size='sm' />}
                    />
                    <DialogEntity
                        content={<AddProjectExtraFieldsTemplate setOpenDialog={setOpenDialogAdd} project_template_id={project_template_id} AddNewRow={handleAddRow}/>}
                        open={openDialogAdd}
                        setOpen={setOpenDialogAdd}
                        title={<Header title={t("projectextrafieldstemplate.title-view")} size='sm' />}
                    /> 
                    <SnackbarComponent />
                </>
            }
        </>
    )
}
