import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTasks";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { useTranslation } from "react-i18next";
import { TaskState } from "../../../../../../interfaces/Projects/Catalogs/taskStates";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { useDates } from "../../../../../../hooks/useDates";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../../../components/Header";
import { ConfirmDialog } from "../../../../ProjectTaskDashboard/components/dialogs/confirmDialog";
import { format, parseISO } from "date-fns";
import { useFormulaEvaluator } from "../../../../../../hooks/useFormulaEvaluator";
import { useBase64 } from "../../../../../../hooks/useBase64";
import { useProjectTaskColor } from "../../../../../../hooks/useProjectTaskColor";
import { ProjectExtraField } from "../../../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Parameter } from "../../../../../../interfaces/Commons/parameters";
import { useCalculateDateByFormula } from "../../../../../../hooks/useCalculateDateByFormula";

interface TaskItemProps {
  task: ProjectTask;
  color: number[] | undefined;
  setSelectedTaskId: (id: number) => void;
  setOpenDialogUpdateTask: (open: boolean) => void;
  isEdit: boolean;
  handleUpdateTask: (updateRow: ProjectTask) => void;
  taskStates: TaskState[],
  pendingState: TaskState | undefined,
  doneState: TaskState | undefined,
  projectExtraFieldsData: ProjectExtraField[]
  parameters: Parameter[];
  projectTaskLocal: ProjectTask[];
  setDialogConfirmChangeStageState: React.Dispatch<React.SetStateAction<boolean>>;
  resetProjecTasks: boolean;
}

export const TaskItem = ({
  task,
  color,
  setSelectedTaskId,
  setOpenDialogUpdateTask,
  isEdit,
  handleUpdateTask,
  taskStates,
  pendingState,
  doneState,
  projectExtraFieldsData,
  parameters,
  projectTaskLocal,
  setDialogConfirmChangeStageState,
  resetProjecTasks,
}: TaskItemProps) => {
  const [t] = useTranslation("global");
  const [onChecked, setOnChecked] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource, GenericPostResource, GenericPutResource } = GenericPromises();
  const [dataloaded, setDataLoaded] = useState(false);
  const { TimeConverter } = useDates();
  const [lastUpdateUser, setLastUpdateUser] = useState<string>();
  const [lastUpdateDate, setLastUpdateDate] = useState<string>();
  const [dialogConfirmUncheckTask, setDialogConfirmUncheckTask] = useState(false);
  const [projectTaskStartedDate, setProjectTaskStartedDate] = useState<Date | string>()
  const [projectTaskDueDate, setProjectTaskDueDate] = useState<Date | string>()
  const { getProjectTaskColor } = useProjectTaskColor();
  const { calculateDateByFormula } = useCalculateDateByFormula();
  const [taskColor, setTaskColor] = useState<string>();


  const fetchLastTaskState = async () => {
    GenericGetResource(`/projecttaskstates/lastprojecttaskstatebyprojecttaskid/${task.project_task_id}`)
      .then((response) => {
        if (response.data.data.length > 0) {
          const lastState = response.data.data[0];
          setLastUpdateUser(lastState.last_update_user);
          setLastUpdateDate(lastState.last_update_date);
          return;
        }
        setLastUpdateUser(task.last_update_user);
        setLastUpdateDate(task.last_update_date?.toString());
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  };

  const formatDates = (date: string) => {
    const dateConverted = TimeConverter(date.toString())
    return dateConverted ? format(dateConverted, 'dd/MM/yyyy HH:mm:ss') : ' ';
  }

  const updateTaskDateAndColor = async (skipFieldTypeValidation = false) => {

    if (!task.project_task_started_date && !task.project_task_due_date) {
      setProjectTaskStartedDate('');
      setProjectTaskDueDate('');
      return;
    }
    if (!task.project_task_started_date) {
      setProjectTaskStartedDate('');
    }
    if (!task.project_task_due_date) {
      setProjectTaskDueDate('');
    }

    if (projectExtraFieldsData.length > 0 && parameters.length > 0) {
      const originalStartedDate = task.project_task_started_date;
      const originalDueDate = task.project_task_due_date;
      try {
        const calculatedStartedDate = await calculateDateByFormula(task.project_task_started_date_field_type_id, task.project_task_started_date, parameters, projectExtraFieldsData, undefined);
        const calculatedDueDate = await calculateDateByFormula(task.project_task_due_date_field_type_id, task.project_task_due_date, parameters, projectExtraFieldsData, undefined);
        if (calculatedStartedDate) {
          task.project_task_started_date = calculatedStartedDate;
          setProjectTaskStartedDate(format(new Date(calculatedStartedDate), 'dd/MM/yyyy HH:mm:ss'));
        }
        if (calculatedDueDate) {
          task.project_task_due_date = calculatedDueDate;
          setProjectTaskDueDate(format(new Date(calculatedDueDate), 'dd/MM/yyyy HH:mm:ss'));
        }
        if (pendingState && doneState) {
          if (task.project_task_started_date_field_type_id === 4 || task.project_task_due_date_field_type_id === 4 || skipFieldTypeValidation) {
            const color = await getProjectTaskColor(task, pendingState, doneState);
            if (color) {
              task.project_task_color = color;
              setTaskColor(task.project_task_color)
            }
          }
        }
      } finally {
        task.project_task_started_date = originalStartedDate;
        task.project_task_due_date = originalDueDate;
      }
    }
  };

  const verifyAllTaskCompleted = () => {
    const allDone = projectTaskLocal.every(
      (t) =>
        t.project_task_id === task.project_task_id || // Ignorar la tarea actual
        t.current_task_state_id === doneState?.task_state_id
    );
    if (!allDone) {
      handleTaskCompletion();
      return;
    }
    setDialogConfirmChangeStageState(true)

  }

  const handleTaskCompletion = () => {

    const currentState = taskStates.find(
      (state) => state.task_state_id === task.current_task_state_id
    );

    const nextState = taskStates.find(
      (state) => state.task_state_name === (onChecked ? "PENDING" : "DONE")
    );

    if (nextState?.task_state_id && currentState?.task_state_id) {
      setLoadingPut(true);
      const myData = {
        previous_state_id: currentState.task_state_id,
        new_state_id: nextState.task_state_id,
        project_task_id: task.project_task_id,
        project_task_state_name: task.project_task_name
      }
      GenericPostResource(`/projecttaskstates`, myData)
        .then((response) => {
          showSnackBar(t("projecttasks.messages.state_updated"), "success");
          setOnChecked(!onChecked);
          if (!onChecked) fetchLastTaskState();
          GenericGetResource(`/projecttask/${response.data.project_task_id}`)
            .then((response2) => {
              task = response2.data;
              handleUpdateTask(response2.data)
              setTaskColor(response2.data.project_task_color);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
            })
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
          setOnChecked(!onChecked);
        })
        .finally(() => setLoadingPut(false));
    }

  };

  useEffect(() => {
    if (taskStates) {
      const currentState = taskStates.find(
        (state: TaskState) =>
          state.task_state_id === task.current_task_state_id
      );
      if (currentState && currentState.task_state_name === "DONE") {
        setOnChecked((prevChecked) => true);
      } else {
        setOnChecked((prevChecked) => false);
      }
      fetchLastTaskState();
      setTaskColor(task.project_task_color);
      updateTaskDateAndColor();
      setDataLoaded(true);
    }
  }, [task]);

  useEffect(() => {
    setDataLoaded(false)
    updateTaskDateAndColor(true);
    setDataLoaded(true)
  }, [task.current_task_state_id]);



  const taskItemStyles = {
    border: `2px solid ${taskColor}`,
    borderRadius: `8px`,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    backgroundColor: color
      ? `rgba(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.2)`
      : "rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: color
        ? `rgba(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.09)`
        : "rgba(0, 0, 0, 0.1)",
    },
    display: "flex",
    alignItems: "center",
    padding: "8px",
    transition: "all 0.3s ease",
  };

  useEffect(() => {
    updateTaskDateAndColor();
    setDataLoaded(true);
  }, [projectExtraFieldsData, task]);

  return (
    <>
      {!dataloaded && (
        <div className="w-100 my-2">
          <Spinner isBox={false} />
        </div>
      )}
      {dataloaded && (
        <Grid item key={task.project_task_id} xs={12}>
          <Box
            sx={taskItemStyles}
            onClick={() => {
              if (task.project_task_id) {
                setSelectedTaskId(task.project_task_id);
                setOpenDialogUpdateTask(true);
              }
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: 0.1 }}
              control={
                <Checkbox
                  checked={onChecked}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (onChecked) {
                      setOpenDialogUpdateTask(false);
                      setDialogConfirmUncheckTask(true);
                      return
                    }
                    setOpenDialogUpdateTask(false);
                    verifyAllTaskCompleted();
                  }}
                  icon={<CircleOutlined />}
                  checkedIcon={<CheckCircle />}
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.main",
                    },
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={<></>}
              disabled={loadingPut}
            />
            <div>
              <div className="d-flex"
                style={{ alignItems: "end" }}>
                <Typography
                  style={{
                    textDecoration: onChecked ? "line-through" : "none",
                    color: onChecked ? "gray" : "inherit",
                    marginRight: "8px",
                  }}
                >
                  {task.project_task_name}
                </Typography>
                <span
                  style={{
                    marginRight: "8px",
                    color: "gray",
                  }}
                >
                  -
                </span>
                {lastUpdateUser && lastUpdateDate && (

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    {' '}  {`${lastUpdateUser}`} {lastUpdateDate && formatDates(lastUpdateDate)}. {' '}
                  </Typography>
                )}

              </div>
              {lastUpdateUser && lastUpdateDate && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  {t("projectsdetails.projecttaskdetails.start_date")}: {projectTaskStartedDate ? projectTaskStartedDate.toString() : ' '}
                  <br />
                  {t("projectsdetails.projecttaskdetails.due_date")}: {projectTaskDueDate ? projectTaskDueDate.toString() : ' '}
                </Typography>
              )}
            </div>
          </Box>
        </Grid>
      )}
      <DialogEntity
        open={dialogConfirmUncheckTask}
        title={<Header title={t("projecttasks.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            projectTaskName={task.project_task_name}
            isEdit={isEdit}
            loadingPut={loadingPut}
            setDialogConfirmUncheckTask={setDialogConfirmUncheckTask}
            handleTaskCompletion={handleTaskCompletion}
          />
        }
      />
      <SnackbarComponent />
    </>
  );
};
