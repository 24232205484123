import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ButtonStyled, myStylePickers, PrimaryButton } from "../../../../../../theme/buttons";
import { Stage } from "../../../../../../interfaces/Projects/Catalogs/stages";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { DatePicker } from "@mui/x-date-pickers";
import { ProjectStage } from "../../../../../../interfaces/Projects/Catalogs/projectStages";
import { FieldType } from "../../../../../../interfaces/Projects/Catalogs/fieldTypes";


interface AddProjectStagesProps {
  handleAddStage: (newRow: ProjectStage) => void;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  project_id: number | undefined;
  comboFieldTypes: FieldType[];
  handleUpdateStage: (updateRow: ProjectStage) => void;
  setShouldReload:React.Dispatch<React.SetStateAction<boolean>>;
  shouldReload: boolean
}

export const AddProjectStages = ({
  handleAddStage,
  setOpenDialog,
  project_id,
  comboFieldTypes,
  handleUpdateStage,
  setShouldReload,
  shouldReload
}: AddProjectStagesProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboStages, setComboStages] = useState<Stage[]>([]);
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [inputStage, setInputStage] = useState("");
  const [isTypeFormulaStartedDate, setIsTypeFormulaStartedDate] = useState(false);
  const [isTypeFormulaDueDate, setIsTypeFormulaDueDate] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<ProjectStage>({
    defaultValues: {
      project_id: undefined,
      stage_id: undefined,
      MS_bucket_id: undefined,
      MS_planner_id: undefined,
      MS_etag_id: undefined,
      project_stage_name: undefined,
      project_stage_consecutive: undefined,
      project_stage_description: undefined,
      project_stage_started_date: undefined,
      project_stage_due_date: undefined,
      project_stage_started_date_field_type_id: undefined,
      project_stage_due_date_field_type_id: undefined
    },
  });

  const startedDateFieldTypeId = useWatch({
    control,
    name: "project_stage_started_date_field_type_id"
  });

  const projectStageStartedDate = useWatch({
    control,
    name: "project_stage_started_date"
  });

  const dueDateFieldTypeId = useWatch({
    control,
    name: "project_stage_due_date_field_type_id"
  });

  const onSubmit: SubmitHandler<ProjectStage> = (data) => onPost(data);

  const onPost = (data: ProjectStage) => {
    let myData = {
      project_id: project_id,
      stage_id: data.stage_id,
      MS_bucket_id: null,
      MS_planner_id: null,
      MS_etag_id: null,
      project_stage_name: data.project_stage_name,
      project_stage_consecutive:
        data.project_stage_consecutive,
      project_stage_description:
        data.project_stage_description || null,
      project_stage_started_date: data.project_stage_started_date || null,
      project_stage_due_date: data.project_stage_due_date || null,
      project_stage_started_date_field_type_id: data.project_stage_started_date_field_type_id,
      project_stage_due_date_field_type_id: data.project_stage_due_date_field_type_id
    };
    setLoadingPost(true);
    GenericPostResource("/projectstages", myData)
      .then(async (response) => {
        setShouldReload(!shouldReload);
        setLoadingPost(false);
        setOpenDialog(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  };

  const onCancel = () => {
    setOpenDialog(false);
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleStageCreation = (stage_name: string) => {
    const existingStage = comboStages.find(stage => stage.stage_name === stage_name);
    if (existingStage) return existingStage;

    let myData = {
      stage_name: stage_name,
      stage_description: null
    };
    setLoadingPost(true);
    GenericPostResource("/stages", myData)
      .then((response) => {
        setComboStages([...comboStages, response.data]);
        setLoadingPost(false);
        return response.data;
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  useEffect(() => {
    let myPromises = [GenericGetResource("/stages")];
    Promise.all(myPromises)
      .then((responses) => {
        setComboStages(responses[0].data.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="stage_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboStages}
                      getOptionLabel={(option) => `${option.stage_name}`}
                      renderOption={(props, option: Stage) => (
                        <div key={option.stage_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.stage_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.stage_id === value.stage_id
                      }
                      onInputChange={(_, newInputValue) => {
                        setInputStage(newInputValue);
                      }}
                      onChange={(_, values) => {
                        field.onChange(values?.stage_id || null);
                      }}
                      noOptionsText={
                        <Button onMouseDown={() => {
                          if (inputStage.trim()) {
                            const newStage = handleStageCreation(inputStage);
                            field.onChange(newStage?.stage_id || null);
                          }
                        }}>
                          {t("projectstages.actions.create")}
                        </Button>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstages.fields.stage")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.stage_id}
                          helperText={GetError(errors.stage_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstages.fields.project_stage_name"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_name}
                      helperText={GetError(
                        errors.project_stage_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_consecutive"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstages.fields.project_stage_consecutive"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_consecutive}
                      helperText={GetError(
                        errors.project_stage_consecutive?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstages.fields.project_stage_description"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_description}
                      helperText={GetError(
                        errors.project_stage_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_started_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaStartedDate(true);
                        } else {
                          setIsTypeFormulaStartedDate(false);
                        }
                        setValue("project_stage_started_date", "", {
                          shouldValidate: true,
                        });
                        setValue("project_stage_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstages.fields.project_stage_started_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_stage_started_date_field_type_id}
                          helperText={GetError(errors.project_stage_started_date_field_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaStartedDate ? (
                <div className="w-50">
                  <Controller
                    name="project_stage_started_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projectstages.fields.project_stage_started_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_stage_started_date}
                        helperText={GetError(
                          errors.project_stage_started_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!startedDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_stage_started_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openStartDateDatePicker}
                        onClose={() => setOpenStartDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projectstages.fields.project_stage_started_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenStartDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_stage_started_date,
                            helperText: GetError(
                              errors.project_stage_started_date?.type
                            ),
                          },
                        }}
                        format="DD/MM/YYYY"
                        disabled={!startedDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_due_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaDueDate(true);
                        } else {
                          setIsTypeFormulaDueDate(false);
                        }
                        setValue("project_stage_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstages.fields.project_stage_due_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_stage_due_date_field_type_id}
                          helperText={GetError(errors.project_stage_due_date_field_type_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaDueDate ? (
                <div className="w-50">
                  <Controller
                    name="project_stage_due_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projectstages.fields.project_stage_due_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_stage_due_date}
                        helperText={GetError(
                          errors.project_stage_due_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!projectStageStartedDate || !dueDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_stage_due_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openEndDateDatePicker}
                        onClose={() => setOpenEndDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projectstages.fields.project_stage_due_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenEndDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_stage_due_date,
                            helperText: GetError(
                              errors.project_stage_due_date?.type
                            ),
                          },
                        }}
                        minDate={dayjs(projectStageStartedDate)}
                        format="DD/MM/YYYY"
                        disabled={!projectStageStartedDate || !dueDateFieldTypeId ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-row-reverse mt-3">
              <PrimaryButton
                type="submit"
                variant="outlined"
                className="m-1"
                disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
