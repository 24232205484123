import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { usePermissions } from '../../../hooks/usePermissions';
import GenericPromises from '../../../api/GenericPromises';
import { Company } from '../../../interfaces/Security/companies';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { Grid } from '@mui/material';
import { Consecutive } from '../../../interfaces/Commons/consecutives';
import { Menuitem } from '../../../interfaces/Security/menu';
import { Customer, CustomerLocation } from '../../../interfaces/Sales/Catalogs/Customers/customers';
import { AuthContext } from '../../../context/AuthContext';
import { UserCompanies } from '../../../interfaces/Security/userCompanies';
import { SalesOrder } from '../../../interfaces/Sales/Orders/salesOrders';
import { SalesOrderAddHeader } from './addHeader';
import { Project } from '../../../interfaces/Projects/projects';

export const AddSalesOrders = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GetParamsFromBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myConsecutive, setMyConsecutive] = useState("");
  const [myConsecutiveId, setMyConsecutiveId] = useState(0);
  const { GenericPostResource, GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  // const [projectId, setProjectId] = useState("");
  const [customerId, setCustomerId] = useState<number>();
  const { user } = useContext(AuthContext);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [company, setCompany] = useState<Company>();
  const [comboCustomers, setComboCustomers] = useState<Customer[]>();
  const [isSelectedCustomerTextfield, setIsSelectedCustomerTextfield] = useState(false);
  const [resourceDelivery, setResourceDelivery] = useState<Menuitem>()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<SalesOrder>({});

  const onSubmit: SubmitHandler<SalesOrder> = async (data) => onPost(data);

  const onPost = async (data: SalesOrder) => {

    // GET and update consecutive
    const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myConsecutiveId}`);

    // GET customer
    let myCustomer = comboCustomers?.find((customer: Customer) => customer.customer_id === data.customer_id);

    // GET customer location by  customer id
    let myCustomerLocationData = await GenericGetResource(`/customerlocations/bycustomerid/${data.customer_id}`);
    let myComboCustomerLocations: CustomerLocation[] = myCustomerLocationData.data.data;
    let myCustomerLocation = myComboCustomerLocations.find((item) => item.fiscal_address === true);
    let myCustomerLocationDelivery = myComboCustomerLocations.find((item) => item.default_delivery_address === true);

    // GET Currency
    let myCurrencyData = await GenericGetResource(`/currencies/${(myCustomer?.default_currency_id ?? 0)}`);
    let myCurrency = myCurrencyData.data;

    // GET company info
    const responseCompany = await GenericGetResource("/companies/1");

    // GET sales delivery addresses
    let deliveryAdreesId: number | null = null;
    let mySalesDelivery = {
      business_name: myCustomerLocationDelivery?.business_name ?? null,
      comercial_name: null,
      street: myCustomerLocationDelivery?.street ?? null,
      postal_code: myCustomerLocationDelivery?.postal_code ?? null,
      city_id: myCustomerLocationDelivery?.city_id ?? null,
      city_name: myCustomerLocationDelivery?.city_name ?? null,
      state_id: myCustomerLocationDelivery?.state_id ?? null,
      state_name: myCustomerLocationDelivery?.state_name ?? null,
      state_abbr: myCustomerLocationDelivery?.state_abbr ?? null,
      country_id: myCustomerLocationDelivery?.country_id ?? null,
      country_name: myCustomerLocationDelivery?.country_name ?? null,
      country_code: myCustomerLocationDelivery?.country_code ?? null,
      rfc: myCustomerLocationDelivery?.customer_rfc ?? null,
      tax_regime_id: myCustomerLocationDelivery?.tax_regime_id ?? null,
      tax_regime_code: myCustomerLocationDelivery?.tax_regime_code ?? null,
      tax_regime_description: myCustomerLocationDelivery?.tax_regime_description ?? null,
      email: myCustomerLocationDelivery?.customer_email ?? null,
      phone_number: myCustomerLocationDelivery?.customer_phone_number ?? null,
    }
    if (resourceDelivery?.create) {
      let deliveryAddress = await GenericPostResource("/salesdeliveryaddresses", mySalesDelivery).catch((error) => { });
      deliveryAdreesId = (deliveryAddress !== undefined) ? deliveryAddress.data.sales_delivery_addresses_id : null;
    }

    let myData = {
      sales_preorder_id: data.sales_preorder_id ?? null,
      sales_order_name: responseConsecutive.data.data[0].Consecutive,
      sales_order_date: data.sales_order_date ?? new Date(),
      purchase_order: data.purchase_order ?? null,
      customer_id: data.customer_id ?? null,
      customer_business_name: myCustomer?.business_name,
      customer_comercial_name: myCustomer?.comercial_name,
      customer_rfc: myCustomer?.customer_rfc,
      customer_street_name: myCustomerLocation?.street ?? null,
      customer_location_id: myCustomerLocation?.customer_location_id,
      customer_postal_code: myCustomerLocation?.postal_code ?? null,
      customer_city_id: 0,
      customer_city_name: myCustomerLocation?.city_name ?? null,
      customer_state_id: 0,
      customer_state_name: myCustomerLocation?.state_name ?? null,
      customer_state_abbr: myCustomerLocation?.state_abbr ?? null,
      customer_country_id: 0,
      customer_country_name: myCustomerLocation?.country_name ?? null,
      customer_country_code: myCustomerLocation?.country_code ?? null,
      subtotal_amount: 0,
      total_amount: 0,
      tax_amount: 0,
      currency_id: data.currency_id ?? null,
      currency_code: myCurrency?.currency_code ?? null,
      currency_description: myCurrency?.currency_description ?? null,
      project_id: data.project_id ?? null,
      payment_mode_id: null,
      payment_mode_code: null,
      payment_mode_description: null,
      payment_method_id: null,
      payment_method_code: null,
      payment_method_name: null,
      cfdi_id: null,
      cfdi_code: null,
      cfdi_description: null,
      discount_amount: 0,
      customer_tax_regime_id: 0,
      customer_tax_regime_code: myCustomerLocation?.tax_regime_code ?? null,
      customer_tax_regime_description: myCustomerLocation?.tax_regime_description ?? null,
      includes_transportation_document: null,
      includes_exterior_complement: null,
      id_consecutive: myConsecutiveId,
      notes: "",
      retention_amount: 0,
      payment_condition_id: null,
      payment_condition_days: null,
      payment_condition_name: null,
      payment_condition_description: null,
      payment_due_date: null,
      customer_language: myCustomer?.language_code ?? "ES",
      customer_email: myCustomerLocation?.customer_email ?? null,
      customer_phone_number: myCustomerLocation?.customer_phone_number ?? null,
      date_mail_send: null,
      user_mail_send: null,
      company_business_name: responseCompany.data?.business_name ?? null,
      company_street: responseCompany.data?.street ?? null,
      company_city_id: responseCompany.data?.city_id ?? null,
      company_city_name: responseCompany.data?.city_name ?? null,
      company_city_code: responseCompany.data?.city_code ?? null,
      company_state_id: responseCompany.data?.state_id ?? null,
      company_state_name: responseCompany.data?.state_name ?? null,
      company_state_abbr: responseCompany.data?.state_abbr ?? null,
      company_country_id: responseCompany.data?.country_id ?? null,
      company_country_name: responseCompany.data?.country_name ?? null,
      company_country_code: responseCompany.data?.country_code ?? null,
      company_postal_code: responseCompany.data?.postal_code ?? null,
      company_rfc: responseCompany.data?.rfc ?? null,
      company_tax_regime_id: responseCompany.data?.tax_regime_id ?? null,
      company_tax_regime_code: responseCompany.data?.tax_regime_code ?? null,
      company_tax_regime_description: responseCompany.data?.tax_regime_description ?? null,
      company_email: responseCompany.data?.email ?? null,
      company_phone_number: responseCompany.data?.phone_number ?? null,
      company_account_alias: responseCompany.data?.primary_account_alias ?? null,
      company_primary_account_alias: responseCompany.data?.primary_account_alias ?? null,
      company_primary_bank_name: responseCompany.data?.primary_bank_name ?? null,
      company_primary_account_number: responseCompany.data?.primary_account_number ?? null,
      company_primary_interbank_key: responseCompany.data?.primary_interbank_key ?? null,
      company_primary_swift_code: responseCompany.data?.primary_swift_code ?? null,
      company_secondary_account_alias: responseCompany.data?.secondary_account_alias ?? null,
      company_secondary_bank_name: responseCompany.data?.secondary_bank_account ?? null,
      company_secondary_account_number: responseCompany.data?.secondary_account_number ?? null,
      company_secondary_interbank_key: responseCompany.data?.secondary_interbank_key ?? null,
      company_secondary_swift_code: responseCompany.data?.secondary_swift_code ?? null,
      total_company_currency_amount: 0,
      url_files: null,
      pdf_file_id: data.pdf_file_id ?? null,
      exchange_rate: 1,
      sales_delivery_addresses_id: deliveryAdreesId ?? null,
    }

    GenericPostResource("/salesorders", myData)
      .then(async (response) => {
        await navigate("/salesorders/view", { state: { ...location.state, row: response.data }, replace: true });
      })
      .catch(async (error) => {
        setIsSelectedCustomerTextfield(false);
        showSnackBar(error.message, "error");
        if (customerId && customerId > 0) {
          await navigate("/salesorders", { state: { ...location.state, method: "error", error: error.message }, replace: true });
        }
      });
  }

  useEffect(() => {
    let myProjectId = "";
    let myCustomerId = "";
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      myProjectId = myParams.get("project_id") ?? '';
      myCustomerId = myParams.get("customer_id") ?? '';
      // setProjectId((prev) => myProjectId ?? "");
      // setCustomerId((prev) => myCustomerId ?? );
    }
    //Buscar si hay un customer_id en la location
    else if (location.state !== null && location.state.customer_id) {
      setCustomerId((prev) => location.state.customer_id);
      reset({
        customer_id: location.state.customer_id
      }, { keepValues: true });
    }
    //Revisar si tiene un default Customer
    else {
      new Promise(async () => {
        let responseUserCompanies = await GenericGetResourceGeneric(`/usercompanies/byuserid/${user?.user_id}`, "/gusercompanies")
        let myCustomerDefault = responseUserCompanies.data.data.find((element: UserCompanies) => element.schema_name === user?.schema)
        if (myCustomerDefault && myCustomerDefault.customer_default_id) {
          setCustomerId((prev) => myCustomerDefault.customer_default_id);
          reset({
            customer_id: myCustomerDefault.customer_default_id
          }, { keepValues: true });
        }
      })
    }

    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salesorders.title")}`;
    });

    GetResourceByUrl(`/salesorders`)
      .then((responsePermission) => {
        setResourceScreen(responsePermission);
      });

    GetResourceByUrl(`/salesdeliveryaddresses`)
      .then((responsePermission) => {
        setResourceDelivery(responsePermission);
      });

  }, []);

  useEffect(() => {
    if (resourceScreen) {
      new Promise(async () => {

        let responseCompanies: any = await GenericGetResourceGeneric(`/companies`, `/gcompanies`)
        setCompany(responseCompanies.data.data[0]);

        let responseCustomers: any = await GenericGetResource(`/customers`)
        setComboCustomers(responseCustomers.data.data);

        let responseConsecutives: any = await GenericGetResource(`/consecutives`)
          .catch((error) => {
            showSnackBar(error.message, "error")
          });
        let myConsecutivesCombo: Consecutive[] = await responseConsecutives.data.data;
        let myConsecutive: Consecutive | undefined = await myConsecutivesCombo.find((item) => item.resource_name === "EntySalesOrders");
        setMyConsecutiveId(myConsecutive?.consecutive_id ?? 0);
        let consecutiveResponse = await GenericGetResource(`/consecutives/byconsecutiveid/${myConsecutive?.consecutive_id}`).catch((error) => {
          showSnackBar(error.message, "error")
        });
        setMyConsecutive((prev) => consecutiveResponse.data.data[0].Consecutive);

        // Si no tiene defaultCustomer
        if (!(customerId && customerId > 0)) {
          setDataLoaded(true);
        }
      })
    }
  }, [resourceScreen]);

  useEffect(() => {
    if (customerId && customerId > 0) {
      new Promise(async () => {
        await onPost(getValues());
      })
    }
  }, [myConsecutive]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("salesorders.title-view")} size='md' />

          {resourceScreen?.create &&
            <form onSubmit={handleSubmit(onSubmit)} className='custom-card-view'>

              <Grid container display={'flex'} flexDirection={'column'}>
                <Grid item>
                  <SalesOrderAddHeader
                    control={control}
                    errors={errors}
                    myConsecutive={myConsecutive}
                    comboCustomers={comboCustomers}
                    company={company}
                    onPost={onPost}
                    getValues={getValues}
                    setValue={setValue}
                    isSelectedCustomerTextfield={isSelectedCustomerTextfield}
                    setIsSelectedCustomerTextfield={setIsSelectedCustomerTextfield}
                    showSnackBar={showSnackBar}
                  />
                </Grid>
              </Grid>

            </form>
          }

        </>
      }
      <SnackbarComponent />
    </>
  )
}
